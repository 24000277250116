import React, { useCallback } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

import { LinkContainer } from './styles';
import {
	FiMic,
	FiFileText,
	FiCommand,
	FiCast,
	FiActivity,
	FiList,
	FiPlay,
	FiBarChart2,
	FiMusic,
	FiRadio
} from 'react-icons/fi';

const TalkNavigation = ({ collapsed }) => {
	const renderMenuTitle = useCallback(
		(title, Icon, to) =>
			to ? (
				<Link to={to}>
					{collapsed ? (
						<>
							<Icon style={{ fontSize: 18, marginBottom: -4 }} />
						</>
					) : (
						<LinkContainer>
							<Icon /> {title}
						</LinkContainer>
					)}
				</Link>
			) : collapsed ? (
				<>
					<Icon style={{ fontSize: 18, marginBottom: -4 }} />
				</>
			) : (
				<LinkContainer>
					<Icon /> {title}
				</LinkContainer>
			),
		[collapsed]
	);

	return (
		<Menu theme='dark' mode='inline' style={{ border: 'none' }}>
			<Menu.Item key='episodes' title='Episódios'>
				{renderMenuTitle('Episódios', FiPlay, '/commom/episodes')}
			</Menu.Item>

			<Menu.SubMenu key='radio-vignettes' title={renderMenuTitle('Vinhetas da Rádio', FiRadio)}>
				<Menu.Item key='my-contents'>
					<Link to='/commom/radio-vignettes'>Gerenciar Vinhetas</Link>
				</Menu.Item>

				<Menu.Item key='config-radio-vignettes'>
					<Link to='/commom/radio-vignettes/configs'>Configurar Cronogramas</Link>
				</Menu.Item>
			</Menu.SubMenu>

			<Menu.SubMenu key='contents' title={renderMenuTitle('Conteúdos', FiCast)}>
				<Menu.Item key='my-contents'>
					<Link to='/commom/programs'>Gerenciar programas</Link>
				</Menu.Item>

				<Menu.Item key='add-elements'>
					<Link to='/commom/elements/create'>Add vinhetas</Link>
				</Menu.Item>
			</Menu.SubMenu>

			<Menu.Item key='my-network' title='Minha Rede'>
				{renderMenuTitle('Minha Rede', FiCommand, '/commom/network')}
			</Menu.Item>

			<Menu.Item key='signatures' title='Assinaturas'>
				{renderMenuTitle('Assinaturas', FiActivity, '/commom/signatures')}
			</Menu.Item>

			<Menu.Item key='soundtracks' title='Trilhas sonoras'>
				{renderMenuTitle('Trilhas sonoras', FiBarChart2, '/commom/soundtracks')}
			</Menu.Item>

			<Menu.SubMenu key='narrations' title={renderMenuTitle('Vozes', FiMic)}>
				<Menu.Item key='offs'>
					<Link to='/commom/voices/offs'>Offs Rede</Link>
				</Menu.Item>
				<Menu.Item key='news'>
					<Link to='/commom/voices/news'>Notícias Rede</Link>
				</Menu.Item>
				<Menu.Item key='blitz'>
					<Link to='/commom/voices/blitz'>Blitz Rede</Link>
				</Menu.Item>
				<Menu.Item key='wpps'>
					<Link to='/commom/voices/wpps'>Wpps Rede</Link>
				</Menu.Item>
				<Menu.Item key='interviews'>
					<Link to='/commom/voices/interviews'>Entrevistas Rede</Link>
				</Menu.Item>
			</Menu.SubMenu>

			<Menu.Item key='track-presentations'>
				{renderMenuTitle('Apresentações Musicais', FiMusic, '/commom/track-presentations')}
			</Menu.Item>

			<Menu.SubMenu key='playlists' title={renderMenuTitle('Playlists', FiList)}>
				<Menu.Item key='playlist-details'>
					<Link to='/commom/playlists'>Gerenciar Playlists</Link>
				</Menu.Item>
				<Menu.Item key='weekly-scripts'>
					<Link to='/commom/playlists/weekly-scripts'>Modelos Semanais</Link>
				</Menu.Item>
			</Menu.SubMenu>

			<Menu.Item key='contracts' title='Contratos'>
				{renderMenuTitle('Contratos', FiFileText, '/commom/contracts')}
			</Menu.Item>
		</Menu>
	);
};

export default TalkNavigation;
