import React from 'react';
import { connect } from 'react-redux';

import AuthStack from './Auth.routes';
import AdminStack from './Adm.routes';
import CommomStack from './Commom.routes';
import TalkUserStack from './TalkUser.routes';
import MarketingStack from './Marketing.routes';
import ElementsManagerStack from './ElementsManager.routes';
import SignaturesManagerStack from './SignaturesManager.routes';
import TracksDbManagerStack from './TracksDbManager.routes';
import SellerStack from './Seller.routes';
import CasterStack from './Caster.routes';

const Routes = ({ user }) => {
	if (!user) {
		return <AuthStack />;
	}

	switch (user?.userLevel) {
		case 0:
			return <AdminStack />;
		case 1:
			return <TalkUserStack />;
		case 2:
			return <CommomStack />;
		case 3:
			return <ElementsManagerStack />;
		case 4:
			return <SignaturesManagerStack />;
		case 5:
			return <TracksDbManagerStack />;
		case 6:
			return <SellerStack />;
		case 7:
			return <CasterStack />;
		case 9:
			return <MarketingStack />;
		default:
			return <AuthStack />;
	}
};

export default connect(({ user }) => ({ user }))(Routes);
