import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { parseISO, format, addMonths } from 'date-fns';
import * as Yup from 'yup';
import {
	Table,
	Icon,
	Modal,
	Typography,
	Input,
	message,
	Avatar,
	Button,
	Divider,
	Select,
	Tag,
	Dropdown,
	Menu,
	Breadcrumb,
	Checkbox,
	Spin,
	Slider,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import Form from '../../../../components/Form';
import {
	Container,
	SearchContainer,
	AvatarCell,
	DetailsContainer,
	FilterContainer,
} from './styles';

import ScriptsAPI from '../../../../services/sdks/scripts';
import ProgramsAPI from '../../../../services/sdks/programs';
import ContractsAPI from '../../../../services/sdks/contracts';
import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';

import { useFormatter } from '../../../../hooks';
import { FiEdit2, FiPlusCircle, FiSave, FiXCircle } from 'react-icons/fi';

const extendDurations = {
	12: '1 ano',
	24: '2 anos',
	36: '3 anos',
	48: '4 anos',
	60: '5 anos',
};

const Contracts = () => {
	const toCurrencyFormat = useFormatter({ style: 'currency', currency: 'BRL' });

	const [fallback, setFallback] = useState({ initialData: true });
	const [handlingContract, setHandlingContract] = useState(null);
	const [contracts, setContracts] = useState([]);
	const [filteredContracts, setFilteredContracts] = useState([]);
	const [visibleModals, setVisibleModals] = useState({ details: false });
	const [filter, setFilter] = useState(null);
	const [programs, setPrograms] = useState([]);
	const [scripts, setScripts] = useState([]);
	const [extendDuration, setExtendDuration] = useState(6);

	const columns = [
		{
			title: 'Radio',
			dataIndex: 'hirer',
			key: 'hirer',
			render: (hirer) => `${hirer?.radioName} - ${hirer?.state}`,
		},
		{
			title: 'Locutor',
			dataIndex: 'caster',
			key: 'caster',
			render: (caster) => (
				<AvatarCell>
					<Avatar
						size='small'
						style={{ background: 'var(--primary)' }}
						src={resolveFileSrc({ fileName: caster?.profilePic })}
					>
						{caster?.name[0] || '-'} {caster?.surname[0] || '-'}
					</Avatar>
					<Typography.Text className='name'>
						{caster?.name} {caster?.surname}
					</Typography.Text>
				</AvatarCell>
			),
		},
		{
			title: 'Programa',
			dataIndex: 'program',
			key: 'program',
			render: (program) =>
				program ? (
					program?.name
				) : (
					<i style={{ textDecoration: 'line-through' }}>Programa excluído</i>
				),
		},
		{
			title: 'Modelo',
			dataIndex: 'script',
			key: 'script',
			render: (script) =>
				script ? script?.name : <i style={{ textDecoration: 'line-through' }}>Modelo excluído</i>,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			align: 'center',
			render: (status) => {
				switch (status) {
					case 3:
					case 0:
						return (
							<Tag color='gold'>
								<Icon type='clock-circle' style={{ marginRight: 4 }} /> Aguardando
							</Tag>
						);
					case 2:
						return (
							<Tag color='red'>
								<Icon type='close-circle' style={{ marginRight: 4 }} /> Encerrado
							</Tag>
						);
					default:
						return (
							<Tag color='green'>
								<Icon type='check-circle' style={{ marginRight: 4 }} /> Vigente
							</Tag>
						);
				}
			},
		},
		{
			title: 'ID',
			dataIndex: '_id',
			align: 'center',
			key: 'id',
			render: (_id) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					8
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Ações',
			key: 'actions',
			align: 'right',
			render: (contract) => (
				<Dropdown
					placement='bottomLeft'
					overlay={
						<Menu>
							<Menu.Item
								onClick={() => {
									setHandlingContract({
										...contract,
										program: contract?.program?._id || undefined,
										script: contract?.script?._id || undefined,
										weeklySchedule: contract?.weeklySchedule,
										playlistPrivilege: contract?.playlistPrivilege,
									});
									setVisibleModals({ ...visibleModals, edit: true });
								}}
							>
								<Icon type='edit' /> Editar
							</Menu.Item>
							<Menu.Item
								disabled={contract?.status !== 2}
								onClick={() => {
									setHandlingContract({
										...contract,
										program: contract?.program?._id || undefined,
										script: contract?.script?._id || undefined,
										weeklySchedule: contract?.weeklySchedule,
										playlistPrivilege: contract?.playlistPrivilege,
									});
									setVisibleModals({ ...visibleModals, extend: true });
								}}
							>
								<Icon type='retweet' /> Estender Contrato
							</Menu.Item>
							<Menu.Item
								onClick={() => {
									setHandlingContract(contract);
									setVisibleModals({ ...visibleModals, details: true });
								}}
							>
								<Icon type='eye' /> Mais detalhes
							</Menu.Item>

							<Menu.Divider />

							<Menu.Item
								disabled={contract?.status !== 1}
								className='ant-dropdown-menu-item-danger'
								onClick={() => {
									Modal.confirm({
										width: 460,
										title: 'Encerrar contrato?',
										icon: 'exclamation-circle',
										content:
											'O locutor não estara mais apto a enviar offs para esse programa, realmente deseja encerrar esse contrato?',
										okText: 'Encerrar contrato',
										onOk: () => handleDeleteContract(contract?._id),
										okButtonProps: {
											icon: 'stop',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}
							>
								<Icon type='close-circle' /> Encerrar
							</Menu.Item>
						</Menu>
					}
				>
					<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 12 }} type='more' />
				</Dropdown>
			),
		},
	];

	const handleSearch = useCallback(
		(search) => {
			if (!search) {
				return setFilteredContracts(contracts);
			}

			setFilteredContracts(
				contracts.filter((contract) => {
					const radioMatch = contract?.hirer?.radioName
						.toUpperCase()
						.includes(search.toUpperCase());
					const casterMatch = `${contract?.caster?.name} ${contract?.caster?.surname}`
						.toUpperCase()
						.includes(search.toUpperCase());
					const programMatch = contract?.program?.name.toUpperCase().includes(search.toUpperCase());

					return radioMatch || casterMatch || programMatch;
				})
			);
		},
		[contracts]
	);

	const handleDeleteContract = useCallback(async (contractId) => {
		try {
			await ContractsAPI.destroy(contractId);

			setContracts((prev) =>
				prev.map((contract) => {
					if (contract?._id === contractId) {
						return { ...contract, status: 2 };
					}

					return contract;
				})
			);

			setFilteredContracts((prev) =>
				prev.map((contract) => {
					if (contract?._id === contractId) {
						return { ...contract, status: 2 };
					}

					return contract;
				})
			);

			message.success('Contrato foi encerrado com sucesso');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, []);

	const handleUpdateContract = useCallback(async () => {
		try {
			const validationSchema = Yup.object().shape({
				program: Yup.string().required('Informe o programa'),
				script: Yup.string().required('Informe o modelo'),
			});

			await validationSchema.validate(handlingContract);

			setFallback((prev) => ({ ...prev, updatingContract: true }));

			const payload = {
				...handlingContract,
				hirer: handlingContract?.hirer?._id,
				caster: handlingContract?.caster?._id,
			};

			const {
				data: { contract: updatedContract },
			} = await ContractsAPI.update(handlingContract?._id, payload);

			updatedContract.hirer = handlingContract?.hirer;
			updatedContract.caster = handlingContract?.caster;
			updatedContract.program = programs.find((p) => p?._id === handlingContract?.program);
			updatedContract.script = scripts.find((s) => s?._id === handlingContract?.script);

			setVisibleModals((prev) => ({ ...prev, edit: false }));
			setHandlingContract(null);
			setContracts((prev) =>
				prev.map((c) => {
					return c?._id === handlingContract?._id ? updatedContract : c;
				})
			);

			setFilteredContracts((prev) =>
				prev.map((c) => {
					return c?._id === handlingContract?._id ? updatedContract : c;
				})
			);

			message.success('O contrato foi atualizado com sucesso');
		} catch (error) {
			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}

			console.error(error);
			message.error('Houve um erro, tente novamente');
		} finally {
			setFallback((prev) => ({ ...prev, updatingContract: false }));
		}
	}, [handlingContract, programs, scripts]);

	const handleExtendContratDuration = useCallback(async () => {
		try {
			setFallback((prev) => ({ ...prev, extendingContract: true }));

			const payload = {
				...handlingContract,
				status: 1,
				duration: extendDuration,
				signatureDate: new Date(),
				endingDate: addMonths(new Date(), extendDuration),
				restores: handlingContract.restores + 1,
			};

			const {
				data: { contract: updatedContract },
			} = await ContractsAPI.update(handlingContract?._id, payload);

			updatedContract.hirer = handlingContract?.hirer;
			updatedContract.caster = handlingContract?.caster;
			updatedContract.program = programs.find((p) => p?._id === handlingContract?.program);
			updatedContract.script = scripts.find((s) => s?._id === handlingContract?.script);

			setExtendDuration(6);
			setVisibleModals((prev) => ({ ...prev, extend: false }));
			setHandlingContract(null);
			setContracts((prev) =>
				prev.map((c) => {
					return c?._id === handlingContract?._id ? updatedContract : c;
				})
			);

			setFilteredContracts((prev) =>
				prev.map((c) => {
					return c?._id === handlingContract?._id ? updatedContract : c;
				})
			);

			message.success('O contrato foi extendido com sucesso');
		} catch (error) {
			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}

			console.error(error);
			message.error('Houve um erro, tente novamente');
		} finally {
			setFallback((prev) => ({ ...prev, extendingContract: false }));
		}
	}, [handlingContract, extendDuration, programs, scripts]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				setFallback((prev) => ({ ...prev, initialData: true }));

				const query = filter === null ? '' : `status=${filter}`;
				const {
					data: { contracts },
				} = await ContractsAPI.index({ query });

				const {
					data: { programs },
				} = await ProgramsAPI.index();

				setPrograms(programs);

				setContracts(contracts);
				setFilteredContracts(contracts);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro, tente novamente');
			}
		};

		fetchInitialData();
	}, [filter]);

	useEffect(() => {
		const fetchProgramScripts = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingScripts: true }));

				const query = `program=${handlingContract?.program}&originalScript=null`;
				const {
					data: { scripts },
				} = await ScriptsAPI.index({ query });

				setScripts(scripts);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os modelos do programa selecionado');
			} finally {
				setFallback((prev) => ({ ...prev, fetchingScripts: false }));
			}
		};

		if (handlingContract?.program && (visibleModals.edit || visibleModals.extend)) {
			fetchProgramScripts();
		}
	}, [handlingContract?.program, visibleModals]); //eslint-disable-line

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Contratos' />

			<Container>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[
						{ breadcrumbName: 'PAINEL ADMINISTRATIVO' },
						{ breadcrumbName: 'CONTRATOS' },
						{ breadcrumbName: 'GERENCIAR CONTRATOS' },
					]}
				/>

				<header>
					<Typography.Title level={2}>Gerenciar contratos</Typography.Title>
					<Link to='/admin/contracts/create-contract'>
						<Button type='primary'>
							<FiPlusCircle /> Novo contrato
						</Button>
					</Link>
				</header>

				<Divider />

				<SearchContainer>
					<Input.Search
						allowClear
						size='large'
						onSearch={handleSearch}
						style={{ width: '300px' }}
						placeholder='Buscar por rádio, programa ou locutor'
					/>
				</SearchContainer>

				<FilterContainer>
					<Select value={filter} onChange={(value) => setFilter(value)} style={{ width: 300 }}>
						<Select.Option value={null}>Exibir todos</Select.Option>
						<Select.Option value={1}>Ativos</Select.Option>
						<Select.Option value={2}>Inativos</Select.Option>
						<Select.Option value={0}>Aguardando assinatura</Select.Option>
						<Select.Option value={3}>Aguardando renovação</Select.Option>
					</Select>
				</FilterContainer>

				<Table
					size='middle'
					rowKey='_id'
					columns={columns}
					dataSource={filteredContracts}
					pagination={{ size: 'large', pageSize: 10, hideOnSinglePage: true }}
					style={{ border: 'none' }}
				/>
			</Container>

			<Modal
				visible={visibleModals?.details}
				title={
					<>
						<Icon type='file-text' style={{ marginRight: 8 }} /> Detalhes
					</>
				}
				footer={null}
				width={600}
				onCancel={() => {
					setHandlingContract(null);
					setVisibleModals((prev) => ({ ...prev, details: false }));
				}}
			>
				<DetailsContainer>
					<ul>
						<li>
							<span className='label'>Rádio</span>
							<strong>{handlingContract?.hirer?.radioName}</strong>
						</li>
						<li>
							<span className='label'>Locutor</span>
							<strong>
								{handlingContract?.caster?.name} {handlingContract?.caster?.surname}
							</strong>
						</li>
						<li>
							<span className='label'>Programa</span>
							<strong>{handlingContract?.program?.name}</strong>
						</li>
						<li>
							<span className='label'>Status</span>
							<strong>
								<Tag
									color={
										[0, 3].includes(handlingContract?.status)
											? 'gold'
											: handlingContract?.status === 2
												? 'red'
												: 'green'
									}
								>
									{[0, 3].includes(handlingContract?.status)
										? 'Aguardando'
										: handlingContract?.status === 2
											? 'Encerrado'
											: 'Vigente'}
								</Tag>
							</strong>
						</li>
						<li>
							<span className='label'>Valor</span>
							<strong>
								{handlingContract?.value ? toCurrencyFormat(handlingContract?.value / 100) : '-'}
							</strong>
						</li>

						<li>
							<span className='label'>Número de renovações</span>
							<strong>{handlingContract?.restores || '-'}</strong>
						</li>
						<li>
							<span className='label'>Assinado em</span>
							<strong>
								{handlingContract?.signatureDate
									? format(parseISO(handlingContract?.signatureDate), 'dd/MM/yyyy')
									: '-'}
							</strong>
						</li>
						<li style={{ border: 'none' }}>
							<span className='label'>Expira em</span>
							<strong>
								{handlingContract?.endingDate
									? format(parseISO(handlingContract?.endingDate), 'dd/MM/yyyy')
									: '-'}
							</strong>
						</li>
					</ul>
				</DetailsContainer>
			</Modal>

			<Modal
				closable={!fallback?.updatingContract}
				visible={visibleModals?.edit}
				title={
					<>
						<FiEdit2 /> Editar contrato
					</>
				}
				okText={
					<>
						<FiSave /> Salvar alterações
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				width={600}
				okButtonProps={{ loading: fallback?.updatingContract }}
				cancelButtonProps={{ disabled: fallback?.updatingContract }}
				onOk={handleUpdateContract}
				onCancel={() => {
					setHandlingContract(null);
					setVisibleModals((prev) => ({ ...prev, edit: false }));
				}}
			>
				<Form.Container>
					<Form.Item label='Programa'>
						<Spin spinning={fallback?.fetchingPrograms ? true : false}>
							<Select
								showSearch
								optionFilterProp='children'
								placeholder='Selecione o programa'
								value={handlingContract?.program}
								onChange={(value) => {
									setHandlingContract({ ...handlingContract, script: undefined, program: value });
								}}
								filterOption={(input, { props: { _search } }) => {
									const regex = new RegExp(input, 'i');

									return _search.match(regex);
								}}
							>
								{programs.map((program) => (
									<Select.Option
										_search={`${program?.name}${program?.userId?.radioName}${program?.userId?.city}${program?.userId?.state}`}
										key={program._id}
										value={program?._id}
									>
										<div style={{ display: 'flex' }}>
											<span style={{ fontSize: 14 }}>{program.name}</span>
											<span style={{ margin: '0 12px' }}>-</span>
											<span style={{ fontSize: 12, opacity: 0.8 }}>
												{program?.userId?.radioName} ({program?.userId?.city}/
												{program?.userId?.state})
											</span>
										</div>
									</Select.Option>
								))}
							</Select>
						</Spin>
					</Form.Item>

					<Form.Item label='Modelo'>
						<Spin spinning={fallback?.fetchingScripts ? true : false}>
							<Select
								showSearch
								optionFilterProp='children'
								placeholder='Selecione o modelo do programa'
								value={handlingContract?.script}
								onChange={(value) => {
									setHandlingContract({ ...handlingContract, script: value });
								}}
								filterOption={(input, { props: { _search } }) => {
									const regex = new RegExp(input, 'i');

									return _search.match(regex);
								}}
							>
								{scripts.map((script) => (
									<Select.Option _search={script?.name} key={script._id} value={script?._id}>
										{script.name}
									</Select.Option>
								))}
							</Select>
						</Spin>
					</Form.Item>

					<Form.Item label='Geração de playlists'>
						<Checkbox
							checked={handlingContract?.playlistPrivilege}
							onChange={({ target: { checked } }) =>
								setHandlingContract({ ...handlingContract, playlistPrivilege: checked })
							}
						>
							Permitir que o locutor gere playlists
						</Checkbox>
					</Form.Item>

					<Form.Item label='Agenda semanal'>
						<Checkbox
							style={{ marginLeft: 8 }}
							checked={handlingContract?.weeklySchedule?.monday}
							onChange={({ target: { checked } }) =>
								setHandlingContract({
									...handlingContract,
									weeklySchedule: { ...handlingContract?.weeklySchedule, monday: checked },
								})
							}
						>
							Segunda-Feira
						</Checkbox>

						<Checkbox
							checked={handlingContract?.weeklySchedule?.tuesday}
							onChange={({ target: { checked } }) =>
								setHandlingContract({
									...handlingContract,
									weeklySchedule: { ...handlingContract?.weeklySchedule, tuesday: checked },
								})
							}
						>
							Terça-Feira
						</Checkbox>

						<Checkbox
							checked={handlingContract?.weeklySchedule?.wednesday}
							onChange={({ target: { checked } }) =>
								setHandlingContract({
									...handlingContract,
									weeklySchedule: { ...handlingContract?.weeklySchedule, wednesday: checked },
								})
							}
						>
							Quarta-Feira
						</Checkbox>

						<Checkbox
							checked={handlingContract?.weeklySchedule?.thursday}
							onChange={({ target: { checked } }) =>
								setHandlingContract({
									...handlingContract,
									weeklySchedule: { ...handlingContract?.weeklySchedule, thursday: checked },
								})
							}
						>
							Quinta-Feira
						</Checkbox>

						<Checkbox
							checked={handlingContract?.weeklySchedule?.friday}
							onChange={({ target: { checked } }) =>
								setHandlingContract({
									...handlingContract,
									weeklySchedule: { ...handlingContract?.weeklySchedule, friday: checked },
								})
							}
						>
							Sexta-Feira
						</Checkbox>

						<Checkbox
							checked={handlingContract?.weeklySchedule?.saturday}
							onChange={({ target: { checked } }) =>
								setHandlingContract({
									...handlingContract,
									weeklySchedule: { ...handlingContract?.weeklySchedule, saturday: checked },
								})
							}
						>
							Sábado
						</Checkbox>

						<Checkbox
							checked={handlingContract?.weeklySchedule?.sunday}
							onChange={({ target: { checked } }) =>
								setHandlingContract({
									...handlingContract,
									weeklySchedule: { ...handlingContract?.weeklySchedule, sunday: checked },
								})
							}
						>
							Domingo
						</Checkbox>
					</Form.Item>
				</Form.Container>
			</Modal>

			<Modal
				closable={!fallback?.extendingContract}
				visible={visibleModals?.extend}
				title={
					<>
						<FiEdit2 /> Estender duração do contrato
					</>
				}
				okText={
					<>
						<FiSave /> Salvar alterações
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				width={600}
				okButtonProps={{ loading: fallback?.extendingContract }}
				cancelButtonProps={{ disabled: fallback?.extendingContract }}
				onOk={handleExtendContratDuration}
				onCancel={() => {
					setHandlingContract(null);
					setVisibleModals((prev) => ({ ...prev, extend: false }));
				}}
			>
				<Form.Container>
					<Form.Item label='Duração' help='Selecione por quanto tempo deseja estender o contrato'>
						<Slider
							min={6}
							max={60}
							value={extendDuration}
							tipFormatter={(value) =>
								extendDurations[value] ? extendDurations[value] : `${value} meses`
							}
							onChange={(value) => setExtendDuration(value)}
							style={{ width: '100%' }}
							marks={extendDurations}
						/>
					</Form.Item>
				</Form.Container>
			</Modal>
		</>
	);
};

export default Contracts;
