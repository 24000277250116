import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import CreateEpisode from '../pages/Commom/Episodes/CreateEpisode';
import CreatedEpisode from '../pages/Commom/Episodes/CreatedEpisode';

import NotFound from '../pages/Exceptions/_404';

import AppLayout from '../components/Layout';

const MarketingStack = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path='/'>
				<Redirect to='/commom/episodes/create-episode' />
			</Route>

			<Route exact path='/login'>
				<Redirect to='/commom/episodes/create-episode' />
			</Route>

			<Route exact path='/commom'>
				<Redirect to='/commom/episodes/create-episode' />
			</Route>

			<Route exact path='/commom/episodes/create-episode'>
				<AppLayout>
					<CreateEpisode />
				</AppLayout>
			</Route>

			<Route exact path='/commom/episodes/:episodeId/details'>
				<AppLayout>
					<CreatedEpisode />
				</AppLayout>
			</Route>

			<Route exact path='*'>
				<NotFound />
			</Route>
		</Switch>
	</BrowserRouter>
);

export default MarketingStack;
