import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import {
	Table,
	Button,
	Select,
	message,
	Typography,
	Breadcrumb,
	Card,
	Divider,
	Input,
	Steps,
	Alert,
	Avatar,
	Result,
} from 'antd';

import Meta from '../../../../components/Meta';
import Form from '../../../../components/Form';
import Fallback from '../../../../components/Fallback';
import PlayCell from '../../../../components/PlayCell';
import {
	PageHeader,
	CardContainer,
	TableHeader,
	UploadsZonesContainer,
	CastersList,
	CastersItem,
	SearchCasterContainer,
	ResultButtons,
} from './styles';

import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';
import { FiArrowRight, FiUpload } from 'react-icons/fi';

import { useFilesValidator, usePlayer } from '../../../../hooks';
import TrackPresentationsAPI from '../../../../services/sdks/trackPresentations';
import FileDuration from '../../../../components/FileDuration';
import FileExt from '../../../../components/FileExt';
import CategoriesAPI from '../../../../services/sdks/categories';
import TracksAPI from '../../../../services/sdks/tracks';
import CastersApi from '../../../../services/sdks/caster';
import FilesUploader from '../../../../components/FilesUploader';

const DEFAULT_PAGINATION = { current: 1, pageSize: 10, total: null };
const DEAFULT_FILTERS = {
	categoryId: undefined,
	artist: '',
	name: '',
	forDownload: '',
};

const CreateTrackPresentations = ({ user }) => {
	const callsUploaderRef = useRef();
	const postsUploaderRef = useRef();
	const { hasValidationError } = useFilesValidator();
	const player = usePlayer();
	const [step, setStep] = useState(() => (user.userLevel === 7 ? 1 : 0));
	const [fallback, setFallback] = useState({ initialData: true });
	const [categories, setCategories] = useState([]);
	const [casterId, setCasterId] = useState(null);
	const [casters, setCasters] = useState([]);
	const [tracks, setTracks] = useState([]);
	const [selectedTracks, setSelectedTracks] = useState([]);
	const [casterSearch, setCasterSearch] = useState('');
	const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
	const [filters, setFilters] = useState(DEAFULT_FILTERS);
	const [isValidatingCalls, setIsValidatingCalls] = useState(false);
	const [isValidatingPosts, setIsValidatingPosts] = useState(false);
	const [calls, setCalls] = useState([]);
	const [posts, setPosts] = useState([]);

	const [filtersStates, setFiltersStates] = useState({
		categoryId: undefined,
		artist: '',
		name: '',
	});

	const columns = [
		{
			key: 'meta',
			title: 'Título',
			render: (track) => (
				<PlayCell
					meta={{ primary: track?.name, secondary: track?.artist }}
					onPlay={() => {
						player.start({
							src: resolveFileSrc({ fileName: track.filename }),
							ref: track?._id,
							meta: { name: track?.name, artist: track?.artist },
						});
					}}
					onPause={player.resume}
					isPlaying={player?.ref === track?._id && player?.isPlaying}
				/>
			),
		},
		{ key: 'category', title: 'Categoria', render: (music) => music?.categoryId?.name || '-' },
		{
			key: 'duration',
			title: 'Duração',
			align: 'center',
			render: (track) => <FileDuration src={resolveFileSrc({ fileName: track.filename })} />,
		},
		{
			key: 'ext',
			title: 'Formato',
			align: 'center',
			render: (track) => <FileExt src={resolveFileSrc({ fileName: track.filename })} />,
		},
	];

	const resetState = useCallback(() => {
		setStep(user.userLevel === 7 ? 1 : 0);
		setFallback({ initialData: false });
		setFilters(DEAFULT_FILTERS);
		setPagination(DEFAULT_PAGINATION);
		setSelectedTracks([]);
		setCasterSearch('');
		setCasterId(null);
		setCalls([]);
		setPosts([]);
	}, [user]);

	const handleSubmit = useCallback(async () => {
		try {
			if (hasValidationError(calls) || hasValidationError(posts)) {
				return message.warning(
					'Alguns dos arquivos selecionados estão corrompidos. Por favor, substitua-os por arquivos válidos.'
				);
			}

			const validationSchema = Yup.object().shape({
				tracks: Yup.array().of(Yup.string()).required('Informe as músicas'),
				type: Yup.string().required('Informe o tipo'),
				casterId: Yup.string().required('Informe o programa'),
				files: Yup.array().required('Selecione os arquivos'),
			});

			setFallback((prev) => ({ ...prev, uploading: true }));

			if (calls.length) {
				const callsPayload = {
					type: 'CALL',
					casterId,
					files: calls.map((c) => c.data),
					tracks: JSON.stringify(selectedTracks),
				};

				await validationSchema.validate(callsPayload);
				const formData = new FormData();

				for (const key in callsPayload) {
					if (key === 'files') {
						callsPayload[key].forEach((file) => formData.append('file', file));
					} else {
						formData.append(key, String(callsPayload[key]));
					}
				}

				await TrackPresentationsAPI.store({
					payload: formData,
				});
			}

			if (posts.length) {
				const postsPayload = {
					type: 'POST',
					casterId,
					files: posts.map((p) => p.data),
					tracks: JSON.stringify(selectedTracks),
				};

				await validationSchema.validate(postsPayload);
				const formData = new FormData();

				for (const key in postsPayload) {
					if (key === 'files') {
						postsPayload[key].forEach((file) => formData.append('file', file));
					} else {
						formData.append(key, postsPayload[key]);
					}
				}

				await TrackPresentationsAPI.store({
					payload: formData,
				});
			}

			setStep(3);
		} catch (error) {
			message.error('Houve um erro, tente novamente');
		}

		setFallback((prev) => ({ ...prev, uploading: false }));
	}, [calls, posts, selectedTracks, casterId, hasValidationError]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { categories },
				} = await CategoriesAPI.index();

				setCategories(categories);

				if (user.userLevel === 7) {
					setCasterId(user.caster._id);
				} else {
					const {
						data: { casters },
					} = await CastersApi.index();

					setCasters(casters);
				}
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar as categorias');
			} finally {
				setFallback((prev) => ({ ...prev, initialData: false }));
			}
		};

		fetchInitialData();
	}, [user]);

	useEffect(() => {
		const fetchDbTracks = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingTracks: true }));

				let query = `page=${pagination?.current - 1}&limit=${pagination?.pageSize}&active=true`;
				let { categoryId, artist, name } = filters;

				artist = artist.replace('&', '|');
				name = name.replace('&', '|');
				categoryId && (query = `${query}&categoryId=${categoryId}`);
				artist.length && (query = `${query}&artist=__REGEX__${artist}`);
				name && (query = `${query}&name=__REGEX__${name}`);

				const {
					data: { tracks, total },
				} = await TracksAPI.index({ query });

				setTracks(tracks);
				setPagination((prev) => ({ ...prev, total }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar as músicas.');
			} finally {
				setFallback((prev) => ({ ...prev, fetchingTracks: false }));
			}
		};

		fetchDbTracks();
	}, [filters, pagination?.current, pagination?.pageSize]); //eslint-disable-line

	if (fallback.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Apresentações Musicais' />

			<PageHeader>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[
						{ breadcrumbName: 'INÍCIO' },
						{ breadcrumbName: 'APRESENTAÇÕES MUSICAIS' },
						{ breadcrumbName: 'CRIAR APRESENTAÇÕES MUSICAIS' },
					]}
				/>

				<header>
					<Typography.Title level={2}>Criar Apresentações Musicais</Typography.Title>
				</header>
			</PageHeader>

			<CardContainer>
				<Card className='_card'>
					<Steps
						current={user.userLevel === 7 ? step - 1 : step}
						style={{ marginBottom: 32 }}
						labelPlacement='vertical'>
						{user.userLevel !== 7 && <Steps.Step title='Locutor' />}
						<Steps.Step title='Músicas' />
						<Steps.Step title='Arquivos' />
						<Steps.Step title='Confirmação' />
					</Steps>

					{step === 0 && (
						<>
							<SearchCasterContainer>
								<div className='title-container'>
									<Typography.Title style={{ marginBottom: 0 }} level={3}>
										Selecione o Locutor
									</Typography.Title>
									<Typography.Text>
										Informe o locutor para o qual deseja cadastrar as apresentações musicais
									</Typography.Text>
								</div>
								<Input.Search
									size='large'
									placeholder='Buscar por nome do locutor'
									style={{ width: 400, height: 'max-content' }}
									onSearch={(value) => setCasterSearch(value)}
								/>
							</SearchCasterContainer>

							<CastersList>
								{casters
									.filter((c) =>
										`${c.name} ${c.surname}`.toUpperCase().includes(casterSearch.toUpperCase())
									)
									.map((caster) => (
										<CastersItem
											key={caster._id}
											onClick={() => setCasterId(caster._id)}
											selected={casterId === caster._id}>
											<Avatar
												size='large'
												style={{ background: 'var(--primary)' }}
												src={resolveFileSrc({
													fileName: caster?.profilePic,
												})}>
												{caster?.name[0] || ''} {caster?.surname[0] || ''}
											</Avatar>
											<div className='caster-infos'>
												<strong>
													{caster.name} {caster.surname}
												</strong>

												<small>
													{caster.city} - {caster.state}
												</small>
												{/* <span>
												{}
											</span> */}
											</div>
										</CastersItem>
									))}
							</CastersList>

							<Divider />

							<div className='align-end'>
								<Button onClick={() => setStep(1)} size='large' type='primary' disabled={!casterId}>
									Selecionar Músicas <FiArrowRight />
								</Button>
							</div>
						</>
					)}

					{step === 1 && (
						<>
							<Alert
								showIcon
								closable
								type='warning'
								style={{ marginBottom: 32 }}
								message='Importante!'
								description={
									<span>
										Certifique-se de selecionar somente músicas do mesmo artista e de mesmo nome, só
										assim a TalkPlay conseguirá inserir as falas corretamente no momento da geração.
										Para facilitar, você pode utilizar os campos abaixo para filtrar somente às
										musicas que deseja. <br /> <br />
										<span>
											<span style={{ marginRight: 12 }}>*Exemplo de Pesquisa:</span>
											<strong>
												<span style={{ fontWeight: 500, marginRight: 6 }}>Artista:</span>
												<i>"4 NON BLONDES"</i>
											</strong>
											<span style={{ margin: '0 16px' }}>&</span>
											<strong>
												<span style={{ fontWeight: 500, marginRight: 6 }}>Nome da Música:</span>
												<i>"Whats Up"</i>
											</strong>
										</span>
									</span>
								}
							/>

							<Form.Container layout='3fr 2.5fr 2.5fr 2fr'>
								<Form.Item label='Filtrar por categoria'>
									<Select
										showSearch
										disabled={fallback?.fetchingTracks}
										placeholder='Selecione uma categoria'
										value={filters?.categoryId}
										onChange={(categoryId) => setFilters({ ...filters, categoryId })}
										filterOption={(input, { props: { children } }) => {
											return String(children).match(new RegExp(input, 'i'));
										}}>
										<Select.Option value=''>TODAS</Select.Option>

										{categories.map((category) => (
											<Select.Option value={category?._id} key={category?._id}>
												{category?.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item label='Buscar por artista' help='Informe no mínimo 3 caracteres'>
									<Input.Search
										allowClear
										disabled={fallback?.fetchingTracks}
										placeholder='Digite o nome do artista'
										value={filtersStates?.artist}
										onChange={({ target: { value: artist } }) => {
											setFiltersStates({ ...filtersStates, artist });

											if (!artist) {
												setFilters({ ...filters, artist: '' });
											}
										}}
										onSearch={(artist) => setFilters({ ...filters, artist })}
									/>
								</Form.Item>
								<Form.Item label='Buscar por nome da música' help='Informe no mínimo 3 caracteres'>
									<Input.Search
										allowClear
										disabled={fallback?.fetchingTracks}
										placeholder='Digite o nome da música'
										value={filtersStates?.name}
										onChange={({ target: { value: name } }) => {
											setFiltersStates({ ...filtersStates, name });

											if (!name) {
												setFilters({ ...filters, name: '' });
											}
										}}
										onSearch={(name) => setFilters({ ...filters, name })}
									/>
								</Form.Item>
							</Form.Container>

							<Divider />

							<TableHeader>
								<div className='actions'>
									<span>
										Quantidade: <strong>{pagination?.dbTracks?.total}</strong>
									</span>
								</div>

								<Select
									// style={{ width: 300 }}
									size='small'
									value={pagination?.pageSize}
									onChange={(pageSize) => setPagination({ ...pagination, pageSize })}>
									<Select.Option value={5}>5 itens por página</Select.Option>
									<Select.Option value={10}>10 itens por página</Select.Option>
									<Select.Option value={20}>20 itens por página</Select.Option>
									<Select.Option value={50}>50 itens por página</Select.Option>
									<Select.Option value={100}>100 itens por página</Select.Option>
									<Select.Option value={500}>500 itens por página</Select.Option>
								</Select>
							</TableHeader>

							<Table
								rowKey='_id'
								size='small'
								columns={columns}
								dataSource={tracks}
								style={{ border: 'none' }}
								loading={fallback?.fetchingTracks}
								pagination={{
									...pagination,
									size: 'large',
									onChange: (current) => setPagination({ ...pagination, current }),
								}}
								rowSelection={{
									onChange: (selectedTrackIds) => {
										setSelectedTracks(selectedTrackIds);
									},
								}}
							/>

							<Divider />

							<div className='align-end'>
								<Button
									onClick={() => setStep(2)}
									size='large'
									type='primary'
									disabled={!selectedTracks.length}>
									Selecionar Arquivos <FiArrowRight />
								</Button>
							</div>
						</>
					)}

					{step === 2 && (
						<>
							<Typography.Paragraph>
								Você selecionou {selectedTracks.length} músicas.
							</Typography.Paragraph>

							<Divider />

							<UploadsZonesContainer>
								<div>
									<FilesUploader
										multiple
										ref={callsUploaderRef}
										onChange={(files) => setCalls(files)}
										onStartValidation={() => setIsValidatingCalls(true)}
										onEndValidation={() => setIsValidatingCalls(false)}
										validTypes={['.mp3', '.wav']}
									/>
								</div>

								<div>
									<FilesUploader
										multiple
										ref={postsUploaderRef}
										onChange={(files) => setPosts(files)}
										onStartValidation={() => setIsValidatingPosts(true)}
										onEndValidation={() => setIsValidatingPosts(false)}
										validTypes={['.mp3', '.wav']}
									/>
								</div>
							</UploadsZonesContainer>

							<Divider />

							<div className='align-end'>
								<Button
									onClick={() => handleSubmit()}
									size='large'
									type='primary'
									loading={fallback?.uploading}
									disabled={
										isValidatingCalls || isValidatingPosts || (!calls.length && !posts.length)
									}>
									Fazer Upload <FiUpload />
								</Button>
							</div>
						</>
					)}

					{step === 3 && (
						<Result
							status='success'
							title='Sucesso'
							subTitle='Elementos criados com sucesso'
							extra={
								<ResultButtons>
									<Link to='/commom/track-presentations'>
										<Button>Ir Para Listagem</Button>
									</Link>
									<Button type='primary' onClick={resetState}>
										Cadastrar Novos Elementos
									</Button>
								</ResultButtons>
							}
						/>
					)}
				</Card>
			</CardContainer>
		</>
	);
};

export default connect(({ user }) => ({ user }))(CreateTrackPresentations);
