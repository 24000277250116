import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Breadcrumb,
	Button,
	Divider,
	Dropdown,
	Icon,
	Menu,
	Modal,
	Select,
	Table,
	Tooltip,
	Typography,
	message,
} from 'antd';
import { connect } from 'react-redux';
import { format } from 'date-fns-tz';
import { ptBR } from 'date-fns/locale';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import Form from '../../../../components/Form';
import RadioVignetteConfigsAPI from '../../../../services/sdks/radio-vignette-configs';
import { ConfigContainer, Container, TableHeader } from './styles';
import { FiDownload, FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import { IoAlertCircle } from 'react-icons/io5';
import RadioVignettesAPI from '../../../../services/sdks/radio-vignettes';
import { useDownload, useFilesValidator, usePlayer, useVignette, useZip } from '../../../../hooks';
import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';
import PageSizeHandler from '../../../../components/PageSizeHandle';
import {
	RadioVignetteTypes,
	RadioVignetteTypesColors,
	RadioVignetteTypesTranslation,
} from '../../../../enums/radio-vignette-types';
import { VignetteExecutionModes } from '../../../../enums/vignette-execution-modes';
import { VignetteSpeechModes } from '../../../../enums/vignette-speech-modes';

import SelectOptWithBadge from '../../../../components/SelectOptWithBadge';
import FilesUploader from '../../../../components/FilesUploader';

const breadcrumbs = [
	{ breadcrumbName: 'INÍCIO' },
	{ breadcrumbName: 'VINHETAS DA RÁDIO' },
	{ breadcrumbName: 'GERENCIAR VINHETAS' },
];

const defaultFallback = {
	initialData: true,
	fetchingVignettes: true,
	creatingVignette: false,
	downloadingMany: false,
	deletingVignettes: false,
};

const defaultFilters = {
	executionMode: undefined,
	speechMode: undefined,
	type: undefined,
	configId: undefined,
};

const defaultNewVignettesPayload = {
	...defaultFilters,
	files: [],
};

const defaultPagination = { current: 0, pageSize: 10, total: null };

const ListRadioVignettes = ({ user }) => {
	const { hasValidationError } = useFilesValidator();
	const [newVignettes, setNewVignettes] = useState(defaultNewVignettesPayload);
	const [vignettes, setVignettes] = useState([]);
	const [selectedVignettes, setSelectedVignettes] = useState([]);
	const [pagination, setPagination] = useState(defaultPagination);
	const [fallback, setFallback] = useState(defaultFallback);
	const [showCreateVignetteModal, setShowCreateVignetteModal] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [typesOptions, setTypesOptions] = useState({ staticTypes: [], configsTypes: [] });

	const zip = useZip();
	const player = usePlayer();
	const download = useDownload();

	const isFormValid = useMemo(() => {
		return (
			(newVignettes.configId || newVignettes.type) &&
			newVignettes.executionMode &&
			newVignettes.speechMode &&
			newVignettes.files.length
		);
	}, [newVignettes]);

	const { getColor, translateExecutionMode, translateSpeechMode, translateType } = useVignette();

	const columns = [
		{
			title: 'Tipo',
			key: 'type',
			render: (vignette) => (
				<SelectOptWithBadge
					color={getColor(vignette)}
					label={translateType(vignette)?.toUpperCase()}
				/>
			),
		},
		{
			title: 'Categoria',
			key: 'speechMode',
			render: (vignette) => translateSpeechMode(vignette.speechMode),
		},
		{
			title: 'Velocidade',
			key: 'executionMode',
			render: (vignette) => translateExecutionMode(vignette.executionMode),
		},
		{
			title: 'Cronograma',
			key: 'config',
			render: (vignette) => (
				<ConfigContainer>
					<Tooltip
						title={
							vignette.config
								? 'Significa que a vinheta entrará na sua programação somente durante o período indicado'
								: 'Significa que a vinheta estará na sua programa independente do período do ano'
						}>
						<IoAlertCircle style={{ color: 'var(--primary-opacity-85)' }} className='help-icon' />
					</Tooltip>

					{vignette.config ? renderVignetteSchedule(vignette) : 'Contínuo'}
				</ConfigContainer>
			),
		},
		{
			title: 'Ações',
			align: 'right',
			key: 'options',
			render: (vignette) => (
				<>
					<Dropdown
						placement='bottomRight'
						overlay={
							<Menu>
								<Menu.Item
									key='play'
									onClick={() => {
										player.start({
											src: resolveFileSrc({ fileName: vignette.filename }),
											ref: vignette._id,
											meta: {
												name: translateType(vignette),
												artist: `${translateSpeechMode(vignette)} - ${translateExecutionMode(
													vignette
												)}`,
											},
										});
									}}>
									<Icon type='play-circle' /> Reproduzir
								</Menu.Item>
								<Menu.Item key='download' onClick={() => downloadSingle(vignette)}>
									<Icon type='download' /> Fazer download
								</Menu.Item>

								<Menu.Divider />

								<Menu.Item
									key='delete'
									className='ant-dropdown-menu-item-danger'
									onClick={() => {
										Modal.confirm({
											title: 'Excluir off?',
											icon: 'exclamation-circle',
											content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
											okText: 'Excluir',
											onOk: () => handleDeleteVignette([vignette._id]),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}>
									<Icon type='delete' /> Excluir
								</Menu.Item>
							</Menu>
						}>
						<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 16 }} type='more' />
					</Dropdown>
				</>
			),
		},
	];

	const getVignetteDownloadName = useCallback(
		(vignette) => {
			return `${translateType(vignette)} (${translateSpeechMode(
				vignette
			)} - ${translateExecutionMode(vignette)})`;
		},
		[translateExecutionMode, translateSpeechMode, translateType]
	);

	const downloadSingle = useCallback(
		async (vignette) => {
			try {
				return download({
					filename: vignette.filename,
					name: getVignetteDownloadName(vignette),
				});
			} catch (error) {
				console.error(error);
				message.error('Não foi possível fazer o download.');
			}
		},
		[download, getVignetteDownloadName]
	);

	const downloadMany = useCallback(async () => {
		setFallback((prev) => ({ ...prev, downloadingMany: true }));

		const zipName = `Vinhetas da Rádio`;
		const zipData = selectedVignettes.map((vignette, index) => ({
			url: resolveFileSrc({ fileName: vignette.filename }),
			name: `${index + 1} - ${getVignetteDownloadName(vignette)}`,
		}));

		await zip.download(zipData, zipName);
		setFallback((prev) => ({ ...prev, downloadingMany: false }));
	}, [selectedVignettes, zip, getVignetteDownloadName]);

	const parseScheduleConfig = useCallback((config) => {
		const date = new Date().setMonth(Number(config.month));
		const day = String(config.day).padStart(2, '0');
		let month = format(date, 'MMMM', { locale: ptBR });
		month = `${month.charAt(0).toUpperCase()}${month.slice(1)}`;
		return `${day} de ${month}`;
	}, []);

	const renderVignetteSchedule = useCallback(
		(vignette) => {
			const startsAt = parseScheduleConfig(vignette.config.startsAt);
			const endsAt = parseScheduleConfig(vignette.config.endsAt);
			return `De ${startsAt} até ${endsAt}`;
		},
		[parseScheduleConfig]
	);

	const fetchVignettes = useCallback(async () => {
		setFallback((prev) => ({ ...prev, fetchingVignettes: true }));

		const params = {
			userId: user._id,
			page: pagination.current,
			limit: pagination.pageSize,
			...filters,
		};

		try {
			const response = await RadioVignettesAPI.list(params);
			setVignettes(response.data.radioVignettes);
			setPagination((prev) => ({ ...prev, total: response.data.total }));
			setFallback((prev) => ({ ...prev, initialData: false, fetchingVignettes: false }));
		} catch (error) {
			message.error('Houve um erro ao carregar as configurações de cronograma');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination.pageSize, pagination.current, user, filters]);

	useEffect(() => {
		fetchVignettes();
	}, [user, fetchVignettes]);

	const initTypesOptions = useCallback((configs) => {
		const staticTypes = Object.values(RadioVignetteTypes).map((t) => ({
			value: t,
			label: (
				<SelectOptWithBadge
					color={RadioVignetteTypesColors[t]}
					label={RadioVignetteTypesTranslation[t].toUpperCase()}
				/>
			),
		}));

		const configsTypes = configs.map((c) => ({
			value: c._id,
			label: <SelectOptWithBadge color={c.color} label={c.name.toUpperCase()} />,
		}));

		setTypesOptions({ staticTypes, configsTypes });
	}, []);

	useEffect(() => {
		const fetchConfigs = async () => {
			try {
				const response = await RadioVignetteConfigsAPI.list(user._id, 0, 100);
				initTypesOptions(response.data.radioVignetteConfigs);
			} catch (error) {
				message.error('Houve um erro ao carregar as configurações de cronograma');
			}
		};

		fetchConfigs();
	}, [initTypesOptions, user]);

	const onChangeType = useCallback((value, setFn) => {
		if (!value) {
			return setFn((prev) => ({ ...prev, configId: undefined, type: undefined }));
		}

		if (Object.values(RadioVignetteTypes).includes(value)) {
			setFn((prev) => ({ ...prev, configId: undefined, type: value }));
		} else {
			setFn((prev) => ({ ...prev, configId: value, type: undefined }));
		}
	}, []);

	const handleDeleteVignette = useCallback(async (ids) => {
		setFallback((prev) => ({ ...prev, deletingVignettes: true }));

		try {
			await RadioVignettesAPI.delete(ids);
			setVignettes((prev) => prev.filter((v) => !ids.includes(v._id)));
			Modal.destroyAll();
			message.success(
				`${ids.length === 1 ? 'A vinheta foi excluída' : 'As vinhetas foram excluídas'} com sucesso`
			);
		} catch (error) {
			message.error('Houve um erro, tente novamente');
		}

		setFallback((prev) => ({ ...prev, deletingVignettes: false }));
	}, []);

	const onSelect = useCallback((vignette, selected) => {
		if (selected) {
			setSelectedVignettes((prev) => [...prev, vignette]);
		} else {
			setSelectedVignettes((prev) => prev.filter((v) => v._id !== vignette._id));
		}
	}, []);

	const onSelectAll = useCallback((selected, vignettes) => {
		if (selected) {
			setSelectedVignettes((prev) => Array.from(new Set([...prev, ...vignettes])));
		} else {
			setSelectedVignettes([]);
		}
	}, []);

	const handleCreateVignette = useCallback(async () => {
		if (hasValidationError(newVignettes.files)) {
			return message.warning(
				'Alguns dos arquivos selecionados estão corrompidos. Por favor, substitua-os por arquivos válidos.'
			);
		}

		setFallback((prev) => ({ ...prev, creatingVignette: true }));

		try {
			const payload = new FormData();
			payload.append('userId', user._id);

			for (const key in newVignettes) {
				const value = newVignettes[key];

				if (value) {
					if (Array.isArray(value)) {
						value.forEach((item) => payload.append(key, item.data));
					} else {
						payload.append(key, value);
					}
				}
			}

			await RadioVignettesAPI.create(payload);
			message.success('Vinhetas criadas com sucesso', 3, () => window.location.reload());
		} catch (error) {
			message.error('Houve um erro ao subir as vinhetas');
		}

		setFallback((prev) => ({ ...prev, creatingVignette: false }));
	}, [hasValidationError, newVignettes, user._id]);

	const renderFormSelects = useCallback(
		({ typeConfig, speechModeConfig, executionModeConfig }) => {
			return (
				<>
					<Form.Item label={typeConfig.label}>
						<Select
							showSearch
							allowClear
							value={typeConfig.value}
							placeholder='Tipo da vinheta'
							onChange={typeConfig.onChange}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							<Select.OptGroup label='Contínuas'>
								{typesOptions.staticTypes.map((t) => (
									<Select.Option key={t.value} value={t.value}>
										{t.label}
									</Select.Option>
								))}
							</Select.OptGroup>

							<Select.OptGroup label='Cronogramadas'>
								{typesOptions.configsTypes.map((t) => (
									<Select.Option key={t.value} value={t.value}>
										{t.label}
									</Select.Option>
								))}
							</Select.OptGroup>
						</Select>
					</Form.Item>

					<Form.Item label={speechModeConfig.label}>
						<Select
							showSearch
							allowClear
							value={speechModeConfig.value}
							placeholder='Categoria da vinheta'
							onChange={speechModeConfig.onChange}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							{Object.values(VignetteSpeechModes).map((mode) => (
								<Select.Option key={mode} value={mode}>
									{translateSpeechMode(mode)}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label={executionModeConfig.label}>
						<Select
							showSearch
							allowClear
							value={executionModeConfig.value}
							placeholder='Velocidade da vinheta'
							onChange={executionModeConfig.onChange}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							{Object.values(VignetteExecutionModes).map((mode) => (
								<Select.Option key={mode} value={mode}>
									{translateExecutionMode(mode)}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</>
			);
		},
		[
			translateExecutionMode,
			translateSpeechMode,
			typesOptions.configsTypes,
			typesOptions.staticTypes,
		]
	);

	if (fallback.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Vinhetas da Rádio' />

			<Container>
				<Breadcrumb style={{ marginBottom: 12 }} separator='>' routes={breadcrumbs} />

				<header>
					<Typography.Title level={2}>Gerenciar Vinhetas da Rádio</Typography.Title>
					<Button
						type='primary'
						onClick={() => {
							setShowCreateVignetteModal(true);
						}}>
						<FiPlusCircle />
						Adicionar Vinhetas
					</Button>
				</header>

				<Divider />

				<Form.Container layout='30% 30% auto'>
					{renderFormSelects({
						typeConfig: {
							onChange: (type) => onChangeType(type, setFilters),
							label: 'Filtrar por tipo',
							value: filters.configId || filters.type,
						},
						speechModeConfig: {
							onChange: (speechMode) => setFilters((prev) => ({ ...prev, speechMode })),
							label: 'Filtrar por categoria',
							value: filters.speechMode,
						},
						executionModeConfig: {
							onChange: (executionMode) => setFilters((prev) => ({ ...prev, executionMode })),
							label: 'Filtrar por velocidade',
							value: filters.executionMode,
						},
					})}
				</Form.Container>

				<TableHeader>
					<div className='actions'>
						<span>
							Quantidade: <strong>{pagination.total}</strong>
						</span>
						<div>
							<Button
								size='small'
								disabled={!selectedVignettes.length || fallback.deletingVignettes}
								type='ghost'
								onClick={downloadMany}
								loading={fallback?.downloadingMany}>
								{!fallback?.downloadingMany && <FiDownload />} Baixar selecionados
								{selectedVignettes.length !== 0 && `(${selectedVignettes.length})`}
							</Button>

							<Button
								size='small'
								disabled={!selectedVignettes.length || fallback.downloadingMany}
								loading={fallback.deletingVignettes}
								type='danger'
								onClick={() => {
									Modal.confirm({
										title: 'Deletar offs selecionadas?',
										type: 'danger',
										content:
											'Todas as vinhetas selecionadas serão excluídos e essa ação não poderá ser revertida, deseja continuar mesmo assim?',
										okText: 'Deletar',
										onOk: () => handleDeleteVignette(selectedVignettes.map((v) => v._id)),
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}>
								{!fallback.deletingVignettes && <FiTrash2 />} Deletar selecionados
								{selectedVignettes.length !== 0 && `(${selectedVignettes.length})`}
							</Button>
						</div>
					</div>

					<PageSizeHandler pagination={pagination} setPagination={setPagination} />
				</TableHeader>

				<Table
					loading={fallback.fetchingVignettes}
					size='middle'
					rowKey='_id'
					columns={columns}
					dataSource={vignettes}
					style={{ border: 'none' }}
					pagination={{
						pageSize: 10,
						total: pagination.total,
						current: pagination.current + 1,
						size: 'large',
						onChange: (current) => setPagination((prev) => ({ ...prev, current: current - 1 })),
					}}
					rowSelection={{ onSelect, onSelectAll }}
				/>
			</Container>

			<Modal
				onCancel={() => {
					setShowCreateVignetteModal(false);
				}}
				okText='Fazer Upload'
				onOk={handleCreateVignette}
				visible={showCreateVignetteModal}
				closable={!fallback.creatingVignette}
				okButtonProps={{
					disabled: !isFormValid,
					loading: fallback.updatingConfig || fallback.creatingVignette,
				}}
				cancelButtonProps={{ disabled: fallback.updatingConfig || fallback.creatingVignette }}
				title='Adicionar Vinhetas'>
				<Form.Container>
					{renderFormSelects({
						typeConfig: {
							onChange: (type) => onChangeType(type, setNewVignettes),
							label: 'Tipo',
							value: newVignettes.configId || newVignettes.type,
						},
						speechModeConfig: {
							onChange: (speechMode) => setNewVignettes((prev) => ({ ...prev, speechMode })),
							label: 'Categoria',
							value: newVignettes.speechMode,
						},
						executionModeConfig: {
							onChange: (executionMode) => setNewVignettes((prev) => ({ ...prev, executionMode })),
							label: 'Velocidade',
							value: newVignettes.executionMode,
						},
					})}

					<FilesUploader
						multiple
						onChange={(files) => setNewVignettes((prev) => ({ ...prev, files }))}
						validTypes={['.mp3', '.wav']}
					/>
				</Form.Container>
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(ListRadioVignettes);
