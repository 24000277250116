import React, { useCallback, useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import {
	PageHeader,
	Descriptions,
	Table,
	Card,
	Menu,
	Form,
	Input,
	Dropdown,
	Tag,
	Button,
	Icon,
	Modal,
	message,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import { Container } from './styles';

import UserAPI from '../../../../services/sdks/user';
import ShareAPI from '../../../../services/sdks/share';
import ProgramsAPI from '../../../../services/sdks/programs';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Usuários' },
		{ breadcrumbName: 'Detalhes do usuário' },
	],
	style: { marginBottom: 12 },
};

const UserDetails = () => {
	const { userId } = useParams();
	const history = useHistory();

	const [user, setUser] = useState(null);
	const [programs, setPrograms] = useState([]);
	const [sharings, setSharings] = useState([]);
	const [fallback, setFallback] = useState('initial-data');
	const [activeKey, setActiveKey] = useState('1');
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [showChangePassword, setShowChangePassword] = useState({ show: false, userId: null });
	const [pInput, setPInput] = useState({ p: 'password', p2: 'password' });
	const [passwords, setPasswors] = useState({ password: '', password2: '' });

	const programsColumns = [
		{
			title: 'Nome do Programa',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Data de criação',
			dataIndex: 'dateUpload',
			key: 'dateUpload',
			render: (dateUpload) => format(parseISO(dateUpload), 'dd/MM/yyyy'),
		},
		{
			title: 'Status',
			dataIndex: 'isActive',
			key: 'isActive',
			render: (isActive) =>
				isActive ? <Tag color='green'>ATIVO</Tag> : <Tag color='red'>BLOQUEADO</Tag>,
		},
		{
			title: 'Edição',
			dataIndex: 'isEditable',
			key: 'isEditable',
			render: (isEditable) =>
				isEditable ? <Tag color='green'>EDITÁVEL</Tag> : <Tag color='red'>NÃO EDITÁVEL</Tag>,
		},
	];

	const sharingsColumns = [
		{
			title: 'Nome do Programa',
			dataIndex: 'program',
			key: 'program',
			render: (program) => `${program?.name || '-'}`,
		},
		{
			title: 'Status',
			dataIndex: 'isActive',
			key: 'isActive',
			render: (isActive) =>
				isActive ? <Tag color='green'>ATIVO</Tag> : <Tag color='red'>INATIVO</Tag>,
		},
	];

	const handleDeleteUser = useCallback(async () => {
		try {
			await UserAPI.destroy(userId);

			message.success('Usuário foi excluído com sucesso');
			setTimeout(() => history.push('/admin/users'), 1000);
		} catch (error) {
			console.error(error);
		}
	}, [userId, history]);

	const handleToggleUserAcces = useCallback(
		async (active) => {
			try {
				await UserAPI.modify(userId, { active });

				setUser((user) => ({ ...user, active }));

				message.success(active ? 'Acesso liberado!' : 'Acesso bloqueado!');
			} catch (error) {
				console.error(error);
			}
		},
		[userId]
	);

	const handleChangePassword = useCallback(async () => {
		try {
			if (!passwords.password) {
				return message.error('Informe a senha');
			}

			if (passwords.password !== passwords.password2) {
				return message.error('Senhas não são iguais');
			}

			setFallback('changing-password');

			await UserAPI.modify(showChangePassword.userId, { password: passwords.password });

			setFallback(null);
			setShowChangePassword({ show: false, userId: null });

			message.success('A senha do usuário foi alterada!');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, [passwords, showChangePassword]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { user },
				} = await UserAPI.show(userId);

				const {
					data: { sharings },
				} = await ShareAPI.index(`user=${userId}`);

				const {
					data: { programs },
				} = await ProgramsAPI.index(`userId=${userId}`);

				setUser(user);
				setPrograms(programs);
				setSharings(sharings);
				setFallback(null);
			} catch (error) {
				console.error(error);
			}
		};

		if (userId) {
			fetchInitialData();
		}
	}, [userId]);

	if (fallback === 'initial-data') {
		return <Fallback title='Carregando...' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title={`Detalhes ${user?.name}`} />

			<PageHeader
				title={user ? `Detalhes do Usuário (${user?.name})` : 'Detalhes do Usuário'}
				breadcrumb={breadcrumb}
				extra={[
					<Link to={`/admin/users/${userId}/edit`} key='1'>
						<Button key='1' type='ghost' icon='edit'>
							Editar usuário
						</Button>
					</Link>,
					<Dropdown.Button
						key='2'
						style={{ marginLeft: 4 }}
						onClick={() => setDropdownVisible(true)}
						visible={dropdownVisible}
						onVisibleChange={(visible) => setDropdownVisible(visible)}
						overlay={
							<Menu>
								<Menu.Item
									key='1'
									onClick={() => {
										setShowChangePassword({ show: true, userId });
									}}
								>
									<Icon type='lock' />
									Alterar senha
								</Menu.Item>

								<Menu.Item
									key='2'
									color='red'
									onClick={() => {
										Modal.confirm({
											title: user?.active ? 'Desativar usuário?' : 'Ativar usuário?',
											icon: 'exclamation-circle',
											content: user?.active
												? 'O usuário não poderá acessar o sistema, deseja continuar mesmo assim?'
												: 'O usuário terá acesso ao sistema, deseja continuar mesmo assim?',
											okText: 'Confirmar',
											onOk: () => handleToggleUserAcces(!user?.active),
											okButtonProps: {
												icon: user?.active ? 'close-circle' : 'check-circle',
												type: user?.active ? 'danger' : 'primary',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}
								>
									<Icon type={user?.active ? 'close-circle' : 'check-circle'} />
									{user?.active ? 'Remover Acesso Temporariamente' : 'Liberar Acesso'}
								</Menu.Item>

								<Menu.Divider />

								<Menu.Item
									key='3'
									onClick={() => {
										Modal.confirm({
											title: 'Deletar usuário?',
											type: 'danger',
											content: 'Essa ação não podera ser revertida, deseja continuar mesmo assim?',
											okText: 'Deletar',
											onOk: () => handleDeleteUser(),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: { icon: 'close-circle' },
										});
									}}
								>
									<Icon type='delete' />
									Deletar Conta Permanentemente
								</Menu.Item>
							</Menu>
						}
					>
						Ações
					</Dropdown.Button>,
				]}
			>
				<Descriptions size='middle' style={{ marginTop: 16 }}>
					<Descriptions.Item label='Nome Completo'>
						{`${user?.name} ${user?.surname}`}
					</Descriptions.Item>
					<Descriptions.Item label='Nome da Rádio'>{user?.radioName}</Descriptions.Item>
					<Descriptions.Item label='Cidade'>{user?.city}</Descriptions.Item>
					<Descriptions.Item label='Estado'>{user?.state}</Descriptions.Item>
					<Descriptions.Item label='CPF'>{user?.cpf || <i>Não informado</i>}</Descriptions.Item>
					<Descriptions.Item label='CNPJ'>{user?.cnpj || <i>Não informado</i>}</Descriptions.Item>
					<Descriptions.Item label='Dia de pagamento'>
						{user?.paymentDay || <i>Não cadastrado</i>}
					</Descriptions.Item>
				</Descriptions>
			</PageHeader>

			<Container>
				<Card
					title={
						<>
							<Icon type='file-text' /> Informações da Conta
						</>
					}
				>
					<Descriptions size='middle'>
						<Descriptions.Item label='Tipo da Conta'>
							{(() => {
								switch (user?.userLevel) {
									case 0:
										return 'Administrador';
									case 1:
										return 'Conta talkPlay (sem acesso às músicas)';
									case 2:
										return 'Conta padrão';
									case 3:
										return 'Gerenciar elementos/modelos';
									case 4:
										return 'Gerenciar assinaturas';
									case 5:
										return 'Gerenciar músicas';
									case 9:
										return 'Conta marketing';
									default:
										break;
								}
							})()}
						</Descriptions.Item>
						<Descriptions.Item label='Acesso ao Sistema' span={2}>
							{user?.subscriptionId === 1 ? (
								<Tag color='red'>
									BLOQUEADO (<strong>INADIMPLÊNCIA NO CARTÃO</strong>)
								</Tag>
							) : user?.active ? (
								<Tag color='green'>ACESSO LIBERADO</Tag>
							) : (
								<Tag color='red'>
									BLOQUEADO (<strong>MANUALMENTE</strong>)
								</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='Data de Registro'>{user?.registerDate}</Descriptions.Item>
						<Descriptions.Item label='Banco de Músicas'>
							{user?.tracksDB ? (
								<Tag color='green'>ACESSO AO BANCO</Tag>
							) : (
								<Tag color='red'>SEM ACESSO BLOQUEADO</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='Download de músicas'>
							{user?.allowTracksDownload ? (
								<Tag color='green'>HABILITADO</Tag>
							) : (
								<Tag color='red'>DESABILITADO</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='Termos Aceitos'>
							{user?.acceptedTermVersion?.length ? (
								user?.acceptedTermVersion.map((v, i) => <span key={i}>versão {v}, </span>)
							) : (
								<i>Ainda não há termos aceitos</i>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='ID Admin'>
							{user?.intranetId || <i>Não informado</i>}
						</Descriptions.Item>
						<Descriptions.Item label='ID GerenciaNet'>
							{user?.subscriptionId || <i>Não informado</i>}
						</Descriptions.Item>
					</Descriptions>
				</Card>

				<Card
					title={
						<>
							<Icon type='play-circle' /> Geração de Conteúdo{' '}
						</>
					}
				>
					<Descriptions size='middle'>
						<Descriptions.Item label='Geração Noturna'>
							{user?.allowNocturnalGeneration ? (
								<Tag color='green'>ATIVA</Tag>
							) : (
								<Tag color='red'>INATIVA</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='Masterização'>
							{user?.masterization === 'OPTIONAL' ? (
								<Tag color='gold'>MASTERIZAÇÃO OPCIONAL</Tag>
							) : user?.masterization === 'MANDATORY' ? (
								<Tag color='green'>MASTERIZAÇÃO OBRIGATÓRIA</Tag>
							) : (
								<Tag color='re'>BLOQUEADA</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='Assinatura'>
							{user?.forceSignature ? (
								<Tag color='green'>COM ASSINATURA</Tag>
							) : (
								<Tag color='red'>SEM ASSINATURA</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='Assinatura de músicas'>
							{user?.allowTracksSignature ? (
								<Tag color='green'>COM ASSINATURA</Tag>
							) : (
								<Tag color='red'>SEM ASSINATURA</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='Inserção de propaganda curta'>
							{user?.allowTracksSignature ? (
								<Tag color='green'>PERMITIDA</Tag>
							) : (
								<Tag color='red'>NÃO PERMITIDA</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='Limite de Gerações Por Dia do Mesmo Programa'>
							{user?.maxGenerationsPerProgram}
						</Descriptions.Item>
						<Descriptions.Item label='Limite de Programas'>
							{user?.programLimit} programas
						</Descriptions.Item>
						<Descriptions.Item label='Blocos por Programa'>
							{user?.blocksPerProgram} blocos
						</Descriptions.Item>
						<Descriptions.Item label='Músicas por Bloco'>
							{user?.musicsPerBlock} músicas
						</Descriptions.Item>
					</Descriptions>
				</Card>

				<Card
					title={
						<>
							<Icon type='global' /> Acesso à elementos globais
						</>
					}
				>
					<Descriptions>
						<Descriptions.Item label='Notícias Globais'>
							{user?.allowGlobalNews ? (
								<Tag color='green'>COM ACESSO</Tag>
							) : (
								<Tag color='red'>SEM ACESSO</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='Wpps Globais'>
							{user?.allowGlobalWpp ? (
								<Tag color='green'>COM ACESSO</Tag>
							) : (
								<Tag color='red'>SEM ACESSO</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='Entrevistas Globais'>
							{user?.allowGlobalInterview ? (
								<Tag color='green'>COM ACESSO</Tag>
							) : (
								<Tag color='red'>SEM ACESSO</Tag>
							)}
						</Descriptions.Item>
					</Descriptions>
				</Card>

				<Card
					title={
						<>
							<Icon type='contacts' /> Contato
						</>
					}
				>
					<Descriptions size='middle'>
						<Descriptions.Item label='Email'>{user?.email}</Descriptions.Item>
						<Descriptions.Item label='Telefone'>
							{user?.phone || <i>Não informado</i>}
						</Descriptions.Item>
					</Descriptions>
				</Card>

				<Card
					defaultActiveTabKey={activeKey}
					onTabChange={(key) => setActiveKey(key)}
					tabList={[
						{ key: '1', tab: 'Programas Carimbados' },
						{ key: '2', tab: 'Programas do Plano' },
					]}
				>
					{activeKey === '1' ? (
						<Table
							style={{ border: 'none' }}
							rowKey='_id'
							dataSource={sharings}
							columns={sharingsColumns}
							size='middle'
							pagination={{ size: 'large' }}
						/>
					) : (
						<Table
							style={{ border: 'none' }}
							rowKey='_id'
							dataSource={programs}
							columns={programsColumns}
							size='middle'
							pagination={{ size: 'large' }}
						/>
					)}
				</Card>
			</Container>

			<Modal
				title='Alterar senha do usuário'
				width={600}
				visible={showChangePassword.show}
				onCancel={() => setShowChangePassword({ show: false, userId: null })}
				okText='Alterar senha do usuário'
				onOk={handleChangePassword}
				okButtonProps={{ icon: 'save', loading: fallback === 'changing-password' }}
				cancelButtonProps={{
					icon: 'close-circle',
					disabled: fallback === 'changing-password',
				}}
			>
				<Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
					<Form.Item required label='Senha'>
						<Input
							placeholder='Informe a senha do usuário'
							type={pInput.p}
							suffix={
								pInput.p === 'password' ? (
									<Icon
										type='eye-invisible'
										onClick={() => {
											setPInput({ ...pInput, p: 'text' });
										}}
									/>
								) : (
									<Icon
										type='eye'
										onClick={() => {
											setPInput({ ...pInput, p: 'password' });
										}}
									/>
								)
							}
							value={passwords?.password}
							onChange={({ target: { value } }) => {
								return setPasswors({ ...passwords, password: value });
							}}
						/>
					</Form.Item>

					<Form.Item required label='Confirmar senha'>
						<Input
							placeholder='Confirme a senha'
							type={pInput.p2}
							suffix={
								pInput.p2 === 'password' ? (
									<Icon
										type='eye-invisible'
										onClick={() => {
											setPInput({ ...pInput, p2: 'text' });
										}}
									/>
								) : (
									<Icon
										type='eye'
										onClick={() => {
											setPInput({ ...pInput, p2: 'password' });
										}}
									/>
								)
							}
							value={passwords?.password2}
							onChange={({ target: { value } }) => {
								return setPasswors({ ...passwords, password2: value });
							}}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default UserDetails;
