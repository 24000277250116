import React, { useState, useEffect, useCallback } from 'react';
import MaskedInput from 'antd-mask-input';
import { cpf as CPF } from 'cpf-cnpj-validator';

import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
	PageHeader,
	Form,
	Select,
	Button,
	Typography,
	Divider,
	Modal,
	Checkbox,
	Switch,
	Card,
	message,
	Spin,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import CurrencyInput from '../../../../components/CurrencyInput';
import { Container, CheckboxesContainer } from './styles';

import CastersAPI from '../../../../services/sdks/caster';
import ProgramsAPI from '../../../../services/sdks/programs';
import ContractsAPI from '../../../../services/sdks/contracts';
import UsersAPI from '../../../../services/sdks/user';
import ScriptsAPI from '../../../../services/sdks/scripts';
import { useHistory } from 'react-router-dom';

const layout = { labelCol: { span: 6 }, wrapperCol: { span: 12 } };
const tailLayout = { wrapperCol: { offset: 6, span: 18 } };
const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Contratos' },
		{ breadcrumbName: 'Criar contrato' },
	],
	style: { marginBottom: 12 },
};

const CreateContract = () => {
	const history = useHistory();

	const [fallback, setFallback] = useState({ initialData: true });
	const [showModals, setShowModals] = useState({ missingInfos: false });
	const [user, setUser] = useState(undefined);
	const [users, setUsers] = useState([]);
	const [caster, setCaster] = useState(undefined);
	const [casters, setCasters] = useState([]);
	const [program, setProgram] = useState(undefined);
	const [programs, setPrograms] = useState([]);
	const [script, setScript] = useState(undefined);
	const [scripts, setScripts] = useState([]);
	const [duration, setDuration] = useState(6);
	const [playlistPrivilege, setPlaylistPrivilege] = useState(false);
	const [value, setValue] = useState(0);
	const [cpf, setCpf] = useState('');
	const [phone, setPhone] = useState('');

	const [weeklySchedule, setWeeklySchedule] = useState({
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false,
		friday: false,
		saturday: false,
		sunday: false,
	});

	const handleCheckChange = useCallback((key, checked) => {
		setWeeklySchedule((prev) => ({ ...prev, [key]: checked }));
	}, []);

	const handleCreateContract = useCallback(async () => {
		try {
			const payload = {
				caster,
				program,
				script,
				hirer: user,
				duration,
				value: value * 100,
				playlistPrivilege,
				weeklySchedule,
			};

			const validationSchema = Yup.object().shape({
				script: Yup.string().required('Informe o modelo'),
				program: Yup.string().required('Informe o progrma'),
				caster: Yup.string().required('Informe o locutor'),
				hirer: Yup.string().required('Informe o cliente'),
			});

			await validationSchema.validate(payload);
			if (value !== 0 && value < 5) return message.error('O valor deve ser maior que R$5,00');

			setFallback((prev) => ({ ...prev, creatingContract: true }));

			await ContractsAPI.store(payload, { 'by-admin': true });

			setFallback((prev) => ({ ...prev, creatingContract: false }));
			setShowModals((prev) => ({ ...prev, missingInfos: false }));

			message.success('Contrato criado com sucesso');

			history.push('/admin/contracts');
		} catch (error) {
			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}

			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, [user, caster, program, script, value, duration, playlistPrivilege, weeklySchedule, history]);

	const handleSubmitMissingInfos = useCallback(async () => {
		try {
			if (!cpf) return message.error('Informe seu CPF');
			if (!phone) return message.error('Informe seu telefone');
			if (!CPF.isValid(cpf)) {
				return message.error('CPF inválido');
			}

			setFallback((prev) => ({ ...prev, missingInfos: true }));

			const payload = { cpf, phone };
			await UsersAPI.modify(user, payload);

			setFallback((prev) => ({ ...prev, missingInfos: false }));
			setShowModals((prev) => ({ ...prev, missingInfos: false }));

			message.success('Informações enviadas, prossiga com a criação do contrato');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro ao enviar informações do usuário');
		}
	}, [user, cpf, phone]);

	useEffect(() => {
		const fetchUserPrograms = async () => {
			try {
				setScript(undefined);
				setProgram(undefined);
				setFallback((prev) => ({ ...prev, fetchingPrograms: true }));

				const {
					data: { programs },
				} = await ProgramsAPI.index(`userId=${user}&isDeleted=false`);

				setPrograms(programs);
				setFallback((prev) => ({ ...prev, fetchingPrograms: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os uusários');
			}
		};

		const verifyUserCpfAndPhone = () => {
			const selectedUser = users.find((u) => u?._id === user);
			const { cpf, phone } = selectedUser;

			setCpf(cpf);
			setPhone(phone);
		};

		if (user) {
			fetchUserPrograms();
			verifyUserCpfAndPhone();
		}
	}, [user, users]);

	useEffect(() => {
		const fetchProgramScripts = async () => {
			try {
				setScript(undefined);
				setFallback((prev) => ({ ...prev, fetchingScripts: true }));

				const {
					data: { scripts },
				} = await ScriptsAPI.index({ query: `program=${program}&userId=${user}` });

				setScripts(scripts);
				scripts.length === 1 && setScript(scripts[0]?._id);

				setFallback((prev) => ({ ...prev, fetchingScripts: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os uusários');
			}
		};

		if (program) {
			fetchProgramScripts();
		}
	}, [program, user]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { users },
				} = await UsersAPI.index();
				const {
					data: { casters },
				} = await CastersAPI.index('active=true');

				setUsers(users);
				setCasters(casters);

				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os uusários');
			}
		};

		fetchInitialData();
	}, []);

	if (fallback?.initialData) {
		return <Fallback title='Carregando...' message='Por favor aguarde' />;
	}

	return (
		<>
			<Meta title='Criar contrato' />

			<PageHeader title='Criar Contrato' breadcrumb={breadcrumb}>
				<Typography.Text>
					Criar contrato para gravações de offs entre usuário e locutor
				</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<Form {...layout}>
						<Form.Item label='Usuário'>
							<Select
								showSearch
								placeholder='Selecione o usuário'
								value={user}
								filterOption={(input, { props: { _search } }) => {
									const regex = new RegExp(input, 'i');

									return _search.match(regex);
								}}
								onChange={(value) => setUser(value)}
							>
								{users.map((user) => (
									<Select.Option
										key={user?._id}
										value={user?._id}
										_search={`${user?.radioName}${user?.email}`}
									>
										{user?.radioName} - {user?.city}/{user?.state}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Locutor'>
							<Select
								showSearch
								placeholder='Selecione o locutor'
								value={caster}
								filterOption={(input, { props: { _search } }) => {
									const regex = new RegExp(input, 'i');

									return _search.match(regex);
								}}
								onChange={(value) => setCaster(value)}
							>
								{casters.map((caster) => (
									<Select.Option
										key={caster?._id}
										value={caster?._id}
										_search={`${caster?.name}${caster?.surname}${caster?.email}`}
									>
										{caster?.name} {caster?.surname}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Spin spinning={fallback?.fetchingPrograms ? true : false}>
							<Form.Item label='Programa'>
								<Select
									showSearch
									disabled={!user}
									placeholder='Selecione o programa'
									value={program}
									filterOption={(input, { props: { children } }) => {
										const regex = new RegExp(input, 'i');

										return children.match(regex);
									}}
									onChange={(value) => setProgram(value)}
								>
									{programs.map((program) => (
										<Select.Option
											disabled={!program?.isActive}
											key={program?._id}
											value={program?._id}
										>
											{program?.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Spin>

						<Spin spinning={fallback?.fetchingScripts ? true : false}>
							<Form.Item
								label='Modelo'
								help={
									scripts.length === 1
										? 'Esse programa possui somente 1 modelo e foi selecionado automaticamente'
										: ''
								}
							>
								<Select
									showSearch
									disabled={!program || scripts.length === 1}
									placeholder='Selecione o modelo'
									value={script}
									onChange={(value) => setScript(value)}
								>
									{scripts.map((script) => (
										<Select.Option key={script?._id} value={script?._id}>
											{script?.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Spin>

						<Divider />

						<Form.Item help='Dias em que o programa vai ao ar' label='Cronograma semanal' required>
							<CheckboxesContainer>
								<Checkbox
									checked={weeklySchedule?.monday}
									onChange={({ target: { checked } }) => {
										handleCheckChange('monday', checked);
									}}
								>
									Segunda-Feira
								</Checkbox>
								<Checkbox
									checked={weeklySchedule?.tuesday}
									onChange={({ target: { checked } }) => {
										handleCheckChange('tuesday', checked);
									}}
								>
									Terça-Feira
								</Checkbox>
								<Checkbox
									checked={weeklySchedule?.wednesday}
									onChange={({ target: { checked } }) => {
										handleCheckChange('wednesday', checked);
									}}
								>
									Quarta-Feira
								</Checkbox>
								<Checkbox
									checked={weeklySchedule?.thursday}
									onChange={({ target: { checked } }) => {
										handleCheckChange('thursday', checked);
									}}
								>
									Quinta-Feira
								</Checkbox>
								<Checkbox
									checked={weeklySchedule?.friday}
									onChange={({ target: { checked } }) => {
										handleCheckChange('friday', checked);
									}}
								>
									Sexta-Feira
								</Checkbox>
								<Checkbox
									checked={weeklySchedule?.saturday}
									onChange={({ target: { checked } }) => {
										handleCheckChange('saturday', checked);
									}}
								>
									Sábado
								</Checkbox>
								<Checkbox
									checked={weeklySchedule?.sunday}
									onChange={({ target: { checked } }) => {
										handleCheckChange('sunday', checked);
									}}
								>
									Domingo
								</Checkbox>
							</CheckboxesContainer>
						</Form.Item>

						<Divider />

						<Form.Item label='Locutor pode criar playlists' required>
							<Switch
								checked={playlistPrivilege}
								onChange={(checked) => setPlaylistPrivilege(checked)}
							/>
						</Form.Item>

						<Form.Item label='Duração' required>
							<Select
								placeholder='Duração do contrato'
								value={duration}
								onChange={(value) => setDuration(value)}
							>
								<Select.Option value={6}>6 meses</Select.Option>
								<Select.Option value={12}>12 meses</Select.Option>
								<Select.Option value={18}>18 meses</Select.Option>
								<Select.Option value={24}>24 meses</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item label='Valor do contrato' required>
							<CurrencyInput value={value} onChange={(_, value) => setValue(value)} />
						</Form.Item>

						<Form.Item {...tailLayout}>
							<Button
								icon='plus-circle'
								type='primary'
								loading={fallback?.creatingContract}
								onClick={() => {
									if (value !== 0 && (!cpf || !phone)) {
										return setShowModals({ ...showModals, missingInfos: true });
									}

									return handleCreateContract();
								}}
							>
								Criar contrato
							</Button>
						</Form.Item>
					</Form>
				</Card>
			</Container>

			<Modal
				visible={showModals?.missingInfos}
				onOk={handleSubmitMissingInfos}
				okButtonProps={{ icon: 'save', loading: fallback?.missingInfos }}
				cancelButtonProps={{ icon: 'close-circle', disabled: fallback?.missingInfos }}
				onCancel={() => setShowModals({ ...showModals, missingInfos: false })}
				okText='Salvar'
				title='Informações obrigatórias'
			>
				<Typography.Text>
					Para criar um contrato com boletos, é necessário cadastrar <strong>CPF</strong> e{' '}
					<strong>Telefone</strong> do cliente.
				</Typography.Text>

				<Divider />

				<Form {...layout}>
					<Form.Item label='CPF' required>
						<MaskedInput
							value={cpf}
							mask='111.111.111-11'
							onChange={({ target: { value } }) => setCpf(value)}
							placeholder='___.___.___-___'
						/>
					</Form.Item>

					<Form.Item label='Telefone' required>
						<MaskedInput
							value={phone}
							mask='(11) 1 1111-1111'
							onChange={({ target: { value } }) => setCpf(value)}
							placeholder='(__) _ ____-____'
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(CreateContract);
