import api from '../api';

const BASE_URL = '/api/v2/subscriptions';

const SubscriptionsAPI = {
	show: (userId) => api.get(`${BASE_URL}/${userId}`),
	update: (userId, payload) => api.put(`${BASE_URL}/${userId}`, payload),
	destroy: (subscriptionId, headers, payload) => {
		return api.delete(`${BASE_URL}/${subscriptionId}`, { data: payload, headers });
	},
};

export default SubscriptionsAPI;
