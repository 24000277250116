import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import Loader from 'react-spinners/ScaleLoader';
import Timer from 'react-compound-timer';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
	Select,
	DatePicker,
	Button,
	Icon,
	Spin,
	Alert,
	Typography,
	Divider,
	Modal,
	Radio,
	Tag,
	Switch,
	message,
	Breadcrumb,
	Collapse,
	Tooltip,
} from 'antd';

import Meta from '../../../../components/Meta';
import Form from '../../../../components/Form';
import Fallback from '../../../../components/Fallback';
import ConfigScriptStrs from '../../../../components/ConfigScriptStrs';
import {
	Container,
	Clock,
	GeneratingEpisodeFallback,
	Errors,
	OptionContent,
	FormWrapper,
	ConfigItem,
	ConfigSectionLabel,
	ButtonsContainer,
	CollapseHeader,
	ReasonButtonContainer,
} from './styles';

import ScriptsAPI from '../../../../services/sdks/scripts';
import ProgramsAPI from '../../../../services/sdks/programs';
import SharingsAPI from '../../../../services/sdks/share';
import EpisodesAPI from '../../../../services/sdks/episodes';

import GenerationAPI from '../../../../services/sdks/generation';

import MasterAPI from '../../../../services/sdks/master';
import StatisticsAPI from '../../../../services/sdks/statistics';
import GenerationSchedulesAPI from '../../../../services/sdks/generation-schedules';
import env from '../../../../config/env';

import { useFilenameTemplate, useScript, useZip } from '../../../../hooks';
import {
	FiActivity,
	FiAlertTriangle,
	FiBarChart2,
	FiDisc,
	FiDownloadCloud,
	FiHash,
	FiHelpCircle,
	FiPlay,
	FiSettings,
	FiShare2,
	FiSliders,
	FiXCircle,
} from 'react-icons/fi';
import TextArea from 'antd/lib/input/TextArea';
import GenerationRequestsApi from '../../../../services/sdks/generationRequests';
import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';

const CreateEpisode = ({ user }) => {
	const { script: contextScript, setScript: setContextScript } = useScript();
	const generateFilename = useFilenameTemplate();

	const zip = useZip();
	const history = useHistory();

	const [fallback, setFallback] = useState({ initialData: true });
	const [programs, setPrograms] = useState([]);
	const [scripts, setScripts] = useState([]);
	const [episode, setEpisode] = useState(null);
	const [activeCollapses, setActiveCollapses] = useState([]);
	const [visibleModals, setVisibleModals] = useState({ configStrs: false });
	const [errors, setErrors] = useState([]);
	const [showRequestGenerationForm, setShowRequestGenerationForm] = useState(false);
	const [reason, setReason] = useState('');
	const [generationSchedule, setGenerationSchedule] = useState(null);

	const [newEp, setNewEp] = useState({
		script: null,
		scriptId: undefined,
		program: null,
		date: null,
		config: {
			ext: 'mp3',
			useDynamicFade: true,
			tracksSignature: {
				on: true,
				amount: 1,
			},
			soundtracks: {
				news: {
					call: true,
					post: true,
				},
				cashtracks: {
					call: true,
					post: true,
				},
				blitz: {
					call: true,
					post: true,
				},
				interviews: {
					call: true,
					post: true,
				},
				wpps: {
					call: true,
					post: true,
				},
			},
		},
	});

	const isSharedProgram = useMemo(() => {
		const p = programs.find(({ _id }) => _id === newEp.program);

		if (!p) {
			return true;
		}

		return p?.userId?._id !== user?._id;
	}, [newEp, programs, user]);

	const handleRequestNewGeneration = useCallback(async () => {
		setFallback((prev) => ({ ...prev, requestingNewGeneration: true }));

		try {
			const payload = {
				reason,
				program: newEp.program,
				date: newEp.date,
				user: user._id,
			};

			await GenerationRequestsApi.store(payload);

			setErrors([]);
			setShowRequestGenerationForm(false);
			message.success(
				'Nossa equipe irá avaliar sua requisição e entrará em contato assim que possível'
			);
		} catch (error) {
			console.error(error);
		}

		setFallback((prev) => ({ ...prev, requestingNewGeneration: true }));
	}, [reason, newEp, user]);

	const handleGenerateEpisode = useCallback(
		async ({ autoMaster }) => {
			try {
				setErrors([]);
				const payload = { ...newEp, script: newEp?.script?.body, autoMaster };

				delete payload.scriptId;

				const validationSchema = Yup.object().shape({
					date: Yup.string().nullable().required('Informe a data de lançamento'),
					script: Yup.array().required('Informe o modelo'),
					program: Yup.string().required('Informe o programa').nullable(),
					autoMaster: Yup.boolean().required(),
					config: Yup.object().shape({
						ext: Yup.string().required(),
						useDynamicFade: Yup.boolean().required(),
						tracksSignature: Yup.object().shape({
							on: Yup.bool().required(),
							amount: Yup.mixed().required(),
						}),
						soundtracks: Yup.object().shape({
							news: Yup.object().shape({
								call: Yup.boolean().required(),
								post: Yup.boolean().required(),
							}),
							cashtracks: Yup.object().shape({
								call: Yup.boolean().required(),
								post: Yup.boolean().required(),
							}),
							blitz: Yup.object().shape({
								call: Yup.boolean().required(),
								post: Yup.boolean().required(),
							}),
							interviews: Yup.object().shape({
								call: Yup.boolean().required(),
								post: Yup.boolean().required(),
							}),
							wpps: Yup.object().shape({
								call: Yup.boolean().required(),
								post: Yup.boolean().required(),
							}),
						}),
					}),
				});

				await validationSchema.validate(payload, { abortEarly: false });

				setActiveCollapses([]);
				setFallback((prev) => ({ ...prev, generating: true }));

				let {
					data: { episode, statistic },
				} = await GenerationAPI.manual({ payload });

				if (autoMaster) {
					setFallback((fallback) => ({
						...fallback,
						generating: false,
						mastering: true,
					}));

					const {
						data: { masterizedEpisode },
					} = await MasterAPI.modify({
						episodeId: episode._id,
						payload: { statisticId: statistic?._id },
					});

					setFallback((fallback) => ({ ...fallback, mastering: false }));

					message.success('Episódio masterizado com sucesso');
					history.push(`/commom/episodes/${masterizedEpisode._id}/details`);
				} else {
					await StatisticsAPI.destroy({ statisticId: statistic?._id });

					setFallback((prev) => ({ ...prev, generating: false }));

					message.success('Episódio criado com sucesso.');
					history.push(`/commom/episodes/${episode._id}/details?statistics=${statistic?._id}`);
				}
			} catch (error) {
				setFallback((prev) => ({ ...prev, generating: false, masterizing: false }));

				if (error instanceof Yup.ValidationError) {
					return error.inner.forEach((error) => message.error(error.message));
				}

				const { isLimitError, message, isFFmpegError } = error?.response?.data ?? {};

				if (isLimitError) {
					return setShowRequestGenerationForm(true);
				}

				if (isFFmpegError) {
					return setErrors((prev) => [...prev, message]);
				}

				return setErrors((prev) => [
					...prev,
					`Houve um erro durante a geração do episódio: ${error.message}`,
				]);
			}
		},
		[newEp, history]
	);

	const getFilePath = useCallback(
		(fileName) => {
			return resolveFileSrc({
				customS3Bucket: episode.isMasterized ? env.episodesS3Bucket : env.miscS3Bucket,
				useAccelerate: true,
				folder: null,
				fileName: episode.isMasterized ? fileName : `tmp${fileName}`,
			});
		},
		[episode]
	);

	const handleDownloadEpisode = useCallback(async () => {
		try {
			if (!episode) return;

			setFallback((prev) => ({ ...prev, downloading: true }));

			const zipName = `${episode.program.name} - ${episode.date}`;
			const zipData = episode.blocks.map((block, index) => ({
				url: getFilePath(block),
				name: generateFilename({
					index,
					template: episode.program.filenameTemplate,
				}),
			}));

			await zip.download(zipData, zipName);

			setFallback((prev) => ({ ...prev, downloading: false }));
			setVisibleModals((prev) => ({ ...prev, downloadEp: false }));
			setNewEp((prev) => ({ ...prev, date: null }));

			message.success('Download realizado com sucesso');
		} catch (error) {
			console.error(error);
		}
	}, [episode, zip, getFilePath, generateFilename]);

	const genTooltipText = useCallback((elementName, type) => {
		const _t = type === 'call' ? 'antes dos' : 'após os';
		const __t = type === 'call' ? 'chamada' : 'saída';

		return (
			<>
				Para que seu conteúdo fique mais dinâmico, o TalkPlay insere automaticamente falas {_t} seus
				elementos "{elementName}".
				<br />
				<br /> Essa opção indica se essa fada de {__t} deverá ou não receber trilhas sonoras.
			</>
		);
	}, []);

	useEffect(() => {
		const fetchPrograms = async () => {
			try {
				let {
					data: { programs },
				} = await ProgramsAPI.index(`userId=${user?._id}&isDeleted=false`);
				let {
					data: { sharings },
				} = await SharingsAPI.index(`user=${user?._id}`);

				sharings = sharings.map(({ program, isActive, isFavorited }) => {
					return { ...program, isFavorited, isActive, isShared: true };
				});

				programs = [...programs, ...sharings].sort((x, y) => {
					return x?.isFavorited === y?.isFavorited ? 0 : x?.isFavorited ? -1 : 1;
				});

				setPrograms(programs);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Erro ao buscar programas');
			}
		};

		fetchPrograms();
	}, [user]);

	useEffect(() => {
		if (newEp?.script?.wasModified) {
			Modal.confirm({
				width: 550,
				title: `Atenção!`,
				icon: 'exclamation-circle',
				content: (
					<>
						<Typography.Paragraph>
							A equipe da Talk Rádio editou o modelo original que você acabou de selecionar. Quando
							isso acontece, todos os patrocínios, oferecimentos e offs locais que você adicionou no
							modelo são retirados.
						</Typography.Paragraph>

						<Typography.Paragraph>Fique a vontade para adicioná-los novamente</Typography.Paragraph>

						<Typography.Paragraph style={{ fontStyle: 'italic' }}>
							Caso não tenha adicionado patrocínios ou oferecimentos ao modelo, ignore essa
							mensagem.
						</Typography.Paragraph>
					</>
				),
				okText: 'Fechar',
				onOk: async () => {
					await ScriptsAPI.modify({
						scriptId: newEp.script._id,
						payload: { ...newEp.script, wasModified: false },
					});
				},
				okButtonProps: {
					icon: 'close-circle',
				},
				cancelButtonProps: {
					style: { display: 'none' },
				},
			});
		}
	}, [newEp]);

	useEffect(() => {
		const fetchProgramScripts = async () => {
			setFallback((prev) => ({ ...prev, fetchingScripts: true }));

			try {
				setNewEp((prev) => ({ ...prev, script: [], scriptId: undefined }));

				const query = isSharedProgram
					? `program=${newEp.program}&userId=${user._id}&isVisible=true`
					: `program=${newEp.program}&userId=${user._id}`;

				const {
					data: { scripts },
				} = await ScriptsAPI.index({ query });

				if (scripts.length === 1) {
					setNewEp((prev) => ({ ...prev, script: scripts[0], scriptId: scripts[0]._id }));
				}

				setScripts(scripts);
				setFallback((prev) => ({ ...prev, fetchingScripts: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os modelos do programa');
			}

			setFallback((prev) => ({ ...prev, fetchingScripts: false }));
		};

		if (newEp?.program) {
			fetchProgramScripts();
		}
	}, [newEp?.program, programs, user, isSharedProgram]);

	useEffect(() => {
		const fetchProgramGenerationSchedule = async () => {
			setFallback((prev) => ({ ...prev, fetchingGenerationSchedule: true }));

			try {
				const response = await GenerationSchedulesAPI.getAll(0, 1, {
					userId: user._id,
					programId: newEp.program,
				});

				setGenerationSchedule(response.data.generationSchedules[0] ?? null);
			} catch (error) {
				message.error('Erro ao validar o cronograma de geração');
				setGenerationSchedule(null);
			}

			setFallback((prev) => ({ ...prev, fetchingGenerationSchedule: false }));
		};

		fetchProgramGenerationSchedule();
	}, [newEp?.program, user._id]);

	useEffect(() => {
		const fetchProgramEpisode = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingEpisode: true }));

				const query = `program=${newEp?.program}&date=${newEp?.date}&user=${user._id}`;
				const {
					data: { episodes },
				} = await EpisodesAPI.index({ query });

				if (episodes.length) {
					const episode = episodes.find((e) => !e.isAuto) || episodes[0];

					if (episode.blocks) {
						setEpisode(episode);
						setVisibleModals((prev) => ({ ...prev, downloadEp: true }));
					}
				} else {
					setEpisode(null);
				}

				setFallback((prev) => ({ ...prev, fetchingEpisode: false }));
			} catch (error) {
				console.error(error);

				setEpisode(null);
			}
		};

		newEp?.date && newEp?.program && fetchProgramEpisode();
	}, [newEp.program, newEp?.date, user]); // eslint-disable-line

	const isDateDisabled = useCallback(
		(date) => {
			if (!generationSchedule) {
				return false;
			}

			const day = new Date(date._d).getDay();
			const { isEnabled } = generationSchedule.weekSchedule.find((i) => i.day === day);
			return !isEnabled;
		},
		[generationSchedule]
	);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta
				title={
					fallback?.generating || fallback?.mastering ? 'Produzindo conteúdo' : 'Produzir conteúdo'
				}
			/>

			<Container>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[{ breadcrumbName: 'INÍCIO' }, { breadcrumbName: 'PRODUZIR CONTEÚDO' }]}
				/>

				<header>
					<Typography.Title level={2}>Produzir conteúdo</Typography.Title>
				</header>

				<Divider />

				<FormWrapper>
					{errors?.length > 0 && (
						<>
							<Errors>
								{errors.map((error, index) => {
									error = error.split('+');

									const message =
										error?.length === 3 ? (
											<>
												{error[0]} <strong>{error[1]}</strong> {error[2]}
											</>
										) : (
											error
										);

									return (
										<Alert
											closable
											showIcon
											key={index}
											type='error'
											message='Erro ao produzir o conteúdo'
											description={message}
											onClose={() => {
												setErrors(errors.filter((_, i) => i !== index));
											}}
										/>
									);
								})}
							</Errors>

							<Divider />
						</>
					)}

					{showRequestGenerationForm && (
						<>
							<Form.Container>
								<Form.Item label='Requisitar nova geração'>
									<TextArea
										disabled={fallback?.requestingNewGeneration}
										onChange={(e) => setReason(e.target.value)}
										value={reason}
										rows='3'
										placeholder='Informe um motivo para gerar esse programa novamente'></TextArea>
								</Form.Item>
								<ReasonButtonContainer>
									<Button
										size='small'
										type='primary'
										disabled={!reason}
										loading={fallback?.requestingNewGeneration}
										onClick={handleRequestNewGeneration}>
										Enviar Requisição
									</Button>
								</ReasonButtonContainer>
							</Form.Container>
							<Divider />
						</>
					)}

					<Spin spinning={!!fallback?.fetchingEpisode} size='large'>
						<Form.Container>
							<Form.Item label='Programa'>
								<Select
									showSearch
									name='program'
									optionFilterProp='children'
									placeholder='Selecione o programa'
									onChange={(program) => setNewEp({ ...newEp, program })}
									filterOption={(input, { props: { _search } }) => {
										return _search.match(new RegExp(input, 'i'));
									}}>
									{programs.map((program) => (
										<Select.Option
											key={program._id}
											_search={program?.name}
											value={program?._id}
											disabled={!program?.isActive}>
											<OptionContent>
												<span>
													<Icon
														theme='filled'
														type='heart'
														style={{
															color: 'var(--danger)',
															marginRight: 8,
															opacity: program?.isFavorited ? 1 : 0,
															pointerEvents: 'none',
														}}
													/>
													{program?.name}
												</span>

												<div className='tags'>
													{!program?.isActive && (
														<Tag style={{ marginLeft: 8 }} color='red'>
															<FiXCircle /> Inadimplente
														</Tag>
													)}
													{program?.isShared && (
														<Tag style={{ marginLeft: 8 }} color='gold'>
															<FiShare2 /> Compartilhado
														</Tag>
													)}
												</div>
											</OptionContent>
										</Select.Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item
								label='Modelo'
								style={{ marginBottom: scripts.length === 1 ? 14 : 0 }}
								help={scripts.length === 1 && 'Esse programa possui somente 1 modelo'}>
								<Spin spinning={!!fallback?.fetchingScripts}>
									<Select
										showSearch
										name='script'
										placeholder='Selecione o modelo que deseja utilizar'
										optionFilterProp='children'
										value={newEp.scriptId}
										onChange={(scriptId) => {
											setNewEp({
												...newEp,
												scriptId,
												script: scripts.find(({ _id }) => _id === scriptId),
											});
										}}
										disabled={scripts.length <= 1}
										filterOption={(input, option) => {
											return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
										}}>
										{scripts.map((script) => (
											<Select.Option key={script._id} value={script._id}>
												<OptionContent>
													<span>{script?.name}</span>

													<div className='tags'>
														{!isSharedProgram && !script?.isVisible && (
															<Tag color='gold'>
																<FiAlertTriangle /> Invisível aos compartilhados
															</Tag>
														)}
													</div>
												</OptionContent>
											</Select.Option>
										))}
									</Select>
								</Spin>
							</Form.Item>

							<Spin spinning={fallback?.fetchingGenerationSchedule === true}>
								<Form.Item label='Data de Lançamento'>
									<DatePicker
										allowClear
										name='date'
										// disabled={!generationSchedule}
										style={{ width: '100%' }}
										disabledDate={isDateDisabled}
										onChange={(_, date) => setNewEp({ ...newEp, date })}
										format={['DD/MM/YYYY', 'DD/MM/YY']}
									/>
								</Form.Item>
							</Spin>

							<Form.Item style={{ marginTop: -24 }}>
								<Collapse
									activeKey={activeCollapses}
									onChange={(visible) => setActiveCollapses(visible)}
									expandIconPosition='right'>
									<Collapse.Panel
										key='config'
										header={
											<CollapseHeader>
												<FiSettings />
												Configurações
											</CollapseHeader>
										}>
										<ConfigSectionLabel>Episódio</ConfigSectionLabel>

										<ConfigItem color='magenta'>
											<label>
												<FiDisc />
												Formato de saída
											</label>

											<Radio.Group
												defaultValue={newEp.config.ext}
												onChange={({ target: { value } }) =>
													setNewEp(({ config, ...rest }) => ({
														...rest,
														config: {
															...config,
															ext: value,
														},
													}))
												}>
												<Radio value='mp3'>MP3</Radio>
												<Radio value='wav'>WAV</Radio>
											</Radio.Group>
										</ConfigItem>

										<ConfigItem>
											<label>
												<FiSliders />
												Masterização 2.0
											</label>
											<Switch
												style={{ width: 'max-content' }}
												checked={newEp.config.useDynamicFade}
												onChange={(checked) => {
													setNewEp(({ config, ...rest }) => ({
														...rest,
														config: {
															...config,
															useDynamicFade: checked,
														},
													}));
												}}
											/>
										</ConfigItem>

										{user?.allowTracksSignature && (
											<>
												<Divider />

												<ConfigSectionLabel>Assinatura de músicas</ConfigSectionLabel>

												<ConfigItem>
													<label>
														<FiActivity />
														Assinar músicas
													</label>

													<div>
														<Switch
															style={{ width: 'max-content' }}
															checked={newEp.config.tracksSignature.on}
															onChange={(checked) => {
																setNewEp(({ config, ...rest }) => ({
																	...rest,
																	config: {
																		...config,
																		tracksSignature: {
																			...config.tracksSignature,
																			on: checked,
																		},
																	},
																}));
															}}
														/>

														<Tooltip
															title={
																<>
																	Ao ativar essa opção, o TalkPlay irá carimbar de forma aleatória
																	as músicas do episódio com sua vinheta de assinatura de músicas.
																	<br />
																	<br />
																	É possível selecionar uma quantidade de músicas por bloco que irão
																	ser carimbadas.
																	<br />
																	<br />
																	<i>Por padrão, uma músicas é carimbada por bloco</i>
																</>
															}>
															<FiHelpCircle />
														</Tooltip>
													</div>
												</ConfigItem>

												<ConfigItem disabled={!newEp.config.tracksSignature.on}>
													<label>
														<FiHash />
														N° de assinaturas por bloco
													</label>

													<Radio.Group
														disabled={!newEp.config.tracksSignature.on}
														defaultValue={newEp.config.tracksSignature.amount}
														onChange={({ target: { value } }) =>
															setNewEp(({ config, ...rest }) => ({
																...rest,
																config: {
																	...config,
																	tracksSignature: {
																		...config.tracksSignature,
																		amount: value,
																	},
																},
															}))
														}>
														<Radio value={1}>1 música</Radio>
														<Radio value={2}>2 músicas</Radio>
													</Radio.Group>
												</ConfigItem>
											</>
										)}

										<Divider />

										<ConfigSectionLabel>Trilhas sonoras</ConfigSectionLabel>

										<Button
											disabled={!newEp?.script || isSharedProgram}
											size='small'
											style={{ marginBottom: 12, width: '100%' }}
											onClick={() => {
												setContextScript(newEp?.script);
												setVisibleModals({ ...visibleModals, configStrs: true });
											}}>
											Configurar trilhas sonoras dos offs
										</Button>

										<ConfigItem>
											<label>
												<FiBarChart2 /> Chamadas de notícias
											</label>

											<div>
												<Switch
													style={{ width: 'max-content' }}
													checked={newEp.config.soundtracks.news.call}
													size='small'
													onChange={(checked) => {
														setNewEp(({ config: { soundtracks, ...restConfig }, ...rest }) => ({
															...rest,
															config: {
																...restConfig,
																soundtracks: {
																	...soundtracks,
																	news: {
																		...soundtracks.news,
																		call: checked,
																	},
																},
															},
														}));
													}}
												/>

												<Tooltip title={genTooltipText('NOTÍCIA', 'call')}>
													<FiHelpCircle />
												</Tooltip>
											</div>
										</ConfigItem>

										<ConfigItem>
											<label>
												<FiBarChart2 /> Chamadas de música-cash
											</label>

											<div>
												<Switch
													style={{ width: 'max-content' }}
													checked={newEp.config.soundtracks.cashtracks.call}
													size='small'
													onChange={(checked) => {
														setNewEp(({ config: { soundtracks, ...restConfig }, ...rest }) => ({
															...rest,
															config: {
																...restConfig,
																soundtracks: {
																	...soundtracks,
																	cashtracks: {
																		...soundtracks.cashtracks,
																		call: checked,
																	},
																},
															},
														}));
													}}
												/>

												<Tooltip title={genTooltipText('MÚSICA-CASH', 'call')}>
													<FiHelpCircle />
												</Tooltip>
											</div>
										</ConfigItem>

										<ConfigItem>
											<label>
												<FiBarChart2 /> Chamadas de blitz
											</label>

											<div>
												<Switch
													style={{ width: 'max-content' }}
													checked={newEp.config.soundtracks.blitz.call}
													size='small'
													onChange={(checked) => {
														setNewEp(({ config: { soundtracks, ...restConfig }, ...rest }) => ({
															...rest,
															config: {
																...restConfig,
																soundtracks: {
																	...soundtracks,
																	blitz: {
																		...soundtracks.blitz,
																		call: checked,
																	},
																},
															},
														}));
													}}
												/>

												<Tooltip title={genTooltipText('BLITZ', 'call')}>
													<FiHelpCircle />
												</Tooltip>
											</div>
										</ConfigItem>

										<ConfigItem>
											<label>
												<FiBarChart2 /> Chamadas de Entrevistas
											</label>

											<div>
												<Switch
													style={{ width: 'max-content' }}
													checked={newEp.config.soundtracks.interviews.call}
													size='small'
													onChange={(checked) => {
														setNewEp(({ config: { soundtracks, ...restConfig }, ...rest }) => ({
															...rest,
															config: {
																...restConfig,
																soundtracks: {
																	...soundtracks,
																	interviews: {
																		...soundtracks.interviews,
																		call: checked,
																	},
																},
															},
														}));
													}}
												/>

												<Tooltip title={genTooltipText('ENTREVISTA', 'call')}>
													<FiHelpCircle />
												</Tooltip>
											</div>
										</ConfigItem>

										<ConfigItem>
											<label>
												<FiBarChart2 /> Chamadas de Wpp's
											</label>

											<div>
												<Switch
													style={{ width: 'max-content' }}
													checked={newEp.config.soundtracks.wpps.call}
													size='small'
													onChange={(checked) => {
														setNewEp(({ config: { soundtracks, ...restConfig }, ...rest }) => ({
															...rest,
															config: {
																...restConfig,
																soundtracks: {
																	...soundtracks,
																	wpps: {
																		...soundtracks.wpps,
																		call: checked,
																	},
																},
															},
														}));
													}}
												/>

												<Tooltip title={genTooltipText('WPP', 'call')}>
													<FiHelpCircle />
												</Tooltip>
											</div>
										</ConfigItem>

										<Divider />

										<ConfigItem>
											<label>
												<FiBarChart2 /> Saída de notícia
											</label>

											<div>
												<Switch
													style={{ width: 'max-content' }}
													checked={newEp.config.soundtracks.news.post}
													size='small'
													onChange={(checked) => {
														setNewEp(({ config: { soundtracks, ...restConfig }, ...rest }) => ({
															...rest,
															config: {
																...restConfig,
																soundtracks: {
																	...soundtracks,
																	news: {
																		...soundtracks.news,
																		post: checked,
																	},
																},
															},
														}));
													}}
												/>

												<Tooltip title={genTooltipText('WPP', 'post')}>
													<FiHelpCircle />
												</Tooltip>
											</div>
										</ConfigItem>

										<ConfigItem>
											<label>
												<FiBarChart2 /> Saída de música-cash
											</label>

											<div>
												<Switch
													style={{ width: 'max-content' }}
													checked={newEp.config.soundtracks.cashtracks.post}
													size='small'
													onChange={(checked) => {
														setNewEp(({ config: { soundtracks, ...restConfig }, ...rest }) => ({
															...rest,
															config: {
																...restConfig,
																soundtracks: {
																	...soundtracks,
																	cashtracks: {
																		...soundtracks.cashtracks,
																		post: checked,
																	},
																},
															},
														}));
													}}
												/>

												<Tooltip title={genTooltipText('BLITZ', 'post')}>
													<FiHelpCircle />
												</Tooltip>
											</div>
										</ConfigItem>

										<ConfigItem>
											<label>
												<FiBarChart2 /> Saída de blitz
											</label>

											<div>
												<Switch
													style={{ width: 'max-content' }}
													checked={newEp.config.soundtracks.blitz.post}
													size='small'
													onChange={(checked) => {
														setNewEp(({ config: { soundtracks, ...restConfig }, ...rest }) => ({
															...rest,
															config: {
																...restConfig,
																soundtracks: {
																	...soundtracks,
																	blitz: {
																		...soundtracks.blitz,
																		post: checked,
																	},
																},
															},
														}));
													}}
												/>

												<Tooltip title={genTooltipText('BLITZ', 'post')}>
													<FiHelpCircle />
												</Tooltip>
											</div>
										</ConfigItem>

										<ConfigItem>
											<label>
												<FiBarChart2 /> Saída de Entrevistas
											</label>

											<div>
												<Switch
													style={{ width: 'max-content' }}
													checked={newEp.config.soundtracks.interviews.post}
													size='small'
													onChange={(checked) => {
														setNewEp(({ config: { soundtracks, ...restConfig }, ...rest }) => ({
															...rest,
															config: {
																...restConfig,
																soundtracks: {
																	...soundtracks,
																	interviews: {
																		...soundtracks.interviews,
																		post: checked,
																	},
																},
															},
														}));
													}}
												/>

												<Tooltip title={genTooltipText('ENTREVISTA', 'post')}>
													<FiHelpCircle />
												</Tooltip>
											</div>
										</ConfigItem>

										<ConfigItem>
											<label>
												<FiBarChart2 /> Saída de Wpp's
											</label>

											<div>
												<Switch
													style={{ width: 'max-content' }}
													checked={newEp.config.soundtracks.wpps.post}
													size='small'
													onChange={(checked) => {
														setNewEp(({ config: { soundtracks, ...restConfig }, ...rest }) => ({
															...rest,
															config: {
																...restConfig,
																soundtracks: {
																	...soundtracks,
																	wpps: {
																		...soundtracks.wpps,
																		post: checked,
																	},
																},
															},
														}));
													}}
												/>

												<Tooltip title={genTooltipText('WPP', 'post')}>
													<FiHelpCircle />
												</Tooltip>
											</div>
										</ConfigItem>
									</Collapse.Panel>
								</Collapse>
							</Form.Item>

							<ButtonsContainer masterization={user?.masterization}>
								{['OPTIONAL', 'MANDATORY'].includes(user.masterization) && (
									<Button
										type='primary'
										size='large'
										onClick={() => handleGenerateEpisode({ autoMaster: true })}>
										<FiPlay /> Produzir & Masterizar
									</Button>
								)}

								{['OPTIONAL', 'BLOCKED'].includes(user.masterization) && (
									<Button
										type={user.masterization === 'OPTIONAL' ? 'GHOST' : 'primary'}
										size='large'
										onClick={() => handleGenerateEpisode({ autoMaster: false })}>
										<FiPlay /> Produzir Conteúdo
									</Button>
								)}
							</ButtonsContainer>
						</Form.Container>
					</Spin>
				</FormWrapper>
			</Container>

			<ConfigScriptStrs
				visible={visibleModals?.configStrs}
				onConfirm={() => {
					setNewEp({ ...newEp, script: contextScript });
					setScripts(scripts.map((s) => (s._id === newEp?.scriptId ? contextScript : s)));
					setVisibleModals({ ...visibleModals, configStrs: false });

					message.success('Trilhas sonoras configuradas');
				}}
			/>

			<Modal
				centered
				onCancel={() => setVisibleModals({ ...visibleModals, downloadEp: false })}
				footer={
					<Button
						type='primary'
						style={{ width: '100%' }}
						size='large'
						loading={fallback?.downloading}
						onClick={handleDownloadEpisode}>
						<FiDownloadCloud /> Baixar episódio
					</Button>
				}
				title={
					<>
						<FiDisc /> Episódio gerado
					</>
				}
				visible={visibleModals?.downloadEp}>
				<Alert
					showIcon
					type='info'
					message='Episódio gerado'
					description='O episódio desse programa nessa data já foi gerado, faça o download clicando no botão abaixo.'
				/>
			</Modal>

			<Modal visible={fallback?.generating} closable={false} footer={null} width={700}>
				<GeneratingEpisodeFallback>
					<Loader color='var(--primary)' />

					<Typography.Title level={4}>Seu episódio está sendo gerado</Typography.Title>

					<Timer>
						<Clock>
							<p>Tempo decorrido: </p>
							<span>
								<Timer.Hours /> :
							</span>
							<span>
								<Timer.Minutes /> :
							</span>
							<span>
								<Timer.Seconds />
							</span>
						</Clock>
					</Timer>

					<Typography.Paragraph style={{ marginTop: 24 }}>
						A criação de programas é um processo que simula exatamente o trabalho dos produtores de
						rádio, concatenando os arquivos de áudio do seu programa, removendo ruídos/silêncio,
						dentre outras tarefas.
						<br />
						Por isso é um processo complexo e que pode ser demorado em algumas situações. O tempo
						que é demandado depende de diversos fatores, sendo o principal deles a duração do
						programa, logo quanto maior for o conteúdo final, mais tempo será necessário para a
						geração do programa.
					</Typography.Paragraph>
				</GeneratingEpisodeFallback>
			</Modal>

			<Modal visible={fallback.mastering} closable={false} footer={null} width={700}>
				<GeneratingEpisodeFallback>
					<Loader color='var(--primary)' />

					<Typography.Title level={4}>Seu episódio está sendo masterizado</Typography.Title>

					<Timer>
						<Clock>
							<p>Tempo decorrido: </p>
							<span>
								<Timer.Hours /> :
							</span>
							<span>
								<Timer.Minutes /> :
							</span>
							<span>
								<Timer.Seconds />
							</span>
						</Clock>
					</Timer>

					<Typography.Paragraph style={{ marginTop: 24 }}>
						<strong>Sabia que um mesmo áudio pode soar diferente, dependendo da situação?</strong>
						<br />
						<br />
						Ouvindo no carro, com fones de ouvido, em aparelhos de som com baixa ou alta
						qualidade...cada um destes <i>players</i> traduzem a faixa de uma maneira diferente. O
						processo de masterização faz com que o conteúdo soe sempre da mesma forma, independente
						do aparelho. Assim é possível consertar alguns problemas(<i>geralmente graves</i>) que
						passam despercebidos, como a diferença de volume entre as faixas por exemplo,
						normalizando todo o arquivo de áudio, deixando-o em um mesmo nível de decibéis.
						<br />
						<br />
						Mas para que isso aconteça a Talk Play Radio Edit precisa processar todo o arquivo e
						isso demanda tempo, sendo esse determinado principalmente pela duração do conteúdo.
						<br />
						<br />
						Mas não se preocupe a Talk Play <strong>não utiliza a sua internet</strong> para
						realizar esse processo. Por isso aperte o Master e vá tomar um café, estamos trabalhando
						para você.
					</Typography.Paragraph>
				</GeneratingEpisodeFallback>
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(CreateEpisode);
