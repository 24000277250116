import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Typography, Form, Button, message, Icon } from 'antd';

import Meta from '../../../components/Meta';
import { Container, Cover, FormContainer, FormContainerInner, Copyright, Logo } from './styles';

import * as UserActions from '../../../store/actions/user';
import AuthAPI from '../../../services/sdks/auth';

import coverSrc from '../../../assets/images/login/cover.jpg';
import logoSrc from '../../../assets/images/logos/logo-secondary-white.svg';

const LoginAdmin = ({ setUser }) => {
	const history = useHistory();

	const [fallback, setFallback] = useState({ authenticating: false });
	const [credentials, setCredentials] = useState({ email: '', password: '' });
	const [errors, setErrors] = useState({});

	const handleChangeCredentials = useCallback((key, value) => {
		setCredentials((prev) => ({ ...prev, [key]: value }));
	}, []);

	const handleSignIn = useCallback(
		async (event) => {
			try {
				event.preventDefault();
				setFallback((prev) => ({ ...prev, authenticating: true }));

				const validationSchema = Yup.object().shape({
					email: Yup.string().email('Informe um email válido').required('Informe o email'),
					password: Yup.string().required('Informe sua senha'),
				});

				await validationSchema.validate(credentials, { abortEarly: false });

				setErrors({});

				const {
					data: { user, token },
				} = await AuthAPI.post(credentials);

				setFallback((prev) => ({ ...prev, authenticating: false }));

				if (![0, 4].includes(user?.userLevel)) {
					return message.error('Você não possui autorização para acessar essa área');
				}

				setUser({ user, token });

				localStorage.setItem('@auth', token);
				history.push('/admin');
			} catch (error) {
				console.error(error);

				setErrors({});
				setFallback((prev) => ({ ...prev, authenticating: false }));

				if (error instanceof Yup.ValidationError) {
					const errors = {};

					error.inner.forEach(({ path, message }) => (errors[path] = message));
					return setErrors(errors);
				}

				if (error?.response?.status) {
					const { status } = error.response;

					switch (status) {
						case 404:
							return setErrors({ email: 'Usuário não encontrado' });
						case 400:
							return setErrors({ password: 'Senha incorreta' });
						default:
							return message.error('Houve um erro no servidor');
					}
				}
			}
		},
		[credentials, history, setUser]
	);

	return (
		<>
			<Meta title='Entrar como administrador' />

			<Container>
				<Cover>
					<img src={coverSrc} alt='talkplay' />
					<div className='overlay'></div>
				</Cover>

				<FormContainer>
					<Logo>
						<img alt='talkplay' src={logoSrc} />
					</Logo>
					<FormContainerInner>
						<Typography.Title level={2}>Log In</Typography.Title>

						<Typography.Text style={{ marginBottom: 24 }}>
							<Icon type='lock' /> Área administrativa
						</Typography.Text>

						<form onSubmit={handleSignIn}>
							<Form.Item
								help={errors['email']}
								style={{ marginBottom: 16, width: '100%' }}
								validateStatus={errors['email'] && 'error'}
							>
								<Input
									placeholder='Informe seu email'
									value={credentials?.email}
									onChange={({ target: { value } }) => {
										handleChangeCredentials('email', value);
									}}
								/>
							</Form.Item>

							<Form.Item
								help={errors['password']}
								style={{ marginBottom: 12, width: '100%' }}
								validateStatus={errors['password'] && 'error'}
							>
								<Input.Password
									placeholder='Informe sua senha'
									value={credentials?.password}
									onChange={({ target: { value } }) => {
										handleChangeCredentials('password', value);
									}}
								/>
							</Form.Item>

							<Button
								style={{ width: '100%' }}
								type='primary'
								icon='login'
								htmlType='submit'
								loading={fallback.authenticating}
							>
								Entrar
							</Button>
						</form>
					</FormContainerInner>

					<Copyright>
						Copyright &trade; {new Date().getFullYear()}{' '}
						<a
							className='__link'
							href='https://talkradio.com.br'
							target='_blank'
							rel='noopener noreferrer'
						>
							TalkRadio
						</a>
					</Copyright>
				</FormContainer>
			</Container>
		</>
	);
};

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) => bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginAdmin);
