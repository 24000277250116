import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
	Table,
	Button,
	message,
	Dropdown,
	Menu,
	Typography,
	Modal,
	Icon,
	Tag,
	Input,
	Select,
} from 'antd';

import { TabHeader, VersionsContainer } from './styles';

import ScriptsAPI from '../../services/sdks/scripts';

import {
	FiAlertTriangle,
	FiCheckSquare,
	FiCopy,
	FiEdit2,
	FiPlus,
	FiRepeat,
	FiXCircle,
} from 'react-icons/fi';
import Form from '../Form';
import RadioCard from '../RadioCard';
import { format } from 'date-fns';

const Scripts = ({ data, setData, user, program }) => {
	const history = useHistory();

	const [visibleModals, setVisibleModals] = useState({});
	const [scriptName, setScriptName] = useState('');
	const [scriptId, setScriptId] = useState(null);
	const [scriptWeekDay, setScriptWeekDay] = useState(undefined);
	const [updatingScript, setUpdatingScript] = useState(false);
	const [cloningScript, setCloningScript] = useState(false);
	const [versions, setVersions] = useState([]);
	const [selectedVersion, setSelectedVersion] = useState(null);

	const isSharedProgram = useMemo(() => {
		if (!user || !program) {
			return true;
		}

		return user?._id !== program.userId._id;
	}, [user, program]);

	const columns = [
		{
			title: 'Nome',
			key: 'name',
			render: (script) => <Link to={`/commom/scripts/${script?._id}/edit/`}>{script?.name}</Link>,
		},
		{
			title: 'Dia da Semana',
			dataIndex: 'weekDay',
			key: 'weekDay',
			align: 'center',
			render: (weekDay) => renderWeekDay(weekDay),
		},
		!isSharedProgram && user?.sharePermissionLevel !== 0
			? {
					title: 'Visibilidade',
					dataIndex: 'isVisible',
					key: 'visibility',
					align: 'center',
					render: (isVisible) =>
						isVisible ? (
							<Tag color='green'>
								<FiCheckSquare /> Visível à todos
							</Tag>
						) : (
							<Tag color='red'>
								<FiAlertTriangle /> Invisível aos compartilhados
							</Tag>
						),
			  }
			: {},
		{
			title: 'ID',
			dataIndex: '_id',
			key: 'id',
			align: 'center',
			render: (_id) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>
					{_id}
				</Typography.Text>
			),
		},
		{
			title: 'Ações',
			align: 'right',
			key: 'actions',
			render: (script) => (
				<Dropdown
					placement='bottomRight'
					overlay={
						<Menu>
							<Menu.Item
								key='edit'
								onClick={() => history.push(`/commom/scripts/${script?._id}/edit`)}
								disabled={
									user?.userLevel === 9 ? true : script?.isShared ? false : !program?.isEditable
								}>
								<Icon type='edit' style={{ marginRight: 6 }} /> Editar roteiro
							</Menu.Item>

							<Menu.Item
								key='duplicate'
								onClick={() => {
									setVisibleModals({ ...visibleModals, cloneScript: true });
									setScriptId(script._id);
								}}
								disabled={script?.isShared || !program?.isEditable}>
								<Icon type='copy' style={{ marginRight: 6 }} /> Duplicar modelo
							</Menu.Item>

							<Menu.Divider key='divider-1' />

							{!isSharedProgram && user?.sharePermissionLevel !== 0 && (
								<Menu.Item
									key='toggle-visibility'
									onClick={() => {
										Modal.confirm({
											title: 'Atenção',
											icon: 'exclamation-circle',
											content: script?.isVisible ? (
												<>
													Todas as rádios com as quais esse conteúdo foi compartilhado não
													conseguirão mais selecionar esse modelo para gerar seus conteúdos.
													<br />
													<br /> Realmente deseja continuar?
												</>
											) : (
												<>
													Esse modelo ficará visível para todas as rádios com as quais esse
													conteúdos foi compartilhado.
													<br />
													<br />
													Deseja contunuar?
												</>
											),
											okText: 'Alterar visibilidade',
											onOk: () => handleToggleVisibility({ script }),
											okButtonProps: {
												icon: 'retweet',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}>
									<Icon
										type={script.isVisible ? 'eye-invisible' : 'eye'}
										style={{ marginRight: 6 }}
									/>{' '}
									Alterar visibilidade
								</Menu.Item>
							)}

							<Menu.Item
								key='rename'
								disabled={!program?.isEditable}
								onClick={() => {
									setScriptId(script._id);
									setScriptName(script.name);
									setVisibleModals({ ...visibleModals, recoverScript: true });
								}}>
								<Icon type='edit' /> Renomear
							</Menu.Item>

							<Menu.Item
								key='change-week-day'
								disabled={!program?.isEditable}
								onClick={() => {
									setScriptId(script._id);
									setScriptWeekDay(script.weekDay);
									setVisibleModals({ ...visibleModals, changeWeekDay: true });
								}}>
								<Icon type='calendar' /> Alterar Dia
							</Menu.Item>

							<Menu.Divider key='divider-3' />

							<Menu.Item
								key='recover'
								className='ant-dropdown-menu-item-danger'
								disabled={user._id !== program?.userId?._id || !program?.isEditable}
								onClick={() => {
									setScriptId(script._id);
									setVisibleModals({ ...visibleModals, recoverScript: true });
								}}>
								<Icon type='rollback' /> Restaurar Versão
							</Menu.Item>

							<Menu.Item
								key='delete'
								className='ant-dropdown-menu-item-danger'
								disabled={user._id !== program?.userId?._id || !program?.isEditable}
								onClick={() => {
									Modal.confirm({
										title: 'Excluir modelo?',
										icon: 'exclamation-circle',
										content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
										okText: 'Excluir',
										onOk: () => handleDeleteScript(script?._id),
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}>
								<Icon type='delete' /> Excluir
							</Menu.Item>
						</Menu>
					}>
					<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 16 }} type='more' />
				</Dropdown>
			),
		},
	];

	const renderWeekDay = useCallback((weekDay) => {
		const map = {
			0: 'Domingo',
			1: 'Segunda-Feira',
			2: 'Terça-Feira',
			3: 'Quarta-Feira',
			4: 'Quinta-Feira',
			5: 'Sexta-Feira',
			6: 'Sábado',
		};

		return map[weekDay] || '-';
	}, []);

	const resetStates = useCallback(() => {
		setScriptId(null);
		setScriptName('');
		setScriptWeekDay(undefined);
	}, []);

	const renderWeekDayOptions = useCallback(() => {
		const days = [0, 1, 2, 3, 4, 5, 6];

		return days.map((day) => {
			const isDisabled = (data ?? []).filter((s) => s.weekDay === day).length >= 2;
			const label = isDisabled ? (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{renderWeekDay(day)}</span>
					<small>O programa já possui 2 modelos configurados para esse dia</small>
				</div>
			) : (
				<span>{renderWeekDay(day)}</span>
			);

			return (
				<Select.Option key={day} value={day} disabled={isDisabled}>
					{label}
				</Select.Option>
			);
		});
	}, [data, renderWeekDay]);

	const handleRenameScript = useCallback(async () => {
		if (!scriptName) {
			return message.error('Informe o nome do modelo');
		}

		setUpdatingScript(true);

		try {
			await ScriptsAPI.renameScript({
				scriptId,
				name: scriptName,
			});

			resetStates();
			setVisibleModals({ ...visibleModals, renameScript: false });
			setData(
				data.map((s) => {
					return s._id === scriptId ? { ...s, name: scriptName } : s;
				})
			);

			message.success('Modelo renomeado com sucesso');
		} catch (error) {
			message.error('Houve um erro ao renomear o modelo');
		}

		setUpdatingScript(false);
	}, [data, resetStates, scriptId, scriptName, setData, visibleModals]);

	const handleChangeWeekDay = useCallback(async () => {
		if ([null, undefined].includes(scriptWeekDay)) {
			return message.error('Informe o dia da semana do modelo');
		}

		setUpdatingScript(true);

		try {
			await ScriptsAPI.changeWeekDay({
				scriptId,
				weekDay: scriptWeekDay,
			});

			resetStates();
			setVisibleModals({ ...visibleModals, changeWeekDay: false });
			setData(
				data.map((s) => {
					return s._id === scriptId ? { ...s, weekDay: scriptWeekDay } : s;
				})
			);

			message.success('Modelo atualizado com sucesso');
		} catch (error) {
			message.error('Houve um erro ao atualizar o modelo');
		}

		setUpdatingScript(false);
	}, [data, resetStates, scriptId, scriptWeekDay, setData, visibleModals]);

	const handleCloneScript = useCallback(async () => {
		setCloningScript(true);

		try {
			const script = data.find((s) => s._id === scriptId);
			const payload = {
				name: scriptName,
				weekDay: scriptWeekDay,
				body: script.body,
				program: program._id,
				userId: program.userId._id,
			};

			const response = await ScriptsAPI.store({ payload });

			setScriptName('');
			setScriptWeekDay(undefined);
			setData([...data, response.data.script]);
			setVisibleModals({ ...visibleModals, cloneScript: false });
			message.success('Modelo duplicado com sucesso');
		} catch (error) {
			message.error('Houve um erro ao renomear o modelo');
		}

		setCloningScript(false);
	}, [
		data,
		program._id,
		program.userId._id,
		scriptId,
		scriptName,
		scriptWeekDay,
		setData,
		visibleModals,
	]);

	const handleDeleteScript = useCallback(
		async (scriptId) => {
			try {
				await ScriptsAPI.destroy({ scriptId });

				setData(data.filter(({ _id }) => _id !== scriptId));
				message.success('Modelo excluído com sucesso!');
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao deletar o modelo');
			}
		},
		[setData, data]
	);

	const handleToggleVisibility = useCallback(
		async ({ script }) => {
			try {
				await ScriptsAPI.toggleVisibility({ scriptId: script._id });

				setData((prev) => {
					return prev.map((s) => {
						return s?._id === script._id ? { ...script, isVisible: !script.isVisible } : s;
					});
				});
			} catch (error) {
				console.error(error);
			}
		},
		[setData]
	);

	const getVersionName = useCallback(
		(version, index) => {
			const scriptBody = data.find((s) => s._id === scriptId)?.body;
			const versionBody = version.body;

			if (versionBody.length !== scriptBody.length) {
				return { name: `Versão ${index + 1}`, isCurrent: false };
			}

			if (scriptBody.some((e, index) => e?.type !== version.body[index]?.type)) {
				return { name: `Versão ${index + 1}`, isCurrent: false };
			}

			return { name: 'Versão Atual', isCurrent: true };
		},
		[scriptId, data]
	);

	const handleRecoverScript = useCallback(async () => {
		setUpdatingScript(true);

		try {
			await ScriptsAPI.recoverScript(selectedVersion);
			setVisibleModals((prev) => ({ ...prev, recoverScript: false }));
			setSelectedVersion(null);
			setVersions([]);
			message.success('O modelo foi restaurado com sucesso');
		} catch (error) {
			message.error('Ocorreu um erro ao restaurar o modelo, tente novamente');
		}

		setUpdatingScript(false);
	}, [selectedVersion]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const {
					data: { scripts },
				} = await ScriptsAPI.index({
					query: `program=${program._id}&userId=${user._id}`,
				});

				setData(scripts);
			} catch (error) {
				console.error(error);
				message.error('Não foi possível buscar os modelos');
			}
		};

		if (data === null && user._id && program._id) {
			fetchData();
		}
	}, [data, setData, user, program._id]);

	useEffect(() => {
		const fetchScriptVersions = async () => {
			try {
				const { data } = await ScriptsAPI.getScriptVersions(scriptId);
				setVersions(data.versions);
			} catch (error) {
				setVersions([]);
				message.error('Houve um erro ao buscar as versões do modelo, tente novamente.');
			}
		};

		if (scriptId && visibleModals.recoverScript) {
			fetchScriptVersions();
		}
	}, [visibleModals, scriptId]);

	return (
		<>
			<TabHeader>
				<div>
					<span></span>

					{!program?.isEditable || isSharedProgram || user?.userLevel === 9 ? (
						<Button disabled>
							<FiPlus /> Novo modelo
						</Button>
					) : (
						<Link to={`/commom/scripts/create?program=${program._id}`}>
							<Button>
								<FiPlus /> Novo modelo
							</Button>
						</Link>
					)}
				</div>
			</TabHeader>

			<Table
				rowKey='_id'
				pagination={{ size: 'large' }}
				style={{ border: 'none' }}
				loading={data === null}
				size='middle'
				columns={columns}
				dataSource={data && isSharedProgram ? data.filter((script) => script.isVisible) : data}
				rowClassName='__row'
			/>

			<Modal
				destroyOnClose
				visible={visibleModals.renameScript}
				okButtonProps={{ disabled: !scriptName, loading: updatingScript }}
				onCancel={() => {
					resetStates();
					setVisibleModals({ ...visibleModals, renameScript: false });
				}}
				onOk={handleRenameScript}
				title={
					<>
						<FiEdit2 /> Renomear Modelo
					</>
				}
				okText={
					<>
						<FiEdit2 /> Confirmar
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}>
				<Form.Container>
					<Form.Item label='Novo nome do modelo'>
						<Input
							value={scriptName}
							style={{ textTransform: 'uppercase' }}
							placeholder='Informe o nome do modelo'
							onChange={(e) => setScriptName(e.target.value.toUpperCase())}
							size='large'
						/>
					</Form.Item>
				</Form.Container>
			</Modal>

			<Modal
				destroyOnClose
				visible={visibleModals.changeWeekDay}
				okButtonProps={{
					disabled: [null, undefined].includes(scriptWeekDay),
					loading: updatingScript,
				}}
				onCancel={() => {
					resetStates();
					setVisibleModals({ ...visibleModals, changeWeekDay: false });
				}}
				onOk={handleChangeWeekDay}
				title={
					<>
						<FiEdit2 /> Alterar Dia da Semana
					</>
				}
				okText={
					<>
						<FiEdit2 /> Confirmar
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}>
				<Form.Container>
					<Form.Item label='Novo dia da semana'>
						<Select
							size='large'
							placeholder='Dia da Semana'
							style={{ fontSize: 14 }}
							value={scriptWeekDay}
							onChange={(value) => setScriptWeekDay(value)}>
							{renderWeekDayOptions()}
						</Select>
					</Form.Item>
				</Form.Container>
			</Modal>

			<Modal
				destroyOnClose
				visible={visibleModals.cloneScript}
				okButtonProps={{
					disabled: !scriptName || scriptWeekDay === undefined,
					loading: cloningScript,
				}}
				onCancel={() => {
					resetStates();
					setVisibleModals({ ...visibleModals, cloneScript: false });
				}}
				onOk={handleCloneScript}
				title={
					<>
						<FiCopy /> Duplicar Modelo
					</>
				}
				okText={
					<>
						<FiCopy /> Confirmar
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}>
				<Form.Container>
					<Form.Item label='Nome do novo modelo'>
						<Input
							value={scriptName}
							className='uppercase-input'
							placeholder='Informe o nome do modelo'
							onChange={(e) => setScriptName(e.target.value.toUpperCase())}
							size='large'
						/>
					</Form.Item>

					<Form.Item label='Dia da semana do novo modelo'>
						<Select
							size='large'
							placeholder='Dia da Semana'
							style={{ fontSize: 14 }}
							value={scriptWeekDay}
							onChange={(value) => setScriptWeekDay(value)}>
							{renderWeekDayOptions()}
						</Select>
					</Form.Item>
				</Form.Container>
			</Modal>

			<Modal
				destroyOnClose
				visible={visibleModals.recoverScript}
				okButtonProps={{
					disabled: !selectedVersion,
					loading: updatingScript,
					type: 'danger',
				}}
				onCancel={() => {
					setVersions([]);
					setSelectedVersion(null);
					setVisibleModals((prev) => ({ ...prev, recoverScript: false }));
				}}
				onOk={handleRecoverScript}
				title={
					<>
						<FiRepeat /> Restaurar Modelo
					</>
				}
				okText={
					<>
						<FiRepeat /> Restaurar
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}>
				<VersionsContainer>
					{versions.length ? (
						versions.map((v, index) => (
							<RadioCard
								key={v._id}
								checked={selectedVersion === v._id}
								disabled={getVersionName(v, index).isCurrent}
								onClick={() => setSelectedVersion(v._id)}
								label={getVersionName(v, index).name}
								icon={null}
								descriptions={[
									{
										text: `Alterado em ${format(new Date(v.createdAt), 'dd/MM/yyyy HH:mm')}`,
									},
								]}
							/>
						))
					) : (
						<span>Carregando versões...</span>
					)}
				</VersionsContainer>
			</Modal>
		</>
	);
};

export default Scripts;
