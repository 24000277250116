import styled from 'styled-components';
import ReactCurrencyInput from 'react-currency-input';

export const StyledInput = styled(ReactCurrencyInput)`
	width: 100%;
	border-radius: var(--border-radius-base);
	border: 1px solid #d9d9d9;
	height: 36px;
	padding: 0 0 0 8px;
	transition: 300ms;
	outline: none;

	&:focus {
		border-color: var(--primary);
		box-shadow: 0px 0px 2px 0px var(--primary);
	}
`;
