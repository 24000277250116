import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaskedInput from 'antd-mask-input';
import * as Yup from 'yup';
import {
	PageHeader,
	Card,
	Button,
	Select,
	Form,
	Divider,
	Modal,
	Icon,
	Input,
	message,
	Typography,
} from 'antd';

import Meta from '../../../components/Meta';
import { Container } from './styles';

import UsersAPI from '../../../services/sdks/user';
import SubscriptionsAPI from '../../../services/sdks/subscriptions';

import { states } from '../../../constants';

import * as UserActions from '../../../store/actions/user';

const layout = { labelCol: { span: 6 }, wrapperCol: { span: 12 } };
const tailLayout = { wrapperCol: { offset: 6, span: 18 } };
const breadcrumb = {
	routes: [{ breadcrumbName: 'Início' }, { breadcrumbName: 'Configurações' }],
	style: { marginBottom: 12 },
};

const Settings = ({ user, updateUser }) => {
	const history = useHistory();

	const [fallback, setFallback] = useState(null);
	const [showModal, setShowModal] = useState({ cancelSignature: false });
	const [password, setPassword] = useState('');
	const [newUser, setNewUser] = useState(() => ({
		name: user?.name,
		surname: user?.surname,
		email: user?.email,
		city: user?.city,
		state: user?.state,
		radioName: user?.radioName,
		phone: user?.phone,
		cpf: user?.cpf,
		cnpj: user?.cnpj,
	}));

	const handleUpdateNewUser = useCallback(
		(key, value, minLength) => {
			if (minLength && value.length !== minLength) {
				if (newUser[key]) {
					return setNewUser((prev) => ({ ...prev, [key]: null }));
				}
			}

			setNewUser((prev) => ({ ...prev, [key]: value }));
		},
		[newUser]
	);

	const handleSubmit = useCallback(
		async (event) => {
			try {
				event.preventDefault();

				const payload = newUser;
				const validationSchema = Yup.object().shape({
					name: Yup.string().required('Informe o seu nome'),
					surname: Yup.string().required('Informe o seu sobrenome'),
					email: Yup.string().email('Informe um email válido').required('Informe o seu email'),
					city: Yup.string().required('Informe a sua cidade'),
					state: Yup.string().required('Informe o seu estado'),
					radioName: Yup.string().required('Informe o nome da sua rádio'),
				});

				await validationSchema.validate(payload);

				setFallback((prev) => ({ ...prev, updatingUser: true }));

				const {
					data: { user: updatedUser },
				} = await UsersAPI.modify(user._id, payload);

				updateUser(updatedUser);

				return message.success('Perfil atualizado com sucesso');
			} catch (error) {
				console.error(error);

				if (error instanceof Yup.ValidationError) {
					return message.error(error.message);
				}

				if (error?.isHttpError) {
					switch (error?.status) {
						case 409:
							return message.error(error?.message);
						default:
							return message.error('Houve um erro');
					}
				}
			} finally {
				setFallback((prev) => ({ ...prev, updatingUser: false }));
			}
		},
		[newUser, user, updateUser]
	);

	const handleCancelSignature = useCallback(async () => {
		try {
			if (!password) {
				return message.error('Informe sua senha para continuar');
			}

			const payload = { password };
			const headers = { user: user._id };
			const subscriptionId = user.subscriptionId;

			setFallback((prev) => ({ ...prev, cancelingSignature: true }));

			await SubscriptionsAPI.destroy(subscriptionId, headers, payload);

			setFallback((prev) => ({ ...prev, cancelingSignature: true }));

			history.push('/');
			localStorage.clear();
			message.success('Cancelamento feito com sucesso!');
		} catch (error) {
			console.error(error);
			setFallback((prev) => ({ ...prev, cancelingSignature: true }));

			const { message: responseMessage } = error.response.data;

			if (responseMessage) {
				return message.error(responseMessage);
			}
		}
	}, [user, history, password]);

	return (
		<>
			<Meta title='Configurações' />

			<PageHeader
				title='Configurações'
				breadcrumb={breadcrumb}
				extra={
					user?.subscriptionId > 1 && (
						<Button
							key='1'
							size='large'
							type='danger'
							icon='close-circle'
							onClick={() => setShowModal({ ...showModal, cancelSignature: true })}
						>
							Cancelar assinatura
						</Button>
					)
				}
			>
				<Typography.Text>Alterar informações da sua conta</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<Form {...layout} onSubmit={handleSubmit}>
						<Form.Item required label='Nome'>
							<Input
								placeholder='Informe seu primeiro nome'
								defaultValue={user?.name}
								onChange={({ target: { value } }) => {
									handleUpdateNewUser('name', value);
								}}
							/>
						</Form.Item>

						<Form.Item required label='Sobrenome'>
							<Input
								placeholder='Informe seu sobrenome'
								defaultValue={user?.surname}
								onChange={({ target: { value } }) => {
									handleUpdateNewUser('surname', value);
								}}
							/>
						</Form.Item>

						<Form.Item required label='Email'>
							<Input
								placeholder='Informe seu email'
								defaultValue={user?.email}
								onChange={({ target: { value } }) => {
									handleUpdateNewUser('email', value);
								}}
							/>
						</Form.Item>

						<Form.Item required label='Cidade'>
							<Input
								placeholder='Informe sua cidade'
								defaultValue={user?.city}
								onChange={({ target: { value } }) => {
									handleUpdateNewUser('city', value);
								}}
							/>
						</Form.Item>

						<Form.Item required label='Selecione o programa'>
							<Select
								showSearch
								defaultValue={user?.state}
								onChange={(value) => handleUpdateNewUser('state', value)}
								placeholder='Selecione seu estado'
								filterOption={(input, { props: { children } }) => {
									return children.toUpperCase().indexOf(input.toUpperCase()) >= 0;
								}}
							>
								{states.map((state) => (
									<Select.Option key={state} value={state}>
										{state}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item required label='Nome da sua rádio'>
							<Input
								placeholder='Informe o nome da sua rádio'
								defaultValue={user?.radioName}
								onChange={({ target: { value } }) => {
									handleUpdateNewUser('radioName', value);
								}}
							/>
						</Form.Item>

						<Form.Item label='Telefone'>
							<MaskedInput
								mask='(11) 1 1111-1111'
								placeholder='(__) _ ____-____'
								defaultValue={user?.phone}
								onChange={({ target: { value } }) => {
									if (value.replace(/[- _()]/g, '').length !== 11) {
										return handleUpdateNewUser('phone', null);
									}

									handleUpdateNewUser('phone', value);
								}}
							/>
						</Form.Item>

						<Form.Item label='CPF'>
							<MaskedInput
								mask='111.111.111-11'
								placeholder='___.___.___-__'
								defaultValue={user?.cpf}
								onChange={({ target: { value } }) => {
									if (value.replace(/[_.-]/g, '').length !== 11) {
										return handleUpdateNewUser('cpf', null);
									}

									handleUpdateNewUser('cpf', value);
								}}
							/>
						</Form.Item>

						<Form.Item label='CNPJ'>
							<MaskedInput
								mask='11.111.111/1111-11'
								placeholder='__.___.___/____-__'
								defaultValue={user?.cnpj}
								onChange={({ target: { value } }) => {
									if (value.replace(/[_/.-]/g, '').length !== 14) {
										return handleUpdateNewUser('cnpj', null);
									}

									handleUpdateNewUser('cnpj', value);
								}}
							/>
						</Form.Item>

						<Divider />

						<Form.Item {...tailLayout} style={{ marginTop: 16 }}>
							<Button htmlType='submit' icon='save' type='primary' loading={fallback?.updatingUser}>
								Salvar
							</Button>

							<Button
								htmlType='button'
								icon='close-circle'
								style={{ marginLeft: 4 }}
								disabled={fallback?.updatingUser}
								onClick={() => history.push('/commom')}
							>
								Cancelar
							</Button>
						</Form.Item>
					</Form>
				</Card>
			</Container>

			<Modal
				visible={showModal.cancelSignature}
				title={
					<>
						<Icon type='close-circle' /> Cancelar assinatura
					</>
				}
				onCancel={() => setShowModal({ ...showModal, cancelSignature: false })}
				footer={
					<Button
						type='danger'
						size='large'
						icon='close-circle'
						onClick={handleCancelSignature}
						loading={fallback?.cancelingSignature}
						style={{ width: '100%' }}
						disabled={!password}
					>
						Cancelar assinatura
					</Button>
				}
			>
				<Input.Password
					size='large'
					style={{ width: '100%' }}
					placeholder='Para cancelar a assinatura, informe sua senha'
					onChange={({ target: { value } }) => setPassword(value)}
				/>
			</Modal>
		</>
	);
};

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) => bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
