import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import BannerContext from './context';
import BannersApi from '../../services/sdks/banners';

const BannerProvider = ({ children, user }) => {
	const [banners, setBanners] = useState([]);

	useEffect(() => {
		async function getBanners() {
			try {
				const {
					data: { banners },
				} = await BannersApi.getUnreadBanners();

				setBanners(banners);
			} catch (error) {
				setBanners([]);
			}
		}

		if (user && ![0, 1, 5, 6, 9].includes(user.userLevel)) {
			getBanners();
		}
	}, [user]);

	return <BannerContext.Provider value={{ banners }}>{children}</BannerContext.Provider>;
};

export default connect(({ user }) => ({ user }))(BannerProvider);
