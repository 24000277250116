import styled from 'styled-components';

export const Container = styled.div`
	background: #fff;
	padding: 16px 16px;

	& .ant-breadcrumb > span:last-child {
		font-weight: 600 !important;
		opacity: 0.8;
	}

	& .ant-breadcrumb-link {
		font-size: 12px;
	}

	& > header {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: 32px;

		& > h2 {
			color: rgba(0, 0, 0, 0.75) !important;
			display: flex;
			align-items: center;
			margin: 0;
			margin-bottom: -10px;
		}
	}
`;

export const SearchContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 8px;
`;

export const FilterContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 12px;
`;

export const AvatarCell = styled.div`
	display: flex;
	align-items: center;

	& > .ant-typography {
		margin-left: 10px;
	}
`;

export const DetailsContainer = styled.div`
	width: 100%;

	& > div.desc {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	& > ul {
		width: 100%;

		& > li {
			padding: 16px 12px;
			border-bottom: 1px dashed #dedede;
			display: flex;
			align-items: center;
			justify-content: space-between;

			& > strong {
				font-size: 16px;
			}

			& > span.label {
				font-size: 15px;
				font-weight: 300;
			}
		}
	}
`;
