import api from '../api';

const BASE_URL = '/api/v2/users';

const UsersAPI = {
	index: (query) => api.get(`${BASE_URL}${query ? `?${query}` : ''}`),
	show: (userId) => api.get(`${BASE_URL}/${userId}`),
	post: (payload) => api.post(`${BASE_URL}`, payload),
	put: (payload, userId) => api.put(`${BASE_URL}/${userId}`, payload),
	modify: (userId, payload) => api.patch(`${BASE_URL}/${userId}`, payload),
	toggleNocturnalGeneration: (userId) => api.patch(`${BASE_URL}/${userId}/nocturnal-generation`),
	destroy: (userId) => api.delete(`${BASE_URL}/${userId}`),
};

export default UsersAPI;
