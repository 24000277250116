import React, { useState, useEffect, useCallback } from 'react';
import {
	PageHeader,
	Card,
	Select,
	Form,
	Spin,
	Typography,
	Icon,
	Tag,
	Divider,
	Slider,
	message,
	Button,
	Modal,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import {
	ButtonContainer,
	Container,
	OptionContent,
	ScriptConfigContainer,
	SelectContainer,
} from './styles';

import UsersAPI from '../../../../services/sdks/user';
import ProgramsAPI from '../../../../services/sdks/programs';
import PlaylistScriptAPI from '../../../../services/sdks/playlistScripts';
import CategoriesAPI from '../../../../services/sdks/categories';
import PlaylistScriptTabs from '../../../../components/PlaylistScriptTabs';

const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Início' },
		{ breadcrumbName: 'Playlists' },
		{ breadcrumbName: 'Modelos Semanais de Playlists' },
	],
	style: { marginBottom: 12 },
};

const initialWeek = {
	seg: { key: 'seg', title: 'Segunda-Feira', active: undefined, blocks: [] },
	ter: { key: 'ter', title: 'Terça-Feira', active: undefined, blocks: [] },
	qua: { key: 'qua', title: 'Quarta-Feira', active: undefined, blocks: [] },
	qui: { key: 'qui', title: 'Quinta-Feira', active: undefined, blocks: [] },
	sex: { key: 'sex', title: 'Sexta-Feira', active: undefined, blocks: [] },
	sab: { key: 'sab', title: 'Sábado', active: undefined, blocks: [] },
	dom: { key: 'dom', title: 'Domingo', active: undefined, blocks: [] },
};

const WeeklyScripts = () => {
	const [fallback, setFallback] = useState({ initialData: true });
	const [user, setUser] = useState(undefined);
	const [users, setUsers] = useState([]);
	const [program, setProgram] = useState(undefined);
	const [programs, setPrograms] = useState([]);
	const [categories, setCategories] = useState([]);
	const [playlistScript, setPlaylistScript] = useState(null);
	const [sharingDayScript, setSharingDayScript] = useState(null);
	const [selectedDaysToShare, setSelectedDaysToShare] = useState([]);

	const [incidence, setIncidence] = useState(1);
	const [week, setWeek] = useState(initialWeek);

	const handleToggleDayActive = useCallback((day, active) => {
		setWeek((prev) => ({ ...prev, [day]: { ...prev[day], active } }));
	}, []);

	const handleAddBlock = useCallback((day) => {
		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: [...prev[day].blocks, []],
			},
		}));
	}, []);

	const handleRemoveBlock = useCallback((day, blockIndex) => {
		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: prev[day].blocks.filter((_, i) => i !== blockIndex),
			},
		}));

		message.success(`O bloco #${blockIndex + 1} foi removido`);
	}, []);

	const handleClearBlock = useCallback((day, blockIndex) => {
		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: prev[day].blocks.map((block, i) => (i === blockIndex ? [] : block)),
			},
		}));

		message.success(`As categorias do bloco #${blockIndex + 1} foram removidas`);
	}, []);

	const handleCloneBlock = useCallback(
		(day, blockIndex) => {
			const dayBlocks = week[day].blocks;
			const clone = dayBlocks[blockIndex];

			dayBlocks.splice(blockIndex + 1, 0, clone);

			setWeek((prev) => ({
				...prev,
				[day]: {
					...prev[day],
					blocks: dayBlocks,
				},
			}));

			message.success(`O bloco #${blockIndex + 1} foi cloando com sucesso`);
		},
		[week]
	);

	const handleAddCategories = useCallback((day, blockIndex, category, inserts) => {
		const newCategories = Array.from({ length: inserts }).fill(category);

		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: prev[day].blocks.map((block, i) => {
					return i === blockIndex ? [...block, ...newCategories] : block;
				}),
			},
		}));

		message.success(`Aa categorias foram inseridas no bloco`);
	}, []);

	const handleReplaceCategory = useCallback((day, blockIndex, newCategory, categoryIndex) => {
		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: prev[day].blocks.map((block, bI) => {
					return blockIndex === bI
						? block.map((category, i) => (i === categoryIndex ? newCategory : category))
						: block;
				}),
			},
		}));

		message.success(`A categoria foi substiruída`);
	}, []);

	const handleRemoveCategory = useCallback((day, blockIndex, categoryIndex) => {
		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: prev[day].blocks.map((block, bI) => {
					return blockIndex === bI ? block.filter((_, i) => i !== categoryIndex) : block;
				}),
			},
		}));

		message.success(`A categoria foi removida`);
	}, []);

	const handleClearDay = useCallback((day) => {
		setWeek((prev) => ({
			...prev,
			[day]: { ...prev[day], blocks: [] },
		}));

		message.success(`A grade do dia foi limpa`);
	}, []);

	const handleShareDay = useCallback(() => {
		const { active, blocks } = sharingDayScript;

		selectedDaysToShare.forEach((targetDay) => {
			setWeek((prev) => ({
				...prev,
				[targetDay]: { ...week[targetDay], active, blocks },
			}));
		});
	}, [sharingDayScript, selectedDaysToShare, week]);

	const calculateCategoryCount = useCallback(
		(categoryId) => {
			if (!categoryId) {
				return 'Categoria removida';
			}

			const category = categories.find(({ _id }) => _id === categoryId);
			const dbTracks = category.tracksCount;
			let scriptTracks = 0;

			/** Sem essa função, o eslint vai exibir um warning */
			const incrementScriptTracks = () => (scriptTracks += 1);

			for (const key in week) {
				if (Object.hasOwnProperty.call(week, key)) {
					const day = week[key];

					day.blocks.forEach((block) => {
						block.forEach(({ id }) => {
							if (id === categoryId) {
								incrementScriptTracks();
							}
						});
					});
				}
			}

			return { dbTracks, scriptTracks, isDanger: scriptTracks >= dbTracks };
		},
		[week, categories]
	);

	const handleUpsertPlaylistScript = useCallback(
		async (ignoreDanger = false) => {
			try {
				let categoriesCount = 0;
				let isDanger = undefined;

				const modifyDangerousStatus = () => (isDanger = true);
				const incrementCategoriesCount = (increment) => (categoriesCount += increment);
				const data = { incidence, program };

				for (const key in week) {
					if (Object.hasOwnProperty.call(week, key)) {
						const day = week[key];

						if (day?.active) {
							data[key] = day.blocks.filter((block) => block.length >= 1);
							day.blocks.forEach((block) => {
								incrementCategoriesCount(block?.length);

								block.forEach((category) => {
									if (calculateCategoryCount(category?.id).isDanger) {
										modifyDangerousStatus();
									}
								});
							});
						} else {
							data[key] = 'unactive';
						}
					}
				}

				if (categoriesCount === 0) {
					return message.warn('Não é possível criar um modelo sem categorias');
				}

				setFallback((prev) => ({ ...prev, upserting: true }));

				if (isDanger && !ignoreDanger) {
					return Modal.confirm({
						title: `Atenção`,
						icon: 'exclamation-circle',
						width: 500,
						content: (
							<>
								Algumas categorias no seu modelo de playlist excedem a quandidade de músicas no
								banco músical.
								<br />
								Criar um modelo nessas condições, pode ocasionar em longos períodos de espera
								durante a geração das playlists e em repetições de algumas músicas durante a semana.
								<br />É aconselhado não prosseguir e reorganizar seu modelo.
							</>
						),
						okText: 'Continuar mesmo assim',
						onOk: () => handleUpsertPlaylistScript(true),
						okButtonProps: {
							icon: 'exclamation-circle',
						},
						cancelText: 'Cancelar',
						cancelButtonProps: {
							icon: 'close-circle',
						},
					});
				}

				await PlaylistScriptAPI.upsert(data);

				setUser(undefined);
				setProgram(undefined);
				setPlaylistScript(null);
				setSharingDayScript(null);
				setSelectedDaysToShare([]);
				setIncidence(1);
				setWeek(initialWeek);
				setFallback((prev) => ({ ...prev, upserting: false }));

				message.success(
					playlistScript ? 'Modelo atualizado com sucesso' : 'Modelo criado com Sucesso!'
				);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro, tente novamente');
			}
		},
		[week, program, incidence, calculateCategoryCount, playlistScript]
	);

	useEffect(() => {
		const fetchUserPrograms = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingPrograms: true }));

				const [
					{
						data: { categories },
					},
					{
						data: { programs },
					},
				] = await Promise.all([
					CategoriesAPI.index({ 'count-tracks': 'true' }),
					ProgramsAPI.index(`userId=${user}&isDeleted=false`),
				]);

				setCategories(
					categories.sort((x, y) => {
						return x?.name?.toUpperCase() >= y?.name.toUpperCase() ? 1 : -1;
					})
				);

				setPrograms(programs);
				setFallback((prev) => ({ ...prev, fetchingPrograms: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os dados');
			}
		};

		if (user) {
			fetchUserPrograms();
		}
	}, [user]);

	useEffect(() => {
		const fetchWeeklyScript = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingScript: true }));

				const {
					data: { playlistScript },
				} = await PlaylistScriptAPI.show(program);

				if (!playlistScript) {
					throw new Error('O programa ainda não possui um modelo semanal de playlist!');
				}

				const _week = {
					seg: { key: 'seg', title: 'Segunda-Feira' },
					ter: { key: 'ter', title: 'Terça-Feira' },
					qua: { key: 'qua', title: 'Quarta-Feira' },
					qui: { key: 'qui', title: 'Quinta-Feira' },
					sex: { key: 'sex', title: 'Sexta-Feira' },
					sab: { key: 'sab', title: 'Sábado' },
					dom: { key: 'dom', title: 'Domingo' },
				};

				for (const key in playlistScript.week) {
					if (Object.hasOwnProperty.call(playlistScript.week, key)) {
						const day = playlistScript.week[key];
						const blocks = [];

						if (day[0] === 'unactive') {
							_week[key] = { ..._week[key], active: false, blocks };
						} else {
							day.forEach(({ value: block }) => {
								blocks.push(block);
							});

							_week[key] = { ..._week[key], active: true, blocks };
						}
					}
				}

				setWeek(_week);
				setIncidence(playlistScript?.incidence);
				setPlaylistScript(playlistScript?._id);
				setFallback((prev) => ({ ...prev, fetchingScript: false }));
			} catch (error) {
				message.warn(String(error?.message), 5);

				setWeek(initialWeek);
				setIncidence(1);
				setFallback((prev) => ({ ...prev, fetchingScript: false }));
			}
		};

		if (program) {
			fetchWeeklyScript();
		}
	}, [program]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { users },
				} = await UsersAPI.index(`active=true`);

				setUsers(users);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os dados');
			}
		};

		fetchInitialData();
	}, []);

	if (fallback.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Modelos semanais de playlists' />

			<PageHeader title='Modelos Semanais de Playlists' breadcrumb={breadcrumb}>
				<Typography.Paragraph>
					Selecione o usuário e em seguida o programa para editar ou criar um novo modelo semanal de
					playlist.
				</Typography.Paragraph>
			</PageHeader>

			<Container>
				<Spin
					spinning={fallback?.fetchingScript ? true : false}
					tip='Buscando modelo semanal, por favor aguarde...'
				>
					<Card>
						<SelectContainer>
							<Select
								showSearch
								size='large'
								style={{ marginBottom: 16 }}
								optionFilterProp='children'
								placeholder='Selecione o usuário'
								value={user}
								onChange={(value) => setUser(value)}
								filterOption={(input, { props: { _search } }) => {
									const regex = new RegExp(input, 'i');

									return _search.match(regex);
								}}
							>
								{users.map((user) => {
									const { radioName, city, state, email } = user;

									return (
										<Select.Option
											_search={`${radioName}${city}${state}${email}`}
											key={user._id}
											value={user?._id}
										>
											{radioName} - {city}/{state} ({email})
										</Select.Option>
									);
								})}
							</Select>

							<Spin spinning={fallback?.fetchingPrograms ? true : false}>
								<Select
									showSearch
									size='large'
									disabled={!user}
									placeholder='Selecione o programa'
									value={program}
									style={{ width: '450px', maxWidth: '100%' }}
									onChange={(value) => setProgram(value)}
									optionFilterProp='_search'
									filterOption={(input, { props: { _search } }) => {
										const regex = new RegExp(input, 'i');

										return _search.match(regex);
									}}
								>
									{programs.map((program) => (
										<Select.Option
											key={program?._id}
											disabled={!program?.isEditable || !program?.isActive}
											_search={program?.name.toLowerCase()}
										>
											<OptionContent>
												<Icon
													theme='filled'
													type='heart'
													style={{
														color: 'var(--danger)',
														marginRight: 8,
														opacity: program?.isFavorited ? 1 : 0,
														pointerEvents: 'none',
													}}
												/>
												<span>
													{program?.name}
													{!program?.isEditable ? (
														<Tag color='red'>Não editável</Tag>
													) : (
														!program?.isActive && <Tag color='red'>Inadimplente</Tag>
													)}
												</span>
											</OptionContent>
										</Select.Option>
									))}
								</Select>
							</Spin>
						</SelectContainer>

						<Divider />

						<ScriptConfigContainer disabled={!program}>
							<Form {...layout}>
								<Form.Item
									label='Configurar incidência das músicas'
									help='A incidência controla o intervalo de dias em que uma mesma música pode aparecer na semana'
								>
									<Slider
										min={1}
										max={7}
										disabled={!program}
										marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7 }}
										value={incidence}
										onChange={(value) => setIncidence(value)}
									/>
								</Form.Item>
							</Form>

							<Divider />

							<PlaylistScriptTabs
								week={week}
								categories={categories}
								actions={{
									handleToggleDayActive,
									handleAddBlock,
									handleRemoveBlock,
									handleClearBlock,
									handleCloneBlock,
									handleAddCategories,
									handleReplaceCategory,
									handleRemoveCategory,
									handleClearDay,
									handleShareDay,
									calculateCategoryCount,
								}}
								share={{
									sharingDayScript,
									setSharingDayScript,
									selectedDaysToShare,
									setSelectedDaysToShare,
								}}
							/>

							<Divider />

							<ButtonContainer>
								<Button
									type='primary'
									disabled={!program}
									onClick={() => handleUpsertPlaylistScript(false)}
									icon='check-circle'
									loading={fallback?.upserting}
									size='large'
								>
									{playlistScript ? 'Atualizar modelo semanal' : 'Criar modelo semanal'}
								</Button>
							</ButtonContainer>
						</ScriptConfigContainer>
					</Card>
				</Spin>
			</Container>
		</>
	);
};

export default WeeklyScripts;
