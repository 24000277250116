import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import ADMManageSignatures from '../pages/Admin/Contents/ManageSignatures';
import NotFound from '../pages/Exceptions/_404';

import AppLayout from '../components/Layout';
import { SignaturesManagerNavigation } from '../components/Navigation';

const SignaturesManagerStack = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path={['/', '/admin', '/login', '/loginAdmin']}>
				<Redirect to='/admin/contents/signatures' />
			</Route>

			<Route exact path='/admin/contents/signatures'>
				<AppLayout isAdmin={true} navigation={<SignaturesManagerNavigation />}>
					<ADMManageSignatures />
				</AppLayout>
			</Route>

			<Route exact path='*'>
				<NotFound />
			</Route>
		</Switch>
	</BrowserRouter>
);

export default SignaturesManagerStack;
