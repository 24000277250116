import React, { useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import {
	PageHeader,
	Card,
	Tag,
	Button,
	Icon,
	Modal,
	Input,
	Tooltip,
	Radio,
	Form,
	Switch,
	InputNumber,
	Empty,
	message,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import CurrencyInput from '../../../../components/CurrencyInput';
import { Container, Grid, PlanContent } from './styles';

import PlansAPI from '../../../../services/sdks/plans';
import { useFormatter } from '../../../../hooks';

const layout = { labelCol: { span: 6 }, wrapperCol: { span: 12 } };
const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Planos' },
		{ breadcrumbName: 'Planos ativos' },
	],
	style: { marginBottom: 12 },
};

const ActivePlans = () => {
	const toCurrencyFormat = useFormatter({ style: 'currency', currency: 'BRL' });

	const [fallback, setFallback] = useState({ initialData: true });
	const [plan, setPlan] = useState(null);
	const [plans, setPlans] = useState([]);
	const [showModal, setShowModal] = useState({ edit: false });

	const handleCretePlan = useCallback(async () => {
		try {
			const paylaod = { ...plan };
			const validationSchema = Yup.object().shape({
				name: Yup.string().required('Infore o nome'),
				price: Yup.number().required('Infore o preço'),
				programQuant: Yup.number()
					.min(1, 'Informe um limite de programas maior do que zero')
					.required('Informe o limite de programas'),
				blocksPerProgram: Yup.number()
					.min(1, 'Informe um número de blocos por programa maior do que zero')
					.required('Informe o limite de blocos'),
				musicsPerBlock: Yup.number()
					.min(1, 'Informe um número de músicas por bloco maior do que zero')
					.required('Informe o limite de músicas'),
				sharePermissionLevel: Yup.number().required(
					'Informe as permissões de compartilhamento do plano'
				),
			});

			await validationSchema.validate(paylaod);

			setFallback((prev) => ({ ...prev, creating: true }));

			const {
				data: { plan: newPlan },
			} = await PlansAPI.store(paylaod);

			setPlans((prev) => [newPlan, ...prev]);

			setPlan(null);
			setFallback((prev) => ({ ...prev, creating: false }));
			setShowModal((prev) => ({ ...prev, create: false }));

			return message.success('O plano foi atualizado com sucesso');
		} catch (error) {
			console.error(error);
			setFallback((prev) => ({ ...prev, creating: false }));

			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}

			message.error('Houve um erro, tente novamente');
		}
	}, [plan]);

	const handleEditPlan = useCallback(async () => {
		try {
			const paylaod = { ...plan, price: plan.price / 100 };
			const planId = plan._id;
			const validationSchema = Yup.object().shape({
				name: Yup.string().required('Infore o nome'),
				price: Yup.number().required('Infore o preço'),
				programQuant: Yup.number()
					.min(1, 'Informe um limite de programas maior do que zero')
					.required('Informe o limite de programas'),
				blocksPerProgram: Yup.number()
					.min(1, 'Informe um número de blocos por programa maior do que zero')
					.required('Informe o limite de blocos'),
				musicsPerBlock: Yup.number()
					.min(1, 'Informe um número de músicas por bloco maior do que zero')
					.required('Informe o limite de músicas'),
			});

			await validationSchema.validate(paylaod);

			setFallback((prev) => ({ ...prev, updating: true }));

			const {
				data: { plan: updatedPlan },
			} = await PlansAPI.update(planId, paylaod);

			setPlans((prev) =>
				prev.map((plan) => {
					if (plan?._id === planId) {
						return updatedPlan;
					}

					return plan;
				})
			);

			setPlan(null);
			setFallback((prev) => ({ ...prev, updating: false }));
			setShowModal((prev) => ({ ...prev, edit: false }));

			return message.success('O plano foi atualizado com sucesso');
		} catch (error) {
			console.error(error);
			setFallback((prev) => ({ ...prev, updating: false }));

			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}

			message.error('Houve um erro, tente novamente');
		}
	}, [plan]);

	const handleDisablePlan = useCallback(async (planId) => {
		try {
			await PlansAPI.delete(planId);

			setPlans((prev) => prev.filter((plan) => plan._id !== planId));

			return message.success(
				'O plano foi desabilitado e movido para a página de planos desativados',
				3
			);
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, []);

	useEffect(() => {
		const fetchPlans = async () => {
			try {
				const {
					data: { plans },
				} = await PlansAPI.index('active=true');

				setPlans(plans.sort((a, b) => (a.price > b.price ? 1 : -1)));
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os planos');
			}
		};

		fetchPlans();
	}, []);

	if (fallback?.initialData) {
		return <Fallback title='Carregando...' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Planos ativos' />

			<PageHeader
				title='Planos Ativos'
				breadcrumb={breadcrumb}
				extra={[
					<Button
						key='1'
						type='ghost'
						size='large'
						icon='plus-circle'
						onClick={() => {
							setShowModal({ ...showModal, create: true });
							setPlan({
								price: 0,
								programQuant: 1,
								blocksPerProgram: 1,
								musicsPerBlock: 1,
							});
						}}
					>
						Criar novo plano
					</Button>,
				]}
			/>

			<Container>
				<Card style={{ marginTop: 16 }}>
					{plans?.length ? (
						<Grid>
							{plans.map((plan) => (
								<Card
									title={plan?.name}
									key={plan?._id}
									actions={[
										<Tooltip title='Editar plano'>
											<Icon
												type='edit'
												onClick={() => {
													setPlan(plan);
													setShowModal({ ...showModal, edit: true });
												}}
											/>
										</Tooltip>,
										<Tooltip title='Desativar plano'>
											<Icon
												type='stop'
												onClick={() => {
													Modal.confirm({
														title: 'Desativar plano?',
														icon: 'exclamation-circle',
														content:
															'O plano não ficará mais disponível para que os usuários o assinem, deseja continuar?',
														okText: 'Desativar',
														onOk: () => handleDisablePlan(plan?._id),
														okButtonProps: {
															icon: 'close-circle',
															type: 'danger',
														},
														cancelText: 'Cancelar',
														cancelButtonProps: {
															icon: 'close-circle',
														},
													});
												}}
											/>
										</Tooltip>,
									]}
								>
									<PlanContent>
										<li>
											<span className='label'>
												<Icon type='dollar' />
												Preço
											</span>
											<strong>{toCurrencyFormat(plan?.price / 100)}</strong>
										</li>
										<li>
											<span className='label'>
												<Icon type='database' />
												Acesso ao banco musical
											</span>
											{plan?.tracksDB ? (
												<Tag color='green'>Com acesso</Tag>
											) : (
												<Tag color='red'>Sem acesso</Tag>
											)}
										</li>
										<li>
											<span className='label'>
												<Icon type='appstore' /> Limite de programas
											</span>
											<strong>
												{plan?.programQuant === 1
													? '1 programa'
													: `${plan?.programQuant} programas`}
											</strong>
										</li>
										<li>
											<span className='label'>
												<Icon type='block' /> Blocos por programa
											</span>
											<strong>{plan?.blocksPerProgram} blocos</strong>
										</li>
										<li>
											<span className='label'>
												<Icon type='customer-service' /> Músicas por bloco
											</span>
											<strong>{plan?.musicsPerBlock} músicas</strong>
										</li>
										<li>
											<span className='label'>
												<Icon type='solution' />
												Assinatura
											</span>
											{plan?.forceSignature ? (
												<Tag color='green'>Com assinatura</Tag>
											) : (
												<Tag color='red'>Sem assinatura</Tag>
											)}
										</li>
										<li>
											<span className='label'>
												<Icon type='share-alt' />
												Compartilhamento
											</span>
											{plan?.sharePermissionLevel === 0 ? (
												<Tag color='red'>Não pode compartilhar</Tag>
											) : plan?.sharePermissionLevel === 1 ? (
												<Tag color='gold'>Compartilhar com a TalkRadio</Tag>
											) : (
												<Tag color='green'>Compartilhar com todos</Tag>
											)}
										</li>
									</PlanContent>
								</Card>
							))}
						</Grid>
					) : (
						<Empty description='Não existem planos ativos' />
					)}
				</Card>
			</Container>

			<Modal
				width={700}
				visible={showModal.create}
				okButtonProps={{ icon: 'save', loading: fallback?.creating }}
				okText='Salvar'
				onOk={handleCretePlan}
				cancelButtonProps={{ icon: 'close-circle', disabled: fallback?.creating }}
				onCancel={() => setShowModal({ ...showModal, create: false })}
				title={
					<>
						<Icon type='plus-circle' style={{ marginRight: 12 }} />
						Criar plano
					</>
				}
			>
				<Form {...layout}>
					<Form.Item label='Nome' required>
						<Input
							value={plan?.name}
							onChange={({ target: { value } }) => setPlan({ ...plan, name: value })}
							placeholder='Informe o nome do plano'
						/>
					</Form.Item>

					<Form.Item label='Limite de programas' required>
						<InputNumber
							min={1}
							value={plan?.programQuant}
							onChange={(value) => setPlan({ ...plan, programQuant: value })}
						/>
					</Form.Item>

					<Form.Item label='Preço' required>
						<CurrencyInput
							value={plan?.price}
							onChange={(_, value) => setPlan({ ...plan, price: value })}
						/>
					</Form.Item>

					<Form.Item label='Acesso ao banco'>
						<Switch
							checked={plan?.tracksDB}
							onChange={(checked) => setPlan({ ...plan, tracksDB: checked })}
						/>
					</Form.Item>

					<Form.Item label='Blocos por programa' required>
						<InputNumber
							min={1}
							value={plan?.blocksPerProgram}
							onChange={(value) => setPlan({ ...plan, blocksPerProgram: value })}
						/>
					</Form.Item>

					<Form.Item label='Músicas por bloco' required>
						<InputNumber
							min={1}
							value={plan?.musicsPerBlock}
							onChange={(value) => setPlan({ ...plan, musicsPerBlock: value })}
						/>
					</Form.Item>

					<Form.Item label='Com assinatura'>
						<Switch
							checked={plan?.forceSignature}
							onChange={(checked) => setPlan({ ...plan, forceSignature: checked })}
						/>
					</Form.Item>

					<Form.Item label='Compartilhamento'>
						<Radio
							checked={plan?.sharePermissionLevel === 0}
							onClick={() => setPlan({ ...plan, sharePermissionLevel: 0 })}
						>
							Não pode compartilhar
						</Radio>
						<Radio
							checked={plan?.sharePermissionLevel === 1}
							onClick={() => setPlan({ ...plan, sharePermissionLevel: 1 })}
						>
							Pode compartilha somente com a conta TalkRadio
						</Radio>
						<Radio
							checked={plan?.sharePermissionLevel === 2}
							onClick={() => setPlan({ ...plan, sharePermissionLevel: 2 })}
						>
							Pode compartilha com qualquer usuário
						</Radio>
					</Form.Item>
				</Form>
			</Modal>

			<Modal
				width={700}
				visible={showModal.edit}
				okButtonProps={{ icon: 'save', loading: fallback?.updating }}
				okText='Salvar'
				onOk={handleEditPlan}
				cancelButtonProps={{ icon: 'close-circle', disabled: fallback?.updating }}
				onCancel={() => setShowModal({ ...showModal, edit: false })}
				title={
					<>
						<Icon type='edit' style={{ marginRight: 12 }} />
						Editar plano
					</>
				}
			>
				<Form {...layout}>
					<Form.Item label='Nome' required>
						<Input
							value={plan?.name}
							onChange={({ target: { value } }) => setPlan({ ...plan, name: value })}
							placeholder='Informe o nome do plano'
						/>
					</Form.Item>

					<Form.Item label='Limite de programas' required>
						<InputNumber
							min={1}
							value={plan?.programQuant}
							onChange={(value) => setPlan({ ...plan, programQuant: value })}
						/>
					</Form.Item>

					<Form.Item label='Preço' required>
						<CurrencyInput
							value={plan?.price / 100}
							onChange={(_, value) => {
								setPlan({ ...plan, price: value * 100 });
							}}
						/>
					</Form.Item>

					<Form.Item label='Acesso ao banco'>
						<Switch
							checked={plan?.tracksDB}
							onChange={(checked) => setPlan({ ...plan, tracksDB: checked })}
						/>
					</Form.Item>

					<Form.Item label='Blocos por programa' required>
						<InputNumber
							min={1}
							value={plan?.blocksPerProgram}
							onChange={(value) => setPlan({ ...plan, blocksPerProgram: value })}
						/>
					</Form.Item>

					<Form.Item label='Músicas por bloco' required>
						<InputNumber
							min={1}
							value={plan?.musicsPerBlock}
							onChange={(value) => setPlan({ ...plan, musicsPerBlock: value })}
						/>
					</Form.Item>

					<Form.Item label='Com assinatura'>
						<Switch
							checked={plan?.forceSignature}
							onChange={(checked) => setPlan({ ...plan, forceSignature: checked })}
						/>
					</Form.Item>

					<Form.Item label='Compartilhamento'>
						<Radio
							checked={plan?.sharePermissionLevel === 0}
							onClick={() => setPlan({ ...plan, sharePermissionLevel: 0 })}
						>
							Não pode compartilhar
						</Radio>
						<Radio
							checked={plan?.sharePermissionLevel === 1}
							onClick={() => setPlan({ ...plan, sharePermissionLevel: 1 })}
						>
							Pode compartilha somente com a conta TalkRadio
						</Radio>
						<Radio
							checked={plan?.sharePermissionLevel === 2}
							onClick={() => setPlan({ ...plan, sharePermissionLevel: 2 })}
						>
							Pode compartilha com qualquer usuário
						</Radio>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default ActivePlans;
