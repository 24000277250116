import styled from 'styled-components';

export const FormContainer = styled.form`
	width: 100%;
	display: grid;
	grid-template-columns: ${({ layout }) => layout || '1fr'};
	gap: 1px 12px;
`;

export const ItemContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	& .ant-slider {
		margin-top: 4px !important;
	}

	& > label.form-item-label {
		font-weight: 500;
		font-size: 12px;
		margin-bottom: 2px;
		height: 18px;
	}

	& > span.help {
		font-size: 12px;
		margin-top: 2px;
		opacity: 0.7;
		margin-left: 6px;
		height: 18px;
	}
`;
