import styled from 'styled-components';

export const Container = styled.div`
	padding: 16px;

	& > .ant-card {
		margin: 12px 0;
	}

	.ant-table-small {
		border: none;
	}

	.ant-table-small > .ant-table-title {
		border-bottom: none !important;
	}
`;

export const TableHeader = styled.header`
	width: 100%;
	display: flex;
	align-items: center;
	padding-bottom: 16px;
	justify-content: flex-end;

	& > * {
		width: 350px !important;
	}
`;

export const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 12px;
`;
