import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import path from 'path';
import {
	Tabs,
	Button,
	Select,
	Modal,
	Typography,
	Menu,
	Badge,
	Dropdown,
	Icon,
	message,
	Table,
} from 'antd';

import FileExt from '../FileExt';
import FileDuration from '../FileDuration';
import Form from '../Form';
import { TabWrapper } from './styles';

import { useElement, usePlayer, useDownload, useFilesValidator } from '../../hooks';
import ElementsAPI from '../../services/sdks/elements';
import { FiUpload, FiXCircle, FiPlusCircle, FiPlus } from 'react-icons/fi';
import { resolveFileSrc } from '../../helpers/fileSrcResolver';
import FilesUploader from '../FilesUploader';

const TkPlayOffs = ({ program, user }) => {
	const player = usePlayer();
	const download = useDownload();
	const { categorizedTypes, getElementIcon, parseElementName, getElementColor } = useElement();

	const [fallback, setFallback] = useState({ creatingElement: false });
	const [tab, setTab] = useState(categorizedTypes.tkPlay[0]);
	const [showModal, setShowModal] = useState(false);
	const [isValidating, setIsValidating] = useState(false);
	const { hasValidationError } = useFilesValidator();
	const [loadedTypes, setLoadedTypes] = useState(() => {
		const _loadedCategs = {};

		categorizedTypes.tkPlay.forEach((type) => (_loadedCategs[type] = false));

		return _loadedCategs;
	});

	const [elements, setElements] = useState(() => {
		const _elements = {};

		categorizedTypes.tkPlay.forEach((type) => (_elements[type] = []));

		return _elements;
	});

	const [type, setType] = useState(undefined);
	const [files, setFiles] = useState(null);

	const columns = [
		{
			title: 'ID',
			dataIndex: '_id',
			key: 'id',
			render: (_id) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					8
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Nome',
			key: 'name',
			render: (element, _, index) => (
				<span>
					<Badge color={getElementColor({ type: element?.type })} />
					{parseElementName({ type: element?.type })} {index + 1}
				</span>
			),
		},
		{
			title: 'Duração',
			align: 'center',
			key: 'duration',
			render: (element) => <FileDuration src={resolveFileSrc({ fileName: element.filename })} />,
		},
		{
			title: 'Formato',
			align: 'center',
			key: 'ext',
			render: (element) => <FileExt src={resolveFileSrc({ fileName: element.filename })} />,
		},
		user.userLevel <= 1
			? {
					title: 'Ações',
					align: 'right',
					key: 'actions',
					render: ({ _id, filename, type }, _, index) => (
						<Dropdown
							placement='bottomRight'
							overlay={
								<Menu>
									<Menu.Item
										key='play-off'
										onClick={() => {
											player.start({
												ref: _id,
												src: resolveFileSrc({ fileName: filename }),
												meta: {
													name: `${parseElementName({ type })} ${index + 1}`,
													artist: program?.name,
												},
											});
										}}
									>
										<Icon type='play-circle' /> Reproduzir
									</Menu.Item>
									<Menu.Item
										key='download'
										disabled={!program?.isEditable || user?.userLevel === 9}
										onClick={() =>
											handleDownload({
												filename,
												name: `${parseElementName({ type })} ${index + 1} - ${program?.name}`,
											})
										}
									>
										<Icon type='download' /> Fazer download
									</Menu.Item>

									<Menu.Divider />

									<Menu.Item
										key='delete'
										className='ant-dropdown-menu-item-danger'
										disabled={!program?.isEditable || user?.userLevel === 9}
										onClick={() => {
											Modal.confirm({
												title: 'Excluir elemento?',
												icon: 'exclamation-circle',
												content:
													'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
												okText: 'Excluir',
												onOk: () => handleDeleteElement({ elementId: _id, type }),
												okButtonProps: {
													icon: 'delete',
													type: 'danger',
												},
												cancelText: 'Cancelar',
												cancelButtonProps: {
													icon: 'close-circle',
												},
											});
										}}
									>
										<Icon type='delete' /> Excluir
									</Menu.Item>
								</Menu>
							}
						>
							<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 16 }} type='more' />
						</Dropdown>
					),
				}
			: {},
	];

	const handleCreateElement = useCallback(async () => {
		try {
			if (hasValidationError(files)) {
				return message.warning(
					'Alguns arquivos selecionados estão corrompidos. Por favor, substitua-os.'
				);
			}

			const validationSchema = Yup.object().shape({
				files: Yup.array().required('Selecione os elementos'),
				type: Yup.string().required('Informe o tipo do elemento'),
			});

			await validationSchema.validate({ files, type });

			setFallback((prev) => ({ ...prev, creatingElement: true }));

			const payload = new FormData();

			payload.append('userId', user._id);
			payload.append('programId', program._id);
			payload.append('type', type);
			payload.append('category', 'OFF');

			[...files].forEach((file, i) => payload.append(`file-${i}`, file.data));

			const {
				data: { elements: newElements },
			} = await ElementsAPI.store({ payload });

			setFiles([]);
			setType(undefined);
			setElements((prev) => ({ ...prev, [type]: [...prev[type], ...newElements] }));
			setShowModal(false);
			setFallback((prev) => ({ ...prev, creatingElement: false }));
		} catch (error) {
			setFallback((prev) => ({ ...prev, creatingElement: false }));

			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}

			const errorMessage = error?.response?.data?.message;

			if (errorMessage) {
				message.error(errorMessage);
			}

			console.error(error);
		}
	}, [program, user, setElements, files, type, hasValidationError]);

	const handleDeleteElement = useCallback(async ({ elementId, type }) => {
		try {
			await ElementsAPI.custom.destroy(elementId);

			setElements((prev) => ({
				...prev,
				[type]: prev[type].filter((e) => e?._id !== elementId),
			}));

			return message.success('Elemento deletada com sucesso!');
		} catch (error) {
			console.error(error);
		}
	}, []);

	const handleDownload = useCallback(
		async ({ filename, name }) => {
			try {
				const ext = path.extname(filename);

				await download({ filename, name: `${name}${ext}` });
			} catch (error) {
				console.error(error);
			}
		},
		[download]
	);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setFallback((prev) => ({ ...prev, [tab]: true }));

				const query = `program=${program?._id}&userId=${user?._id}&type=${tab}`;
				const {
					data: { elements: newElements },
				} = await ElementsAPI.custom.index({ query });

				setLoadedTypes((prev) => ({ ...prev, [tab]: true }));
				setElements((prev) => ({ ...prev, [tab]: newElements }));
				setFallback((prev) => ({ ...prev, [tab]: false }));
			} catch (error) {
				console.error(error);
				message.error(error);
			}
		};

		if (!loadedTypes[tab]) {
			fetchData();
		}
	}, [tab, elements, program, loadedTypes, user]);

	return (
		<>
			<TabWrapper>
				<header>
					<Select style={{ width: 350 }} value={tab} onChange={(value) => setTab(value)}>
						{categorizedTypes.tkPlay.map((type) => (
							<Select.Option value={type} key={type}>
								{parseElementName({ type })}
							</Select.Option>
						))}
					</Select>

					{user.userLevel <= 1 && (
						<Button
							disabled={!program?.isEditable || user?.userLevel === 9}
							onClick={() => setShowModal(true)}
						>
							<FiPlus /> Novo TKPLAY
						</Button>
					)}
				</header>

				<Tabs size='small' activeKey={tab}>
					{categorizedTypes.tkPlay.map((type) => (
						<Tabs.TabPane tab={type} key={type}>
							{loadedTypes[type] && (
								<Table
									rowKey='_id'
									pagination={{ size: 'large' }}
									style={{ border: 'none' }}
									loading={fallback?.fetchingData}
									size='middle'
									columns={columns}
									dataSource={elements[type]}
									rowClassName='__row'
								/>
							)}
						</Tabs.TabPane>
					))}
				</Tabs>
			</TabWrapper>

			<Modal
				width={500}
				visible={showModal}
				closable={!fallback?.creatingElement}
				onOk={handleCreateElement}
				onCancel={() => setShowModal(false)}
				cancelButtonProps={{ disabled: fallback?.creatingElement }}
				okButtonProps={{ loading: fallback?.creatingElement, disabled: isValidating }}
				okText={
					<>
						{!isValidating && <FiUpload />}
						{isValidating ? 'Validando Arquivos' : 'Fazer upload'}
					</>
				}
				cancelText={
					<>
						<FiXCircle />
						Cancelar
					</>
				}
				title={
					<>
						<FiPlusCircle />
						Novo TKVOX
					</>
				}
			>
				<Form.Container>
					<Form.Item label='Tipo'>
						<Select onChange={(value) => setType(value)} value={type} placeholder='Tipo do TKVOX'>
							{categorizedTypes.tkPlay.map((type) => (
								<Select.Option key={type} value={type}>
									{getElementIcon({ type })}
									<span style={{ marginLeft: 4 }}>{parseElementName({ type })}</span>
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<FilesUploader
						multiple
						onChange={(files) => setFiles(files)}
						onStartValidation={() => setIsValidating(true)}
						onEndValidation={() => setIsValidating(false)}
						validTypes={['.mp3', '.wav']}
					/>
				</Form.Container>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps)(TkPlayOffs);
