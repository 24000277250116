import React, { useState, cloneElement, useCallback, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { Layout, Icon, Tooltip, Modal, Alert, Checkbox } from 'antd';
import TextTransition, { presets } from 'react-text-transition';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Player from '../PPlayer';
import GenerationStatus from '../GenerationStatus';
import LoggedUser from '../LoggedUser';
import {
	Container,
	Header,
	NavContainer,
	NamedLogo,
	NoNamedLogo,
	ActionsContainer,
	PlayButton as StyledPlayButton,
	AdminLabel,
	HeaderInner,
	TutorialVideo,
	AlertMessage,
	PaymentAlertContainer,
	Banners,
} from './styles';

import { usePlayer } from '../../hooks';

import namedLogoSrc from '../../assets/images/logos/logo-secondary-white.svg';
import noNamedLogoSrc from '../../assets/images/logos/logo-noname-white.svg';
import { BannerContext } from '../../contexts/BannersContext';
import { FiSettings, FiLogOut, FiHelpCircle } from 'react-icons/fi';
import BannersApi from '../../services/sdks/banners';

const PlayButton = () => {
	const [text, setText] = useState('Produzir Conteúdo');

	return (
		<Link to='/commom/episodes/create-episode'>
			<StyledPlayButton
				onMouseEnter={() => setText('Da o play!!')}
				onMouseLeave={() => setText('Produzir Conteúdo')}
				type='primary'
				shape='round'>
				<Icon type='play-circle' />
				<span>
					<TextTransition text={text} springConfig={presets.wobbly} />{' '}
				</span>
			</StyledPlayButton>
		</Link>
	);
};

const AppLayout = ({ user, children, navigation, isAdmin = false }) => {
	const { visibility: playerVisibility } = usePlayer();

	const [collapsed, setCollapsed] = useState(false);
	const [visibleModals, setVisibleModals] = useState({ tutorials: false });

	const [showTutoMessage, setShowTutoMessage] = useState(false);
	const [hideAlert, setHideAlert] = useState(false);
	const [destroyAlert, setDestroyAlert] = useState(false);

	const { banners } = useContext(BannerContext);

	const handleLogOut = useCallback(() => {
		localStorage.clear();
		sessionStorage.clear();
		window.location.replace('/');
	}, []);

	const setMonthPaymentAlertConset = () => {
		setDestroyAlert(true);

		document.querySelector('body').style.overflow = 'auto';

		if (!hideAlert) {
			return;
		}

		const month = new Date().getMonth();
		const year = new Date().getFullYear();

		localStorage.setItem('@PAYMENT_CONSENT', `${month}-${year}`);
	};

	const renderClosePaymentAlert = () => {
		const today = new Date().getDate();
		const month = new Date().getMonth();
		const year = new Date().getFullYear();

		const thisMonthConsent = localStorage.getItem('@PAYMENT_CONSENT');
		const haveSeenMessageThisMonth = `${month}-${year}` === thisMonthConsent;
		const paymentDay = user.paymentDay;
		const diff = paymentDay - today;

		if (
			// // !isAdmin &&
			// // user?.userLevel !== 9 &&
			!destroyAlert &&
			paymentDay &&
			diff > 0 &&
			diff <= 5 &&
			!haveSeenMessageThisMonth
		) {
			const body = document.querySelector('body');

			body.style.overflow = 'hidden';

			return ReactDOM.createPortal(
				<PaymentAlertContainer>
					<Alert
						showIcon
						description={
							<AlertMessage>
								<strong>
									Restam {paymentDay - today} dias até o vencimento da sua fatura, regularize-a para
									não perder o acesso à ferramenta.
								</strong>

								<p style={{ marginTop: 20 }}>
									Para efetuar o pagamento da mensalidade, acesse{' '}
									<a
										className='__link'
										href='https://www.talkradio.com.br/segunda-via-boletos'
										target='_blank'
										rel='noopener noreferrer'>
										www.talkradio.com.br/segunda-via-boletos
									</a>
									, na sequencia digite o CNPJ, efetue o pagamento e por gentileza nos encaminhe o
									comprovante para providenciar a baixa no sistema.
									<br />
									<br />
									Atenciosamente <strong>Grupo Talk Radio de Comunicação.</strong>
								</p>

								<span>Desconsidere em caso de fatura já paga.</span>

								<Checkbox
									checked={hideAlert}
									style={{ marginTop: 30 }}
									onChange={({ target: { checked } }) => setHideAlert(checked)}>
									Não exibir novamente esse mês
								</Checkbox>
							</AlertMessage>
						}
						type='warning'
						closable
						afterClose={() => setMonthPaymentAlertConset()}
						style={{ width: 500 }}
						message='Atenção'
					/>
				</PaymentAlertContainer>,
				body
			);
		}
	};

	const handleCreateBannerView = useCallback(async (bannerId) => {
		try {
			await BannersApi.createBannerView(bannerId);
		} catch (error) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		const sleep = (time) => new Promise((resolve) => setTimeout(() => resolve(), time));

		(async () => {
			if (localStorage.getItem('tuto-displayed') !== 'true') {
				localStorage.setItem('tuto-displayed', 'true');

				await sleep(2000);
				setShowTutoMessage(true);

				await sleep(10000);
				setShowTutoMessage(false);
			}
		})();
	}, []);

	return (
		<Container
			style={{
				paddingBottom: playerVisibility === 'full' ? '120px' : '0px',
				paddingLeft: user?.userLevel === 9 ? '0' : collapsed ? '80px' : '260px',
			}}
			isadmin={isAdmin.toString()}>
			{user?.userLevel !== 9 && (
				<NavContainer
					collapsible
					width={260}
					theme='dark'
					isadmin={isAdmin.toString()}
					collapsed={collapsed}
					style={{ paddingBottom: playerVisibility === 'full' ? '220px' : '0px' }}
					onCollapse={(collapsed) => setCollapsed(collapsed)}>
					{collapsed ? (
						<NoNamedLogo>
							<img src={noNamedLogoSrc} alt='TalkPlay' />
						</NoNamedLogo>
					) : (
						<NamedLogo>
							<img src={namedLogoSrc} alt='TalkPlay' />
						</NamedLogo>
					)}
					<AdminLabel>{isAdmin && !collapsed && <span>Painel Administrativo</span>}</AdminLabel>

					{cloneElement(navigation, { collapsed })}
				</NavContainer>
			)}

			<Layout style={{ position: 'relative' }}>
				<Header
					style={{
						width:
							user?.userLevel === 9
								? '100%'
								: collapsed
								? 'calc(100% - 80px)'
								: 'calc(100% - 260px)',
						left: user?.userLevel === 9 ? '0' : collapsed ? '80px' : '260px',
					}}>
					<HeaderInner>
						{!isAdmin && user?.userLevel !== 9 && <PlayButton />}
						{isAdmin && <GenerationStatus />}
						<ActionsContainer>
							<Tooltip
								visible={showTutoMessage}
								placement='bottom'
								title={
									<>
										Primeira vez por aqui ?
										<br />
										Confira nosso tutorial inicial.
									</>
								}>
								<Link to='/help'>
									<FiHelpCircle
										style={{ cursor: 'pointer' }}
									/>
								</Link>
							</Tooltip>

							{user?.userLevel !== 9 && (
								<Link to='/commom/settings'>
									<Tooltip title='Configurações' placement='bottom'>
										<FiSettings />
									</Tooltip>
								</Link>
							)}

							<Tooltip title='Sair' placement='bottom'>
								<FiLogOut onClick={handleLogOut} style={{ cursor: 'pointer' }} />
							</Tooltip>
						</ActionsContainer>

						<LoggedUser />
					</HeaderInner>
				</Header>
				<Layout.Content
					style={{
						position: 'relative',
						paddingTop: '48px',
						margin: '0 16px',
						paddingBottom:
							playerVisibility === 'full' ? '120px' : playerVisibility === 'minimized' ? '50px' : 0,
					}}>
					<Banners>
						{banners.map((banner) => (
							<Alert
								closable
								showIcon
								banner={true}
								type='warning'
								style={{ marginBottom: 3 }}
								message={banner.title}
								description={banner.message}
								onClose={() => handleCreateBannerView(banner._id)}
							/>
						))}
					</Banners>
					{children}
					<Player />
				</Layout.Content>
			</Layout>

			<Modal
				centered
				destroyOnClose
				closable={false}
				visible={visibleModals?.tutorials}
				width={1000}
				title={null}
				bodyStyle={{ padding: 8 }}
				footer={null}
				onCancel={() => setVisibleModals({ ...visibleModals, tutorials: false })}>
				<TutorialVideo
					allowFullScreen
					frameBorder='0'
					src='https://www.youtube.com/embed/mdhdIn9l-SQ'
					height='520'
				/>
			</Modal>

			{renderClosePaymentAlert()}
		</Container>
	);
};

export default connect(({ user }) => ({ user }))(AppLayout);
