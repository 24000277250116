import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import TutorialsRoutes from './Tutorials.routes';

import ADMContactRequests from '../pages/Admin/ContactRequests';

import NotFound from '../pages/Exceptions/_404';

import AppLayout from '../components/Layout';
import TutorialsLayout from '../components/TutorialsLayout';

import { SellerNavigation } from '../components/Navigation';

const AdminStack = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path='/commom'>
				<Redirect to='/commom/programs' />
			</Route>
			<Route exact path={['/', '/admin', '/login', '/loginAdmin']}>
				<Redirect to='/admin/contact-requests' />
			</Route>

			<Route exact path='/admin/contact-requests'>
				<AppLayout isAdmin={true} navigation={<SellerNavigation />}>
					<ADMContactRequests />
				</AppLayout>
			</Route>

			<TutorialsLayout>
				<TutorialsRoutes />
			</TutorialsLayout>

			<Route exact path='*'>
				<NotFound />
			</Route>
		</Switch>
	</BrowserRouter>
);

export default AdminStack;
