import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { format, parseISO, addMonths } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import path from 'path';
import axios from 'axios';
import JSZip from 'jszip';
import * as Yup from 'yup';
import {
	Table,
	Button,
	Icon,
	Modal,
	Input,
	Select,
	message,
	Menu,
	Dropdown,
	Typography,
	Breadcrumb,
	Divider,
	Tabs,
	Card,
	Checkbox,
	Slider,
	DatePicker,
	Switch,
	Tag,
} from 'antd';

import Meta from '../../../components/Meta';
import Form from '../../../components/Form';
import Fallback from '../../../components/Fallback';
import PlayCell from '../../../components/PlayCell';
import Progress from '../../../components/Progress';
import FileExt from '../../../components/FileExt';
import FileDuration from '../../../components/FileDuration';
import {
	PageHeader,
	CardContainer,
	TableHeader,
	DetailsContainer,
	SwitchContainer,
} from './styles';

import TracksAPI from '../../../services/sdks/tracks';
import CategoriesAPI from '../../../services/sdks/categories';
import { resolveFileSrc } from '../../../helpers/fileSrcResolver';

import {
	FiPlusCircle,
	FiDownload,
	FiEdit2,
	FiSave,
	FiXCircle,
	FiTrash2,
	FiRefreshCw,
	FiGlobe,
	FiUser,
	FiEye,
	FiEdit3,
	FiUpload,
} from 'react-icons/fi';

import { usePlayer, useDownload, useFilesValidator } from '../../../hooks';
import FilesUploader from '../../../components/FilesUploader';

const Tracks = ({ user }) => {
	const player = usePlayer();
	const download = useDownload();

	const [fallback, setFallback] = useState({ initialData: true });
	const [categories, setCategories] = useState([]);
	const [multiCategChange, setMultiCategChange] = useState({
		dataBase: null,
		categoryId: undefined,
	});
	const [handlingTrack, setHandlingTrack] = useState(null);
	const [selectedTracks, setSelectedTracks] = useState({
		dbTracks: [],
		userTracks: [],
		tracksForDownload: [],
	});
	const [tracks, setTracks] = useState({ dbTracks: [], userTracks: [], tracksForDownload: [] });
	const [visibleModals, setVisibleModals] = useState({});
	const [progress, setProgress] = useState(null);
	const [pagination, setPagination] = useState({
		dbTracks: { current: 1, pageSize: 10, total: null },
		userTracks: { current: 1, pageSize: 10, total: null },
		tracksForDownload: { current: 1, pageSize: 10, total: null },
	});

	const [isValidating, setIsValidating] = useState(false);
	const { hasValidationError } = useFilesValidator();

	const [filters, setFilters] = useState({
		dbTracks: {
			categoryId: undefined,
			artist: '',
			name: '',
			forDownload: '',
		},
		userTracks: {
			categoryId: undefined,
			artist: '',
			name: '',
		},
		tracksForDownload: {
			categoryId: undefined,
			artist: '',
			name: '',
		},
	});
	const [filtersStates, setFiltersStates] = useState({
		dbTracks: {
			categoryId: undefined,
			artist: '',
			name: '',
		},
		userTracks: {
			categoryId: undefined,
			artist: '',
			name: '',
		},
		tracksForDownload: {
			categoryId: undefined,
			artist: '',
			name: '',
		},
	});

	const columns = [
		{
			key: 'meta',
			title: 'Título',
			render: (track) => (
				<PlayCell
					meta={{ primary: track?.name, secondary: track?.artist }}
					onPlay={() => {
						player.start({
							src: resolveFileSrc({ fileName: track.filename }),
							ref: track?._id,
							meta: { name: track?.name, artist: track?.artist },
						});
					}}
					onPause={player.resume}
					isPlaying={player?.ref === track?._id && player?.isPlaying}
				/>
			),
		},
		{ key: 'category', title: 'Categoria', render: (music) => music?.categoryId?.name || '-' },
		[0, 5].includes(user?.userLevel)
			? {
					key: 'download',
					title: 'Download',
					align: 'center',
					render: (track) => {
						return track?.forDownload ? (
							<Tag color='green'>Disponível</Tag>
						) : (
							<Tag color='red'>Não disponível</Tag>
						);
					},
			  }
			: {},
		{
			key: 'duration',
			title: 'Duração',
			align: 'center',
			render: (track) => <FileDuration src={resolveFileSrc({ fileName: track.filename })} />,
		},
		{
			key: 'ext',
			title: 'Formato',
			align: 'center',
			render: (track) => <FileExt src={resolveFileSrc({ fileName: track.filename })} />,
		},
	];

	const handleSingleDelete = useCallback(
		async ({ trackId, dataBase, showSuccessMessage = true }) => {
			try {
				await TracksAPI.destroy({ trackId });

				setTracks((prev) => ({
					...prev,
					[dataBase]: prev[dataBase].filter((track) => track._id !== trackId),
				}));

				setPagination((prev) => ({
					...prev,
					[dataBase]: {
						...prev[dataBase],
						total: prev[dataBase].total - 1,
					},
				}));

				if (showSuccessMessage) return message.success('Música deletada com sucesso!');
			} catch (error) {
				console.error(error);
				message.error('Erro ao deletar música!');
			}
		},
		[]
	);

	const handleMultiDownload = useCallback(
		async ({ dataBase }) => {
			try {
				setFallback((prev) => ({ ...prev, multiDownload: true }));

				const zip = new JSZip();

				for (let index = 0; index < selectedTracks[dataBase].length; index++) {
					const track = selectedTracks[dataBase][index];
					const filePath = resolveFileSrc({ fileName: track.filename });
					const blob = await axios.get(filePath, { responseType: 'blob' });
					const name = `${track.artist} - ${track.name}`;
					const ext = path.extname(track?.filename);
					const fileName = `${name}${ext}`;

					zip.file(fileName, blob.data, { binary: true });
				}

				const zipName = 'MÚSICAS';
				const zipContent = await zip.generateAsync({ type: 'blob' });

				saveAs(zipContent, zipName);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao baixar as músicas, tente novamente');
			} finally {
				setFallback((prev) => ({ ...prev, multiDownload: false }));
			}
		},
		[selectedTracks]
	);

	const handleMultiDelete = useCallback(
		async ({ dataBase }) => {
			try {
				setFallback((prev) => ({ ...prev, multiDelete: true }));

				const ids = selectedTracks[dataBase].map((t) => t._id);
				await TracksAPI.deleteMany(ids);

				setTracks((prev) => ({
					...prev,
					[dataBase]: prev[dataBase].filter((t) => !ids.includes(t._id)),
				}));

				setSelectedTracks((prev) => ({
					...prev,
					[dataBase]: [],
				}));

				message.success('Músicas deletadas com sucesso');
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao deletar as músicas, tente novamente');
			}

			setFallback((prev) => ({ ...prev, multiDelete: true }));
		},
		[selectedTracks]
	);

	const handleSingleUpdate = useCallback(
		async ({ track }) => {
			try {
				setFallback((prev) => ({ ...prev, editing: true }));

				const { _id: trackId, dataBase, ...payload } = track;

				delete payload.filename;
				delete payload.autoCategChange;

				const validationSchema = Yup.object().shape({
					name: Yup.string().required('Informe o nome'),
					artist: Yup.string().required('Informe o artista'),
					categoryId: Yup.string().required('Informe a categoria'),
				});

				await validationSchema.validate(payload);

				const {
					data: { track: updatedTrack },
				} = await TracksAPI.update({ trackId, payload });

				const updatedTrackCategory = categories.find((c) => c?._id === payload?.categoryId);
				const updatedTrackCategoryChange =
					categories.find((c) => c?._id === payload?.categoryChange?.replacer) || null;

				updatedTrack.categoryId = updatedTrackCategory;
				updatedTrack.categoryChange.replacer = updatedTrackCategoryChange;

				setTracks((prev) => ({
					...prev,
					[dataBase]: prev[dataBase].map((t) => {
						return t?._id === trackId ? updatedTrack : t;
					}),
				}));

				setVisibleModals((prev) => ({ ...prev, edit: false }));
			} catch (error) {
				if (error instanceof Yup.ValidationError) {
					return message.error(error.message);
				}

				console.error(error);
				message.error(`Houve um erro ao editar a música, tente novamente`);
			} finally {
				setFallback((prev) => ({ ...prev, editing: false }));
			}
		},
		[categories]
	);

	const handleChangeManyCategories = useCallback(async () => {
		try {
			setFallback((prev) => ({ ...prev, multiCategChange: true }));

			const { categoryId, dataBase } = multiCategChange;

			for (let index = 0; index < selectedTracks[dataBase].length; index++) {
				await handleSingleUpdate({
					track: {
						...selectedTracks[dataBase][index],
						categoryId,
						dataBase,
					},
				});
			}

			setMultiCategChange({ dataBase: null, categoryId: undefined });
			setVisibleModals((prev) => ({ ...prev, multiCategChange: false }));

			message.success('Categorias alteradas com sucesso');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		} finally {
			setFallback((prev) => ({ ...prev, multiCategChange: false }));
		}
	}, [selectedTracks, handleSingleUpdate, multiCategChange]);

	const handleAllowManyToDownload = useCallback(async () => {
		try {
			for (let index = 0; index < selectedTracks.dbTracks.length; index++) {
				await handleSingleUpdate({
					track: {
						...selectedTracks.dbTracks[index],
						forDownload: true,
						dataBase: 'dbTracks',
					},
				});
			}

			setTracks((prev) => ({
				...prev,
				tracksForDownload: [...prev.tracksForDownload, ...selectedTracks.dbTracks],
			}));

			message.success('Músicas disponibilizadas para download');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, [selectedTracks, handleSingleUpdate]);

	const isTrackFilenameValid = useCallback((filename) => {
		if (filename.split('-').length !== 2) {
			return false;
		}

		return true;
	}, []);

	const handleUploadSongs = useCallback(async () => {
		try {
			if (!handlingTrack?.files?.length) return message.warn('Seleciona no mínimo um arquivo');
			if (!handlingTrack?.categoryId) return message.warn('Informe a categoria das músicas');

			if (hasValidationError(handlingTrack.files)) {
				return message.warning(
					'Alguns arquivos selecionados estão corrompidos ou com nome fora do padrão. Por favor, substitua-os.'
				);
			}

			setFallback((prev) => ({ ...prev, creating: true }));

			const payload = new FormData();

			payload.append('categoryId', handlingTrack.categoryId);
			payload.append('forDownload', handlingTrack.forDownload ?? false);
			payload.append('categoryChange', JSON.stringify(handlingTrack.categoryChange));
			handlingTrack.files.forEach((file) => payload.append('file', file.data));

			const {
				data: { tracks: createdTracks },
			} = await TracksAPI.store({
				payload,
				onUploadProgress: ({ total, loaded }) => {
					const percentage = Math.floor((loaded * 100) / total);
					setProgress(percentage);
				},
			});

			const newTarcksCategory = categories.find((c) => c?._id === handlingTrack?.categoryId);

			setTracks((prev) => {
				return user?.userLevel === 5
					? {
							...prev,
							dbTracks: [
								...prev.dbTracks,
								...createdTracks.map((t) => ({ ...t, categoryId: newTarcksCategory })),
							],
					  }
					: {
							...prev,
							userTracks: [
								...prev.userTracks,
								...createdTracks.map((t) => ({ ...t, categoryId: newTarcksCategory })),
							],
					  };
			});

			setProgress(null);
			setHandlingTrack(null);
			setVisibleModals((prev) => ({ ...prev, create: false }));

			/** Aguarda 2 sgundos para fechar o Progress */
			await new Promise((resolve) => setTimeout(() => resolve(), 2000));
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}

		setFallback((prev) => ({ ...prev, creating: false }));
	}, [handlingTrack, user, categories, hasValidationError]);

	const isUploadButtonDisabled = useMemo(() => {
		return isValidating || !handlingTrack?.files?.length || !handlingTrack?.categoryId;
	}, [isValidating, handlingTrack]);

	const renderActions = useCallback(
		({ track, dataBase }) => (
			<Dropdown
				placement='bottomLeft'
				overlay={
					<Menu>
						<Menu.Item
							key='details'
							onClick={() => {
								setHandlingTrack(track);
								setVisibleModals({ ...visibleModals, details: true });
							}}>
							<Icon type='eye' /> Detalhes
						</Menu.Item>

						<Menu.Item
							key='play-track'
							onClick={() => {
								if (player?.ref === track?._id && player?.isPlaying) {
									return player.resume();
								}

								player.start({
									src: resolveFileSrc({ fileName: track.filename }),
									ref: track?._id,
									meta: { name: track?.name, artist: track?.artist },
								});
							}}>
							<Icon type='play-circle' /> Reproduzir
						</Menu.Item>

						<Menu.Item
							key='edit-track'
							onClick={() => {
								setHandlingTrack({
									...track,
									dataBase,
									autoCategChange: !!track?.categoryChange?.replacer,
									categoryId: track?.categoryId?._id,
									categoryChange: {
										...track?.categoryChange,
										replacer: track?.categoryChange?.replacer?._id || undefined,
									},
								});
								setVisibleModals({ ...visibleModals, edit: true });
							}}>
							<Icon type='edit' /> Editar
						</Menu.Item>

						<Menu.Item
							key='download-track'
							onClick={() => {
								download({
									filename: track?.filename,
									name: `${track?.artist} - ${track?.name}`,
								});
							}}>
							<Icon type='download' /> Fazer download
						</Menu.Item>

						<Menu.Divider />

						<Menu.Item
							key='delete'
							className='ant-dropdown-menu-item-danger'
							onClick={() => {
								Modal.confirm({
									title: 'Deletar música?',
									icon: 'exclamation-circle',
									content:
										'Essa ação não poderá ser revertida, realmente deseja continuar mesmo assim?',
									okText: 'Excluir',
									onOk: () => handleSingleDelete({ trackId: track?._id, dataBase }),
									okButtonProps: {
										icon: 'delete',
										type: 'danger',
									},
									cancelText: 'Cancelar',
									cancelButtonProps: {
										icon: 'close-circle',
									},
								});
							}}>
							<Icon type='delete' /> Excluir
						</Menu.Item>
					</Menu>
				}>
				<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 16 }} type='more' />
			</Dropdown>
		),
		[handleSingleDelete, download, player, visibleModals]
	);

	useEffect(() => {
		setPagination(({ dbTracks, ...rest }) => ({
			...rest,
			dbTracks: { ...dbTracks, current: 1 },
		}));
	}, [filters.dbTracks]);

	useEffect(() => {
		setPagination(({ userTracks, ...rest }) => ({
			...rest,
			userTracks: { ...userTracks, current: 1 },
		}));
	}, [filters.userTracks]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { categories },
				} = await CategoriesAPI.index();

				setCategories(categories);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar as categorias');
			} finally {
				setFallback((prev) => ({ ...prev, initialData: false }));
			}
		};

		fetchInitialData();
	}, []);

	useEffect(() => {
		const fetchDbTracks = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingDbTracks: true }));

				let query = `page=${pagination?.dbTracks?.current - 1}&limit=${
					pagination?.dbTracks?.pageSize
				}&active=true`;

				let { categoryId, artist, name, forDownload } = filters?.dbTracks;

				artist = artist.replace('&', '|');
				name = name.replace('&', '|');

				forDownload !== '' && (query = `${query}&forDownload=${forDownload}`);
				categoryId && (query = `${query}&categoryId=${categoryId}`);
				artist.length && (query = `${query}&artist=__REGEX__${artist}`);
				name && (query = `${query}&name=__REGEX__${name}`);

				const {
					data: { tracks, total },
				} = await TracksAPI.index({ query });

				setTracks((prev) => ({ ...prev, dbTracks: tracks }));
				setPagination(({ dbTracks, ...prev }) => ({ ...prev, dbTracks: { ...dbTracks, total } }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar as músicas do banco.');
			} finally {
				setFallback((prev) => ({ ...prev, fetchingDbTracks: false }));
			}
		};

		if (user?.tracksDB) {
			fetchDbTracks();
		}
	}, [user, filters.dbTracks, pagination.dbTracks?.current, pagination?.dbTracks?.pageSize]); //eslint-disable-line

	useEffect(() => {
		const fetchTracksForDownload = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingTracksForDownload: true }));

				let query = `page=${pagination?.tracksForDownload?.current - 1}&limit=${
					pagination?.tracksForDownload?.pageSize
				}&active=true&forDownload=true`;

				let { categoryId, artist, name } = filters?.tracksForDownload;

				artist = artist.replace('&', '|');
				name = name.replace('&', '|');

				categoryId && (query = `${query}&categoryId=${categoryId}`);
				artist.length && (query = `${query}&artist=__REGEX__${artist}`);
				name && (query = `${query}&name=__REGEX__${name}`);

				const {
					data: { tracks, total },
				} = await TracksAPI.index({ query });

				setTracks((prev) => ({ ...prev, tracksForDownload: tracks }));
				setPagination(({ tracksForDownload, ...prev }) => ({
					...prev,
					tracksForDownload: { ...tracksForDownload, total },
				}));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar as músicas para download.');
			} finally {
				setFallback((prev) => ({ ...prev, fetchingTracksForDownload: false }));
			}
		};

		if (user?.allowTracksDownload) {
			fetchTracksForDownload();
		}

		//eslint-disable-next-line
	}, [
		user,
		filters.tracksForDownload, //eslint-disable-next-line
		pagination.tracksForDownload?.current, //eslint-disable-next-line
		pagination?.tracksForDownload?.pageSize,
	]);

	useEffect(() => {
		const fetchUserTracks = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingUserTracks: true }));

				let query = `page=${pagination?.userTracks?.current - 1}&limit=${
					pagination?.userTracks?.pageSize
				}&active=false&userId=${user?._id}`;

				let { categoryId, artist, name } = filters?.userTracks;

				artist = artist.replace('&', '|');
				name = name.replace('&', '|');

				categoryId && (query = `${query}&categoryId=${categoryId}`);
				artist.length && (query = `${query}&artist=__REGEX__${artist}`);
				name && (query = `${query}&name=__REGEX__${name}`);

				const {
					data: { tracks, total },
				} = await TracksAPI.index({ query });

				setTracks((prev) => ({ ...prev, userTracks: tracks }));
				setPagination(({ userTracks, ...prev }) => ({
					...prev,
					userTracks: { ...userTracks, total },
				}));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar as músicas do banco.');
			} finally {
				setFallback((prev) => ({ ...prev, fetchingUserTracks: false }));
			}
		};

		fetchUserTracks();
	}, [user, filters.userTracks, pagination.userTracks?.current, pagination?.userTracks?.pageSize]); //eslint-disable-line

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Músicas' />

			<PageHeader>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[{ breadcrumbName: 'INÍCIO' }, { breadcrumbName: 'BANCO MUSICAL' }]}
				/>

				<header>
					<Typography.Title level={2}>Banco Musical</Typography.Title>

					<div>
						<Button
							type='primary'
							onClick={() => {
								setHandlingTrack({
									files: [],
									categoryId: undefined,
									categoryChange: {
										replacer: undefined,
										date: null,
									},
								});
								setVisibleModals((prev) => ({ ...prev, create: true }));
							}}>
							<FiPlusCircle /> Adicionar Músicas
						</Button>
					</div>
				</header>
			</PageHeader>

			<CardContainer>
				<Card className='_card'>
					<Tabs size='large'>
						<Tabs.TabPane
							key='db-tracks'
							disabled={!user?.tracksDB}
							tab={
								<>
									<FiGlobe /> Músicas da Rede
								</>
							}>
							<div className='tab-content'>
								<Form.Container layout='3fr 2.5fr 2.5fr 2fr'>
									<Form.Item label='Filtrar por categoria'>
										<Select
											showSearch
											disabled={fallback?.fetchingDbTracks}
											placeholder='Selecione uma categoria'
											value={filters?.dbTracks?.categoryId}
											onChange={(value) =>
												setFilters(({ dbTracks, ...filters }) => ({
													...filters,
													dbTracks: { ...dbTracks, categoryId: value },
												}))
											}
											filterOption={(input, { props: { children } }) => {
												return String(children).match(new RegExp(input, 'i'));
											}}>
											<Select.Option value=''>TODAS</Select.Option>

											{categories.map((category) => (
												<Select.Option value={category?._id} key={category?._id}>
													{category?.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item label='Buscar por artista' help='Informe no mínimo 3 caracteres'>
										<Input.Search
											allowClear
											disabled={fallback?.fetchingDbTracks}
											placeholder='Digite o nome do artista'
											value={filtersStates?.dbTracks?.artist}
											onChange={({ target: { value } }) => {
												setFiltersStates({
													...filtersStates,
													dbTracks: { ...filtersStates.dbTracks, artist: value },
												});

												if (!value) {
													setFilters({
														...filters,
														dbTracks: { ...filters.dbTracks, artist: '' },
													});
												}
											}}
											onSearch={(value) => {
												setFilters({
													...filters,
													dbTracks: { ...filters.dbTracks, artist: value },
												});
											}}
										/>
									</Form.Item>
									<Form.Item
										label='Buscar por nome da música'
										help='Informe no mínimo 3 caracteres'>
										<Input.Search
											allowClear
											disabled={fallback?.fetchingDbTracks}
											placeholder='Digite o nome da música'
											value={filtersStates?.dbTracks?.name}
											onChange={({ target: { value } }) => {
												setFiltersStates({
													...filtersStates,
													dbTracks: { ...filtersStates.dbTracks, name: value },
												});

												if (!value) {
													setFilters({
														...filters,
														dbTracks: { ...filters.dbTracks, name: '' },
													});
												}
											}}
											onSearch={(value) => {
												setFilters({
													...filters,
													dbTracks: { ...filters.dbTracks, name: value },
												});
											}}
										/>
									</Form.Item>
									<Form.Item label='Download'>
										<Select
											showSearch
											disabled={fallback?.fetchingDbTracks}
											value={filters?.dbTracks?.forDownload}
											onChange={(value) =>
												setFilters(({ dbTracks, ...filters }) => ({
													...filters,
													dbTracks: { ...dbTracks, forDownload: value },
												}))
											}
											filterOption={(input, { props: { children } }) => {
												return String(children).match(new RegExp(input, 'i'));
											}}>
											<Select.Option value=''>TODAS</Select.Option>
											<Select.Option value={'true'}>Somente disponívels</Select.Option>
											<Select.Option value={'false'}>Somente não disponíveis</Select.Option>
										</Select>
									</Form.Item>
								</Form.Container>

								<Divider />

								<TableHeader>
									<div className='actions'>
										<span>
											Quantidade: <strong>{pagination?.dbTracks?.total}</strong>
										</span>
										{[0, 5].includes(user?.userLevel) && (
											<div>
												<Button
													size='small'
													disabled={!selectedTracks.dbTracks.length}
													onClick={() => {
														setMultiCategChange({ ...multiCategChange, dataBase: 'dbTracks' });
														setVisibleModals({ ...visibleModals, multiCategChange: true });
													}}
													loading={fallback?.multiCategChange}>
													<FiRefreshCw /> Alterar categoria
													{selectedTracks.dbTracks.length !== 0 &&
														`(${selectedTracks.dbTracks.length})`}
												</Button>

												<Button
													size='small'
													disabled={!selectedTracks.dbTracks.length}
													onClick={() => {
														Modal.confirm({
															title: 'Disponibilizar músicas para download?',
															icon: 'exclamation-circle',
															content:
																'As músicas ficarão disponíveis para que usuários com acesso ao módulo de download de músicsa possam baixá-las, deseja continuar?',
															okText: 'Confirmar',
															onOk: handleAllowManyToDownload,
															okButtonProps: {
																icon: 'check-circle',
																type: 'primary',
															},
															cancelText: 'Cancelar',
															cancelButtonProps: {
																icon: 'close-circle',
															},
														});
													}}
													loading={fallback?.multiCategChange}>
													<FiEdit3 /> Disponibilizar para download
													{selectedTracks.dbTracks.length !== 0 &&
														`(${selectedTracks.dbTracks.length})`}
												</Button>

												<Button
													size='small'
													disabled={!selectedTracks.dbTracks.length}
													onClick={() => handleMultiDownload({ dataBase: 'dbTracks' })}
													loading={fallback?.multiDownload}>
													<FiDownload /> Baixar
													{selectedTracks.dbTracks.length !== 0 &&
														`(${selectedTracks.dbTracks.length})`}
												</Button>

												<Button
													size='small'
													type='danger'
													disabled={!selectedTracks.dbTracks.length}
													loading={fallback?.multiDelete}
													onClick={() => {
														Modal.confirm({
															title: 'Deletar músicas selecionadas?',
															icon: 'exclamation-circle',
															content:
																'Essa ação não poderá ser revertida, realmente deseja continuar mesmo assim?',
															okText: 'Excluir',
															onOk: () => handleMultiDelete({ dataBase: 'dbTracks' }),
															okButtonProps: {
																icon: 'delete',
																type: 'danger',
															},
															cancelText: 'Cancelar',
															cancelButtonProps: {
																icon: 'close-circle',
															},
														});
													}}>
													<FiTrash2 /> Excluir
													{selectedTracks.dbTracks.length !== 0 &&
														`(${selectedTracks.dbTracks.length})`}
												</Button>
											</div>
										)}
									</div>

									<Select
										size='small'
										value={pagination?.dbTracks?.pageSize}
										onChange={(pageSize) => {
											setPagination(({ dbTracks, ...prev }) => ({
												...prev,
												dbTracks: { ...dbTracks, pageSize },
											}));
										}}>
										<Select.Option value={5}>5 itens por página</Select.Option>
										<Select.Option value={10}>10 itens por página</Select.Option>
										<Select.Option value={20}>20 itens por página</Select.Option>
										<Select.Option value={50}>50 itens por página</Select.Option>
										<Select.Option value={100}>100 itens por página</Select.Option>
										<Select.Option value={500}>500 itens por página</Select.Option>
									</Select>
								</TableHeader>

								<Table
									rowKey='_id'
									size='middle'
									columns={
										[0, 5].includes(user?.userLevel)
											? [
													...columns,
													{
														align: 'end',
														title: 'Ações',
														render: (track) => {
															return renderActions({ track, dataBase: 'dbTracks' });
														},
													},
											  ]
											: columns
									}
									dataSource={tracks?.dbTracks}
									style={{ border: 'none' }}
									loading={fallback?.fetchingDbTracks}
									pagination={{
										...pagination.dbTracks,
										size: 'large',
										onChange: (current) => {
											setPagination({
												...pagination,
												dbTracks: { ...pagination.dbTracks, current },
											});
										},
									}}
									rowSelection={
										[0, 5].includes(user.userLevel)
											? {
													onChange: (_, selectedRows) => {
														setSelectedTracks({ ...selectedTracks, dbTracks: selectedRows });
													},
											  }
											: undefined
									}
								/>
							</div>
						</Tabs.TabPane>

						<Tabs.TabPane
							key='user-tracks'
							tab={
								<>
									<FiUser /> Minhas Músicas
								</>
							}>
							<div className='tab-content'>
								<Form.Container layout='30% 30% 30%'>
									<Form.Item label='Filtrar por categoria'>
										<Select
											showSearch
											placeholder='Selecione uma categoria'
											value={filters?.userTracks?.categoryId}
											onChange={(value) =>
												setFilters(({ userTracks, ...filters }) => ({
													...filters,
													userTracks: { ...userTracks, categoryId: value },
												}))
											}
											filterOption={(input, { props: { children } }) => {
												return String(children).match(new RegExp(input, 'i'));
											}}>
											{categories.map((category) => (
												<Select.Option value={category?._id} key={category?._id}>
													{category?.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item label='Buscar por artisa' help='Informe no mínimo 3 caracteres'>
										<Input.Search
											allowClear
											placeholder='Digite o nome do artista'
											value={filtersStates?.userTracks?.artist}
											onChange={({ target: { value } }) => {
												setFiltersStates({
													...filtersStates,
													userTracks: { ...filtersStates.userTracks, artist: value },
												});

												if (!value) {
													setFilters({
														...filters,
														userTracks: { ...filters.userTracks, artist: '' },
													});
												}
											}}
											onSearch={(value) => {
												setFilters({
													...filters,
													userTracks: { ...filters.userTracks, artist: value },
												});
											}}
										/>
									</Form.Item>
									<Form.Item
										label='Buscar por nome da música'
										help='Informe no mínimo 3 caracteres'>
										<Input.Search
											allowClear
											placeholder='Digite o nome da música'
											value={filtersStates?.userTracks?.name}
											onChange={({ target: { value } }) => {
												setFiltersStates({
													...filtersStates,
													userTracks: { ...filtersStates.userTracks, name: value },
												});

												if (!value) {
													setFilters({
														...filters,
														userTracks: { ...filters.userTracks, name: '' },
													});
												}
											}}
											onSearch={(value) => {
												setFilters({
													...filters,
													userTracks: { ...filters.userTracks, name: value },
												});
											}}
										/>
									</Form.Item>
								</Form.Container>

								<Divider />

								<TableHeader>
									<div className='actions'>
										<span>
											Quantidade: <strong>{pagination?.userTracks?.total}</strong>
										</span>
										<div>
											<Button
												size='small'
												disabled={!selectedTracks.userTracks.length}
												onClick={() => handleMultiDownload({ dataBase: 'userTracks' })}
												loading={fallback?.multiDownload}>
												<FiDownload /> Baixar
												{selectedTracks.userTracks.length !== 0 &&
													`(${selectedTracks.userTracks.length})`}
											</Button>

											<Button
												size='small'
												disabled={!selectedTracks.userTracks.length}
												onClick={() => {
													setMultiCategChange({ ...multiCategChange, dataBase: 'userTracks' });
													setVisibleModals({ ...visibleModals, multiCategChange: true });
												}}
												loading={fallback?.multiCategChange}>
												<FiRefreshCw /> Alterar categoria
												{selectedTracks.userTracks.length !== 0 &&
													`(${selectedTracks.userTracks.length})`}
											</Button>

											<Button
												size='small'
												type='danger'
												disabled={!selectedTracks.userTracks.length}
												loading={fallback?.multiDelete}
												onClick={() => {
													Modal.confirm({
														title: 'Deletar músicas selecionadas?',
														icon: 'exclamation-circle',
														content:
															'Essa ação não poderá ser revertida, realmente deseja continuar mesmo assim?',
														okText: 'Excluir',
														onOk: () => handleMultiDelete({ dataBase: 'userTracks' }),
														okButtonProps: {
															icon: 'delete',
															type: 'danger',
														},
														cancelText: 'Cancelar',
														cancelButtonProps: {
															icon: 'close-circle',
														},
													});
												}}>
												<FiTrash2 /> Excluir
												{selectedTracks.userTracks.length !== 0 &&
													`(${selectedTracks.userTracks.length})`}
											</Button>
										</div>
									</div>

									<Select
										size='small'
										value={pagination?.userTracks?.pageSize}
										onChange={(pageSize) => {
											setPagination(({ userTracks, ...prev }) => ({
												...prev,
												userTracks: { ...userTracks, pageSize },
											}));
										}}>
										<Select.Option value={5}>5 itens por página</Select.Option>
										<Select.Option value={10}>10 itens por página</Select.Option>
										<Select.Option value={20}>20 itens por página</Select.Option>
										<Select.Option value={50}>50 itens por página</Select.Option>
										<Select.Option value={100}>100 itens por página</Select.Option>
										<Select.Option value={500}>500 itens por página</Select.Option>
									</Select>
								</TableHeader>

								<Table
									rowKey='_id'
									size='middle'
									columns={[
										...columns,
										{
											align: 'end',
											title: 'Ações',
											render: (track) => {
												return renderActions({ track, dataBase: 'userTracks' });
											},
										},
									]}
									dataSource={tracks?.userTracks}
									style={{ border: 'none' }}
									loading={fallback?.fetchingUserTracks}
									pagination={{
										...pagination.userTracks,
										size: 'large',
										onChange: (current) => {
											setPagination({
												...pagination,
												userTracks: { ...pagination.userTracks, current },
											});
										},
									}}
									rowSelection={{
										onChange: (_, selectedRows) => {
											setSelectedTracks({ ...selectedTracks, userTracks: selectedRows });
										},
									}}
								/>
							</div>
						</Tabs.TabPane>

						<Tabs.TabPane
							key='download-tracks'
							disabled={!user?.allowTracksDownload}
							tab={
								<>
									<FiDownload /> Músicas para download
								</>
							}>
							<div className='tab-content'>
								<Form.Container layout='30% 30% 30%'>
									<Form.Item label='Filtrar por categoria'>
										<Select
											showSearch
											disabled={fallback?.fetchingTracksForDownload}
											placeholder='Selecione uma categoria'
											value={filters?.tracksForDownload?.categoryId}
											onChange={(value) =>
												setFilters(({ tracksForDownload, ...filters }) => ({
													...filters,
													tracksForDownload: { ...tracksForDownload, categoryId: value },
												}))
											}
											filterOption={(input, { props: { children } }) => {
												return String(children).match(new RegExp(input, 'i'));
											}}>
											<Select.Option value=''>TODAS</Select.Option>

											{categories.map((category) => (
												<Select.Option value={category?._id} key={category?._id}>
													{category?.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item label='Buscar por artista' help='Informe no mínimo 3 caracteres'>
										<Input.Search
											allowClear
											disabled={fallback?.fetchingTracksForDownload}
											placeholder='Digite o nome do artista'
											value={filtersStates?.tracksForDownload?.artist}
											onChange={({ target: { value } }) => {
												setFiltersStates({
													...filtersStates,
													tracksForDownload: { ...filtersStates.tracksForDownload, artist: value },
												});

												if (!value) {
													setFilters({
														...filters,
														tracksForDownload: { ...filters.tracksForDownload, artist: '' },
													});
												}
											}}
											onSearch={(value) => {
												setFilters({
													...filters,
													tracksForDownload: { ...filters.tracksForDownload, artist: value },
												});
											}}
										/>
									</Form.Item>
									<Form.Item
										label='Buscar por nome da música'
										help='Informe no mínimo 3 caracteres'>
										<Input.Search
											allowClear
											disabled={fallback?.fetchingTracksForDownload}
											placeholder='Digite o nome da música'
											value={filtersStates?.tracksForDownload?.name}
											onChange={({ target: { value } }) => {
												setFiltersStates({
													...filtersStates,
													tracksForDownload: { ...filtersStates.tracksForDownload, name: value },
												});

												if (!value) {
													setFilters({
														...filters,
														tracksForDownload: { ...filters.tracksForDownload, name: '' },
													});
												}
											}}
											onSearch={(value) => {
												setFilters({
													...filters,
													tracksForDownload: { ...filters.tracksForDownload, name: value },
												});
											}}
										/>
									</Form.Item>
								</Form.Container>

								<Divider />

								<TableHeader>
									<div className='actions'>
										<span>
											Quantidade: <strong>{pagination?.tracksForDownload?.total}</strong>
										</span>
										{[0, 5].includes(user?.userLevel) && (
											<div>
												<Button
													size='small'
													disabled={!selectedTracks.tracksForDownload.length}
													onClick={() => handleMultiDownload({ dataBase: 'tracksForDownload' })}
													loading={fallback?.multiDownload}>
													<FiDownload /> Baixar
													{selectedTracks.tracksForDownload.length !== 0 &&
														`(${selectedTracks.tracksForDownload.length})`}
												</Button>
											</div>
										)}
									</div>

									<Select
										size='small'
										value={pagination?.tracksForDownload?.pageSize}
										onChange={(pageSize) => {
											setPagination(({ tracksForDownload, ...prev }) => ({
												...prev,
												tracksForDownload: { ...tracksForDownload, pageSize },
											}));
										}}>
										<Select.Option value={5}>5 itens por página</Select.Option>
										<Select.Option value={10}>10 itens por página</Select.Option>
										<Select.Option value={20}>20 itens por página</Select.Option>
										<Select.Option value={50}>50 itens por página</Select.Option>
										<Select.Option value={100}>100 itens por página</Select.Option>
										<Select.Option value={500}>500 itens por página</Select.Option>
									</Select>
								</TableHeader>

								<Table
									rowKey='_id'
									size='middle'
									columns={[
										...columns.filter(({ key }) => key !== 'download'),
										{
											title: 'Baixar',
											align: 'right',
											key: 'download',
											render: (track) => {
												return (
													<Button
														type='primary'
														size='small'
														onClick={() => {
															download({
																filename: track?.filename,
																name: `${track?.artist} - ${track?.name}`,
															});
														}}>
														<FiDownload /> Baixar
													</Button>
												);
											},
										},
									]}
									dataSource={tracks?.tracksForDownload}
									style={{ border: 'none' }}
									loading={fallback?.fetchingTracksForDownload}
									pagination={{
										...pagination.tracksForDownload,
										size: 'large',
										onChange: (current) => {
											setPagination({
												...pagination,
												tracksForDownload: { ...pagination.tracksForDownload, current },
											});
										},
									}}
									rowSelection={{
										onChange: (_, selectedRows) => {
											setSelectedTracks({ ...selectedTracks, tracksForDownload: selectedRows });
										},
									}}
								/>
							</div>
						</Tabs.TabPane>
					</Tabs>
				</Card>
			</CardContainer>

			<Modal
				width={800}
				closable={!fallback?.creating}
				title='Novas músicas'
				visible={visibleModals?.create}
				onOk={handleUploadSongs}
				cancelButtonProps={{ disabled: fallback?.creating }}
				okButtonProps={{ loading: fallback?.creating, disabled: isUploadButtonDisabled }}
				okText={
					<>
						{!isValidating && <FiUpload />}
						{isValidating ? 'Validando Arquivos' : 'Fazer upload'}
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				onCancel={() => {
					setHandlingTrack(null);
					setVisibleModals({ ...visibleModals, create: false });
				}}>
				{visibleModals?.create && (
					<>
						<Typography.Text style={{ textAlign: 'center' }}>
							Utilize o nome padrão de arquivo{' '}
							<strong>
								<i>ARTISTA - NOME DA MÚSICA</i>
							</strong>{' '}
							para que o sistema organize automaticamente suas músicas.
						</Typography.Text>

						<Divider />

						<Form.Container>
							<Form.Item label='Categoria'>
								<Select
									showSearch
									placeholder='Selecione a categoria'
									optionFilterProp='children'
									value={handlingTrack?.categoryId}
									onChange={(value) => setHandlingTrack({ ...handlingTrack, categoryId: value })}
									filterOption={(input, option) => {
										return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
									}}>
									{categories.map((category) => (
										<Select.Option key={category._id}>{category.name}</Select.Option>
									))}
								</Select>
							</Form.Item>

							<FilesUploader
								multiple
								onChange={(files) => setHandlingTrack((prev) => ({ ...prev, files }))}
								onStartValidation={() => setIsValidating(true)}
								onEndValidation={() => setIsValidating(false)}
								validTypes={['.mp3', '.wav']}
								customValidations={[
									(file) => {
										if (!isTrackFilenameValid(file.metadata.name)) {
											throw new Error(
												'Nome inválido. Utilize o nome padrão de arquivo [ARTISTA] - [NOME DA MÚSICA]'
											);
										}
									},
								]}
							/>

							<Form.Item>
								<Checkbox
									style={{ marginBottom: 8 }}
									checked={handlingTrack?.forDownload}
									onChange={({ target: { checked } }) => {
										return setHandlingTrack({ ...handlingTrack, forDownload: checked });
									}}>
									Disponibilizar músicas para download
								</Checkbox>
							</Form.Item>

							<Checkbox
								style={{ marginBottom: 8 }}
								checked={handlingTrack?.autoCategChange}
								onChange={({ target: { checked } }) => {
									return checked
										? setHandlingTrack({
												...handlingTrack,
												autoCategChange: true,
												categoryChange: {
													replacer: undefined,
													date: addMonths(new Date(), 1),
												},
										  })
										: setHandlingTrack({
												...handlingTrack,
												autoCategChange: false,
												categoryChange: { replacer: undefined, date: null },
										  });
								}}>
								Programar troca automática de categoria
							</Checkbox>

							{handlingTrack?.autoCategChange && (
								<>
									<Form.Item label='Nova categoria'>
										<Select
											showSearch
											placeholder='Selecione a categoria que irá substituir a atual'
											optionFilterProp='children'
											value={handlingTrack?.categoryChange?.replacer}
											onChange={(value) => {
												setHandlingTrack({
													...handlingTrack,
													categoryChange: {
														...handlingTrack?.categoryChange,
														replacer: value,
													},
												});
											}}
											filterOption={(input, option) => {
												return (
													option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
												);
											}}>
											{categories.map((category) => (
												<Select.Option key={category._id}>{category.name}</Select.Option>
											))}
										</Select>
									</Form.Item>

									<Form.Item label='Informe o tempo até a troca de categoria'>
										<Slider
											marks={{
												1: '1 mês',
												2: '2 meses',
												3: '3 meses',
												4: '4 meses',
												5: '5 meses',
												6: '6 meses',
											}}
											dots
											min={1}
											max={6}
											step={1}
											onChange={(value) => {
												setHandlingTrack({
													...handlingTrack,
													categoryChange: {
														...handlingTrack.categoryChange,
														date: addMonths(new Date(), value),
													},
												});
											}}
										/>
									</Form.Item>

									{handlingTrack?.categoryChange?.date && (
										<Typography.Text>
											A categoria da(s) música(s) irá mudar automaticamente em{' '}
											<strong>
												{format(handlingTrack.categoryChange?.date, "dd 'de' MMMM 'de' yyyy", {
													locale: ptBR,
												})}
											</strong>
										</Typography.Text>
									)}
								</>
							)}
						</Form.Container>
					</>
				)}
			</Modal>

			<Modal
				width={650}
				visible={visibleModals?.details}
				title={
					<>
						<FiEye /> Detalhes
					</>
				}
				footer={null}
				onCancel={() => {
					setVisibleModals({ ...visibleModals, details: false });
					setHandlingTrack(null);
				}}>
				{handlingTrack && visibleModals?.details && (
					<DetailsContainer>
						<li>
							<strong className='label'>Artista</strong>
							<span>{handlingTrack?.artist}</span>
						</li>
						<li>
							<strong className='label'>Música</strong>
							<span>{handlingTrack?.name}</span>
						</li>
						<li>
							<strong className='label'>Categoria</strong>
							<span>{handlingTrack?.categoryId?.name}</span>
						</li>
						<li>
							<strong className='label'>Data do upload</strong>
							<span>
								{format(parseISO(handlingTrack?.dateUpload), "dd 'de' MMMM',' yyyy", {
									locale: ptBR,
								})}
							</span>
						</li>
						<li>
							<strong className='label'>Duração</strong>
							<span>
								<FileDuration src={resolveFileSrc({ fileName: handlingTrack?.filename })} />
							</span>
						</li>
						<li>
							<strong className='label'>Formato</strong>
							<span>
								<FileExt src={resolveFileSrc({ fileName: handlingTrack?.filename })} />
							</span>
						</li>
						<li>
							<strong className='label'>ID</strong>
							<span>
								<Typography.Text copyable>{handlingTrack?._id}</Typography.Text>{' '}
							</span>
						</li>
						<li>
							<strong className='label'>Mudança de categoria(data)</strong>
							<span>
								{handlingTrack?.categoryChange?.date ? (
									`${format(parseISO(handlingTrack?.categoryChange?.date), 'dd/MM/yyyy')}`
								) : (
									<i>Sem mudança programada</i>
								)}
							</span>
						</li>

						{handlingTrack?.categoryChange?.replacer && (
							<li>
								<strong className='label'>Nova categoria</strong>
								<span>{handlingTrack?.categoryChange?.replacer?.name}</span>
							</li>
						)}
					</DetailsContainer>
				)}
			</Modal>

			<Modal
				closable={!fallback?.editing}
				visible={visibleModals?.edit}
				title={
					<>
						<FiEdit2 /> Editar música
					</>
				}
				okButtonProps={{ loading: fallback?.editing }}
				cancelButtonProps={{ disabled: fallback?.editing }}
				okText={
					<>
						<FiSave /> Salvar
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				onOk={() => handleSingleUpdate({ track: handlingTrack })}
				onCancel={() => {
					setHandlingTrack(null);
					setVisibleModals({ ...visibleModals, edit: false });
				}}>
				<Form.Container>
					<Form.Item label='Artista'>
						<Input
							placeholder='Informe o nome do artista'
							value={handlingTrack?.artist}
							onChange={({ target: { value } }) => {
								setHandlingTrack({ ...handlingTrack, artist: value });
							}}
						/>
					</Form.Item>
					<Form.Item label='Nome da música'>
						<Input
							placeholder='Informe o nome da música'
							value={handlingTrack?.name}
							onChange={({ target: { value } }) => {
								setHandlingTrack({ ...handlingTrack, name: value });
							}}
						/>
					</Form.Item>
					<Form.Item label='Categoria'>
						<Select
							showSearch
							placeholder='Selecione uma categoria'
							value={handlingTrack?.categoryId}
							onChange={(value) => {
								setHandlingTrack({ ...handlingTrack, categoryId: value });
							}}
							filterOption={(input, { props: { children } }) => {
								return String(children).match(new RegExp(input, 'i'));
							}}>
							{categories.map((category) => (
								<Select.Option value={category?._id} key={category?._id}>
									{category?.name}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					{[0, 5].includes(user?.userLevel) && (
						<>
							<Divider style={{ margin: '1px 0 16px 0' }} />
							<Form.Item style={{ marginTop: -16 }}>
								<SwitchContainer>
									<label>
										<FiDownload style={{ marginRight: 4 }} /> Disponível para download
									</label>
									<Switch
										checked={handlingTrack?.forDownload}
										onChange={(value) => {
											setHandlingTrack((prev) => ({ ...prev, forDownload: value }));
										}}
									/>
								</SwitchContainer>
							</Form.Item>
						</>
					)}

					<Divider style={{ margin: '1px 0 12px 0' }} />

					<Form.Item style={{ marginTop: -16 }}>
						<SwitchContainer>
							<label>
								<FiRefreshCw style={{ marginRight: 4 }} /> Mudar automaticamente a categoria
							</label>

							<Switch
								checked={handlingTrack?.autoCategChange}
								onChange={(checked) => {
									checked
										? setHandlingTrack((prev) => ({ ...prev, autoCategChange: true }))
										: setHandlingTrack((prev) => ({
												...prev,
												autoCategChange: false,
												categoryChange: { replacer: undefined, date: null },
										  }));
								}}
							/>
						</SwitchContainer>
					</Form.Item>

					{handlingTrack?.autoCategChange && (
						<>
							<Form.Item
								style={{ marginBottom: 16 }}
								label='Nova categoria'
								help='Informe qual será a nova categoria'>
								<Select
									showSearch
									placeholder='Selecione uma categoria'
									value={handlingTrack?.categoryChange?.replacer}
									onChange={(value) => {
										setHandlingTrack({
											...handlingTrack,
											categoryChange: {
												...handlingTrack?.categoryChange,
												replacer: value,
											},
										});
									}}
									filterOption={(input, { props: { children } }) => {
										return String(children).match(new RegExp(input, 'i'));
									}}>
									{categories.map((category) => (
										<Select.Option value={category?._id} key={category?._id}>
											{category?.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item label='Data da mudança automática'>
								<DatePicker
									value={
										handlingTrack?.categoryChange?.date
											? moment(handlingTrack?.categoryChange?.date)
											: null
									}
									format='DD/MM/YYYY'
									onChange={({ _d: date }) => {
										setHandlingTrack({
											...handlingTrack,
											categoryChange: {
												...handlingTrack?.categoryChange,
												date,
											},
										});
									}}
								/>
							</Form.Item>
						</>
					)}
				</Form.Container>
			</Modal>

			<Modal
				centered
				title={
					<>
						<FiRefreshCw /> Alterar categoria de músicas
					</>
				}
				visible={visibleModals.multiCategChange}
				okText={
					<>
						<FiRefreshCw /> Alterar categorias
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				onCancel={() => setVisibleModals({ ...visibleModals, multiCategChange: false })}
				onOk={() => {
					multiCategChange.categoryId
						? Modal.confirm({
								width: 500,
								title: 'Mudar categoria das músicas selecionadas?',
								icon: 'exclamation-circle',
								content:
									'Atenção, a categoria de todas as músicas selecionadas será alterada, realmente deseja continuar?',
								cancelText: (
									<>
										<FiXCircle /> Cancelar
									</>
								),
								okText: (
									<>
										<FiRefreshCw /> Confirmar alteração
									</>
								),
								onOk: handleChangeManyCategories,
								okButtonProps: { type: 'primary' },
						  })
						: message.warn('Selecione a nova categoria');
				}}>
				<Form.Container>
					<Form.Item label='Nova categoria'>
						<Select
							showSearch
							placeholder='Selecione uma categoria'
							value={multiCategChange.categoryId}
							onChange={(value) => {
								setMultiCategChange({ ...multiCategChange, categoryId: value });
							}}
							filterOption={(input, { props: { children } }) => {
								return String(children).match(new RegExp(input, 'i'));
							}}>
							{categories.map((category) => (
								<Select.Option value={category?._id} key={category?._id}>
									{category?.name}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Form.Container>
			</Modal>

			<Progress
				progress={progress}
				succesTitle='Músicas enviadas com sucesso'
				title='Enviando músicas'
			/>
		</>
	);
};

export default connect(({ user }) => ({ user }))(Tracks);
