import React, { useState, useEffect, useCallback } from 'react';
import { Drawer, Divider, Input, Form, Tabs, Button, Select, message } from 'antd';
import * as Yup from 'yup';
import MaskedInput from 'antd-mask-input';

import RadioCard from '../RadioCard';
import { RegisterContainer, PlansContainer, ButtonsContainer } from './styles';

import SubscriptionsAPI from '../../services/sdks/subscriptions';
import PlansAPI from '../../services/sdks/plans';

import brState from '../../constants/states';
import { useFormatter } from '../../hooks';

import {
	FiUserCheck,
	FiFileMinus,
	FiCreditCard,
	FiArrowLeft,
	FiArrowRight,
	FiXCircle,
	FiCheckCircle,
} from 'react-icons/fi';

const layout = { labelCol: { span: 6 }, wrapperCol: { span: 12 } };

const SignUp = ({ visible, onClose, userId }) => {
	const toCurrencyFormat = useFormatter({ style: 'currency', currency: 'BRL' });

	const [fallback, setFallback] = useState(null);
	const [selectedPlan, setSelectedPlan] = useState(null);
	const [plans, setPlans] = useState([]);
	const [currentStep, setCurrentStep] = useState('plan');
	const [paymentInfos, setPaymentInfos] = useState({});

	const handleUpdatePaymentInfos = useCallback((key, value, minLength) => {
		if (minLength && value.length !== minLength) {
			setPaymentInfos((prev) => ({ ...prev, [key]: null }));
		} else {
			setPaymentInfos((prev) => ({ ...prev, [key]: value }));
		}
	}, []);

	const verifyPaymentInfos = useCallback(() => {
		try {
			const validationSchema = Yup.object().shape({
				brand: Yup.string().required(),
				number: Yup.string().required(),
				payment_name: Yup.string().required(),
				payment_cpf: Yup.string().required(),
				payment_birth: Yup.string().required(),
				payment_phone: Yup.string().required(),
				cvv: Yup.string().required(),
				expiration_month: Yup.string().required(),
				expiration_year: Yup.string().required(),
				payment_street: Yup.string().required(),
				payment_neighborhood: Yup.string().required(),
				payment_number: Yup.string().required(),
				payment_city: Yup.string().required(),
				payment_state: Yup.string().required(),
				payment_zipcode: Yup.string().required(),
			});

			validationSchema.validateSync(paymentInfos);

			return false;
		} catch (error) {
			return true;
		}
	}, [paymentInfos]);

	const getPaymentToken = useCallback(() => {
		return new Promise((resolve) => {
			const { brand, number, cvv, expiration_month, expiration_year } = paymentInfos;
			const cardInfos = { brand, number, cvv, expiration_month, expiration_year };

			window.$gn.checkout.getPaymentToken(cardInfos, (error, response) => {
				if (error) {
					if (error?.code === 3500058) {
						throw new Error('Número do cartão é inválido');
					}

					throw new Error(String(error));
				}

				return resolve(response.data.payment_token);
			});
		});
	}, [paymentInfos]);

	const handleRenewSubscription = useCallback(async () => {
		try {
			if (selectedPlan?.price !== 0) {
				const customerName = paymentInfos.payment_name;
				const expirationMonth = Number(paymentInfos.expiration_month);

				if (customerName.split(' ').length <= 1) {
					return message.error('Informe seu nome completo');
				}

				if (expirationMonth < 1 || expirationMonth > 12) {
					return message.error('O mês de pagamento informado é inválido');
				}

				setFallback((fallback) => ({ ...fallback, creatingAcc: true }));

				const payment_token = await getPaymentToken();
				const payload = {
					...paymentInfos,
					payment_token,
					planId: selectedPlan._id,
				};

				await SubscriptionsAPI.update(userId, payload);
			} else {
				setFallback((fallback) => ({ ...fallback, creatingAcc: true }));

				const payload = { planId: selectedPlan._id };

				await SubscriptionsAPI.update(userId, payload);
			}

			setSelectedPlan(null);
			setPaymentInfos({});
			setFallback((fallback) => ({ ...fallback, creatingAcc: false }));

			onClose();
			message.success('Seu cadastro foi renovado com sucesso!');
		} catch (error) {
			if (error.message) {
				message.error(error.message);
			}

			if (error?.response?.data?.message) {
				message.error(error.response.data.message);
			}

			setFallback((fallback) => ({ ...fallback, creatingAcc: false }));
		}
	}, [userId, onClose, paymentInfos, getPaymentToken, selectedPlan]);

	useEffect(() => {
		const fetchPlans = async () => {
			try {
				const { data } = await PlansAPI.index('active=true');
				const plans = data.plans
					.sort((a, b) => (a.price > b.price ? 1 : -1))
					.map((plan) => {
						const descriptions = [];

						if (plan.name.toUpperCase().trim() === 'PLANO MASTER') {
							descriptions.push({ text: '1 programa', options: { strong: false } });
							descriptions.push({
								text: 'Geração automática de programas',
								options: { strong: false },
							});
							descriptions.push({
								text: 'Customização (vinhetas da sua emissora)',
								options: { strong: false },
							});
							descriptions.push({
								text: 'Departamento comercial (inserção de publicidade gravada)',
								options: { strong: false },
							});
							descriptions.push({
								text: 'Banco musical com atualizações diárias',
								options: { strong: false },
							});
							descriptions.push({
								text: 'TalkLoc (acesso a locutores de todo o Brasil)',
								options: { strong: false },
							});
							descriptions.push({
								text: '1 Locutor/Locutora disponível para gravar conteúdo.',
								options: { strong: false },
							});
							descriptions.push({ text: 'Suporte total', options: { strong: true } });

							return { ...plan, descriptions };
						}

						if (plan.name.toUpperCase().trim() === 'PLANO PLAY') {
							descriptions.push({
								text: 'Número negociável de programas',
								options: { strong: false },
							});
							descriptions.push({
								text: 'Geração automática de programas',
								options: { strong: false },
							});
							descriptions.push({
								text: 'Customização (vinhetas da sua emissora)',
								options: { strong: false },
							});
							descriptions.push({
								text: 'Departamento comercial (inserção de publicidade gravada)',
								options: { strong: false },
							});
							descriptions.push({
								text: 'Música cash (inserção de músicas pagas)',
								options: { strong: false },
							});
							descriptions.push({
								text: 'Caracterização local (programas ao vivo)',
								options: { strong: false },
							});
							descriptions.push({
								text: 'Banco musical com atualizações diárias',
								options: { strong: false },
							});
							descriptions.push({
								text: 'TalkLoc (acesso a locutores de todo o Brasil)',
								options: { strong: false },
							});
							descriptions.push({
								text: 'Permissão de Produtora (gerar seus próprios conteúdos + Distribuição para sua rede)',
								options: { strong: false },
							});
							descriptions.push({
								text: '1 Locutor/Locutora disponível para gravar conteúdo.',
								options: { strong: false },
							});
							descriptions.push({ text: 'Suporte total', options: { strong: true } });

							return { ...plan, descriptions };
						}

						plan?.programQuant && descriptions.push(`${plan?.programQuant} programas`);
						plan?.auto &&
							descriptions.push({
								text: 'Geração automática de programas',
								options: { strong: false },
							});
						plan?.forceSignature &&
							descriptions.push({
								text: 'Assinatura de programas',
								options: { strong: false },
							});
						plan?.tracksDB &&
							descriptions.push({
								text: 'Acesso ao banco musical',
								options: { strong: false },
							});

						if (plan?.sharePermissionLevel === 1) {
							descriptions.push({
								text: 'Pode produzir e disponibilizar conteúdos para a TalkRadio',
								options: { strong: false },
							});
						}

						if (plan?.sharePermissionLevel === 2) {
							descriptions.push({
								text: 'Pode produzir e disponibilizar conteúdos para qualquer empresa',
								options: { strong: false },
							});
						}

						descriptions.push({ text: 'Suporte total', options: { strong: true } });

						return { ...plan, descriptions };
					});

				setPlans(plans);
			} catch (error) {
				console.error(error);
			}
		};

		fetchPlans();
	}, []);

	return (
		<Drawer
			destroyOnClose
			placement='left'
			title={
				<>
					<FiUserCheck />
					<span>Renovar assinatura</span>
				</>
			}
			visible={visible}
			onClose={onClose}
			closable={!fallback?.creatingAcc}
			width='95vw'
			footer={null}
		>
			<RegisterContainer>
				<Tabs tabPosition='left' activeKey={currentStep}>
					<Tabs.TabPane
						tab={
							<>
								<FiFileMinus />
								Selecionar plano
							</>
						}
						key='plan'
						disabled={currentStep !== 'plan'}
					>
						<PlansContainer>
							{plans.map((plan) => (
								<RadioCard
									key={plan._id}
									label={plan?.name}
									title={toCurrencyFormat(plan?.price / 100)}
									checked={selectedPlan?._id === plan?._id}
									onClick={() => setSelectedPlan(plan)}
									descriptions={plan?.descriptions}
								/>
							))}
						</PlansContainer>

						<Divider />

						<ButtonsContainer>
							<Button size='large' type='ghost' onClick={onClose}>
								Cancelar renovação <FiXCircle />
							</Button>

							{selectedPlan?.price === 0 ? (
								<Button
									size='large'
									type='primary'
									onClick={handleRenewSubscription}
									loading={fallback?.creatingAcc}
								>
									Finalizar renovação <FiCheckCircle />
								</Button>
							) : (
								<Button
									size='large'
									type='primary'
									disabled={!selectedPlan}
									onClick={() => setCurrentStep('payment')}
								>
									Prosseguir com a renovação <FiArrowRight />
								</Button>
							)}
						</ButtonsContainer>
					</Tabs.TabPane>

					{selectedPlan?.price !== 0 && (
						<Tabs.TabPane
							tab={
								<>
									<FiCreditCard /> Pagamento
								</>
							}
							key='payment'
							disabled={currentStep !== 'payment'}
						>
							<Form {...layout}>
								<Form.Item label='Bandeira do cartão' required>
									<Select
										placeholder='Selecione a bandeira do cartão'
										onChange={(value) => handleUpdatePaymentInfos('brand', value)}
									>
										<Select.Option key='visa'>VISA</Select.Option>
										<Select.Option key='mastercard'>MASTERCARD</Select.Option>
										<Select.Option key='elo'>ELO</Select.Option>
										<Select.Option key='amex'>AMEX</Select.Option>
									</Select>
								</Form.Item>

								<Form.Item label='Número do cartão' required>
									<MaskedInput
										placeholder='____.____.____.____'
										mask={
											paymentInfos?.brand === 'amex' ? '1111.1111.1111.111' : '1111.1111.1111.1111'
										}
										onChange={({ target: { value } }) => {
											value = value.replace(/[._]/g, '');
											const minLength = paymentInfos?.brand === 'amex' ? 15 : 16;

											handleUpdatePaymentInfos('number', value, minLength);
										}}
									/>
								</Form.Item>

								<Form.Item label='Nome do titular' required>
									<Input
										placeholder='Informe o nome completo do titular do cartão'
										onChange={({ target: { value } }) => {
											handleUpdatePaymentInfos('payment_name', value);
										}}
									/>
								</Form.Item>

								<Form.Item label='CPF do titular' required>
									<MaskedInput
										mask='111.111.111-11'
										placeholder='____.____.____-__'
										onChange={({ target: { value } }) => {
											value = value.replace(/[_.-]/g, '');
											handleUpdatePaymentInfos('payment_cpf', value, 11);
										}}
									/>
								</Form.Item>

								<Form.Item label='Data de nascimento' required>
									<MaskedInput
										mask='11/11/1111'
										placeholder='__/__/____'
										onChange={({ target: { value } }) => {
											value = value.split('/').reverse().join('-');
											handleUpdatePaymentInfos('payment_birth', value, 10);
										}}
									/>
								</Form.Item>

								<Form.Item label='Telefone' required>
									<MaskedInput
										mask='(11) 1 1111-1111'
										placeholder='(__) _ ____-____'
										onChange={({ target: { value } }) => {
											value = value.replace(/[_( )-]/g, '');
											handleUpdatePaymentInfos('payment_phone', value, 11);
										}}
									/>
								</Form.Item>

								<Form.Item label='CVV' required>
									<MaskedInput
										mask='111'
										placeholder='___'
										onChange={({ target: { value } }) => {
											value = value.replace(/_/g, '');
											handleUpdatePaymentInfos('cvv', value, 3);
										}}
									/>
								</Form.Item>

								<Form.Item label='Mês do vencimento' required>
									<MaskedInput
										mask='11'
										placeholder='__'
										onChange={({ target: { value } }) => {
											value = value.replace(/_/g, '');
											handleUpdatePaymentInfos('expiration_month', value, 2);
										}}
									/>
								</Form.Item>

								<Form.Item label='Ano do vencimento' required>
									<MaskedInput
										mask='1111'
										placeholder='____'
										onChange={({ target: { value } }) => {
											value = value.replace(/_/g, '');
											handleUpdatePaymentInfos('expiration_year', value, 4);
										}}
									/>
								</Form.Item>
							</Form>

							<Divider>Endereço de cobrança</Divider>

							<Form {...layout}>
								<Form.Item label='Rua' required>
									<Input
										placeholder='Informe a rua'
										onChange={({ target: { value } }) => {
											handleUpdatePaymentInfos('payment_street', value);
										}}
									/>
								</Form.Item>
								<Form.Item label='Bairro' required>
									<Input
										placeholder='Informe o bairro'
										onChange={({ target: { value } }) => {
											handleUpdatePaymentInfos('payment_neighborhood', value);
										}}
									/>
								</Form.Item>
								<Form.Item label='Número' required>
									<Input
										placeholder='Informe o número'
										onChange={({ target: { value } }) => {
											handleUpdatePaymentInfos('payment_number', value);
										}}
									/>
								</Form.Item>
								<Form.Item label='Cidade' required>
									<Input
										placeholder='Informe a cidade'
										onChange={({ target: { value } }) => {
											handleUpdatePaymentInfos('payment_city', value);
										}}
									/>
								</Form.Item>

								<Form.Item label='Estado' required>
									<Select
										placeholder='Selecione o estado'
										onChange={(value) => {
											handleUpdatePaymentInfos('payment_state', value);
										}}
									>
										{brState.map((state) => (
											<Select.Option key={state}>{state}</Select.Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item label='CEP' required>
									<MaskedInput
										mask='11111-111'
										placeholder='Informe o cep'
										onChange={({ target: { value } }) => {
											value = value.replace(/[-_]/g, '');
											handleUpdatePaymentInfos('payment_zipcode', value, 8);
										}}
									/>
								</Form.Item>
							</Form>

							<Divider />

							<ButtonsContainer>
								<Button
									size='large'
									type='ghost'
									disabled={fallback?.creatingAcc}
									onClick={() => setCurrentStep('plan')}
								>
									<FiArrowLeft /> Escolher outro plano
								</Button>

								<Button
									size='large'
									type='primary'
									onClick={handleRenewSubscription}
									loading={fallback?.creatingAcc}
									disabled={verifyPaymentInfos()}
								>
									Finalizar cadastro <FiCheckCircle />
								</Button>
							</ButtonsContainer>
						</Tabs.TabPane>
					)}
				</Tabs>
			</RegisterContainer>
		</Drawer>
	);
};

export default SignUp;
