import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PageHeader, Table, Button, Typography, Card, message, Select, Tag } from 'antd';

import Meta from '../../../../../components/Meta';
import Fallback from '../../../../../components/Fallback';
import { Container, TableHeader } from './styles';
import { FiEdit } from 'react-icons/fi';

import NetworksAPI from '../../../../../services/sdks/networks';
import UsersAPI from '../../../../../services/sdks/user';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Geral' },
		{ breadcrumbName: 'Categorias musicais' },
	],
	style: { marginBottom: 12 },
};

const ListNetworks = () => {
	const [fallback, setFallback] = useState({ initialData: true });
	const [page, setPage] = useState(0);
	const [total, setTotal] = useState(0);
	const [networks, setNetworks] = useState([]);
	const [userId, setUserId] = useState(undefined);
	const [users, setUsers] = useState([]);

	const columns = [
		{
			key: 'id',
			title: 'ID',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					10
				)}...`}</Typography.Text>
			),
		},
		{
			key: 'user',
			title: 'Usuário',
			render: (network) => network.userId.radioName,
		},
		{
			key: 'email',
			title: 'Email do Usuário',
			render: (network) => network.userId.email,
		},
		{
			key: 'sharing-status',
			align: 'center',
			title: 'Compartilhamento',
			render: (network) =>
				network.userId.sharePermissionLevel === 0 ? (
					<Tag color='#ccc'>Inativo</Tag>
				) : (
					<Tag color='#3DD598'>Ativo</Tag>
				),
		},
		{
			key: 'count',
			title: 'Radios na Rede',
			render: (network) => `${network.users.length} rádios`,
		},
		{
			key: 'actions',
			title: 'Ações',
			align: 'center',
			render: (network) =>
				network.userId.sharePermissionLevel === 0 ? (
					<Button disabled size='small'>
						<FiEdit />
						Editar
					</Button>
				) : (
					<Link to={`/admin/users/networks/${network._id}`}>
						<Button size='small'>
							<FiEdit />
							Editar
						</Button>
					</Link>
				),
		},
	];

	useEffect(() => {
		const fetchNetworks = async () => {
			setFallback((prev) => ({ ...prev, fetchingNetworks: true }));

			try {
				let query = `page=${page}&limit=20`;

				if (userId) {
					query = `${query}&userId=${userId}`;
				}

				const {
					data: { networks, total },
				} = await NetworksAPI.list(query);

				setNetworks(networks);
				setTotal(total);
				setFallback(false);
			} catch (error) {
				console.error(error);
				message.error('Erro ao buscar as categorias');
			}

			setFallback((prev) => ({ ...prev, fetchingNetworks: false }));
		};

		fetchNetworks();
	}, [userId, page]);

	useEffect(() => {
		const fetchUsers = async () => {
			setFallback((prev) => ({ ...prev, fetchingUsers: true }));

			try {
				const {
					data: { users },
				} = await UsersAPI.index(`active=true`);
				setUsers(users);
			} catch (error) {
				setUsers([]);
				message.success('Erro ao carregar os usuários');
			}

			setFallback((prev) => ({ ...prev, fetchingUsers: false }));
		};

		fetchUsers();
	}, []);

	if (fallback?.initialData) {
		return <Fallback title='Carregando...' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Redes de Usuários' />

			<PageHeader title='Redes de Usuários' breadcrumb={breadcrumb} />

			<Container>
				<Card>
					<Table
						pagination={{
							pageSize: 20,
							size: 'large',
							hideOnSinglePage: true,
							total,
							onChange: (page) => setPage(page),
						}}
						loading={fallback?.fetchingNetworks}
						rowKey='_id'
						size='middle'
						style={{ border: 'none' }}
						columns={columns}
						dataSource={networks}
						title={() => (
							<TableHeader key='tb-header'>
								<Select
									showSearch
									loading={fallback?.fetchingUsers}
									optionFilterProp='children'
									placeholder='Filtrar por usuário'
									value={userId}
									onChange={(value) => setUserId(value)}
									filterOption={(input, { props: { _search } }) => {
										const regex = new RegExp(input, 'i');
										return _search.match(regex);
									}}>
									{users.map((user) => {
										const { radioName, city, state, email } = user;

										return (
											<Select.Option
												_search={`${radioName}${city}${state}${email}`}
												key={user._id}
												value={user._id}>
												{radioName} - {city}/{state} ({email})
											</Select.Option>
										);
									})}
								</Select>
							</TableHeader>
						)}
					/>
				</Card>
			</Container>
		</>
	);
};

export default ListNetworks;
