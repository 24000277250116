import React, { useState, useEffect, useCallback } from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import axios from 'axios';
import JSZip from 'jszip';
import * as Yup from 'yup';
import path from 'path';
import { connect } from 'react-redux';

import {
	Table,
	Menu,
	Dropdown,
	Button,
	Icon,
	Select,
	Modal,
	Typography,
	DatePicker,
	Divider,
	Tag,
	Breadcrumb,
	message,
	Spin,
	Alert,
	Tooltip,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import FileDuration from '../../../../components/FileDuration';
import FileExt from '../../../../components/FileExt';
import PageSizeHandler from '../../../../components/PageSizeHandle';
import UploadZone from '../../../../components/UploadZone';
import Progress from '../../../../components/Progress';
import PlayCell from '../../../../components/PlayCell';
import Form from '../../../../components/Form';
import { Container, TableHeader } from './styles';

import UsersAPI from '../../../../services/sdks/user';
import OffsAPI from '../../../../services/sdks/offs';
import ProgramsAPI from '../../../../services/sdks/programs';
import ScriptsAPI from '../../../../services/sdks/scripts';
import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';

import { usePlayer, useDownload } from '../../../../hooks';
import { FiDownload, FiPlusCircle, FiTrash2, FiUploadCloud, FiXCircle } from 'react-icons/fi';

const Offs = () => {
	const player = usePlayer();
	const download = useDownload();

	const [fallback, setFallback] = useState({ initialData: false });
	const [offs, setOffs] = useState([]);
	const [selectedOffs, setSelectedOffs] = useState([]);
	const [users, setUsers] = useState([]);
	const [programs, setPrograms] = useState([]);
	const [visibleModals, setVisibleModals] = useState({ addOffs: false });
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: null });
	const [progress, setProgress] = useState(null);
	const [scripts, setScripts] = useState([]);
	const [neededOffs, setNeededOffs] = useState(null);
	const [storedOffs, setStoredOffs] = useState(null);
	const [newOffs, setNewOffs] = useState({ program: undefined, dateRelease: null, files: [] });
	const [filters, setFilters] = useState({
		program: undefined,
		user: undefined,
		dateRelease: null,
	});

	const columns = [
		{
			title: 'Off & Programa',
			key: 'category',
			render: (off) => (
				<PlayCell
					meta={{
						primary: off?.name,
						secondary: off?.program?.name,
					}}
					onPlay={() => {
						player.start({
							src: resolveFileSrc({ fileName: off?.filename }),
							ref: off?._id,
							meta: {
								name: off?.name,
								artist: off?.program?.name,
							},
						});
					}}
					onPause={player.resume}
					isPlaying={player?.ref === off?._id && player?.isPlaying}
				/>
			),
		},

		{
			title: 'Data de Lançamento',
			dataIndex: 'dateRelease',
			align: 'center',
			key: 'date',
		},
		{
			title: 'Duração',
			align: 'center',
			key: 'duration',
			render: (off) => <FileDuration src={resolveFileSrc({ fileName: off?.filename })} />,
		},
		{
			title: 'Formato',
			align: 'center',
			key: 'ext',
			render: (off) => <FileExt src={resolveFileSrc({ fileName: off?.filename })} />,
		},
		{
			key: 'id',
			title: 'ID',
			align: 'center',
			render: (off) => (
				<Typography.Text title={off?._id} copyable={{ text: off?._id }}>{`${off?._id.slice(
					0,
					12
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Ações',
			align: 'right',
			key: 'options',
			render: (off) => (
				<>
					<Dropdown
						placement='bottomRight'
						overlay={
							<Menu>
								<Menu.Item
									key='play'
									onClick={() => {
										player.start({
											src: resolveFileSrc({ fileName: off?.filename }),
											ref: off?._id,
											meta: {
												name: off?.name,
												artist: off?.program?.name,
											},
										});
									}}
								>
									<Icon type='play-circle' /> Reproduzir
								</Menu.Item>
								<Menu.Item key='download' onClick={() => handleDownload({ off })}>
									<Icon type='download' /> Fazer download
								</Menu.Item>

								<Menu.Divider />

								<Menu.Item
									key='delete'
									className='ant-dropdown-menu-item-danger'
									onClick={() => {
										Modal.confirm({
											title: 'Excluir off?',
											icon: 'exclamation-circle',
											content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
											okText: 'Excluir',
											onOk: () => handleDelete({ offId: off?._id }),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}
								>
									<Icon type='delete' /> Excluir
								</Menu.Item>
							</Menu>
						}
					>
						<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 16 }} type='more' />
					</Dropdown>
				</>
			),
		},
	];

	const handleDownload = useCallback(
		async ({ off }) => {
			try {
				const { filename, name } = off;

				return download({ filename, name });
			} catch (error) {
				console.error(error);
				message.error('Não foi possível fazer o download.');
			}
		},
		[download]
	);

	const handleDownloadAsZIP = useCallback(async () => {
		setFallback((prev) => ({ ...prev, multiDownload: true }));

		const zip = new JSZip();

		for (let index = 0; index < selectedOffs.length; index++) {
			const off = selectedOffs[index];
			const filePath = resolveFileSrc({ fileName: off?.filename });
			const blob = await axios.get(filePath, { responseType: 'blob' });
			const ext = path.extname(off?.filename);
			const fName = `${off?.program?.name} - ${off?.name}${ext}`;

			zip.file(fName, blob.data, { binary: true });
		}

		const zipContent = await zip.generateAsync({ type: 'blob' });
		const selectedProgram = programs.find(({ _id }) => _id === filters?.program);

		let downloadName = 'OFFS';

		selectedProgram && (downloadName = `${downloadName} - ${selectedProgram.name}`);
		filters?.dateRelease && (downloadName = `${downloadName} - ${filters.dateRelease}`);

		saveAs(zipContent, downloadName);
		setFallback((prev) => ({ ...prev, multiDownload: false }));
	}, [programs, selectedOffs, filters]);

	const handleDelete = useCallback(async ({ offId }) => {
		try {
			setFallback((prev) => ({ ...prev, deleting: true }));

			await OffsAPI.destroy({ offId });

			setFallback((prev) => ({ ...prev, deleting: false }));
			setOffs((prev) => prev.filter(({ _id }) => _id !== offId));
		} catch (error) {
			console.error(error);
			message.error('Houve um erro ao deletar o off');

			setFallback((prev) => ({ ...prev, deleting: false }));
		}
	}, []);

	const handleMultiDelete = useCallback(async () => {
		for (let index = 0; index < selectedOffs.length; index++) {
			const { _id: offId } = selectedOffs[index];
			await handleDelete({ offId });
		}

		setSelectedOffs([]);
	}, [selectedOffs, handleDelete]);

	const handleCreateOffs = useCallback(async () => {
		try {
			const validationSchema = Yup.object().shape({
				program: Yup.string().required('Informe o programa'),
				dateRelease: Yup.string().required('Informe a data de lançamento'),
				files: Yup.array().required('Selecione os arquivos'),
			});

			if (newOffs.files.length !== neededOffs - storedOffs) {
				return message.error('Selecione a quantidade correta de arquivos');
			}

			setFallback((prev) => ({ ...prev, uploadingOffs: true }));
			setVisibleModals((prev) => ({ ...prev, uploadProgress: true }));

			await validationSchema.validate(newOffs);

			const payload = new FormData();

			for (const key in newOffs) {
				if (key === 'files') {
					newOffs[key].forEach((file) => payload.append('file', file));
				} else {
					payload.append(key, newOffs[key]);
				}
			}

			const {
				data: { offs },
			} = await OffsAPI.store({
				payload,
				onUploadProgress: ({ total, loaded }) => {
					setProgress(Math.floor((loaded * 100) / total));
				},
			});

			const { name: programName } = programs.find((p) => p._id === newOffs.program);

			setOffs((prev) => [...prev, ...offs.map((n) => ({ ...n, program: { name: programName } }))]);
			setNewOffs({ dateRelease: null, program: undefined, files: [] });
			setVisibleModals((prev) => ({ ...prev, addOffs: false }));

			setTimeout(() => {
				setProgress(null);
				setFallback((prev) => ({ ...prev, uploadingOffs: false }));
			}, 2000);
		} catch (error) {
			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}

			message.error('Houve um erro ao enviar os offs');

			setProgress(null);
			setFallback((prev) => ({ ...prev, uploadingOffs: false }));
		}
	}, [newOffs, programs, neededOffs, storedOffs]);

	const renderNeededOffsInfo = useCallback(() => {
		if (newOffs.program === null) return null;
		if (newOffs.dateRelease === null) return null;
		if (neededOffs === null) return null;
		if (storedOffs === null) return null;

		if (neededOffs === 0) {
			return <Alert showIcon type='warning' description='Esse modelo não utiliza OFFS REDE' />;
		}

		if (neededOffs === storedOffs) {
			return (
				<Alert showIcon type='success' description='Todos os OFFS dessa data já foram enviados' />
			);
		}

		return (
			<Alert
				style={{ marginBottom: 12 }}
				showIcon
				description={`${neededOffs - storedOffs} OFFS ainda devem ser enviados`}
			/>
		);
	}, [newOffs, neededOffs, storedOffs]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { users },
				} = await UsersAPI.index(`active=true`);

				setUsers(users);
				setFallback(null);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os usuários');
			}
		};

		fetchInitialData();
	}, []);

	useEffect(() => {
		const fetchUserPrograms = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingPrograms: true }));

				const {
					data: { programs },
				} = await ProgramsAPI.index(`userId=${filters.user}&isDeleted=false`);

				setPrograms(programs);
				setFallback((prev) => ({ ...prev, fetchingPrograms: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os programas');

				setFallback((prev) => ({ ...prev, fetchingPrograms: false }));
			}
		};

		if (filters.user) {
			fetchUserPrograms();
		}
	}, [filters.user]);

	useEffect(() => {
		const fetchProgramScripts = async () => {
			try {
				setFallback((prev) => ({ ...prev, calculatingNeededOffs: true }));

				const query = `program=${newOffs.program}&userId=${filters.user}`;
				const {
					data: { scripts },
				} = await ScriptsAPI.index({ query });

				if (scripts.length === 1) {
					const { body } = scripts[0];
					const neededOffs = body.filter((e) => e.type === 'OFF-MAIN').length;

					setNeededOffs(neededOffs);
				}

				setScripts(scripts);
				setFallback((prev) => ({ ...prev, calculatingNeededOffs: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os modelos');

				setFallback((prev) => ({ ...prev, calculatingNeededOffs: false }));
			}
		};

		if (newOffs.program) {
			fetchProgramScripts();
		}
	}, [newOffs.program, filters]);

	useEffect(() => {
		const fetchOffs = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingOffs: true }));

				let query = `page=${pagination?.current - 1}&limit=${pagination?.pageSize}&`;

				filters?.user && (query = `${query}userId=${filters.user}&`);
				filters?.program && (query = `${query}program=${filters.program}&`);
				filters?.dateRelease && (query = `${query}dateRelease=${filters.dateRelease}&`);

				const {
					data: { offs, total },
				} = await OffsAPI.index({ query });

				setOffs(offs);
				setPagination((prev) => ({ ...prev, total }));
				setFallback((prev) => ({ ...prev, fetchingOffs: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os offs');
			}
		};

		fetchOffs();
	}, [filters, pagination.current, pagination.pageSize]); //eslint-disable-line

	useEffect(() => {
		const fetchStoredOffs = async () => {
			try {
				setFallback((prev) => ({ ...prev, calculatingNeededOffs: true }));

				const query = `page=0&limit=1000&program=${newOffs.program}&dateRelease=${newOffs.dateRelease}`;
				const {
					data: { offs },
				} = await OffsAPI.index({ query });

				setStoredOffs(offs.length || 0);
				setFallback((prev) => ({ ...prev, calculatingNeededOffs: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os offs nessa data');

				setFallback((prev) => ({ ...prev, calculatingNeededOffs: false }));
			}
		};

		if (newOffs.program) {
			fetchStoredOffs();
		}
	}, [newOffs.program, newOffs.dateRelease]);

	useEffect(() => {
		setPagination((prev) => ({ ...prev, current: 1 }));
	}, [filters]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Offs' />

			<Container>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[
						{ breadcrumbName: 'PAINEL ADMINISTRATIVO' },
						{ breadcrumbName: 'CONTEÚDOS' },
						{ breadcrumbName: 'GERENCIAR OFFS' },
					]}
				/>

				<header>
					<Typography.Title level={2}>Offs rede</Typography.Title>
					{programs.length ? (
						<Button
							type='primary'
							onClick={() => setVisibleModals({ ...visibleModals, addOffs: true })}
						>
							<FiPlusCircle /> Adicionar offs
						</Button>
					) : (
						<Tooltip placement='left' title='Selecione o usuário'>
							<Button type='primary' disabled>
								<FiPlusCircle /> Adicionar offs
							</Button>
						</Tooltip>
					)}
				</header>

				<Form.Container layout='30% 30% 30%'>
					<Form.Item label='Filtrar por usuário'>
						<Select
							showSearch
							value={filters.user}
							placeholder='Selecione um usuário'
							onChange={(value) => {
								setPrograms([]);
								setFilters({ ...filters, program: undefined, user: value });
							}}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}
						>
							<Select.Option key='all' value={undefined} _search='TODOS'>
								TODOS
							</Select.Option>

							{users.map((user) => (
								<Select.Option
									key={user._id}
									value={user?._id}
									_search={`${user?.radioName}${user?.email}${user?.city}`}
								>
									{user?.radioName} - {user?.city}/{user?.state}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label='Filtrar por programa'>
						<Select
							showSearch
							value={filters.program}
							placeholder='Programa'
							onChange={(value) => setFilters({ ...filters, program: value })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}
						>
							<Select.Option key='all' value={undefined} _search='TODOS'>
								TODOS
							</Select.Option>

							{programs.map((program) => (
								<Select.Option
									key={program._id}
									disabled={!program.isActive}
									_search={program?.name}
								>
									{program?.name}
									{!program?.isActive && <Tag color='red'>Inadimplente</Tag>}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label='Filtrar por data'>
						<DatePicker
							placeholder='Data de lançamento'
							format='DD/MM/yyyy'
							value={filters?.dateRelease && moment(filters.dateRelease, 'DD/MM/yyyy')}
							onChange={(_, dateRelease) => setFilters({ ...filters, dateRelease })}
						/>
					</Form.Item>
				</Form.Container>

				<Divider />

				<TableHeader>
					<div className='actions'>
						<span>
							Quantidade: <strong>{pagination?.total}</strong>
						</span>
						<div>
							<Button
								size='small'
								disabled={!selectedOffs.length}
								type='ghost'
								onClick={handleDownloadAsZIP}
								loading={fallback?.multiDownload}
							>
								<FiDownload /> Baixar selecionados{' '}
								{selectedOffs.length !== 0 && `(${selectedOffs.length})`}
							</Button>

							<Button
								size='small'
								disabled={!selectedOffs.length}
								type='danger'
								onClick={() => {
									Modal.confirm({
										title: 'Deletar offs selecionadas?',
										type: 'danger',
										content:
											'Todos os offs selecionados serão excluídos e essa ação não poderá ser revertida, deseja continuar mesmo assim?',
										okText: 'Deletar',
										onOk: handleMultiDelete,
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}
							>
								<FiTrash2 /> Deletar selecionados
								{selectedOffs.length !== 0 && `(${selectedOffs.length})`}
							</Button>
						</div>
					</div>

					<PageSizeHandler pagination={pagination} setPagination={setPagination} />
				</TableHeader>

				<Table
					loading={fallback?.fetchingOffs}
					size='middle'
					rowKey='_id'
					columns={columns}
					dataSource={offs}
					style={{ border: 'none' }}
					pagination={{
						...pagination,
						size: 'large',
						onChange: (current) => setPagination({ ...pagination, current }),
					}}
					rowSelection={{
						onChange: (_, selectedRows) => setSelectedOffs(selectedRows),
					}}
				/>
			</Container>

			<Modal
				width={600}
				destroyOnClose
				visible={visibleModals?.addOffs}
				onCancel={() => {
					setVisibleModals({ ...visibleModals, addOffs: false });
					setNewOffs({ program: undefined, dateRelease: null, files: [] });
				}}
				onOk={handleCreateOffs}
				okButtonProps={{
					loading: fallback?.uploadingOffs,
					disabled: !newOffs?.files.length || !newOffs?.program,
				}}
				cancelButtonProps={{ disabled: fallback?.uploadingOffs }}
				okText={
					<>
						<FiUploadCloud /> Enviar offs
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				title={
					<>
						<FiPlusCircle /> Adicionar offs
					</>
				}
			>
				<Spin
					spinning={fallback?.calculatingNeededOffs ? true : false}
					tip='Calculando offs a serem enviados'
				>
					<Form.Container>
						<Form.Item label='Programa'>
							<Select
								showSearch
								value={newOffs.program}
								placeholder='Programa'
								onChange={(program) => {
									setNewOffs({ ...newOffs, program });
									setNeededOffs(null);
								}}
								filterOption={(input, { props: { _search } }) => {
									return _search.match(new RegExp(input, 'i'));
								}}
							>
								{programs.map((program) => (
									<Select.Option
										key={program._id}
										disabled={!program.isActive}
										_search={program?.name}
									>
										{program?.name}
										{!program?.isActive && <Tag color='red'>Inadimplente</Tag>}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						{scripts !== null && scripts?.length > 1 && (
							<Form.Item label='Selecione o modelo'>
								<Select
									placeholder='Modelo'
									onChange={(script) => {
										const { body } = JSON.parse(script);
										const neededOffs = body.filter((e) => e.type === 'OFF-MAIN').length;

										setNeededOffs(neededOffs);
									}}
								>
									{scripts.map((script) => (
										<Select.Option value={JSON.stringify(script)} key={script._id}>
											{script.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						)}

						<Form.Item label='Data de lançamento'>
							<DatePicker
								placeholder='Data de lançamento'
								format='DD/MM/yyyy'
								value={newOffs?.dateRelease && moment(newOffs.dateRelease, 'DD/MM/yyyy')}
								onChange={(_, dateRelease) => setNewOffs({ ...newOffs, dateRelease })}
							/>
						</Form.Item>

						{renderNeededOffsInfo()}

						<UploadZone
							id='upload'
							multiple={true}
							disabled={!neededOffs || storedOffs === null || neededOffs === storedOffs}
							inputProps={{ accept: 'audio/mp3, audio/wav', multiple: true }}
							label='Clique para selecionar os arquivos'
							icon='paper-clip'
							secondaryLabel={
								<p>
									Selecione um arquivo com a extensão <strong>MP3</strong> ou <strong>WAV</strong>
								</p>
							}
							uploads={newOffs.files}
							onChange={({ target: { files } }) => setNewOffs({ ...newOffs, files: [...files] })}
							onRemoveItem={(index) => {
								setNewOffs({ ...newOffs, files: newOffs.files.filter((_, i) => i !== index) });
							}}
						/>
					</Form.Container>
				</Spin>
			</Modal>

			<Progress
				progress={progress}
				succesTitle='Notícias enviadas com sucesso'
				title={
					<Typography.Paragraph>
						Enviando notícias do <strong>{progress?.program}</strong>, por favor aguarde...
					</Typography.Paragraph>
				}
			/>
		</>
	);
};

export default connect(({ user }) => ({ user }))(Offs);
