import React, { useState, useEffect, useCallback } from 'react';
import Highlighter from 'react-highlight-words';

import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
	PageHeader,
	Form,
	Select,
	Button,
	Input,
	Tabs,
	Icon,
	Typography,
	Divider,
	Modal,
	Checkbox,
	Progress,
	Card,
	message,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import UploadZone from '../../../../components/UploadZone';
import { Container, ButtonsContainer, highlightStyle } from './styles';

import GenresAPI from '../../../../services/sdks/genres';
import ProgramsAPI from '../../../../services/sdks/programs';
import ElementsAPI from '../../../../services/sdks/elements';
import UsersAPI from '../../../../services/sdks/user';
import CastersAPI from '../../../../services/sdks/caster';

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };
const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Admin' },
		{ breadcrumbName: 'Conteúdos' },
		{ breadcrumbName: 'Criar Programa' },
	],
	style: { marginBottom: 24 },
};

const CreateProgram = () => {
	const [fallback, setFallback] = useState({ initialData: true });
	const [search, setSearch] = useState(['']);
	const [casters, setCasters] = useState(['']);
	const [users, setUsers] = useState([]);
	const [genres, setGenres] = useState([]);
	const [infos, setInfos] = useState({
		name: '',
		description: '',
		filenameTemplate: '',
		isEditable: false,
		userId: undefined,
		genreId: undefined,
		caster: undefined,
	});

	const [vignettes, setVignettes] = useState({
		programIntro: [],
		programOutro: [],
		blockIntro: [],
		blockOutro: [],
		break: [],
	});

	const [progress, setProgress] = useState({
		programIntro: null,
		programOutro: null,
		blockIntro: null,
		blockOutro: null,
		break: null,
	});

	const handleChangeInfo = useCallback((key, value) => {
		setInfos((prev) => ({ ...prev, [key]: value }));
	}, []);

	const handlePickVignettes = useCallback((key, value) => {
		setVignettes((prev) => ({ ...prev, [key]: [...value] }));
	}, []);

	const handleRemoveVignettes = useCallback(
		(key, index) => {
			const updatedVignettes = vignettes[key].filter((_, i) => {
				return i !== index;
			});

			setVignettes((prev) => ({ ...prev, [key]: updatedVignettes }));
		},
		[vignettes]
	);

	const uploadVignettes = useCallback(
		async (programId) => {
			try {
				setFallback((prev) => ({ ...prev, uploading: true }));

				const promises = [];

				if (vignettes?.programIntro.length) {
					const payload = new FormData();

					payload.append('type', 'VIGNETTE-PROGRAM-INTRO');
					payload.append('category', 'VIGNETTE');
					payload.append('programId', programId);
					vignettes.programIntro.forEach((file, i) => payload.append(`file-${i}`, file));

					const promise = ElementsAPI.store({
						payload,
						onUploadProgress: ({ total, loaded }) => {
							const percentage = Math.floor((loaded * 100) / total);

							setProgress((prev) => ({ ...prev, programIntro: percentage }));
						},
					});

					promises.push(promise);
				}

				if (vignettes?.programOutro.length) {
					const payload = new FormData();

					payload.append('type', 'VIGNETTE-PROGRAM-OUTRO');
					payload.append('category', 'VIGNETTE');
					payload.append('programId', programId);
					payload.append('userId', infos?.userId);
					vignettes.programOutro.forEach((file, i) => payload.append(`file-${i}`, file));

					const promise = ElementsAPI.store({
						payload,
						onUploadProgress: ({ total, loaded }) => {
							const percentage = Math.floor((loaded * 100) / total);

							setProgress((prev) => ({ ...prev, programOutro: percentage }));
						},
					});

					promises.push(promise);
				}

				if (vignettes?.blockIntro.length) {
					const payload = new FormData();

					payload.append('type', 'VIGNETTE-BLOCK-INTRO');
					payload.append('category', 'VIGNETTE');
					payload.append('programId', programId);
					payload.append('userId', infos?.userId);
					vignettes.blockIntro.forEach((file, i) => payload.append(`file-${i}`, file));

					const promise = ElementsAPI.store({
						payload,
						onUploadProgress: ({ total, loaded }) => {
							const percentage = Math.floor((loaded * 100) / total);

							setProgress((prev) => ({ ...prev, blockIntro: percentage }));
						},
					});

					promises.push(promise);
				}

				if (vignettes?.blockOutro.length) {
					const payload = new FormData();

					payload.append('type', 'VIGNETTE-BLOCK-OUTRO');
					payload.append('category', 'VIGNETTE');
					payload.append('programId', programId);
					payload.append('userId', infos?.userId);
					vignettes.blockOutro.forEach((file, i) => payload.append(`file-${i}`, file));

					const promise = ElementsAPI.store({
						payload,
						onUploadProgress: ({ total, loaded }) => {
							const percentage = Math.floor((loaded * 100) / total);

							setProgress((prev) => ({ ...prev, blockOutro: percentage }));
						},
					});

					promises.push(promise);
				}

				if (vignettes?.break.length) {
					const payload = new FormData();

					payload.append('type', 'VIGNETTE-BREAK');
					payload.append('category', 'VIGNETTE');
					payload.append('programId', programId);
					payload.append('userId', infos?.userId);
					vignettes.break.forEach((file, i) => payload.append(`file-${i}`, file));

					const promise = ElementsAPI.store({
						payload,
						onUploadProgress: ({ total, loaded }) => {
							const percentage = Math.floor((loaded * 100) / total);

							setProgress((prev) => ({ ...prev, break: percentage }));
						},
					});

					promises.push(promise);
				}

				await Promise.allSettled(promises);

				setVignettes({
					programIntro: [],
					programOutro: [],
					blockIntro: [],
					blockOutro: [],
					break: [],
				});
				setProgress({
					programIntro: null,
					programOutro: null,
					blockIntro: null,
					blockOutro: null,
					break: null,
				});
				setInfos({
					name: '',
					description: '',
					filenameTemplate: '',
					isEditable: false,
					userId: undefined,
					genreId: undefined,
				});

				setSearch(['']);
				setFallback({ initialData: false });

				message.success('Programa criado com sucesso!');
			} catch (error) {
				console.error(error);
				message.error('Houve um erro, tente novamente');
			}
		},
		[vignettes, infos]
	);

	const handleCreateProgram = useCallback(async () => {
		try {
			const payload = infos;
			const validationSchema = Yup.object().shape({
				userId: Yup.string().required('Informe o usuário (dono do programa)'),
				filenameTemplate: Yup.string().required('Informe o nome padrão dos arquivos'),
				description: Yup.string().required('Informe a descrição do programa'),
				name: Yup.string().required('Informe o nome do programa'),
			});

			await validationSchema.validate(payload);

			setFallback((prev) => ({ ...prev, creatingProgram: true }));

			const {
				data: { program },
			} = await ProgramsAPI.store(payload);

			setFallback((prev) => ({ ...prev, creatingProgram: false }));

			await uploadVignettes(program?._id);
		} catch (error) {
			console.error(error);
			console.error({ ...error });

			setFallback((prev) => ({ ...prev, creatingProgram: false }));

			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}

			if (error?.response?.data?.message) {
				message.error(error?.response?.data?.message);
			} else {
				message.error('Houve um erro, tente novamente');
			}
		}
	}, [infos, uploadVignettes]);

	useEffect(() => {
		async function getUsers() {
			const res = await UsersAPI.index(`active=true`);
			return res.data.users;
		}

		async function getCasters() {
			const res = await CastersAPI.index(`active=true`);
			return res.data.casters;
		}

		async function getGenres() {
			const res = await GenresAPI.index();
			return res.data.genres;
		}

		const fetchInitialData = async () => {
			try {
				const [users, casters, genres] = await Promise.all([getUsers(), getCasters(), getGenres()]);

				setUsers(users);
				setCasters(casters);
				setGenres(genres);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os gêneros, tente novamente');
			}
		};

		fetchInitialData();
	}, []);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor, aguarde...' />;
	}

	return (
		<>
			<Meta title='Criar programa' />

			<PageHeader title='Criar Programa' breadcrumb={breadcrumb} />

			<Container>
				<Card>
					<Tabs tabPosition='left'>
						<Tabs.TabPane
							key='infos'
							tab={
								<>
									<Icon type='file-text' /> Informações
								</>
							}
						>
							<Form {...layout} onSubmit={handleCreateProgram}>
								<Form.Item label='Usuário (dono do programa)' required>
									<Select
										showSearch
										optionFilterProp='children'
										placeholder='Selecione o usuário'
										value={infos?.userId}
										onSearch={(value) => setSearch([value])}
										onChange={(value) => {
											handleChangeInfo('userId', value);
										}}
										filterOption={(input, { props: { _search } }) => {
											const regex = new RegExp(input, 'i');

											return _search.match(regex);
										}}
									>
										{users.map((user) => {
											const { radioName, city, state, email } = user;

											return (
												<Select.Option
													_search={`${radioName}${city}${state}${email}`}
													key={user._id}
													value={user._id}
												>
													<Highlighter
														highlightStyle={highlightStyle}
														searchWords={search}
														textToHighlight={`${radioName} - ${city}/${state} (${email})`}
													/>
												</Select.Option>
											);
										})}
									</Select>
								</Form.Item>

								<Form.Item label='Locutor (Opcional)'>
									<Select
										showSearch
										optionFilterProp='children'
										placeholder='Selecione o locutor'
										value={infos?.caster}
										onSearch={(value) => setSearch([value])}
										onChange={(value) => {
											handleChangeInfo('caster', value);
										}}
										filterOption={(input, { props: { _search } }) => {
											const regex = new RegExp(input, 'i');
											return _search.match(regex);
										}}
									>
										{casters.map((caster) => {
											const { name, surname } = caster;

											return (
												<Select.Option
													_search={`${name}${surname}`}
													key={caster._id}
													value={caster._id}
												>
													<Highlighter
														highlightStyle={highlightStyle}
														searchWords={search}
														textToHighlight={`${caster} ${surname}`}
													/>
												</Select.Option>
											);
										})}
									</Select>
								</Form.Item>

								<Form.Item label='Gênero do programa'>
									<Select
										showSearch
										value={infos?.genreId}
										onChange={(value) => handleChangeInfo('genreId', value)}
										optionFilterProp='children'
										placeholder='Selecione o gênero'
										filterOption={(input, { props: { children } }) => {
											return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
										}}
									>
										{genres.map((genre) => (
											<Select.Option key={genre._id} value={genre._id}>
												{genre?.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>

								<Form.Item label='Nome' required>
									<Input
										placeholder='Informe o nome do programa'
										value={infos?.name}
										onChange={({ target: { value } }) => {
											handleChangeInfo('name', value);
										}}
									/>
								</Form.Item>

								<Form.Item
									label='Nome padrão dos arquivos'
									help='Será usado para nomear os arquivos dos blocos gerados'
									required
								>
									<Input
										placeholder='Informe o nome padrão dos arquivos'
										value={infos?.filenameTemplate}
										onChange={({ target: { value } }) => {
											handleChangeInfo('filenameTemplate', value);
										}}
									/>
								</Form.Item>

								<Form.Item label='Descrição' style={{ marginTop: 16 }} required>
									<Input.TextArea
										placeholder='Descreva o programa'
										value={infos?.description}
										onChange={({ target: { value } }) => {
											handleChangeInfo('description', value);
										}}
									/>
								</Form.Item>

								<Form.Item label='' wrapperCol={{ offset: 6 }}>
									<Checkbox
										checked={infos?.isEditable}
										onChange={({ target: { checked } }) => {
											handleChangeInfo('isEditable', checked);
										}}
									>
										Usuário poderá editar o programa
									</Checkbox>
								</Form.Item>
							</Form>
						</Tabs.TabPane>

						<Tabs.TabPane
							key='vignettes'
							tab={
								<>
									<Icon type='unordered-list' /> Adicionar vinhetas
								</>
							}
						>
							<Typography.Paragraph style={{ fontStyle: 'italic' }}>
								<span style={{ color: 'red' }}>*</span> Essa etapa é opcional, as vinhetas podem ser
								adicionadas depois pelo acesso do cliente.
							</Typography.Paragraph>

							<Divider />

							<Tabs size='small' tabPosition='left'>
								<Tabs.TabPane
									key='programIntro'
									tab={`(${vignettes?.programIntro.length}) Vinhetas de introdução de programa`}
								>
									<UploadZone
										id='program-intro'
										label='Clique para selecionar os arquivos'
										secondaryLabel={
											<p>
												Selecione arquivos com a extensão <strong>MP3</strong> ou <strong>WAV</strong>
											</p>
										}
										icon='cloud-upload'
										uploadIcon='customer-service'
										inputProps={{ accept: 'audio/mp3, audio/wav', multiple: true }}
										uploads={vignettes?.programIntro}
										onChange={({ target: { files } }) => {
											return handlePickVignettes('programIntro', files);
										}}
										onRemoveItem={(index) => {
											return handleRemoveVignettes('programIntro', index);
										}}
									/>
								</Tabs.TabPane>

								<Tabs.TabPane
									key='programOutro'
									tab={`(${vignettes?.programOutro.length}) Vinhetas de encerramento de programa`}
								>
									<UploadZone
										id='program-outro'
										label='Clique para selecionar os arquivos'
										secondaryLabel={
											<p>
												Selecione arquivos com a extensão <strong>MP3</strong> ou <strong>WAV</strong>
											</p>
										}
										icon='cloud-upload'
										uploadIcon='customer-service'
										inputProps={{ accept: 'audio/mp3, audio/wav', multiple: true }}
										uploads={vignettes?.programOutro}
										onChange={({ target: { files } }) => {
											return handlePickVignettes('programOutro', files);
										}}
										onRemoveItem={(index) => {
											return handleRemoveVignettes('programOutro', index);
										}}
									/>
								</Tabs.TabPane>

								<Tabs.TabPane
									key='blockIntro'
									tab={`(${vignettes?.blockIntro.length}) Vinhetas de introdução de bloco`}
								>
									<UploadZone
										id='block-intro'
										label='Clique para selecionar os arquivos'
										secondaryLabel={
											<p>
												Selecione arquivos com a extensão <strong>MP3</strong> ou <strong>WAV</strong>
											</p>
										}
										icon='cloud-upload'
										uploadIcon='customer-service'
										inputProps={{ accept: 'audio/mp3, audio/wav', multiple: true }}
										uploads={vignettes?.blockIntro}
										onChange={({ target: { files } }) => {
											return handlePickVignettes('blockIntro', files);
										}}
										onRemoveItem={(index) => {
											return handleRemoveVignettes('blockIntro', index);
										}}
									/>
								</Tabs.TabPane>

								<Tabs.TabPane
									key='blockOutro'
									tab={`(${vignettes?.blockOutro.length}) Vinhetas de encerramento de bloco`}
								>
									<UploadZone
										id='block-outro'
										label='Clique para selecionar os arquivos'
										secondaryLabel={
											<p>
												Selecione arquivos com a extensão <strong>MP3</strong> ou <strong>WAV</strong>
											</p>
										}
										icon='cloud-upload'
										uploadIcon='customer-service'
										inputProps={{ accept: 'audio/mp3, audio/wav', multiple: true }}
										uploads={vignettes?.blockOutro}
										onChange={({ target: { files } }) => {
											return handlePickVignettes('blockOutro', files);
										}}
										onRemoveItem={(index) => {
											return handleRemoveVignettes('blockOutro', index);
										}}
									/>
								</Tabs.TabPane>

								<Tabs.TabPane key='break' tab={`(${vignettes?.break.length}) Vinhetas de passagem`}>
									<UploadZone
										id='break'
										label='Clique para selecionar os arquivos'
										secondaryLabel={
											<p>
												Selecione arquivos com a extensão <strong>MP3</strong> ou <strong>WAV</strong>
											</p>
										}
										icon='cloud-upload'
										uploadIcon='customer-service'
										inputProps={{ accept: 'audio/mp3, audio/wav', multiple: true }}
										uploads={vignettes?.break}
										onChange={({ target: { files } }) => {
											return handlePickVignettes('break', files);
										}}
										onRemoveItem={(index) => {
											return handleRemoveVignettes('break', index);
										}}
									/>
								</Tabs.TabPane>
							</Tabs>
						</Tabs.TabPane>
					</Tabs>

					<Divider />

					<ButtonsContainer>
						<Button
							loading={fallback?.creatingProgram}
							size='large'
							type='primary'
							onClick={handleCreateProgram}
							htmlType='button'
						>
							Finalizar criação do programa <Icon type='save' />
						</Button>
					</ButtonsContainer>
				</Card>
			</Container>

			<Modal
				title={
					<>
						<Icon type='cloud-upload' style={{ marginRight: 8 }} />
						Fazendo upload das vinhetas
					</>
				}
				footer={null}
				visible={fallback?.uploading}
				closable={false}
			>
				{progress?.programIntro !== null && (
					<>
						<Typography.Text>Vinhetas de introdução de programa...</Typography.Text>
						<Progress percent={progress?.programIntro} showInfo />
						<Divider />
					</>
				)}

				{progress?.programOutro !== null && (
					<>
						<Typography.Text>Vinhetas de encerramento de programa...</Typography.Text>
						<Progress percent={progress?.programOutro} />
						<Divider />
					</>
				)}

				{progress?.blockIntro !== null && (
					<>
						<Typography.Text>Vinhetas de introdução de bloco...</Typography.Text>
						<Progress percent={progress?.blockIntro} />
						<Divider />
					</>
				)}

				{progress?.blockOutro !== null && (
					<>
						<Typography.Text>Vinhetas de encerramento de bloco...</Typography.Text>
						<Progress percent={progress?.blockOutro} />
						<Divider />
					</>
				)}

				{progress?.break !== null && (
					<>
						<Typography.Text>Vinhetas de passagem...</Typography.Text>
						<Progress percent={progress?.break} />
						<Divider />
					</>
				)}
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(CreateProgram);
