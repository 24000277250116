import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;
	height: 100%;
	position: relative;
	overflow-y: none;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Logo = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 32px;

	& > img {
		width: 200px;
	}
`;

export const Cover = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;

	& > div.overlay {
		width: 100%;
		height: 100%;
		background: linear-gradient(67.29deg, #f135f5 -38.22%, #990cfb 81.71%);
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.94;
		z-index: 10;
	}

	& > img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 5;
	}
`;

export const FormContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	width: 490px;
	max-width: 90vw;
	z-index: 25;
`;

export const FormContainerInner = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	background: #fff;
	padding: 32px;
	border-radius: var(--border-radius-base);
`;

export const Copyright = styled.span`
	font-size: 14px;
	opacity: 0.7;
	text-align: center;
	width: 100%;
	color: #fff;
	margin-top: 16px;

	& a {
		color: #fff;

		&:hover {
			color: #fff;
			text-decoration: underline;
		}
	}
`;
