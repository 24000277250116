import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import PlayerContext from './context';
import TKPlayConfigAPI from '../../services/sdks/tkPlayConfig';
import TKVoiceConfigAPI from '../../services/sdks/tkVoiceConfig';
import RadioVignetteConfigsAPI from '../../services/sdks/radio-vignette-configs';
import RadioVignettesAPI from '../../services/sdks/radio-vignettes';

const INITIAL_RADIO_V_STATE = {
	staticTypes: [],
	scheduledTypes: [],
};

const ConfigsProvider = ({ children, user }) => {
	const [tkPlayConfig, setTkPlayConfig] = useState(null);
	const [tkVoiceConfig, setTkVoiceConfig] = useState(null);
	const [radioVignetteConfigs, setRadioVignetteConfigs] = useState(INITIAL_RADIO_V_STATE);
	const [populatedRadioVignettes, setPopulatedRadioVignettes] = useState(INITIAL_RADIO_V_STATE);

	useEffect(() => {
		async function getTkPlayConfig() {
			const res = await TKPlayConfigAPI.show();
			setTkPlayConfig(res.data.tkPlayConfig);
		}

		async function getTkVoiceConfig() {
			const res = await TKVoiceConfigAPI.show();
			setTkVoiceConfig(res.data.tkVoiceConfig);
		}

		async function getPopulatedRadioVignettes() {
			const res = await RadioVignettesAPI.getPopulatedTypes();
			setPopulatedRadioVignettes(res.data.populatedTypes);
		}

		async function getRadioVignetteConfigs() {
			const res = await RadioVignetteConfigsAPI.list(user._id, 0, 100);
			setRadioVignetteConfigs(res.data.radioVignetteConfigs);
		}

		const fetchConfigs = async () => {
			try {
				await Promise.all([
					getTkPlayConfig(),
					getTkVoiceConfig(),
					getRadioVignetteConfigs(),
					getPopulatedRadioVignettes(),
				]);
			} catch (error) {
				setTkPlayConfig(null);
				setTkVoiceConfig(null);
				setRadioVignetteConfigs(INITIAL_RADIO_V_STATE);
				setPopulatedRadioVignettes([]);
			}
		};

		if (user?._id) {
			fetchConfigs();
		}
	}, [user]);

	return (
		<PlayerContext.Provider
			value={{
				tkPlayConfig,
				tkVoiceConfig,
				populatedRadioVignettes,
				radioVignetteConfigs,
			}}>
			{children}
		</PlayerContext.Provider>
	);
};

export default connect(({ user }) => ({ user }))(ConfigsProvider);
