import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { differenceInDays, parseISO } from 'date-fns';

import { Input, Typography, Form, Button, Modal, Divider, Alert, Tag, message } from 'antd';

import Meta from '../../../components/Meta';
import Terms from '../../../components/Terms';
import Renew from '../../../components/Renew';
import SignUp from '../../../components/SignUp';
import LoginCards from '../../../components/LoginCards';
import {
	Container,
	Cover,
	CoverText,
	Billets,
	Billet,
	FormContainer,
	FormContainerInner,
	Copyright,
	ModalBanner,
	DemoCanvas,
	StopedDemo,
	AboutButton,
	FormFooter,
	IFrameContainer,
	CoverArea,
} from './styles';

import AuthAPI from '../../../services/sdks/auth';
import BilletsAPI from '../../../services/sdks/billets';
import ContractsAPI from '../../../services/sdks/contracts';
import UseTermsAPI from '../../../services/sdks/useTerms';

import * as UserActions from '../../../store/actions/user';

import { useFormatter } from '../../../hooks';

import logoSrc from '../../../assets/images/logos/logo-secondary-white.svg';
import coverSrc from '../../../assets/images/login/cover.jpg';
import demoSrc from '../../../assets/audios/audio-demo.mp3';

const Login = ({ setUser }) => {
	const toCurrencyFormat = useFormatter({ style: 'currency', currency: 'BRL' });

	const audioRef = useRef(null);
	const canvasRef = useRef(null);
	const history = useHistory();

	const [fallback, setFallback] = useState({ authenticating: false });
	const [credentials, setCredentials] = useState({
		email: '',
		password: '',
		rememberMe: false,
	});
	const [errors, setErrors] = useState({});
	const [tmpAuth, setTmpAuth] = useState(null);
	const [userId, setUserId] = useState(null);
	const [term, setTerm] = useState(null);
	const [isPlayingDemo, setIsPlayingDemo] = useState(false);
	const [billets, setBillets] = useState([]);
	const [showModals, setShowModals] = useState({
		signUp: false,
		renew: false,
		intranet: false,
		term: false,
		descriptions: false,
	});

	const handelToggleDemoPlayer = useCallback(() => {
		const audio = audioRef.current;
		const canvas = canvasRef.current;
		const context = new AudioContext();

		if (isPlayingDemo) {
			setIsPlayingDemo(false);
			return audio.pause();
		}

		setIsPlayingDemo(true);

		audio.load();
		audio.play();

		const src = context.createMediaElementSource(audio);
		const analyser = context.createAnalyser();
		const ctx = canvas.getContext('2d');

		src.connect(analyser);
		analyser.connect(context.destination);
		analyser.fftSize = 256;

		const bufferLength = analyser.frequencyBinCount;
		const dataArray = new Uint8Array(bufferLength);

		const WIDTH = canvas.width;
		const HEIGHT = canvas.height;

		const renderFrame = () => {
			requestAnimationFrame(renderFrame);

			let x = 0;

			analyser.getByteFrequencyData(dataArray);
			const bars = [...dataArray];
			const gap = 1;
			const barWidth = Math.ceil(WIDTH / bars.length) - gap;

			ctx.clearRect(0, 0, WIDTH, HEIGHT);

			ctx.beginPath();
			ctx.lineWidth = barWidth;
			ctx.lineCap = 'round';
			ctx.strokeStyle = '#670092';

			for (let i = 0; i < bars.length; i++) {
				const barHeight = bars[i] * 0.55 >= HEIGHT ? HEIGHT - 10 : bars[i] * 0.55;

				const verticalPadding = (HEIGHT - barHeight) / 2;

				ctx.moveTo(x, verticalPadding);
				ctx.lineTo(x, HEIGHT - verticalPadding);

				x += barWidth + gap;
			}

			ctx.closePath();
			ctx.stroke();
		};

		audio.play();
		renderFrame();
	}, [isPlayingDemo]);

	const hasUnpaidBillets = useMemo(() => {
		if (!billets) return false;

		return billets.some((billet) => billet?.status === 'unpaid');
	}, [billets]);

	const openModal = useCallback((modal) => {
		setShowModals((prev) => ({ ...prev, [modal]: true }));
	}, []);

	const closeModal = useCallback((modal) => {
		setShowModals((prev) => ({ ...prev, [modal]: false }));
	}, []);

	const handleChangeCredentials = useCallback((key, value) => {
		setCredentials((prev) => ({ ...prev, [key]: value }));
	}, []);

	const fetchUserBillets = useCallback(async (user) => {
		try {
			let userBillets = [];
			const {
				data: { contracts },
			} = await ContractsAPI.index({ query: `hirer=${user?._id}` });

			if (!contracts.length) {
				return [];
			}

			for (let index = 0; index < contracts.length; index++) {
				const contractId = contracts[index]._id;

				const {
					data: { billets },
				} = await BilletsAPI.show(contractId);

				userBillets = billets;
			}

			const defaultingBillets = userBillets.filter((billet) => {
				return billet.status === 'unpaid';
			});

			const closerPayments = userBillets
				.filter((billet) => billet.status !== 'canceled' && billet.status !== 'unpaid')
				.map((billet) => {
					const today = new Date();
					const { expire_at } = billet.payment.banking_billet;
					const daysForExpiration = differenceInDays(parseISO(expire_at), today);

					return { daysForExpiration, ...billet };
				})
				.filter(({ daysForExpiration }) => daysForExpiration <= 5);

			return [...defaultingBillets, ...closerPayments];
		} catch (error) {
			console.error(error);
		}
	}, []);

	const handleAuthenticate = useCallback(
		(user, token) => {
			setUser({ user, token });

			localStorage.setItem('@auth', token);
			history.push([1, 2, 5, 9].includes(user?.userLevel) ? '/commom' : '/admin');
		},
		[setUser, history]
	);

	const handleAcceptTerms = useCallback(() => {
		closeModal('term');
		return handleAuthenticate(tmpAuth.user, tmpAuth.token);
	}, [closeModal, handleAuthenticate, tmpAuth]);

	const handleCloseBilletsModal = useCallback(() => {
		if (hasUnpaidBillets) {
			return closeModal('billets');
		} else {
			if (term) {
				closeModal('billets');
				return openModal('term');
			} else {
				return handleAuthenticate(tmpAuth.user, tmpAuth.token);
			}
		}
	}, [term, closeModal, openModal, tmpAuth, hasUnpaidBillets, handleAuthenticate]);

	const handleSignIn = useCallback(
		async (event) => {
			try {
				event.preventDefault();
				setFallback((prev) => ({ ...prev, authenticating: true }));

				const validationSchema = Yup.object().shape({
					email: Yup.string().email('Informe um email válido').required('Informe o email'),
					password: Yup.string().required('Informe sua senha'),
				});

				await validationSchema.validate(credentials, { abortEarly: false });

				setErrors({});

				const {
					data: { user, token },
				} = await AuthAPI.post(credentials);

				if (user.userLevel <= 1) {
					return handleAuthenticate(user, token);
				}

				const {
					data: { term, hasTermUpdated },
				} = await UseTermsAPI.verify(user._id);

				const billets = await fetchUserBillets(user);

				setTmpAuth({ user, token });
				setFallback((prev) => ({ ...prev, authenticating: false }));
				setBillets(billets || []);
				setTerm(term);

				if (billets?.length) {
					return openModal('billets');
				}

				if (hasTermUpdated) {
					return openModal('term');
				}

				return handleAuthenticate(user, token);
			} catch (error) {
				console.error(error);

				setErrors({});
				setFallback((prev) => ({ ...prev, authenticating: false }));

				if (error instanceof Yup.ValidationError) {
					const errors = {};

					error.inner.forEach(({ path, message }) => (errors[path] = message));
					return setErrors(errors);
				}

				if (error?.response?.status) {
					const { status } = error.response;

					switch (status) {
						case 404:
							return setErrors({ email: 'Usuário não encontrado' });
						case 400:
							return setErrors({ password: 'Senha incorreta' });
						case 401:
							return message.error('Seu acesso ao sistema foi bloqueado!');
						case 402:
							if (error?.response?.data?.message === '--ADMIN--') {
								return openModal('intranet');
							} else {
								const userId = error?.response?.data?.id;

								openModal('renew');
								setUserId(userId);
								message.info('Por favor, renove sua assinatura');

								break;
							}
						default:
							return message.error('Houve um erro no servidor');
					}
				}
			}
		},
		[credentials, fetchUserBillets, handleAuthenticate, openModal]
	);

	return (
		<>
			<Meta title='Entrar' />

			<Container>
				<Cover>
					<img src={coverSrc} alt='talkplay' />
					<div className='overlay'></div>
				</Cover>

				<CoverArea>
					<span></span>

					<CoverText>
						<img className='logo' src={logoSrc} alt='TalkPlay Logo' />

						<Typography.Title>
							Atualize a sua estação de rádio com conteúdos <i>Ao Vivo</i>
						</Typography.Title>

						<p>
							A Talk Play dá a você controle total sobre todas as peças de imagem dos conteúdos.
							Edite, reorganize ou reimagine qualquer um dos projetos existentes ou crie o seu
							próprio em segundos
						</p>
					</CoverText>

					<LoginCards />
				</CoverArea>

				<FormContainer>
					<span></span>
					<FormContainerInner>
						<Typography.Title level={2} style={{ marginBottom: 24 }}>
							Log In
						</Typography.Title>

						<form onSubmit={handleSignIn}>
							<Form.Item
								help={errors['email']}
								style={{ marginBottom: 16, width: '100%' }}
								validateStatus={errors['email'] && 'error'}
							>
								<Input
									size='large'
									placeholder='Informe seu email'
									value={credentials?.email}
									onChange={({ target: { value } }) => {
										handleChangeCredentials('email', value);
									}}
								/>
							</Form.Item>

							<Form.Item
								help={errors['password']}
								style={{ marginBottom: 12, width: '100%' }}
								validateStatus={errors['password'] && 'error'}
							>
								<Input.Password
									size='large'
									placeholder='Informe sua senha'
									value={credentials?.password}
									onChange={({ target: { value } }) => {
										handleChangeCredentials('password', value);
									}}
								/>
							</Form.Item>

							<div
								style={{
									width: '100%',
									marginBottom: 24,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-end',
								}}
							>
								<Link className='__link' to='/forgot-password'>
									Esqueceu a senha?
								</Link>
							</div>

							<Button
								style={{ width: '100%' }}
								type='primary'
								icon='login'
								size='large'
								htmlType='submit'
								loading={fallback.authenticating}
							>
								Entrar
							</Button>
						</form>

						<FormFooter>
							<Typography.Text style={{ marginTop: 24, textAlign: 'center' }}>
								Ainda não possui cadastro?{' '}
								<a
									className='__link'
									target='_self'
									href='#sign-up'
									onClick={() => openModal('signUp')}
								>
									Criar conta
								</a>
							</Typography.Text>
							<AboutButton onClick={() => openModal('descriptions')}>
								Como o TalkPlay funciona?
							</AboutButton>
						</FormFooter>
					</FormContainerInner>

					<Copyright>
						Copyright &trade; {new Date().getFullYear()}{' '}
						<a
							className='__link'
							href='https://talkradio.com.br'
							target='_blank'
							rel='noopener noreferrer'
						>
							TalkRadio
						</a>
					</Copyright>
				</FormContainer>
			</Container>

			<SignUp
				visible={showModals.signUp}
				onClose={() => {
					closeModal('signUp');
					history.replace('/login');
				}}
			/>
			<Renew visible={showModals.renew} userId={userId} onClose={() => closeModal('renew')} />
			<Terms
				user={tmpAuth?.user}
				visible={showModals.term}
				onAccept={handleAcceptTerms}
				onClose={() => closeModal('term')}
				term={term}
			/>

			<Modal
				title='Boletos'
				footer={null}
				width={600}
				onCancel={handleCloseBilletsModal}
				visible={showModals?.billets}
			>
				{hasUnpaidBillets ? (
					<>
						<Alert
							showIcon
							type='error'
							message='Atenção!'
							description='Você possui boletos com o pagamento pendente. Por favor efetue o pagamento para continuar utilizando o sistema.'
						/>

						<Divider />

						<Billets>
							{billets
								.filter((billet) => billet.status === 'unpaid')
								.map((billet) => (
									<Billet key={billet._id}>
										<header>
											<span>
												O boleto expirou em{' '}
												{billet.payment.banking_billet.expire_at.split('-').reverse().join('/')}
											</span>
											<Typography.Title level={4}>{billet.items[0].name}</Typography.Title>
										</header>

										<Tag color='green' className='__value'>
											{toCurrencyFormat(billet.total / 100)} reais
										</Tag>

										<Typography.Text copyable>
											{billet.payment.banking_billet.barcode}
										</Typography.Text>

										<footer>
											<Button
												type='primary'
												icon='eye'
												target='_blank'
												rel='noopener noreferrer'
												href={billet.payment.banking_billet.link}
											>
												Visualizar boleto
											</Button>
										</footer>
									</Billet>
								))}
						</Billets>
					</>
				) : (
					<>
						<Alert
							showIcon
							type='warning'
							message='Atenção!'
							description='Você possui boletos que irão expirar em breve.'
						/>

						<Divider />

						<Billets>
							{billets.map((billet) => (
								<Billet key={billet._id}>
									<header>
										<span>
											O boleto irá expirar em{' '}
											{billet.payment.banking_billet.expire_at.split('-').reverse().join('/')},{' '}
											{billet.daysForExpiration} dias restantes;
										</span>
										<Typography.Title level={4}>{billet.items[0].name}</Typography.Title>
									</header>

									<Tag color='green' className='__value'>
										{toCurrencyFormat(billet.total / 100)} reais
									</Tag>

									<Typography.Text copyable>
										{billet.payment.banking_billet.barcode}
									</Typography.Text>

									<footer>
										<Button
											type='primary'
											icon='eye'
											target='_blank'
											href={billet.payment.banking_billet.link}
										>
											Visualizar boleto
										</Button>
									</footer>
								</Billet>
							))}
						</Billets>
					</>
				)}
			</Modal>

			<Modal
				centered={true}
				width={768}
				title='Pagamento não efetuado'
				visible={showModals.intranet}
				onCancel={() => closeModal('intranet')}
				footer={null}
			>
				<p>
					Olá querido cliente, para regularizar sua mensalidade acesse{' '}
					<a
						className='__link'
						href='https://www.talkradio.com.br/segunda-via-boletos'
						target='_blank'
						rel='noopener noreferrer'
					>
						www.talkradio.com.br/segunda-via-boletos
					</a>
					, na sequencia digite o CNPJ e retire as faturas de sua mensalidade. Na sequencia por
					gentileza nos encaminhe o comprovante para providenciar a baixa no sistema.
					<br />
					<br />
					Atenciosamente <strong>Grupo Talk Radio de Comunicação.</strong>
				</p>
			</Modal>

			<Modal
				centered
				destroyOnClose
				visible={showModals.descriptions}
				onCancel={() => {
					closeModal('descriptions');
					audioRef.current.pause();
					setIsPlayingDemo(false);
				}}
				footer={null}
				width={800}
			>
				<ModalBanner>
					<Typography.Title level={3}>Talk Play Radio Edit</Typography.Title>

					<IFrameContainer>
						<iframe
							title='TalkPlay'
							src='https://streamable.com/e/ees0hg?loop=0'
							frameborder='0'
							width='100%'
							height='100%'
							allowfullscreen
						></iframe>
					</IFrameContainer>

					<Typography.Paragraph style={{ marginTop: 16 }}>
						Produzir conteúdos é um processo bem peculiar. Envolve um conhecimento específico em
						determinadas áreas como softwares de edição, masterização, multipistas, plugin's entre
						outros sistemas bem superiores ao que nós estamos acostumados, alguns equipamentos bem
						caros e um par de ouvidos acostumados com coisas diferentes.
					</Typography.Paragraph>

					<Typography.Text>
						Talk Play Radio Edit é uma ferramenta de edição que faz todo o trabalho para você, dando
						inúmeras possibilidades na hora de dar vida a sua ideia.
					</Typography.Text>

					<Typography.Title style={{ width: '100%' }} level={4}>
						Para quem é o TalkPlay?
					</Typography.Title>

					<ul>
						<li>Emissoras de Rádio</li>
						<li>Produtoras de Áudio</li>
						<li>Criadores de Conteúdos (Programas/Podcasts) </li>
						<li>Agências de Publicidades </li>
						<li>Empresas</li>
						<li>Governo </li>
					</ul>

					<Typography.Paragraph style={{ marginTop: 16 }}>
						Se você precisa produzir conteúdos com formato realístico e ao-vivo, eliminando todo o
						processo de mão-de-obra humana, demora na criação, mixagem, música, playlists, trilhas,
						vinhetas, voz, masterização e todos os outros processos da área, venha conhecer a{' '}
						<strong>Talk Play Radio Edit</strong>.
					</Typography.Paragraph>

					<Divider />

					<Button size='large' icon='play-circle' type='primary' onClick={handelToggleDemoPlayer}>
						{isPlayingDemo ? 'Parar player' : 'Clique e veja o poder do TalkPlay'}
					</Button>

					<DemoCanvas ref={canvasRef} show={isPlayingDemo} />

					<StopedDemo show={!isPlayingDemo}>
						<div></div>
					</StopedDemo>

					<div>
						<Button
							type='link'
							href='https://talkradio.com.br#entre-em-contato'
							target='_blank'
							rel='noopener noreferrer'
						>
							<span style={{ textDecoration: 'underline' }}>Entre em contato</span>
						</Button>
					</div>

					<audio src={demoSrc} ref={audioRef} />
				</ModalBanner>
			</Modal>
		</>
	);
};

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) => bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
