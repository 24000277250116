import * as Yup from 'yup';

const env = {
	baseURL: process.env.REACT_APP_API_URL,
	talkId: process.env.REACT_APP_TALK_ID,
	episodesS3Bucket: process.env.REACT_APP_EPISODES_BUCKET,
	playlistsS3Bucket: process.env.REACT_APP_PLAYLISTS_BUCKET,
	miscS3Bucket: process.env.REACT_APP_MISC_BUCKET,
};

const validationSchema = Yup.object().shape({
	baseURL: Yup.string().required(),
	talkId: Yup.string().required(),
	episodesS3Bucket: Yup.string().required(),
	playlistsS3Bucket: Yup.string().required(),
	miscS3Bucket: Yup.string().required(),
});

export default validationSchema.validateSync(env);
