import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Settings from '../pages/Commom/Settings';
import ProgramDetails from '../pages/Commom/Programs/ProgramDetails';
import ManagePrograms from '../pages/Commom/Programs/ManagePrograms';
import CreateProgram from '../pages/Commom/Programs/CreateProgram';
import CreateVignettes from '../pages/Commom/CreateVignettes';
import Network from '../pages/Commom/Network';
import EditScript from '../pages/Commom/Scripts/EditScript';
import CreateScript from '../pages/Commom/Scripts/CreateScript';
import Signatures from '../pages/Commom/Signatures';
import Soundtracks from '../pages/Commom/Sountracks';
import WeeklyScripts from '../pages/Commom/Playlists/WeeklyScripts';
import ManagePlaylists from '../pages/Commom/Playlists/ManagePlaylists';
import News from '../pages/Commom/Voices/News';
import Blitz from '../pages/Commom/Voices/Blitz';
import Offs from '../pages/Commom/Voices/Offs';
import Wpps from '../pages/Commom/Voices/Wpps';
import Interviews from '../pages/Commom/Voices/Interviews';
import Contracts from '../pages/Commom/Contracts';
import ManageEpisodes from '../pages/Commom/Episodes/Manage';
import CreateEpisode from '../pages/Commom/Episodes/CreateEpisode';
import CreatedEpisode from '../pages/Commom/Episodes/CreatedEpisode';
import TrackPresentations from '../pages/Commom/TrackPresentations/ManageTrackPresentations';
import CreateTrackPresentations from '../pages/Commom/TrackPresentations/CreateTrackPresentations';

import NotFound from '../pages/Exceptions/_404';

import AppLayout from '../components/Layout';
import { TalkNavigation } from '../components/Navigation';
import RadioVignettesConfigs from '../pages/Commom/RadioVignettes/RadioVignettesConfigs';
import ListRadioVignettes from '../pages/Commom/RadioVignettes/ListRadioVignettes';

const CommomStack = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path='/'>
				<Redirect to='/commom/episodes' />
			</Route>

			<Route exact path='/login'>
				<Redirect to='/commom/episodes' />
			</Route>

			<Route exact path='/commom'>
				<Redirect to='/commom/episodes' />
			</Route>

			<Route exact path='/commom/settings'>
				<AppLayout navigation={<TalkNavigation />}>
					<Settings />
				</AppLayout>
			</Route>

			<Route exact path='/commom/radio-vignettes/configs'>
				<AppLayout navigation={<TalkNavigation />}>
					<RadioVignettesConfigs />
				</AppLayout>
			</Route>
			<Route exact path='/commom/radio-vignettes'>
				<AppLayout navigation={<TalkNavigation />}>
					<ListRadioVignettes />
				</AppLayout>
			</Route>

			<Route exact path='/commom/programs'>
				<AppLayout navigation={<TalkNavigation />}>
					<ManagePrograms />
				</AppLayout>
			</Route>
			<Route exact path='/commom/programs/create'>
				<AppLayout navigation={<TalkNavigation />}>
					<CreateProgram />
				</AppLayout>
			</Route>
			<Route exact path='/commom/programs/:programId/details'>
				<AppLayout navigation={<TalkNavigation />}>
					<ProgramDetails />
				</AppLayout>
			</Route>
			<Route exact path='/commom/elements/create'>
				<AppLayout navigation={<TalkNavigation />}>
					<CreateVignettes />
				</AppLayout>
			</Route>

			<Route exact path='/commom/network'>
				<AppLayout navigation={<TalkNavigation />}>
					<Network />
				</AppLayout>
			</Route>

			<Route exact path='/commom/soundtracks'>
				<AppLayout navigation={<TalkNavigation />}>
					<Soundtracks />
				</AppLayout>
			</Route>

			<Route exact path='/commom/scripts/:scriptId/edit'>
				<AppLayout navigation={<TalkNavigation />}>
					<EditScript />
				</AppLayout>
			</Route>
			<Route exact path='/commom/scripts/create'>
				<AppLayout navigation={<TalkNavigation />}>
					<CreateScript />
				</AppLayout>
			</Route>

			<Route exact path='/commom/signatures'>
				<AppLayout navigation={<TalkNavigation />}>
					<Signatures />
				</AppLayout>
			</Route>

			<Route exact path='/commom/voices/offs'>
				<AppLayout navigation={<TalkNavigation />}>
					<Offs />
				</AppLayout>
			</Route>
			<Route exact path='/commom/voices/news'>
				<AppLayout navigation={<TalkNavigation />}>
					<News />
				</AppLayout>
			</Route>
			<Route exact path='/commom/voices/blitz'>
				<AppLayout navigation={<TalkNavigation />}>
					<Blitz />
				</AppLayout>
			</Route>
			<Route exact path='/commom/voices/wpps'>
				<AppLayout navigation={<TalkNavigation />}>
					<Wpps />
				</AppLayout>
			</Route>
			<Route exact path='/commom/voices/interviews'>
				<AppLayout navigation={<TalkNavigation />}>
					<Interviews />
				</AppLayout>
			</Route>

			<Route exact path='/commom/playlists'>
				<AppLayout navigation={<TalkNavigation />}>
					<ManagePlaylists />
				</AppLayout>
			</Route>
			<Route exact path='/commom/playlists/weekly-scripts'>
				<AppLayout navigation={<TalkNavigation />}>
					<WeeklyScripts />
				</AppLayout>
			</Route>

			<Route exact path='/commom/contracts'>
				<AppLayout navigation={<TalkNavigation />}>
					<Contracts />
				</AppLayout>
			</Route>

			<Route exact path='/commom/episodes'>
				<AppLayout navigation={<TalkNavigation />}>
					<ManageEpisodes />
				</AppLayout>
			</Route>
			<Route exact path='/commom/episodes/create-episode'>
				<AppLayout navigation={<TalkNavigation />}>
					<CreateEpisode />
				</AppLayout>
			</Route>
			<Route exact path='/commom/episodes/:episodeId/details'>
				<AppLayout navigation={<TalkNavigation />}>
					<CreatedEpisode />
				</AppLayout>
			</Route>

			<Route exact path='/commom/track-presentations'>
				<AppLayout navigation={<TalkNavigation />}>
					<TrackPresentations />
				</AppLayout>
			</Route>

			<Route exact path='/commom/track-presentations/create'>
				<AppLayout navigation={<TalkNavigation />}>
					<CreateTrackPresentations />
				</AppLayout>
			</Route>

			<Route exact path='*'>
				<NotFound />
			</Route>
		</Switch>
	</BrowserRouter>
);

export default CommomStack;
