import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader, Table, Button, Typography, Card, message, Input } from 'antd';

import Meta from '../../../../../components/Meta';
import Fallback from '../../../../../components/Fallback';
import { Container, TableHeader, ButtonContainer } from './styles';
import { FiSave } from 'react-icons/fi';

import NetworksAPI from '../../../../../services/sdks/networks';
import UsersAPI from '../../../../../services/sdks/user';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel Administrativo' },
		{ breadcrumbName: 'Geral' },
		{ breadcrumbName: 'Editar Rede' },
	],
	style: { marginBottom: 12 },
};

const ListNetworks = () => {
	const { networkId } = useParams();
	const [fallback, setFallback] = useState({ initialData: true });
	const [search, setSearch] = useState('');
	const [network, setNetwork] = useState([]);
	const [users, setUsers] = useState([]);

	const filteredUsers = useMemo(() => {
		if (!search) {
			return users;
		}

		return users.filter((user) => user.radioName.toLowerCase().includes(search.toLowerCase()));
	}, [search, users]);

	const columns = [
		{
			key: 'id',
			title: 'ID',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					8
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Nome da Rádio',
			key: 'name',
			render: (user) => user?.radioName,
		},
		{
			title: 'Cidade',
			key: 'city',
			render: (user) => (
				<>
					{user?.city}
					<span>/</span>
					{user?.state}
				</>
			),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: (email) => email,
		},
	];

	useEffect(() => {
		const fetchInitialData = async () => {
			setFallback((prev) => ({ ...prev, initialData: true }));

			try {
				const {
					data: { network },
				} = await NetworksAPI.getOneById(networkId);

				const {
					data: { users },
				} = await UsersAPI.index(`active=true`);

				setUsers(users.filter((u) => u._id !== network.userId._id));
				setNetwork(network);
				setFallback(false);
			} catch (error) {
				console.error(error);
				message.error('Erro ao buscar as categorias');
			}

			setFallback((prev) => ({ ...prev, initialData: false }));
		};

		fetchInitialData();
	}, [networkId]);

	const handleUpdateNetwork = useCallback(async () => {
		setFallback((prev) => ({ ...prev, updatingNetwork: true }));

		try {
			const data = { users: network.users };
			await NetworksAPI.updateOne(networkId, data);
			message.success('Rede atualizada com sucesso');
		} catch (error) {
			message.error('Houve um erro ao atuzalizar a rede, tente novamente');
		}

		setFallback((prev) => ({ ...prev, updatingNetwork: false }));
	}, [network, networkId]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando...' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Editar Rede' />

			<PageHeader title={`Editar Rede - ${network.userId.radioName}`} breadcrumb={breadcrumb} />

			<Container>
				<Card>
					<Table
						rowKey='_id'
						pagination={{ pageSize: filteredUsers.length, hideOnSinglePage: true }}
						size='small'
						style={{ border: 'none' }}
						columns={columns}
						dataSource={filteredUsers}
						rowSelection={{
							selectedRowKeys: network.users,
							onChange: (_, selectedRows) => {
								setNetwork((prev) => ({ ...prev, users: selectedRows.map((u) => u._id) }));
							},
						}}
						title={() => (
							<TableHeader key='tb-header'>
								<Input.Search
									onSearch={(s) => setSearch(s)}
									placeholder='Pesquisar Usuário'
									size='large'
								/>
							</TableHeader>
						)}
					/>

					<ButtonContainer>
						<Button
							onClick={handleUpdateNetwork}
							size='large'
							type='primary'
							loading={fallback?.updatingNetwork}>
							<FiSave />
							Salvar
						</Button>
					</ButtonContainer>
				</Card>
			</Container>
		</>
	);
};

export default ListNetworks;
