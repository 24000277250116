import './styles/styles.css';

import React, { useEffect } from 'react';
import { Icon, Spin } from 'antd';
import { Provider as ReduxProvider } from 'react-redux';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import ReactGa from 'react-ga';
import pt_BR from 'antd/es/locale/pt_BR';

import CookieConsent from './components/CookiesConsent';
import { GlobalStyles } from './styles/Global';

import store from './store';
import Routes from './routes';
import { PlayerProvider } from './contexts/PlayerContext';
import { ScriptProvider } from './contexts/ScriptContext';
import { ConfigsProvider } from './contexts/ConfigsContext';
import { BannerProvider } from './contexts/BannersContext';

function App() {
	useEffect(() => {
		ReactGa.initialize('UA-116617834-2');
		ReactGa.pageview(window.location.pathname + window.location.search);
	}, []);

	useEffect(() => {
		Spin.setDefaultIndicator(<Icon type='loading' spin />);
	}, []);

	return (
		<AntdConfigProvider locale={pt_BR}>
			<ReduxProvider store={store}>
				<ConfigsProvider>
					<PlayerProvider>
						<ScriptProvider>
							<BannerProvider>
								<Routes />
							</BannerProvider>
						</ScriptProvider>
					</PlayerProvider>
				</ConfigsProvider>
			</ReduxProvider>

			<CookieConsent />

			<GlobalStyles />
		</AntdConfigProvider>
	);
}

export default App;
