import api from '../api';

const BASE_URL = '/api/v2/auth';

const AuthAPI = {
	index: () => api.get(BASE_URL),
	post: (payload) => api.post(`${BASE_URL}/user`, payload),
};

export default AuthAPI;
