import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { SortableElement } from 'react-sortable-hoc';
import {
	Button,
	Dropdown,
	Menu,
	Modal,
	Typography,
	Divider,
	Select,
	Alert,
	Slider,
	Popconfirm,
	Radio,
	Spin,
	Icon,
	Progress,
	message,
	Input,
} from 'antd';

import Form from '../Form';
import { ElementContainer, ElementContent, RadioVignetteOpContent } from './styles';

import { useElement, useFilesValidator, useScript, useVignette } from '../../hooks';

import SelectOptWithBadge from '../SelectOptWithBadge';
import {
	FiCopy,
	FiEdit,
	FiPlusCircle,
	FiRadio,
	FiSave,
	FiUpload,
	FiUploadCloud,
	FiXCircle,
} from 'react-icons/fi';
import SoundtracksAPI from '../../services/sdks/soundtracks';
import ScriptsAPI from '../../services/sdks/scripts';
import { VignetteExecutionModes } from '../../enums/vignette-execution-modes';
import { VignetteSpeechModes } from '../../enums/vignette-speech-modes';
import FilesUploader from '../FilesUploader';

const DEFAULT_OFF_OPTIONS = {
	withSoundtrack: true,
	specificSoundtrack: null,
};

export const Element = SortableElement(
	({
		element,
		showInactiveElements,
		shouldDisplayTrackAddMenu,
		shouldDisplayOffOptionsMenu,
		shouldDisplayTrackMenu,
		radioVignetteConfigs,
		blockIndex,
		isAdmin,
		_disabled,
		tkVoiceTypes,
		allowGlobalNews,
		userId,
		blockTemplates,
	}) => {
		const isNewBlock = element.type === 'NEW-BLOCK';
		const isTag = element.type === 'TAG';
		const {
			parseElementName,
			getElementColor,
			getElementIcon,
			categorizedTypes,
			isTKVoice,
			radioVignettesMap,
		} = useElement({ userId });

		const {
			program,
			script: { _id: scriptId, isShared: isSharedProgram, body: scriptBody },
			elements: { customs: customElements, cashTracks, merchans, trackAds },
			addElement,
			removeElement,
			addTrackAdd,
			removeBlock,
			cloneBlock,
			addMerchanElement,
			compact,
			limits,
			toggleElementSoundtrack,
			setElementSpecificSoundtrack,
			removeElementSpecificSoundtrack,
			deletedSoundtracks,
			changeElementExecutionMode,
			changeElementSpeechModeMode,
			addTrackPresentation,
			changeElementOptions,
			loadBlockTemplate,
		} = useScript();

		const { hasValidationError } = useFilesValidator();
		const { translateExecutionMode, translateSpeechMode } = useVignette();
		const [visibleModals, setVisibleModals] = useState({});
		const [selectedElement, setSelectedElement] = useState(undefined);
		const [insertions, setInsertions] = useState(1);
		const [trackAddPlacement, setTrackAddPlacement] = useState('before');
		const [uploadProgress, setUploadProgress] = useState(null);
		const [soundtrackFile, setSoundtrackFile] = useState(null);
		const [isValidating, setIsValidating] = useState(null);
		const [handlingElement, setHandlingElement] = useState(null);
		const [selectedTemplate, setSelectedTemplate] = useState(null);

		const radioVignetteConfig = useMemo(() => {
			if (element.type !== 'RADIO-VIGNETTE-CONFIG') {
				return null;
			}

			return radioVignetteConfigs.find((c) => c._id === element.options.configId);
		}, [radioVignetteConfigs, element]);

		const isRadioVignette = useMemo(() => {
			if (radioVignetteConfig) {
				return true;
			}

			return radioVignettesMap.staticTypes.some((t) => t.value === element.type);
		}, [element.type, radioVignetteConfig, radioVignettesMap]);

		const isActive = useMemo(() => {
			return radioVignetteConfig ? radioVignetteConfig.isActive : true;
		}, [radioVignetteConfig]);

		const isRemoveButtonDisabled = useCallback(() => {
			if (isAdmin) {
				return false;
			}

			if (!program?.isEditable) {
				return true;
			}

			if (isSharedProgram) {
				if (element.type.startsWith('OFF-LOCAL')) {
					return true;
				}

				if (['OFFERING', 'SPONSOR', 'CASHTRACK', 'TESTIMONIAL'].includes(element.type)) {
					return false;
				}

				if (element.type.includes('-LOCAL')) {
					return false;
				}

				return true;
			}

			return false;
		}, [element, isAdmin, program, isSharedProgram]);

		const renderElementName = useCallback(() => {
			if (isTag) {
				return 'ETIQUETA';
			}

			if (element?.options?.name) {
				return element?.options?.name;
			}

			if (radioVignetteConfig) {
				return radioVignetteConfig.name;
			}

			let name = '';

			if (isTKVoice(element?.type)) {
				const { isLocal } = element.options;
				name = `IA ${isLocal ? 'LOCAL' : 'GLOBAL'}`;
			} else if (element?.type.startsWith('NEWS-')) {
				if (element?.type.startsWith('NEWS-MAIN')) {
					name = `NOTÍCIA REDE - ${parseElementName({ type: element?.options?.category })}`;
				}

				if (element?.type.startsWith('NEWS-LOCAL')) {
					name = `NOTÍCIA LOCAL - ${parseElementName({ type: element?.options?.category })}`;
				}

				if (element?.type.startsWith('NEWS-GLOBAL')) {
					name = `NOTÍCIA GLOBAL - ${parseElementName({ type: element?.options?.category })}`;
				}
			} else if (element?.type.startsWith('BLITZ-')) {
				if (element?.type.startsWith('BLITZ-MAIN')) {
					name = `BLITZ REDE - ${element?.options?.category.split('-')[1].trim()}`;
				}

				if (element?.type.startsWith('BLITZ-LOCAL')) {
					name = `BLITZ LOCAL - ${element?.options?.category.split('-')[1].trim()}`;
				}
			} else if (element?.type.startsWith('WPP-')) {
				if (element?.type.startsWith('WPP-MAIN')) {
					name = `WPP REDE - ${element?.options?.category.split('-')[1].trim()}`;
				}

				if (element?.type.startsWith('WPP-LOCAL')) {
					name = `WPP LOCAL - ${element?.options?.category.split('-')[1].trim()}`;
				}

				if (element?.type.startsWith('WPP-GLOBAL')) {
					name = `WPP GLOBAL - ${element?.options?.category.split('-')[1].trim()}`;
				}
			} else if (element?.type.startsWith('INTERVIEW-')) {
				if (element?.type.startsWith('INTERVIEW-MAIN')) {
					name = `ENTREVISTA REDE - ${element?.options?.category.split('-')[1].trim()}`;
				}

				if (element?.type.startsWith('INTERVIEW-LOCAL')) {
					name = `ENTREVISTA LOCAL - ${element?.options?.category.split('-')[1].trim()}`;
				}

				if (element?.type.startsWith('INTERVIEW-GLOBAL')) {
					name = `ENTREVISTA GLOBAL - ${element?.options?.category.split('-')[1].trim()}`;
				}
			} else {
				name = parseElementName({ type: element?.type });
			}

			if (isNewBlock) {
				name = `${name} #${String(blockIndex + 1).padStart(2, '0')}`;
			}

			return name;
		}, [
			isTag,
			element.options,
			element.type,
			radioVignetteConfig,
			isTKVoice,
			isNewBlock,
			parseElementName,
			blockIndex,
		]);

		const handleSaveEditedVignette = useCallback(() => {
			const { executionMode, speechMode } = handlingElement;
			changeElementExecutionMode({ index: element.index, executionMode });
			changeElementSpeechModeMode({ index: element.index, speechMode });
			setVisibleModals({ ...visibleModals, editRadioVignette: false });
			setHandlingElement(null);
		}, [
			changeElementExecutionMode,
			changeElementSpeechModeMode,
			element.index,
			handlingElement,
			visibleModals,
		]);

		const renderElementDescription = useCallback(() => {
			if (isTag) {
				const tagName = element.options?.name;
				return tagName ? tagName : 'Edite a etiqueta para adicionar um nome';
			}

			if (isRadioVignette) {
				const executionMode = translateExecutionMode(element.options.executionMode);
				const speechMode = translateSpeechMode(element.options.speechMode);
				const isInactive = isActive ? null : (
					<>
						<span style={{ margin: '0px 12px' }}>|</span>
						<span style={{ marginRight: 12 }}>Vinhetas fora da programação no momento</span>
					</>
				);

				return (
					<>
						<span style={{ marginRight: 12 }}>
							Categoria: <strong style={{ fontWeight: 500 }}>{speechMode}</strong>
						</span>
						<span>
							Velocidade: <strong style={{ fontWeight: 500 }}>{executionMode}</strong>
						</span>
						{isInactive}
					</>
				);
			}

			if (isTKVoice(element.type)) {
				return parseElementName({ type: element?.type });
			}

			if (element.type === 'SPONSOR') return 'Patrocínio';
			if (element.type === 'OFFERING') return 'Oferecimento';
			if (element.type === 'TESTIMONIAL') return 'Testemunhal';
			if (element.type === 'TRACK-AD') return 'Propaganda Curta';

			if (!element?.options) {
				return null;
			}

			if (
				element?.type.startsWith('OFF-') ||
				element?.type.startsWith('NEWS-') ||
				element?.type.startsWith('WPP-') ||
				element?.type.startsWith('BLITZ-') ||
				element?.type.startsWith('INTERVIEW-')
			) {
				const label = [];

				if (element.options.withSoundtrack === false) {
					label.push('Trilhas sonoras desabilitadas');
				}

				if (typeof element.options.specificSoundtrack === 'string') {
					label.push('Com trilha específica');
				}

				return label.join(' ● ');
			}

			if (element?.type === 'CASHTRACK') {
				return `Música-Ca$h | Na programação até ${element.options?.expirationDate}`;
			}
		}, [
			isTag,
			element.options,
			element.type,
			isActive,
			isRadioVignette,
			isTKVoice,
			parseElementName,
			translateExecutionMode,
			translateSpeechMode,
		]);

		const getIcon = useCallback(() => {
			if (radioVignetteConfig) {
				return <FiRadio />;
			}

			return getElementIcon({ type: element.type });
		}, [element.type, getElementIcon, radioVignetteConfig]);

		const getColor = useCallback(() => {
			if (radioVignetteConfig) {
				return radioVignetteConfig.color;
			}

			return element?.options?.color
				? element?.options?.color
				: getElementColor({ type: element.type, isLocal: element?.options?.isLocal });
		}, [element, getElementColor, radioVignetteConfig]);

		const handleUploadStr = useCallback(async () => {
			if (!soundtrackFile) return message.error('Informe o arquivo de áudio');

			if (hasValidationError([soundtrackFile])) {
				return message.warning(
					'Alguns dos arquivos selecionados estão corrompidos. Por favor, substitua-os por arquivos válidos.'
				);
			}

			const userId = program?.userId?._id;
			const category = 'OFF-SPECIFIC';
			const name = `TRILHA ESPECÍFICA - ${renderElementName()} (POSIÇÃO ${element?.index + 1}) - ${
				program?.name
			}`;

			const payload = new FormData();

			payload.append('name', name);
			payload.append('userId', userId);
			payload.append('category', category);
			payload.append('programId', program?._id);
			payload.append('file', soundtrackFile.data);

			try {
				let {
					data: { soundtracks },
				} = await SoundtracksAPI.store(payload, ({ total, loaded }) => {
					const percentage = Math.floor((loaded * 100) / total);

					if (percentage !== uploadProgress) {
						setUploadProgress(percentage);
					}

					if (percentage === 100) {
						setUploadProgress('ffmpeg-processing');
					}
				});

				setElementSpecificSoundtrack({ index: element?.index, soundtrackId: soundtracks[0]._id });

				await ScriptsAPI.modify({
					scriptId,
					payload: {
						body: scriptBody.map((e, i) => {
							if (i === element.index) {
								return { ...e, options: { ...e.options, specificSoundtrack: soundtracks[0]._id } };
							}

							return e;
						}),
					},
				});

				for (const deletedSoundtrackId of deletedSoundtracks) {
					await SoundtracksAPI.destroy(deletedSoundtrackId);
				}

				setVisibleModals((prev) => ({ ...prev, addSpecificStr: false }));
				setSoundtrackFile(null);

				return message.success('Trilha sonora cadastrada com sucesso');
			} catch (error) {
				console.error(error);
				message.error('Houve um erro');
			}

			setUploadProgress(null);
		}, [
			soundtrackFile,
			hasValidationError,
			program,
			renderElementName,
			element,
			setElementSpecificSoundtrack,
			scriptId,
			scriptBody,
			uploadProgress,
			deletedSoundtracks,
		]);

		const handleRemoveSpecificStr = useCallback(
			async ({ soundtrackId, index }) => {
				try {
					await SoundtracksAPI.destroy(soundtrackId);

					await ScriptsAPI.modify({
						scriptId,
						payload: {
							body: scriptBody.map((e, i) => {
								if (i === element.index) {
									return { ...e, options: { ...e.options, specificSoundtrack: null } };
								}

								return e;
							}),
						},
					});

					for (const deletedSoundtrackId of deletedSoundtracks) {
						await SoundtracksAPI.destroy(deletedSoundtrackId);
					}

					removeElementSpecificSoundtrack({ index });
					message.success('Trilha removida com sucesso');
				} catch (error) {
					console.error(error);
					message.error('Houve um erro');
				}
			},
			[deletedSoundtracks, element, scriptBody, removeElementSpecificSoundtrack, scriptId]
		);

		const handleLoadBlockTemplate = useCallback(() => {
			loadBlockTemplate(handlingElement.index, selectedTemplate);
			setVisibleModals((prev) => ({ ...prev, loadBlockTemplate: false }));
		}, [handlingElement, loadBlockTemplate, selectedTemplate]);

		useEffect(() => {
			setSelectedElement(undefined);
			setInsertions(1);
		}, [visibleModals]);

		return (
			<>
				<ElementContainer isNewBlock={isNewBlock} isHided={!isActive && !showInactiveElements}>
					<ElementContent
						compact={compact}
						_disabled={_disabled}
						isNewBlock={isNewBlock}
						isInactive={!isActive}
						color={getColor()}>
						<div className='element-infos'>
							{getIcon()}
							<div className='placeholder'>
								<p>{renderElementName()}</p>
								{!compact && <span className='option'>{renderElementDescription()}</span>}
							</div>
						</div>

						{element.indexLabel && <span className='index-label'>#{element.indexLabel}</span>}
					</ElementContent>
					{isRadioVignette ? (
						<Dropdown
							trigger={['click']}
							disabled={isAdmin ? false : !program?.isEditable}
							overlay={
								<Menu>
									<Menu.Item
										onClick={() => {
											setVisibleModals({ ...visibleModals, editRadioVignette: true });
											setHandlingElement({
												executionMode: element.options.executionMode,
												speechMode: element.options.speechMode,
											});
										}}>
										<FiEdit /> Alterar Categoria/Velocidade
									</Menu.Item>
									<Menu.Item
										className='ant-dropdown-menu-item-danger'
										onClick={() => removeElement({ index: element?.index })}>
										<FiXCircle /> Remover elemento
									</Menu.Item>
								</Menu>
							}>
							<Button icon='more' size={compact ? 'small' : 'default'} />
						</Dropdown>
					) : shouldDisplayTrackAddMenu ? (
						<Dropdown
							trigger={['click']}
							disabled={isAdmin ? false : isSharedProgram ? false : !program?.isEditable}
							overlay={
								<Menu>
									<Menu.Item
										disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
										onClick={() => setVisibleModals({ ...visibleModals, addTrackAds: true })}>
										{getElementIcon({ type: 'TRACK-AD' })} Add propaganda curta
									</Menu.Item>

									<Menu.Item
										className='ant-dropdown-menu-item-danger'
										disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
										onClick={() => removeElement({ index: element?.index })}>
										<FiXCircle /> Remover elemento
									</Menu.Item>
								</Menu>
							}>
							<Button icon='more' size={compact ? 'small' : 'default'} />
						</Dropdown>
					) : shouldDisplayTrackMenu ? (
						<Dropdown
							trigger={['click']}
							disabled={isAdmin ? false : isSharedProgram ? false : !program?.isEditable}
							overlay={
								<Menu>
									<Menu.Item
										disabled={
											(!isAdmin && (isSharedProgram || !program?.isEditable)) ||
											scriptBody[element.index - 1]?.type === 'TRACK-CALL'
										}
										onClick={() =>
											addTrackPresentation({ index: element.index, type: 'TRACK-CALL' })
										}>
										{getElementIcon({ type: 'TRACK-CALL' })} Add Chamada de Música
									</Menu.Item>

									<Menu.Item
										disabled={
											(!isAdmin && (isSharedProgram || !program?.isEditable)) ||
											scriptBody[element.index + 1]?.type === 'TRACK-POST'
										}
										onClick={() =>
											addTrackPresentation({ index: element.index, type: 'TRACK-POST' })
										}>
										{getElementIcon({ type: 'TRACK-POST' })} Add Saída de Música
									</Menu.Item>

									<Menu.Item
										className='ant-dropdown-menu-item-danger'
										disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
										onClick={() => removeElement({ index: element?.index })}>
										<FiXCircle /> Remover elemento
									</Menu.Item>
								</Menu>
							}>
							<Button icon='more' size={compact ? 'small' : 'default'} />
						</Dropdown>
					) : shouldDisplayOffOptionsMenu ? (
						<Dropdown
							trigger={['click']}
							disabled={isAdmin ? false : isSharedProgram ? false : !program?.isEditable}
							overlay={
								<Menu>
									<Menu.Item
										disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
										onClick={() =>
											toggleElementSoundtrack({
												index: element?.index,
												withSoundtrack: !element?.options?.withSoundtrack,
											})
										}>
										{getElementIcon({ type: 'STR' })}{' '}
										{element?.options?.withSoundtrack ? 'Desligar' : 'Ligar'} Trilha Sonora
									</Menu.Item>

									{!element?.options?.specificSoundtrack && (
										<Menu.Item
											disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
											onClick={() => setVisibleModals({ ...visibleModals, addSpecificStr: true })}>
											{getElementIcon({ type: 'STR' })} Add Trilha Específica
										</Menu.Item>
									)}

									{element?.options?.specificSoundtrack && (
										<Menu.Item
											disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
											onClick={() => {
												Modal.confirm({
													title: `Remover trilha específica?`,
													icon: 'exclamation-circle',
													content:
														'A trilha sonora será removida e excluída do banco de dados, deseja continuar?',
													okText: 'Remover',
													onOk: () =>
														handleRemoveSpecificStr({
															soundtrackId: element?.options?.specificSoundtrack,
															index: element?.index,
														}),
													okButtonProps: {
														icon: 'delete',
														type: 'danger',
													},
													cancelText: 'Cancelar',
													cancelButtonProps: {
														icon: 'close-circle',
													},
												});
											}}>
											{getElementIcon({ type: 'STR' })} Remover Trilha Específica
										</Menu.Item>
									)}

									<Menu.Item
										className='ant-dropdown-menu-item-danger'
										disabled={isRemoveButtonDisabled()}
										onClick={() => removeElement({ index: element?.index })}>
										<FiXCircle /> Remover elemento
									</Menu.Item>
								</Menu>
							}>
							<Button icon='more' size={compact ? 'small' : 'default'} />
						</Dropdown>
					) : isTag ? (
						<Dropdown
							trigger={['click']}
							disabled={isAdmin ? false : isSharedProgram ? false : !program?.isEditable}
							overlay={
								<Menu>
									<Menu.Item
										disabled={
											isAdmin ? false : isAdmin || !isSharedProgram ? false : !program?.isEditable
										}
										onClick={() => {
											setHandlingElement(element);
											setVisibleModals({ ...visibleModals, editTag: true });
										}}>
										<FiEdit /> Editar
									</Menu.Item>

									<Menu.Item
										disabled={
											isAdmin ? false : isAdmin || !isSharedProgram ? false : !program?.isEditable
										}
										onClick={() => {
											addElement({
												index: element.index + 1,
												blockIndex,
												insertions: 1,
												element: {
													type: 'TAG',
													options: element.options,
												},
											});
										}}>
										<FiCopy /> Duplicar Tag
									</Menu.Item>

									<Menu.Item
										disabled={
											isAdmin ? false : isAdmin || !isSharedProgram ? false : !program?.isEditable
										}
										onClick={() => {
											setHandlingElement(element);
											setVisibleModals({ ...visibleModals, loadBlockTemplate: true });
										}}>
										<FiUpload /> Carregar Template
									</Menu.Item>

									<Menu.Item
										className='ant-dropdown-menu-item-danger'
										disabled={isRemoveButtonDisabled()}
										onClick={() => removeElement({ index: element?.index })}>
										<FiXCircle /> Remover Etiqueta
									</Menu.Item>
								</Menu>
							}>
							<Button icon='more' size={compact ? 'small' : 'default'} />
						</Dropdown>
					) : isNewBlock ? (
						<Dropdown
							trigger={['click']}
							disabled={
								isAdmin ? false : isAdmin || !isSharedProgram ? false : !program?.isEditable
							}
							overlay={
								<Menu>
									<Menu.Item
										disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
										onClick={() =>
											setVisibleModals({ ...visibleModals, addDefaultElements: true })
										}>
										{getElementIcon({ type: null })} Adicionar Elemento Padrão
									</Menu.Item>

									<Menu.Item
										disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
										onClick={() => setVisibleModals({ ...visibleModals, addCustomElements: true })}>
										{getElementIcon({ type: 'CUSTOM' })} Adicionar Elemento Customizado
									</Menu.Item>

									<Menu.Divider />

									<Menu.Item
										disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
										onClick={() =>
											setVisibleModals({ ...visibleModals, addProgramVignettes: true })
										}>
										{getElementIcon({ type: 'VIGNETTE' })} Adicionar Vinheta de Programa
									</Menu.Item>

									<Menu.Item
										disabled={!isAdmin && !program?.isEditable}
										onClick={() => setVisibleModals({ ...visibleModals, addRadioVignettes: true })}>
										{getElementIcon({ type: 'RADIO-VIGNETTE-' })} Adicionar Vinheta da Rádio
									</Menu.Item>

									<Menu.Divider />

									<Menu.Item
										disabled={!isAdmin && _disabled}
										onClick={() => setVisibleModals({ ...visibleModals, addSignature: true })}>
										{getElementIcon({ type: 'SIGNATURE' })} Adicionar Assinatura
									</Menu.Item>

									<Menu.Divider />

									<Menu.SubMenu
										title={<>{getElementIcon({ type: 'OFFERING' })} Adicionar Publicidade</>}>
										<Menu.Item
											disabled={!isAdmin && !program?.isEditable}
											onClick={() => setVisibleModals({ ...visibleModals, addCashTrack: true })}>
											{getElementIcon({ type: 'CASHTRACK' })} Música-Cash
										</Menu.Item>

										<Menu.Item
											disabled={!isAdmin && !program?.isEditable}
											onClick={() => {
												addMerchanElement({
													blockIndex,
													element: {
														type: 'TESTIMONIAL',
														options: {
															...DEFAULT_OFF_OPTIONS,
														},
													},
												});
											}}>
											{getElementIcon({ type: 'TESTIMONIAL' })} Testemunhal
										</Menu.Item>

										<Menu.Item
											disabled={!isAdmin && !program?.isEditable}
											onClick={() => setVisibleModals({ ...visibleModals, addSponsor: true })}>
											{getElementIcon({ type: 'SPONSOR' })} Patrocínio
										</Menu.Item>

										<Menu.Item
											disabled={!isAdmin && !program?.isEditable}
											onClick={() => setVisibleModals({ ...visibleModals, addOffering: true })}>
											{getElementIcon({ type: 'OFFERING' })} Oferecimento
										</Menu.Item>
									</Menu.SubMenu>

									<Menu.Divider />

									<Menu.SubMenu
										title={<>{getElementIcon({ type: 'OFF-RECORDED' })} Adicionar Off</>}>
										<Menu.Item
											disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
											onClick={() => setVisibleModals({ ...visibleModals, addRecordedOffs: true })}>
											{getElementIcon({ type: 'OFF-RECORDED' })} TKVOX
										</Menu.Item>

										<Menu.Item
											disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
											onClick={() => setVisibleModals({ ...visibleModals, addTKPlay: true })}>
											{getElementIcon({ type: 'TK-PLAY' })} TKPLAY
										</Menu.Item>

										<Menu.Item
											disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
											onClick={() => setVisibleModals({ ...visibleModals, addLocalTKVoice: true })}>
											{getElementIcon({ type: 'TK-VOICE' })} IA Local
										</Menu.Item>

										<Menu.Item
											disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
											onClick={() =>
												setVisibleModals({ ...visibleModals, addCasterTKVoice: true })
											}>
											{getElementIcon({ type: 'TK-VOICE' })} IA Global
										</Menu.Item>

										<Menu.Item
											disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
											onClick={() => setVisibleModals({ ...visibleModals, addLocalOffs: true })}>
											{getElementIcon({ type: 'OFF-LOCAL' })} Off Local
										</Menu.Item>
									</Menu.SubMenu>

									<Menu.SubMenu
										title={<>{getElementIcon({ type: 'NEWS-LOCAL' })} Adicionar Notícias</>}>
										<Menu.Item
											disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
											onClick={() => setVisibleModals({ ...visibleModals, addMainNews: true })}>
											{getElementIcon({ type: 'NEWS-MAIN' })} Notícia Rede
										</Menu.Item>

										<Menu.Item
											disabled={!isAdmin && !program?.isEditable}
											onClick={() => setVisibleModals({ ...visibleModals, addLocalNews: true })}>
											{getElementIcon({ type: 'NEWS-LOCAL' })} Notícia Local
										</Menu.Item>

										<Menu.Item
											disabled={
												!isAdmin && (isSharedProgram || !allowGlobalNews || !program?.isEditable)
											}
											onClick={() => setVisibleModals({ ...visibleModals, addGlobalNews: true })}>
											{getElementIcon({ type: 'NEWS-GLOBAL' })} Notícia Global
										</Menu.Item>
									</Menu.SubMenu>

									<Menu.SubMenu
										title={<>{getElementIcon({ type: 'BLITZ-LOCAL' })} Adicionar Blitz</>}>
										<Menu.Item
											disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
											onClick={() => setVisibleModals({ ...visibleModals, addMainBlitz: true })}>
											{getElementIcon({ type: 'BLITZ-MAIN' })} Blitz Rede
										</Menu.Item>

										<Menu.Item
											disabled={!isAdmin && !program?.isEditable}
											onClick={() => setVisibleModals({ ...visibleModals, addLocalBlitz: true })}>
											{getElementIcon({ type: 'BLITZ-LOCAL' })} Blitz Local
										</Menu.Item>
									</Menu.SubMenu>

									<Menu.SubMenu
										title={
											<>{getElementIcon({ type: 'INTERVIEW-LOCAL' })} Adicionar Entrevistas</>
										}>
										<Menu.Item
											disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
											onClick={() =>
												setVisibleModals({ ...visibleModals, addMainInterviews: true })
											}>
											{getElementIcon({ type: 'INTERVIEW-MAIN' })} Entrevista Rede
										</Menu.Item>

										<Menu.Item
											disabled={!isAdmin && !program?.isEditable}
											onClick={() =>
												setVisibleModals({ ...visibleModals, addLocalInterviews: true })
											}>
											{getElementIcon({ type: 'INTERVIEW-LOCAL' })} Entrevista Local
										</Menu.Item>
									</Menu.SubMenu>

									<Menu.SubMenu
										title={<>{getElementIcon({ type: 'WPP-LOCAL' })} Adicionar Wpp's</>}>
										<Menu.Item
											disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
											onClick={() => setVisibleModals({ ...visibleModals, addMainWpps: true })}>
											{getElementIcon({ type: 'WPP-MAIN' })} Wpp Rede
										</Menu.Item>

										<Menu.Item
											disabled={!isAdmin && !program?.isEditable}
											onClick={() => setVisibleModals({ ...visibleModals, addLocalWpps: true })}>
											{getElementIcon({ type: 'WPP-LOCAL' })} Wpp Local
										</Menu.Item>
									</Menu.SubMenu>

									<Menu.Divider />

									<Menu.Item disabled={_disabled} onClick={() => cloneBlock({ blockIndex })}>
										<FiCopy /> Replicar bloco
									</Menu.Item>

									<Menu.Item
										disabled={!isAdmin && (isSharedProgram || !program?.isEditable)}
										onClick={() => removeBlock({ blockIndex })}
										className='ant-dropdown-menu-item-danger'>
										<FiXCircle /> Remover bloco
									</Menu.Item>
								</Menu>
							}>
							<Button icon='more' size={compact ? 'small' : 'default'} />
						</Dropdown>
					) : (
						<Popconfirm
							arrowPointAtCenter
							disabled={isRemoveButtonDisabled()}
							onConfirm={() => removeElement({ index: element?.index })}
							title='Realmente deseja remover esse elemento?'
							okType='danger'
							okText='Remover'
							placement='left'>
							<Button
								icon='close-circle'
								size={compact ? 'small' : 'default'}
								disabled={isRemoveButtonDisabled()}
							/>
						</Popconfirm>
					)}
				</ElementContainer>

				<Modal
					destroyOnClose
					visible={visibleModals.addSpecificStr}
					title={
						<>
							<FiPlusCircle /> Adicionar Trilha Específica ao Off
						</>
					}
					footer={
						uploadProgress ? (
							uploadProgress === 'ffmpeg-processing' ? (
								<Spin
									size='large'
									indicator={<Icon spin type='setting' />}
									style={{ width: '100%', margin: '12px 0' }}
									tip='Estamos terminando de configurar a trilha, por favor aguarde...'
								/>
							) : (
								<Progress percent={uploadProgress} />
							)
						) : (
							<>
								<Button
									onClick={() => {
										setSoundtrackFile(null);
										isValidating(false);
										setVisibleModals({ ...visibleModals, addSpecificStr: false });
									}}>
									<FiXCircle /> Cancelar
								</Button>

								<Button type='primary' onClick={handleUploadStr} disabled={isValidating}>
									<FiUploadCloud /> Subir trilha
								</Button>
							</>
						)
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<FilesUploader
						multiple={false}
						onChange={(files) => setSoundtrackFile(files[0])}
						onStartValidation={() => setIsValidating(true)}
						onEndValidation={() => setIsValidating(false)}
						validTypes={['.mp3', '.wav']}
					/>
				</Modal>

				<Modal
					destroyOnClose
					visible={visibleModals.editTag}
					onCancel={() => {
						setHandlingElement(null);
						setVisibleModals({ ...visibleModals, editTag: false });
					}}
					onOk={() => {
						changeElementOptions({ index: element.index, options: handlingElement.options });
						setVisibleModals({ ...visibleModals, editTag: false });
					}}
					title={
						<>
							<FiEdit /> Editar Tag
						</>
					}
					okText={
						<>
							<FiSave /> Salvar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Nome'>
							<Input
								placeholder='Informe um nome para o elemento'
								value={handlingElement?.options?.name || ''}
								onChange={({ target: { value: name } }) => {
									setHandlingElement((prev) => ({
										...prev,
										options: { ...handlingElement.options, name },
									}));
								}}
							/>
						</Form.Item>

						<Form.Item label='Cor da Tag'>
							<Input
								type='color'
								value={handlingElement?.options?.color || '#FFF'}
								style={{ cursor: 'pointer' }}
								onChange={({ target: { value: color } }) => {
									setHandlingElement((prev) => ({
										...prev,
										options: { ...handlingElement.options, color },
									}));
								}}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					visible={visibleModals.addDefaultElements}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addDefaultElements: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addDefaultElements: false });
					}}
					title={
						<>
							<FiPlusCircle /> Novo elemento
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Tipo do elemento'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: value,
											options: value === 'OFF-MAIN' ? { ...DEFAULT_OFF_OPTIONS } : null,
										},
									});
								}}
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{categorizedTypes.defaultElements.map((type) => {
									return type === 'NEW-BLOCK' ? null : (
										<Select.Option value={type} key={type}>
											{getElementIcon({ type })} {parseElementName({ type })}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>

						<Form.Item
							label='Quantidade'
							help={`Serão inseridos ${insertions} elementos do tipo selecionado`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					visible={visibleModals.addProgramVignettes}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addProgramVignettes: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addProgramVignettes: false });
					}}
					title={
						<>
							<FiPlusCircle /> Nova Vinheta de Programa
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Tipo do elemento'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: value,
											options: value === 'OFF-MAIN' ? { ...DEFAULT_OFF_OPTIONS } : null,
										},
									});
								}}
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{categorizedTypes.programVignettes.map((type) => {
									return type === 'NEW-BLOCK' ? null : (
										<Select.Option value={type} key={type}>
											{getElementIcon({ type })} {parseElementName({ type })}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>

						<Form.Item
							label='Quantidade'
							help={`Serão inseridos ${insertions} elementos do tipo selecionado`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					visible={visibleModals.addRadioVignettes}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addRadioVignettes: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addRadioVignettes: false });
					}}
					title={
						<>
							<FiPlusCircle /> Nova Vinheta da Rádio
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Tipo do elemento'>
							<Select
								onChange={(e) => {
									setSelectedElement({
										index: element.index + 1,
										element: JSON.parse(e),
									});
								}}
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								<Select.OptGroup label='CONTÍNUAS'>
									{radioVignettesMap.staticTypes.map((item, index) => (
										<Select.Option
											value={JSON.stringify({
												type: item.value,
												options: {
													executionMode: item.executionMode,
													speechMode: item.speechMode,
												},
											})}
											key={index}>
											<SelectOptWithBadge
												badgeHeight={32}
												color={item.color}
												label={
													<RadioVignetteOpContent>
														{item.label}
														<div>
															<small>
																Categoria: <strong>{translateSpeechMode(item.speechMode)}</strong>
															</small>
															<small>
																Velocidade:{' '}
																<strong>{translateExecutionMode(item.executionMode)}</strong>
															</small>
														</div>
													</RadioVignetteOpContent>
												}
											/>
										</Select.Option>
									))}
								</Select.OptGroup>

								<Select.OptGroup label='CRONOGRAMADAS'>
									{radioVignettesMap.scheduledTypes.map((item, index) => (
										<Select.Option
											value={JSON.stringify({
												type: 'RADIO-VIGNETTE-CONFIG',
												options: {
													configId: item.value,
													executionMode: item.executionMode,
													speechMode: item.speechMode,
												},
											})}
											key={index}>
											<SelectOptWithBadge
												badgeHeight={32}
												color={item.color}
												label={
													<RadioVignetteOpContent>
														{item.label}
														<div>
															<small>
																Categoria: <strong>{translateSpeechMode(item.speechMode)}</strong>
															</small>
															<small>
																Velocidade:{' '}
																<strong>{translateExecutionMode(item.executionMode)}</strong>
															</small>
														</div>
													</RadioVignetteOpContent>
												}
											/>
										</Select.Option>
									))}
								</Select.OptGroup>
							</Select>
						</Form.Item>

						<Form.Item
							label='Quantidade'
							help={`Serão inseridos ${insertions} elementos do tipo selecionado`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					visible={visibleModals.addCustomElements}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addCustomElements: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addCustomElements: false });
					}}
					title={
						<>
							<FiPlusCircle /> Novo elemento customizado
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Elemento'>
							<Select
								onChange={(value) => {
									value = JSON.parse(value);

									setSelectedElement({
										index: element.index + 1,
										element: {
											type: 'CUSTOM',
											options: {
												elementId: value?._id,
												color: value?.color,
												name: value?.name,
											},
										},
									});
								}}
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{customElements.map((element, i) => (
									<Select.Option value={JSON.stringify(element)} key={i}>
										{getElementIcon({ type: 'CUSTOM' })} {element?.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item
							label='Quantidade'
							help={`Serão inseridos ${insertions} elementos customizados`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					visible={visibleModals.addRecordedOffs}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addRecordedOffs: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addRecordedOffs: false });
					}}
					title={
						<>
							<FiPlusCircle /> Novo TKVOX
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Tipo do TKVOX'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: value,
											options: { ...DEFAULT_OFF_OPTIONS },
										},
									});
								}}
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{categorizedTypes.recordedOffs.map((type) => (
									<Select.Option value={type} key={type}>
										{getElementIcon({ type })} {parseElementName({ type })}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridos ${insertions} TKVOX`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					visible={visibleModals.loadBlockTemplate}
					okButtonProps={{ disabled: !selectedTemplate }}
					onCancel={() => {
						setSelectedElement(null);
						setSelectedTemplate(null);
						setVisibleModals({ ...visibleModals, loadBlockTemplate: false });
					}}
					onOk={handleLoadBlockTemplate}
					title={
						<>
							<FiUpload /> Carregar Template de Bloco
						</>
					}
					okText={
						<>
							<FiUpload /> Carregar Template
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Template'>
							<Select
								onChange={(value) => {
									setSelectedTemplate(JSON.parse(value));
								}}
								style={{ width: '100%' }}
								placeholder='Selecione o template'>
								{blockTemplates.map((template) => (
									<Select.Option value={JSON.stringify(template)} key={template._id}>
										{template.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					visible={visibleModals.addTKPlay}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addTKPlay: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addTKPlay: false });
					}}
					title={
						<>
							<FiPlusCircle /> Novo TKPLAY
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Tipo do TKPLAY'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: value,
											options: { ...DEFAULT_OFF_OPTIONS },
										},
									});
								}}
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{categorizedTypes.tkPlay.map((type) => (
									<Select.Option value={type} key={type}>
										{getElementIcon({ type })} {parseElementName({ type })}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridos ${insertions} TKPLAY`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					visible={visibleModals.addLocalTKVoice}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addLocalTKVoice: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addLocalTKVoice: false });
					}}
					title={
						<>
							<FiPlusCircle /> Nova IA Local
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Tipo da IA Local'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: value,
											options: { ...DEFAULT_OFF_OPTIONS, isLocal: true },
										},
									});
								}}
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{tkVoiceTypes?.local?.map((type) => (
									<Select.Option value={type} key={type}>
										<span>IA Local - {parseElementName({ type })}</span>
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridos ${insertions} IA's`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					visible={visibleModals.addCasterTKVoice}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addCasterTKVoice: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addCasterTKVoice: false });
					}}
					title={
						<>
							<FiPlusCircle /> Nova IA Global
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Tipo da IA Global'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: value,
											options: { ...DEFAULT_OFF_OPTIONS, isLocal: false },
										},
									});
								}}
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{tkVoiceTypes?.caster?.map((type) => (
									<Select.Option value={type} key={type}>
										<span>IA GLOBAL - {parseElementName({ type })}</span>
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridos ${insertions} IA's`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					visible={visibleModals.addLocalOffs}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addLocalOffs: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addLocalOffs: false });
					}}
					title={
						<>
							<FiPlusCircle /> Novo off local
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Tipo do off'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: value,
											options: { ...DEFAULT_OFF_OPTIONS },
										},
									});
								}}
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{categorizedTypes.localOffs.map((type) => (
									<Select.Option value={type} key={type}>
										{getElementIcon({ type })} {parseElementName({ type })}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridos ${insertions} offs locais`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					visible={visibleModals.addSignature}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addSignature: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addSignature: false });
					}}
					title={
						<>
							<FiPlusCircle /> Nova assinatura
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Tipo de assinatura'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: value,
											options: null,
										},
									});
								}}
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{categorizedTypes.signatures.map((type) => (
									<Select.Option value={type} key={type}>
										{getElementIcon({ type })} {parseElementName({ type })}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridas ${insertions} assinaturas`}>
							<Slider
								value={insertions}
								min={1}
								max={10}
								onChange={(value) => setInsertions(value)}
								marks={{
									1: '1',
									2: '2',
									3: '3',
									4: '4',
									5: '5',
									6: '6',
									7: '7',
									8: '8',
									9: '9',
									10: '10',
								}}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					width={580}
					visible={visibleModals.addCashTrack}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addCashTrack: false });
					}}
					onOk={() => {
						addMerchanElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addCashTrack: false });
					}}
					title={
						<>
							<FiPlusCircle /> Nova música cash
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					{program?.isTalkProgram && (
						<>
							<Alert
								showIcon
								message='Atenção'
								description={`Você pode adicionar no máximo ${limits.SPONSORS} músicas-cash por bloco`}
							/>
							<Divider />
						</>
					)}

					<Typography.Text>
						<ul style={{ paddingLeft: 12 }}>
							<li style={{ listStyle: 'disc' }}>
								Inserir a música-cash sempre entre duas músicas;
							</li>
							<li style={{ listStyle: 'disc' }}>
								<strong>NUNCA</strong> inserir a música-cash no início ou fechamento dos blocos;
							</li>
							<li style={{ listStyle: 'disc' }}>
								<strong>NUNCA</strong> inserir a música-cash após um <i>OFF</i>;
							</li>
							<li style={{ listStyle: 'disc' }}>
								Inserir no máximo <strong>UMA</strong> música-cash por bloco;
							</li>
						</ul>
					</Typography.Text>

					<Typography.Paragraph strong>
						<i>
							A Talk Radio não se responsabiliza pela interferência local das afiliadas nos
							conteúdos.
						</i>
					</Typography.Paragraph>

					<Divider />

					<Form.Container>
						<Form.Item label='Seleciona a Música-Cash'>
							<Select
								onChange={(value) => {
									value = JSON.parse(value);

									setSelectedElement({
										blockIndex,
										element: {
											type: 'CASHTRACK',
											options: {
												elementId: value?._id,
												name: value?.name,
												expirationDate: format(parseISO(value?.expirationDate), 'dd/MM/yyyy'),
											},
										},
									});
								}}
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{cashTracks.map((element, i) => (
									<Select.Option value={JSON.stringify(element)} key={i}>
										{getElementIcon({ type: 'CASHTRACK' })} {element?.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridas ${insertions} músicas-cash`}>
							<Slider
								value={insertions}
								min={1}
								max={(() => {
									if (!program?.isTalkProgram) {
										return 5;
									}

									const cashtracksPerBlock = scriptBody.reduce((_blocks, element) => {
										if (element.type === 'NEW-BLOCK') {
											return (_blocks = [..._blocks, 0]);
										}

										if (element.type === 'CASHTRACK') {
											_blocks[_blocks.length - 1] = _blocks[_blocks.length - 1] + 1;
										}

										return _blocks;
									}, []);

									const cashTracksInThisBlock = cashtracksPerBlock[blockIndex];

									return limits.CASHTRACK - cashTracksInThisBlock;
								})()}
								onChange={(value) => setInsertions(value)}
								marks={
									program?.isTalkProgram
										? { 1: '1', 2: '2' }
										: { 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }
								}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					width={550}
					visible={visibleModals.addOffering}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addOffering: false });
					}}
					onOk={() => {
						addMerchanElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addOffering: false });
					}}
					title={
						<>
							<FiPlusCircle /> Novo oferecimento
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					{program?.isTalkProgram && (
						<>
							<Alert
								showIcon
								message='Atenção'
								description={`Você pode adicionar no máximo ${limits.SPONSORS} oferecimentos por bloco`}
							/>
							<Divider />
						</>
					)}

					<Form.Container>
						<Form.Item label='Selecione o oferecimento'>
							<Select
								onChange={(value) => {
									value = JSON.parse(value);

									setSelectedElement({
										blockIndex,
										element: {
											type: 'OFFERING',
											options: {
												elementId: value?._id,
												color: value?.color,
												name: value?.name,
											},
										},
									});
								}}
								size='large'
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{merchans
									.filter(({ type }) => type === 'OFFERING')
									.map((element, i) => (
										<Select.Option value={JSON.stringify(element)} key={i}>
											{getElementIcon({ type: 'OFFERING' })} {element?.name}
										</Select.Option>
									))}
							</Select>
						</Form.Item>
						<Form.Item label='Quantidade' help={`Serão inseridos ${insertions} oferecimentos`}>
							<Slider
								value={insertions}
								min={1}
								max={(() => {
									if (!program?.isTalkProgram) {
										return 5;
									}

									const cashtracksPerBlock = scriptBody.reduce((_blocks, element) => {
										if (element.type === 'NEW-BLOCK') {
											return (_blocks = [..._blocks, 0]);
										}

										if (element.type === 'OFFERING') {
											_blocks[_blocks.length - 1] = _blocks[_blocks.length - 1] + 1;
										}

										return _blocks;
									}, []);

									const cashTracksInThisBlock = cashtracksPerBlock[blockIndex];

									return limits.OFFERINGS - cashTracksInThisBlock;
								})()}
								onChange={(value) => setInsertions(value)}
								marks={
									program?.isTalkProgram
										? { 1: '1', 2: '2', 3: '3' }
										: { 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }
								}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					width={550}
					visible={visibleModals.addSponsor}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addSponsor: false });
					}}
					onOk={() => {
						addMerchanElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addSponsor: false });
					}}
					title={
						<>
							<FiPlusCircle /> Novo patrocínio
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					{program?.isTalkProgram && (
						<>
							<Alert
								showIcon
								message='Atenção'
								description={`Você pode adicionar no máximo ${limits.SPONSORS} patrocínios por bloco`}
							/>
							<Divider />
						</>
					)}

					<Form.Container>
						<Form.Item label='Selecione o patrocínio'>
							<Select
								onChange={(value) => {
									value = JSON.parse(value);

									setSelectedElement({
										blockIndex,
										element: {
											type: 'SPONSOR',
											options: {
												elementId: value?._id,
												color: value?.color,
												name: value?.name,
											},
										},
									});
								}}
								size='large'
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{merchans
									.filter(({ type }) => type === 'SPONSOR')
									.map((element, i) => (
										<Select.Option value={JSON.stringify(element)} key={i}>
											{getElementIcon({ type: 'SPONSOR' })} {element?.name}
										</Select.Option>
									))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridos ${insertions} patrocínios`}>
							<Slider
								value={insertions}
								min={1}
								max={(() => {
									if (!program?.isTalkProgram) {
										return 5;
									}

									const cashtracksPerBlock = scriptBody.reduce((_blocks, element) => {
										if (element.type === 'NEW-BLOCK') {
											return (_blocks = [..._blocks, 0]);
										}

										if (element.type === 'OFFERING') {
											_blocks[_blocks.length - 1] = _blocks[_blocks.length - 1] + 1;
										}

										return _blocks;
									}, []);

									const cashTracksInThisBlock = cashtracksPerBlock[blockIndex];

									return limits.SPONSORS - cashTracksInThisBlock;
								})()}
								onChange={(value) => setInsertions(value)}
								marks={
									program?.isTalkProgram
										? { 1: '1', 2: '2', 3: '3' }
										: { 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }
								}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					width={550}
					visible={visibleModals.editRadioVignette}
					onCancel={() => {
						setVisibleModals({ ...visibleModals, editRadioVignette: false });
					}}
					onOk={handleSaveEditedVignette}
					title={
						<>
							<FiEdit /> Alterar Categoria/Velocidade
						</>
					}
					okText={
						<>
							<FiEdit /> Salvar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Categoria'>
							<Select
								showSearch
								allowClear
								value={handlingElement?.speechMode}
								placeholder='Categoria da vinheta'
								onChange={(speechMode) => setHandlingElement((prev) => ({ ...prev, speechMode }))}
								filterOption={(input, { props: { _search } }) => {
									return _search.match(new RegExp(input, 'i'));
								}}>
								{Object.values(VignetteSpeechModes).map((mode) => (
									<Select.Option key={mode} value={mode}>
										{translateSpeechMode(mode)}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Velocidade'>
							<Select
								showSearch
								allowClear
								value={handlingElement?.executionMode}
								placeholder='Velocidade da vinheta'
								onChange={(executionMode) =>
									setHandlingElement((prev) => ({ ...prev, executionMode }))
								}
								filterOption={(input, { props: { _search } }) => {
									return _search.match(new RegExp(input, 'i'));
								}}>
								{Object.values(VignetteExecutionModes).map((mode) => (
									<Select.Option key={mode} value={mode}>
										{translateExecutionMode(mode)}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					width={550}
					visible={visibleModals.addTrackAds}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setTrackAddPlacement('before');
						setVisibleModals({ ...visibleModals, addTrackAds: false });
					}}
					onOk={() => {
						addTrackAdd({ ...selectedElement, index: element.index, placement: trackAddPlacement });
						setVisibleModals({ ...visibleModals, addTrackAds: false });
					}}
					title={
						<>
							<FiPlusCircle /> Nova propaganda curta
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item>
							<Radio.Group
								buttonStyle='solid'
								style={{ width: '100%' }}
								onChange={({ target: { value } }) => setTrackAddPlacement(value)}>
								<Radio.Button value='before' checked={trackAddPlacement === 'before'}>
									Inserir antes da vinheta
								</Radio.Button>
								<Radio.Button value='after' checked={trackAddPlacement === 'after'}>
									Inserir após à vinheta
								</Radio.Button>
							</Radio.Group>
						</Form.Item>

						<Form.Item label='Selecione a propaganda curta'>
							<Select
								onChange={(value) => {
									value = JSON.parse(value);

									setSelectedElement({
										blockIndex,
										element: {
											type: 'TRACK-AD',
											options: {
												elementId: value?._id,
												color: value?.color,
												name: value?.name,
											},
										},
									});
								}}
								size='large'
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{trackAds.map((element, i) => (
									<Select.Option value={JSON.stringify(element)} key={i}>
										{getElementIcon({ type: element.type })} {element?.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					width={550}
					visible={visibleModals.addMainNews}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addMainNews: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addMainNews: false });
					}}
					title={
						<>
							<FiPlusCircle /> Nova notícia rede
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Seleciona uma categoria'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: 'NEWS-MAIN',
											options: {
												...DEFAULT_OFF_OPTIONS,
												category: value,
											},
										},
									});
								}}
								size='large'
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{categorizedTypes.news.map((type, i) => (
									<Select.Option value={type} key={i}>
										{getElementIcon({ type: 'NEWS-MAIN' })} {parseElementName({ type })}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridas ${insertions} notícias`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					width={550}
					visible={visibleModals.addLocalNews}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addLocalNews: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addLocalNews: false });
					}}
					title={
						<>
							<FiPlusCircle /> Nova notícia local
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Seleciona a categoria'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: 'NEWS-LOCAL',
											options: {
												...DEFAULT_OFF_OPTIONS,
												category: value,
											},
										},
									});
								}}
								size='large'
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{categorizedTypes.news.map((type, i) => (
									<Select.Option value={type} key={i}>
										{getElementIcon({ type: 'NEWS-LOCAL' })} {parseElementName({ type })}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridas ${insertions} notícias`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					width={550}
					visible={visibleModals.addGlobalNews}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addGlobalNews: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addGlobalNews: false });
					}}
					title={
						<>
							<FiPlusCircle /> Nova notícia global
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Seleciona a categoria'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: 'NEWS-GLOBAL',
											options: {
												...DEFAULT_OFF_OPTIONS,
												category: value,
											},
										},
									});
								}}
								size='large'
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{categorizedTypes.news.map((type, i) => (
									<Select.Option value={type} key={i}>
										{getElementIcon({ type: 'NEWS-LOCAL' })} {parseElementName({ type })}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridas ${insertions} notícias`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					width={550}
					visible={visibleModals.addLocalWpps}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addLocalWpps: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addLocalWpps: false });
					}}
					title={
						<>
							<FiPlusCircle /> Novo wpp local
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Seleciona a categoria'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: 'WPP-LOCAL',
											options: {
												...DEFAULT_OFF_OPTIONS,
												category: value,
											},
										},
									});
								}}
								size='large'
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{categorizedTypes.wpps.map((type, i) => (
									<Select.Option value={type} key={i}>
										{getElementIcon({ type: 'WPP-LOCAL' })} WPP LOCAL - {type.split('-')[1]}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridos ${insertions} wpp's`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					width={550}
					visible={visibleModals.addMainWpps}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addMainWpps: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addMainWpps: false });
					}}
					title={
						<>
							<FiPlusCircle /> Novo wpp rede
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Seleciona a categoria'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: 'WPP-MAIN',
											options: {
												...DEFAULT_OFF_OPTIONS,
												category: value,
											},
										},
									});
								}}
								size='large'
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{categorizedTypes.wpps.map((type, i) => (
									<Select.Option value={type} key={i}>
										{getElementIcon({ type: 'WPP-MAIN' })} WPP REDE - {type.split('-')[1]}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridos ${insertions} wpp's`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					width={550}
					visible={visibleModals.addMainInterviews}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addMainInterviews: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addMainInterviews: false });
					}}
					title={
						<>
							<FiPlusCircle /> Nova entrevista rede
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Seleciona a categoria'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: 'INTERVIEW-MAIN',
											options: {
												...DEFAULT_OFF_OPTIONS,
												category: value,
											},
										},
									});
								}}
								size='large'
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{categorizedTypes.interviews.map((type, i) => (
									<Select.Option value={type} key={i}>
										{getElementIcon({ type: 'INTERVIEW-MAIN' })} ENTREVISTA REDE -{' '}
										{type.split('-')[1]}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridos ${insertions} entrevistas`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					width={550}
					visible={visibleModals.addLocalInterviews}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addLocalInterviews: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addLocalInterviews: false });
					}}
					title={
						<>
							<FiPlusCircle /> Nova entrevista local
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Seleciona a categoria'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: 'INTERVIEW-LOCAL',
											options: {
												...DEFAULT_OFF_OPTIONS,
												category: value,
											},
										},
									});
								}}
								size='large'
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{categorizedTypes.interviews.map((type, i) => (
									<Select.Option value={type} key={i}>
										{getElementIcon({ type: 'INTERVIEW-LOCAL' })} ENTREVISTA LOCAL -{' '}
										{type.split('-')[1]}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridos ${insertions} entrevistas`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					width={550}
					visible={visibleModals.addMainBlitz}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addMainBlitz: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addMainBlitz: false });
					}}
					title={
						<>
							<FiPlusCircle /> Novo blitz rede
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Seleciona a categoria'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: 'BLITZ-MAIN',
											options: {
												...DEFAULT_OFF_OPTIONS,
												category: value,
											},
										},
									});
								}}
								size='large'
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{categorizedTypes.blitz.map((type, i) => (
									<Select.Option value={type} key={i}>
										{getElementIcon({ type: 'BLITZ-MAIN' })} BLITZ REDE - {type.split('-')[1]}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridos ${insertions} blitz`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>

				<Modal
					destroyOnClose
					width={550}
					visible={visibleModals.addLocalBlitz}
					okButtonProps={{ disabled: !selectedElement }}
					onCancel={() => {
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addLocalBlitz: false });
					}}
					onOk={() => {
						addElement({ ...selectedElement, insertions });
						setInsertions(1);
						setVisibleModals({ ...visibleModals, addLocalBlitz: false });
					}}
					title={
						<>
							<FiPlusCircle /> Novo blitz local
						</>
					}
					okText={
						<>
							<FiPlusCircle /> Adicionar
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}>
					<Form.Container>
						<Form.Item label='Seleciona a categoria'>
							<Select
								onChange={(value) => {
									setSelectedElement({
										index: element.index + 1,
										element: {
											type: 'BLITZ-LOCAL',
											options: {
												...DEFAULT_OFF_OPTIONS,
												category: value,
											},
										},
									});
								}}
								size='large'
								style={{ width: '100%' }}
								placeholder='Selecione o elemento'>
								{categorizedTypes.blitz.map((type, i) => (
									<Select.Option value={type} key={i}>
										{getElementIcon({ type: 'BLITZ-LOCAL' })} BLITZ LOCAL - {type.split('-')[1]}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Quantidade' help={`Serão inseridos ${insertions} blitz`}>
							<Slider
								value={insertions}
								min={1}
								max={5}
								onChange={(value) => setInsertions(value)}
								marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' }}
							/>
						</Form.Item>
					</Form.Container>
				</Modal>
			</>
		);
	}
);
