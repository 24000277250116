import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 24px;
	padding: 0 16px;

	& > .ant-card {
		margin: 12px 0;
	}
`;

export const CheckboxesContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	& > label {
		margin: 0 !important;
	}
`;
