import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 24px;
	padding: 0 16px;

	& > .ant-card {
		margin: 12px 0;
	}
`;

export const Grid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 12px;
`;

export const PlanContent = styled.ul`
	width: 100%;
	margin: 0;

	& > li {
		width: 100%;
		border-bottom: 1px dashed #dedede;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		padding: 12px 6px 6px 6px;

		& > span.label {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.6);

			& > i {
				margin-right: 4px;
			}
		}

		& > strong {
			font-size: 15px;
			color: rgba(0, 0, 0, 0.8);
		}
	}

	& > li:first-child {
		padding-top: 0;

		& > strong {
			font-size: 24px;
		}
	}

	& > li:last-child {
		border: none;
	}
`;
