import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import MaskedInput from 'antd-mask-input';
import * as Yup from 'yup';
import {
	PageHeader,
	Form,
	Card,
	Button,
	Icon,
	Select,
	message,
	Input,
	InputNumber,
	Switch,
	Steps,
	Typography,
} from 'antd';

import Meta from '../../../../components/Meta';
import { ButtonsContainer, Container } from './styles';

import UserAPI from '../../../../services/sdks/user';

import { states as brStates } from '../../../../constants';
import CastersApi from '../../../../services/sdks/caster';
import Fallback from '../../../../components/Fallback';

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 12 } };
const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Usuários' },
		{ breadcrumbName: 'Novo Usuário' },
	],
	style: { marginBottom: 12 },
};

const NewUser = () => {
	const history = useHistory();

	const [currentStep, setCurrentStep] = useState(0);
	const [fallback, setFallback] = useState(null);
	const [pInput, setPInput] = useState({ p: 'password', p2: 'password' });
	const [casters, setCasters] = useState([]);
	const [user, setUser] = useState({
		password: '',
		intranetId: '',
		state: undefined,
		city: '',
		email: '',
		radioName: '',
		surname: '',
		name: '',
		password2: '',
		programLimit: 3,
		blocksPerProgram: 5,
		musicsPerBlock: 9,
		masterization: 'OPTIONAL',
		forceSignature: false,
		tracksDB: false,
		allowTracksDownload: false,
		allowAutoDownload: false,
		userLevel: undefined,
		sharePermissionLevel: undefined,
		planId: 'byAdmin',
		paymentDay: null,
	});

	const handleSubmit = useCallback(
		async (event) => {
			try {
				event.preventDefault();

				const payload = { ...user, caster: user.userLevel === 7 ? user.caster : null };
				const validationSchema = Yup.object().shape({
					userLevel: Yup.number().required('Informe o tipo da conta'),
					sharePermissionLevel: Yup.number().required('Informe as permissões de compartilhamento'),
				});

				await validationSchema.validate(payload);

				if (payload.userLevel === 7 && !payload.caster) {
					return message.error('Por favor, vincule um locutor à essa conta');
				}

				setFallback('creating-user');

				const {
					data: { user: createdUser },
				} = await UserAPI.post(payload);

				message.success('Usuário foi criado com sucesso!', 3, () => {
					return history.push(`/admin/users/${createdUser?._id}/details`);
				});
			} catch (error) {
				setFallback(null);

				if (error instanceof Yup.ValidationError) {
					return message.error(error.message);
				}

				console.error(error);
				message.error('Houve um erro, tente novamente');
			}
		},
		[user, history]
	);

	const handleChangeUser = useCallback((key, value) => {
		setUser((user) => ({ ...user, [key]: value }));
	}, []);

	const handleChangeStep = useCallback(
		async (event, nextStep) => {
			try {
				if (event) {
					event.preventDefault();
				}

				const schemes = [
					Yup.object().shape({
						password: Yup.string().required('Informe a senha do usuário'),
						intranetId: Yup.string().nullable().required('Informe o ID do Admin'),
						state: Yup.string().required('Informe o estado do usuário'),
						city: Yup.string().required('Informe a cidade do usuário'),
						email: Yup.string().required('Informe o email do usuário'),
						radioName: Yup.string().required('Informe o nome da rádio'),
						surname: Yup.string().required('Informe o sobrenome do usuário'),
						name: Yup.string().required('Informe o nome do usuário'),
					}),
					null,
				];

				if (nextStep > currentStep) {
					if (schemes[nextStep - 1]) {
						await schemes[nextStep - 1].validate(user);
					}

					if (currentStep === 0 && user?.password2 !== user?.password) {
						return message.error('Senhas não são iguais');
					}

					setCurrentStep(nextStep);
				} else {
					setCurrentStep(nextStep);
				}
			} catch (error) {
				return message.error(error.message);
			}
		},
		[currentStep, user]
	);

	useEffect(() => {
		const fetchInitialData = async () => {
			setFallback('initial-data');

			try {
				const {
					data: { casters },
				} = await CastersApi.index();

				setCasters(casters);
			} catch (error) {
				console.error(error);
			}

			setFallback(null);
		};

		fetchInitialData();
	}, []);

	if (fallback === 'initial-data') {
		return <Fallback title='Carregando' message='Por favor, aguarde...' />;
	}

	return (
		<>
			<Meta title='Novo usuário' />

			<PageHeader title='Novo Usuário' breadcrumb={breadcrumb}>
				<Typography.Text>Cadastrar novo usuário na TalkPlay</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<Steps style={{ marginBottom: 24, padding: '0 100px' }} current={currentStep}>
						<Steps.Step key={0} title='Informações principais' />
						<Steps.Step key={1} title='Geração de conteúdo' />
						<Steps.Step key={2} title='Permissões' />
					</Steps>

					{currentStep === 0 && (
						<Form {...layout} onSubmit={(e) => handleChangeStep(e, 1)}>
							<Form.Item required label='Nome'>
								<Input
									autoComplete='off'
									placeholder='Nome do cliente'
									value={user?.name}
									onChange={({ target: { value } }) => {
										return handleChangeUser('name', value);
									}}
								/>
							</Form.Item>

							<Form.Item required label='Sobrenome'>
								<Input
									autoComplete='off'
									placeholder='Sobrenome do cliente'
									value={user?.surname}
									onChange={({ target: { value } }) => {
										return handleChangeUser('surname', value);
									}}
								/>
							</Form.Item>

							<Form.Item required label='Nome da rádio'>
								<Input
									autoComplete='off'
									placeholder='Nome da Rádio'
									value={user?.radioName}
									onChange={({ target: { value } }) => {
										return handleChangeUser('radioName', value);
									}}
								/>
							</Form.Item>

							<Form.Item required label='Email'>
								<Input
									autoComplete='off'
									placeholder='Email'
									value={user?.email}
									onChange={({ target: { value } }) => {
										return handleChangeUser('email', value);
									}}
								/>
							</Form.Item>

							<Form.Item required label='Cidade'>
								<Input
									autoComplete='off'
									placeholder='Cidade'
									value={user?.city}
									onChange={({ target: { value } }) => {
										return handleChangeUser('city', value);
									}}
								/>
							</Form.Item>

							<Form.Item required label='Estado'>
								<Select
									showSearch
									placeholder='Selecione o estado'
									value={user?.state}
									onChange={(value) => handleChangeUser('state', value)}
									filterOption={(input, { props: { children } }) => {
										return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
									}}>
									{brStates.map((state) => (
										<Select.Option key={state}>{state}</Select.Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item
								required
								label='ID do Admin'
								help='ID presente no sistema de gerenciamento dos pagamentos'>
								<Input
									autoComplete='off'
									placeholder='ID do Admin'
									value={user?.intranetId}
									onChange={({ target: { value } }) => {
										return handleChangeUser('intranetId', value);
									}}
								/>
							</Form.Item>

							<Form.Item label='CPF'>
								<MaskedInput
									autoComplete='off'
									mask='000.000.000-00'
									placeholder='___.___.___-__'
									onChange={({ target: { value } }) => {
										handleChangeUser('cpf', value);
									}}
								/>
							</Form.Item>

							<Form.Item label='CNPJ'>
								<MaskedInput
									autoComplete='off'
									mask='00.000.000/0000-00'
									placeholder='__.___.___/____-__'
									onChange={({ target: { value } }) => handleChangeUser('cnpj', value)}
								/>
							</Form.Item>

							<Form.Item label='Dia de pagamento'>
								<InputNumber
									autoComplete='off'
									value={user?.paymentDay}
									min={1}
									max={31}
									onChange={(value) => {
										return handleChangeUser('paymentDay', value);
									}}
								/>
							</Form.Item>

							<Form.Item label='Telefone'>
								<MaskedInput
									autoComplete='off'
									mask='(00) 0 0000-0000'
									onChange={({ target: { value } }) => {
										return handleChangeUser('phone', value);
									}}
								/>
							</Form.Item>

							<Form.Item required label='Senha'>
								<Input
									autoComplete='off'
									placeholder='Informe a senha do usuário'
									type={pInput.p}
									suffix={
										pInput.p === 'password' ? (
											<Icon
												type='eye-invisible'
												onClick={() => {
													setPInput({ ...pInput, p: 'text' });
												}}
											/>
										) : (
											<Icon
												type='eye'
												onClick={() => {
													setPInput({ ...pInput, p: 'password' });
												}}
											/>
										)
									}
									value={user?.password}
									onChange={({ target: { value } }) => {
										return handleChangeUser('password', value);
									}}
								/>
							</Form.Item>

							<Form.Item required label='Confirmar senha'>
								<Input
									autoComplete='off'
									placeholder='Confirme a senha'
									type={pInput.p2}
									suffix={
										pInput.p2 === 'password' ? (
											<Icon
												type='eye-invisible'
												onClick={() => {
													setPInput({ ...pInput, p2: 'text' });
												}}
											/>
										) : (
											<Icon
												type='eye'
												onClick={() => {
													setPInput({ ...pInput, p2: 'password' });
												}}
											/>
										)
									}
									value={user?.password2}
									onChange={({ target: { value } }) => {
										return handleChangeUser('password2', value);
									}}
								/>
							</Form.Item>

							<Form.Item wrapperCol={{ offset: 12, span: 8 }} style={{ marginTop: 16 }}>
								<ButtonsContainer>
									<Link to='/admin/users/active'>
										<Button htmlType='button'>
											Cancelar <Icon type='close-circle' />
										</Button>
									</Link>
									<Button type='primary' htmlType='submit' style={{ marginLeft: 4 }}>
										Próximo <Icon type='arrow-right' />
									</Button>
								</ButtonsContainer>
							</Form.Item>
						</Form>
					)}

					{currentStep === 1 && (
						<Form {...layout} onSubmit={(e) => handleChangeStep(e, 2)}>
							<Form.Item label='Limite de '>
								<InputNumber
									autoComplete='off'
									value={user?.programLimit}
									min={0}
									onChange={(value) => {
										return handleChangeUser('programLimit', value);
									}}
								/>
								<span className='ant-form-text'> programas</span>
							</Form.Item>

							<Form.Item label='Limite de '>
								<InputNumber
									autoComplete='off'
									value={user?.blocksPerProgram}
									min={1}
									onChange={(value) => {
										return handleChangeUser('blocksPerProgram', value);
									}}
								/>
								<span className='ant-form-text'> blocos por programa</span>
							</Form.Item>

							<Form.Item label='Limite de '>
								<InputNumber
									autoComplete='off'
									value={user?.musicsPerBlock}
									min={1}
									onChange={(value) => {
										return handleChangeUser('musicsPerBlock', value);
									}}
								/>
								<span className='ant-form-text'> músicas por blocos</span>
							</Form.Item>

							<Form.Item label='Masterização'>
								<Select
									showSearch
									placeholder='Configure a masterização do usuário'
									value={user?.masterization}
									onChange={(value) => handleChangeUser('masterization', value)}>
									<Select.Option key='OPTIONAL'>Opcional</Select.Option>
									<Select.Option key='MANDATORY'>Obrigatório</Select.Option>
									<Select.Option key='BLOCKED'>Bloqueado</Select.Option>
								</Select>
							</Form.Item>

							<Form.Item label='Com assinatura'>
								<Switch
									checked={user?.forceSignature}
									unCheckedChildren='Não'
									checkedChildren='Sim'
									onChange={(value) => handleChangeUser('forceSignature', value)}
								/>
							</Form.Item>

							<Form.Item label='Permitir inserção de propagandas curtas'>
								<Switch
									checked={user?.allowShortAdds}
									unCheckedChildren='Não'
									checkedChildren='Sim'
									onChange={(value) => handleChangeUser('allowShortAdds', value)}
								/>
							</Form.Item>

							<Form.Item label='Permitir assinatura de músicas'>
								<Switch
									checked={user?.allowTracksSignature}
									unCheckedChildren='Não'
									checkedChildren='Sim'
									onChange={(value) => handleChangeUser('allowTracksSignature', value)}
								/>
							</Form.Item>

							<Form.Item
								label='Acesso ao banco de músicas'
								title={
									user?.userLevel === 7
										? 'Usuários do tipo "Locutor" precisam obrigatoriamente ter acesso ao banco de músicas'
										: ''
								}>
								<Switch
									checked={user?.tracksDB}
									unCheckedChildren='Não'
									checkedChildren='Sim'
									disabled={user.userLevel === 7}
									onChange={(value) => handleChangeUser('tracksDB', value)}
								/>
							</Form.Item>

							<Form.Item label='Com conteúdo ao-vivo'>
								<Switch
									checked={user?.withLiveContent}
									unCheckedChildren='Não'
									checkedChildren='Sim'
									onChange={(value) => handleChangeUser('withLiveContent', value)}
								/>
							</Form.Item>

							<Form.Item wrapperCol={{ offset: 12, span: 8 }} style={{ marginTop: 16 }}>
								<ButtonsContainer>
									<Button htmlType='button' onClick={() => handleChangeStep(null, 0)}>
										<Icon type='arrow-left' /> Anterior
									</Button>
									<Button type='primary' htmlType='submit' style={{ marginLeft: 4 }}>
										Próximo <Icon type='arrow-right' />
									</Button>
								</ButtonsContainer>
							</Form.Item>
						</Form>
					)}

					{currentStep === 2 && (
						<Form {...layout} onSubmit={handleSubmit}>
							<Form.Item required label='Tipo da conta'>
								<Select
									value={user?.userLevel}
									onChange={(value) => {
										handleChangeUser('userLevel', value);
										handleChangeUser('caster', undefined);

										if (value === 7) {
											handleChangeUser('tracksDB', true);
										}
									}}>
									<Select.Option value={0}>Administrador</Select.Option>
									<Select.Option value={1}>
										Conta Talk <i>(Sem acesso às músicas)</i>
									</Select.Option>
									<Select.Option value={2}>Conta padrão</Select.Option>
									<Select.Option value={3}>Gerenciar elementos/modelos</Select.Option>
									<Select.Option value={4}>Gerenciar assinaturas</Select.Option>
									<Select.Option value={5}>Gerenciar músicas</Select.Option>
									<Select.Option value={6}>Vendedor</Select.Option>
									<Select.Option value={7}>Locutor</Select.Option>
									<Select.Option value={9}>Conta marketing</Select.Option>
								</Select>
							</Form.Item>

							{user.userLevel === 7 && (
								<Form.Item
									required
									extra='É necessário vincular um locutor à essa conta'
									label='Locutor'>
									<Select
										showSearch
										placeholder='Selecione um locutor'
										value={user?.caster}
										filterOption={(input, { props: { _search } }) => {
											return _search.match(new RegExp(input, 'i'));
										}}
										onChange={(value) => handleChangeUser('caster', value)}>
										{casters.map((c) => (
											<Select.Option value={c._id} key={c._id} _search={`${c.name} ${c.surname}`}>
												{c.name} {c.surname}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							)}

							<Form.Item required label='Permissões de compartilhamento'>
								<Select
									placeholder='Selecione as permissões de compartilhamento'
									value={user?.sharePermissionLevel}
									onChange={(value) => {
										return handleChangeUser('sharePermissionLevel', value);
									}}>
									<Select.Option value={0}>Não pode compartilhar programas</Select.Option>
									<Select.Option value={1}>
										Pode compartilhar somente com a conta Talk
									</Select.Option>
									<Select.Option value={2}>Pode compartilhar com qualquer usuário</Select.Option>
								</Select>
							</Form.Item>

							<Form.Item label='Habilitar download de músicas'>
								<Switch
									checked={user?.allowTracksDownload}
									unCheckedChildren='Não'
									checkedChildren='Sim'
									onChange={(value) => handleChangeUser('allowTracksDownload', value)}
								/>
							</Form.Item>

							<Form.Item label='Habilitar download automático'>
								<Switch
									checked={user?.allowAutoDownload}
									unCheckedChildren='Não'
									checkedChildren='Sim'
									onChange={(value) => handleChangeUser('allowAutoDownload', value)}
								/>
							</Form.Item>

							<Form.Item wrapperCol={{ offset: 12, span: 8 }} style={{ marginTop: 16 }}>
								<ButtonsContainer>
									<Button
										htmlType='button'
										disabled={fallback === 'creating-user'}
										onClick={() => handleChangeStep(null, 1)}>
										<Icon type='arrow-left' /> Anterior
									</Button>
									<Button
										type='primary'
										htmlType='submit'
										loading={fallback === 'creating-user'}
										style={{ marginLeft: 4 }}>
										Salvar usuário <Icon type='save' />
									</Button>
								</ButtonsContainer>
							</Form.Item>
						</Form>
					)}
				</Card>
			</Container>
		</>
	);
};

export default NewUser;
