import styled from 'styled-components';

export const RegisterContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const PlansContainer = styled.div`
	margin-top: 32px;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 16px;
`;

export const ButtonsContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	grid-column: span 24;

	& > button {
		margin-left: 8px;
	}
`;
