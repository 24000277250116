import React, { useState, useEffect, useCallback } from 'react';
import { parseISO, format, getYear, getMonth } from 'date-fns';
import {
	PageHeader,
	Table,
	Card,
	Icon,
	Modal,
	Typography,
	Input,
	message,
	Button,
	Select,
	Tag,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import {
	Container,
	SearchContainer,
	DetailsContainer,
	FilterContainer,
	ContractCell,
} from './styles';

import BilletsAPI from '../../../../services/sdks/billets';
import { useFormatter } from '../../../../hooks';

export const years = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
export const months = [
	'Janeiro',
	'Fevereiro',
	'Março',
	'Abril',
	'Maio',
	'Junho',
	'Julho',
	'Agosto',
	'Setembro',
	'Outubro',
	'Novembro',
	'Dezembro',
];

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Início' },
		{ breadcrumbName: 'Contratos' },
		{ breadcrumbName: 'Boletos' },
	],
	style: { marginBottom: 12 },
};

const Billets = () => {
	const toCurrencyFormat = useFormatter({ style: 'currency', currency: 'BRL' });

	const [fallback, setFallback] = useState({ initialData: true });
	const [contract, setContract] = useState(null);
	const [billets, setBillets] = useState([]);
	const [filteredBillets, setFilteredBillets] = useState([]);
	const [showModals, setShowModals] = useState({ details: false });

	const [month, setMonth] = useState(getMonth(new Date()));
	const [year, setYear] = useState(getYear(new Date()));

	const columns = [
		{
			title: 'ID',
			dataIndex: '_id',
			key: 'id',
			render: (_id) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					3
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Contrato',
			key: 'contract',
			render: (billet) => (
				<ContractCell>
					<div>
						<Typography.Text>
							{billet?.contract?.hirer?.radioName} - {billet?.contract?.hirer?.city}/
							{billet?.contract?.hirer?.state}
						</Typography.Text>
						<span className='arrow'>⇄</span>
						<Typography.Text>
							{billet?.contract?.caster?.name} {billet?.contract?.caster?.surname}
						</Typography.Text>
					</div>

					<span
						onClick={() => {
							setShowModals({ ...showModals, details: true });
							setContract(billet?.contract);
						}}
						className='details'
					>
						Visualizar
					</span>
				</ContractCell>
			),
		},
		{
			title: 'Vencimento',
			key: 'program',
			render: (billet) =>
				billet.payment.banking_billet.expire_at
					? billet.payment.banking_billet.expire_at.split('-').reverse().join('/')
					: '-',
		},
		{
			title: 'Valor',
			key: 'value',
			render: (billet) => toCurrencyFormat(billet?.total / 100),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			align: 'center',
			render: (status) => {
				switch (status) {
					case 'waiting':
						return (
							<Tag color='gold'>
								<Icon type='clock-circle' style={{ marginRight: 4 }} /> Aguardando
							</Tag>
						);
					case 'unpaid':
						return (
							<Tag color='red'>
								<Icon type='close-circle' style={{ marginRight: 4 }} /> Inadimplente
							</Tag>
						);
					case 'canceled':
						return (
							<Tag color='red'>
								<Icon type='stop' style={{ marginRight: 4 }} /> Cancelado
							</Tag>
						);
					default:
						return (
							<Tag color='green'>
								<Icon type='check-circle' style={{ marginRight: 4 }} /> Pago
							</Tag>
						);
				}
			},
		},
		{
			title: 'Visualizar',
			key: 'view',
			align: 'right',
			render: (billet) => (
				// eslint-disable-next-line jsx-a11y/anchor-is-valid
				<a href={billet?.payment?.banking_billet?.link} target='_blank' rel='noopener noreferrer'>
					<Button type='ghost' size='small' icon='eye'>
						Visualizar
					</Button>
				</a>
			),
		},
	];

	const handleSearch = useCallback(
		(search) => {
			if (!search) {
				return setFilteredBillets(billets);
			}

			setFilteredBillets(
				billets.filter((billet) => {
					const radioMatch = billet?.contract?.hirer?.radioName
						.toUpperCase()
						.includes(search.toUpperCase());
					const casterMatch =
						`${billet?.contract?.caster?.name} ${billet?.contract?.caster?.surname}`
							.toUpperCase()
							.includes(search.toUpperCase());
					const programMatch = billet?.contract?.program?.name
						.toUpperCase()
						.includes(search.toUpperCase());

					return radioMatch || casterMatch || programMatch;
				})
			);
		},
		[billets]
	);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				setFallback((prev) => ({ ...prev, initialData: true }));

				const {
					data: { billets },
				} = await BilletsAPI.index({ query: `month=${month + 1}&year=${year}` });

				setBillets(billets);
				setFilteredBillets(billets);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro, tente novamente');
			}
		};

		fetchInitialData();
	}, [month, year]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Boletos' />

			<PageHeader title='Boletos' breadcrumb={breadcrumb}>
				<Typography.Text>
					Exibindo boletos de {months[month - 1]} de {years[year]}
				</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<SearchContainer>
						<Input.Search
							allowClear
							size='large'
							onSearch={handleSearch}
							style={{ width: '300px' }}
							placeholder='Buscar por rádio, programa ou locutor'
						/>
					</SearchContainer>

					<FilterContainer>
						<Select
							value={month}
							onChange={(value) => setMonth(value)}
							style={{ width: 130, marginRight: 12 }}
						>
							{months.map((month, index) => (
								<Select.Option key={month} value={index}>
									{month}
								</Select.Option>
							))}
						</Select>
						<Select value={year} onChange={(value) => setYear(value)} style={{ width: 100 }}>
							{years.map((year) => (
								<Select.Option key={year} value={year}>
									{year}
								</Select.Option>
							))}
						</Select>
					</FilterContainer>

					<Table
						size='middle'
						rowKey='_id'
						columns={columns}
						dataSource={filteredBillets}
						pagination={{ size: 'large', pageSize: 10, hideOnSinglePage: true }}
						style={{ border: 'none' }}
					/>
				</Card>
			</Container>

			<Modal
				visible={showModals?.details}
				title={
					<>
						<Icon type='file-text' style={{ marginRight: 8 }} /> Detalhes
					</>
				}
				footer={null}
				width={600}
				onCancel={() => setShowModals((prev) => ({ ...prev, details: false }))}
			>
				<DetailsContainer>
					<ul>
						<li>
							<span className='label'>Rádio</span>
							<strong>{contract?.hirer?.radioName}</strong>
						</li>
						<li>
							<span className='label'>Locutor</span>
							<strong>
								{contract?.caster?.name} {contract?.caster?.surname}
							</strong>
						</li>
						<li>
							<span className='label'>Programa</span>
							<strong>{contract?.program?.name}</strong>
						</li>
						<li>
							<span className='label'>Status</span>
							<strong>
								<Tag
									color={
										[0, 3].includes(contract?.status)
											? 'gold'
											: contract?.status === 2
												? 'red'
												: 'green'
									}
								>
									{[0, 3].includes(contract?.status)
										? 'Aguardando'
										: contract?.status === 2
											? 'Encerrado'
											: 'Vigente'}
								</Tag>
							</strong>
						</li>
						<li>
							<span className='label'>Valor</span>
							<strong>{contract?.value ? toCurrencyFormat(contract?.value / 100) : '-'}</strong>
						</li>
						<li>
							<span className='label'>Número de renovações</span>
							<strong>{contract?.restores || '-'}</strong>
						</li>
						<li style={{ border: 'none' }}>
							<span className='label'>Assinatura</span>
							<strong>
								{contract?.signatureDate
									? format(parseISO(contract?.signatureDate), 'dd/MM/yyyy')
									: '-'}
							</strong>
						</li>
					</ul>
				</DetailsContainer>
			</Modal>
		</>
	);
};

export default Billets;
