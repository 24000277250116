import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
	PageHeader,
	Button,
	Typography,
	Modal,
	Card,
	message,
	Input,
	Divider,
	Tooltip,
	Form,
} from 'antd';

import Meta from '../../../../../components/Meta';
import ScriptTemplatesAPI from '../../../../../services/sdks/scriptTemplates';
import { useScript } from '../../../../../hooks';
import { Container, ButtonContainer, Grid, OptionsContainer, ScriptWrapper } from './styles';
import ElementsTabs from '../../../../../components/ElementsTabs';
import { FiMaximize, FiMinimize, FiPlusCircle, FiXSquare } from 'react-icons/fi';
import Script from '../../../../../components/Script';
import { connect } from 'react-redux';
import TKVoiceConfigAPI from '../../../../../services/sdks/tkVoiceConfig';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Templates' },
		{ breadcrumbName: 'Templates de Modelo' },
		{ breadcrumbName: 'Criar Template' },
	],
	style: { marginBottom: 12 },
};

const CreateScriptTemplate = ({ user }) => {
	const [fallback, setFallback] = useState();
	const [errors, setErrors] = useState({});
	const [name, setName] = useState('');
	const [tkVoiceTypes, setTkVoiceTypes] = useState({ caster: [], local: [] });
	const history = useHistory();
	const {
		cleanUp,
		script,
		setScript,
		isSorting,
		setIsSorting,
		onElementMove,
		compact,
		setCompact,
	} = useScript();

	const handleCreateTemplate = useCallback(async () => {
		setErrors({});

		if (!name) {
			setErrors((prev) => ({ ...prev, name: true }));
			return message.error('Informe o nome do template');
		}

		if (!script.body?.length) {
			return message.error('Preencha o modelo');
		}

		setFallback((prev) => ({ ...prev, creatingTemplate: true }));

		try {
			await ScriptTemplatesAPI.create({ payload: { name, body: script.body } });
			message.success('Modelo criado com sucesso');
			setFallback((prev) => ({ ...prev, creatingTemplate: false }));
			history.push(`/admin/templates/script-templates`);
		} catch (error) {
			setFallback((prev) => ({ ...prev, creatingTemplate: false }));
			console.error(error);
			message.error('Houve um erro, tente novamente');
			message.error(error?.response?.data?.message);
		}
	}, [name, history, script.body]);

	useEffect(() => {
		cleanUp();
		return () => cleanUp();
	}, [cleanUp]);

	useEffect(() => {
		const fetchTkVoiceTypes = async () => {
			try {
				const res = await TKVoiceConfigAPI.getPopulatedTypes('', '');
				setTkVoiceTypes(res.data.types);
			} catch (error) {
				setTkVoiceTypes({ caster: [], local: [] });
			}
		};

		fetchTkVoiceTypes();
	}, []);

	return (
		<>
			<Meta title='Criar Template' />

			<PageHeader title='Criar Template' breadcrumb={breadcrumb}></PageHeader>

			<Container isSorting={isSorting}>
				<Card>
					<Grid>
						<ElementsTabs
							isAdmin={true}
							allowGlobalNews={true}
							allowGlobalWpp={true}
							allowGlobalInterview={true}
							tkVoiceTypes={tkVoiceTypes}
							isTemplate={true}
						/>

						<ScriptWrapper>
							<Typography.Title level={4}>Template</Typography.Title>

							<Divider style={{ margin: '8px 0' }} />

							<OptionsContainer>
								<div className='divider'></div>

								<Tooltip title={compact ? 'Maximizar' : 'Compactar'}>
									<Button
										size='small'
										onClick={() => setCompact(!compact)}
										disabled={script?.body?.length === 0}
									>
										{compact ? <FiMaximize /> : <FiMinimize />}
									</Button>
								</Tooltip>

								<Tooltip
									title={script?.body?.length !== 0 && !script?.isShared && 'Limpar template'}
								>
									<Button
										disabled={script?.body?.length === 0 || script?.isShared}
										type='danger'
										size='small'
										onClick={() => {
											Modal.confirm({
												title: 'Limpar template?',
												icon: 'exclamation-circle',
												content: 'Todos os blocos e elementos serão removidos, deseja continuar?',
												okText: 'Limpar template',
												onOk: () => setScript({ ...script, body: [] }),
												okButtonProps: {
													icon: 'delete',
													type: 'danger',
												},
												cancelText: 'Cancelar',
												cancelButtonProps: {
													icon: 'close-circle',
												},
											});
										}}
									>
										<FiXSquare />
									</Button>
								</Tooltip>
							</OptionsContainer>

							<Divider style={{ margin: '8px 0' }} />

							<Script
								onSortEnd={onElementMove}
								onSortStart={() => setIsSorting(true)}
								loggedUser={user}
								tkVoiceTypes={tkVoiceTypes}
								isAdmin={true}
							/>
						</ScriptWrapper>
					</Grid>

					<Form.Item
						style={{ marginTop: 16 }}
						validateStatus={errors['name'] && 'error'}
						help={errors['name'] && 'Informe o nome do template'}
					>
						<Input
							onChange={(e) => setName(e.target.value)}
							placeholder='Nome do template'
							size='large'
						/>
					</Form.Item>

					<ButtonContainer>
						<Button
							size='large'
							type='primary'
							disabled={!script.body?.length}
							onClick={handleCreateTemplate}
							loading={fallback?.creatingTemplate}
						>
							<FiPlusCircle /> Criar Template
						</Button>
					</ButtonContainer>
				</Card>
			</Container>
		</>
	);
};

export default connect(({ user }) => ({ user }))(CreateScriptTemplate);
