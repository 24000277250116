import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import MaskedInput from 'antd-mask-input';
import * as Yup from 'yup';
import {
	PageHeader,
	Form,
	Tabs,
	Card,
	Button,
	Icon,
	Select,
	message,
	Input,
	Divider,
	InputNumber,
	Switch,
	Typography,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import { Container } from './styles';

import UserAPI from '../../../../services/sdks/user';
import CasterAPI from '../../../../services/sdks/caster';
import { states as brStates } from '../../../../constants';

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 12 } };
const tailLayout = { wrapperCol: { offset: 6, span: 18 } };
const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Usuários' },
		{ breadcrumbName: 'Editar usuário' },
	],
	style: { marginBottom: 12 },
};

const EditUser = () => {
	const history = useHistory();
	const { userId } = useParams();

	const [fallback, setFallback] = useState('initial-data');
	const [user, setUser] = useState(null);
	const [casters, setCasters] = useState([]);

	const handleSubmit = useCallback(
		async (event) => {
			try {
				event.preventDefault();

				const payload = { ...user, caster: user.userLevel === 7 ? user.caster : null };
				const validationSchema = Yup.object().shape({
					intranetId: Yup.string().nullable().required('Informe o ID do Admin'),
					state: Yup.string().required('Informe o estado do usuário'),
					city: Yup.string().required('Informe a cidade do usuário'),
					email: Yup.string().required('Informe o email do usuário'),
					radioName: Yup.string().required('Informe o nome da rádio'),
					surname: Yup.string().required('Informe o sobrenome do usuário'),
					name: Yup.string().required('Informe o nome do usuário'),
				});

				await validationSchema.validate(payload);

				if (payload.userLevel === 7 && !payload.caster) {
					return message.error('Por favor, vincule um locutor à essa conta');
				}

				setFallback('updating-user');

				await UserAPI.modify(userId, payload);

				setFallback(null);
				message.success('Usuário foi atualizado com sucesso!');
			} catch (error) {
				if (error instanceof Yup.ValidationError) {
					return message.error(error.message);
				}

				console.error(error);
				message.error('Houve um erro, tente novamente');
			}
		},
		[user, userId]
	);

	const handleChangeUser = useCallback((key, value) => {
		setUser((user) => ({ ...user, [key]: value }));
	}, []);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { casters },
				} = await CasterAPI.index();

				const {
					data: { user },
				} = await UserAPI.show(userId);

				setCasters(casters);
				setUser({ ...user, caster: user.caster ? user.caster._id : null });
				setFallback(null);
			} catch (error) {
				console.error(error);
			}
		};

		if (userId) {
			fetchInitialData();
		}
	}, [userId]);

	if (fallback === 'initial-data') {
		return <Fallback title='Carregando' message='Por favor, aguarde...' />;
	}

	return (
		<>
			<Meta title='Editar usuário' />

			<PageHeader title='Editar Usuário' breadcrumb={breadcrumb}>
				<Typography.Text>Alterar informações do usuário</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<Tabs tabPosition='left'>
						<Tabs.TabPane
							tab={
								<>
									<Icon type='file-text' /> Informações principais
								</>
							}
							key='1'>
							<Form {...layout}>
								<Form.Item required label='Nome'>
									<Input
										autoComplete='off'
										placeholder='Nome do cliente'
										value={user?.name}
										onChange={({ target: { value } }) => {
											return handleChangeUser('name', value);
										}}
									/>
								</Form.Item>

								<Form.Item required label='Sobrenome'>
									<Input
										autoComplete='off'
										placeholder='Sobrenome do cliente'
										value={user?.surname}
										onChange={({ target: { value } }) => {
											return handleChangeUser('surname', value);
										}}
									/>
								</Form.Item>

								<Form.Item required label='Nome da rádio'>
									<Input
										autoComplete='off'
										placeholder='Nome da Rádio'
										value={user?.radioName}
										onChange={({ target: { value } }) => {
											return handleChangeUser('radioName', value);
										}}
									/>
								</Form.Item>

								<Form.Item required label='Email'>
									<Input
										autoComplete='off'
										placeholder='Email'
										value={user?.email}
										onChange={({ target: { value } }) => {
											return handleChangeUser('email', value);
										}}
									/>
								</Form.Item>

								<Form.Item required label='Cidade'>
									<Input
										autoComplete='off'
										placeholder='Cidade'
										value={user?.city}
										onChange={({ target: { value } }) => {
											return handleChangeUser('city', value);
										}}
									/>
								</Form.Item>

								<Form.Item required label='Estado'>
									<Select
										showSearch
										placeholder='Selecione o estado'
										value={user?.state}
										onChange={(value) => handleChangeUser('state', value)}
										filterOption={(input, { props: { children } }) => {
											return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
										}}>
										{brStates.map((state) => (
											<Select.Option key={state}>{state}</Select.Option>
										))}
									</Select>
								</Form.Item>

								<Form.Item
									required
									label='ID do Admin'
									help='ID presente no sistema de gerenciamento dos pagamentos'>
									<Input
										autoComplete='off'
										placeholder='ID do Admin'
										value={user?.intranetId}
										onChange={({ target: { value } }) => {
											return handleChangeUser('intranetId', value);
										}}
									/>
								</Form.Item>

								<Form.Item label='CPF'>
									<MaskedInput
										autoComplete='off'
										mask='000.000.000-00'
										defaultValue={user?.cpf}
										onChange={({ target: { value } }) => handleChangeUser('cpf', value)}
									/>
								</Form.Item>

								<Form.Item label='CNPJ'>
									<MaskedInput
										autoComplete='off'
										mask='00.000.000/0000-00'
										placeholder='__.___.___/____-__'
										defaultValue={user?.cnpj}
										onChange={({ target: { value } }) => handleChangeUser('cnpj', value)}
									/>
								</Form.Item>

								<Form.Item label='Dia de pagamento'>
									<InputNumber
										autoComplete='off'
										value={user?.paymentDay}
										min={1}
										max={31}
										onChange={(value) => {
											return handleChangeUser('paymentDay', value);
										}}
									/>
								</Form.Item>

								<Form.Item label='Telefone'>
									<MaskedInput
										autoComplete='off'
										mask='(00) 0 0000-0000'
										defaultValue={user?.phone}
										onChange={({ target: { value } }) => {
											return handleChangeUser('phone', value);
										}}
									/>
								</Form.Item>
							</Form>
						</Tabs.TabPane>

						<Tabs.TabPane
							tab={
								<>
									<Icon type='play-circle' /> Geração de conteúdo
								</>
							}
							key='2'>
							<Form {...layout}>
								<Form.Item label='Limite de '>
									<InputNumber
										autoComplete='off'
										value={user?.programLimit}
										min={0}
										onChange={(value) => {
											return handleChangeUser('programLimit', value);
										}}
									/>
									<span className='ant-form-text'> programas</span>
								</Form.Item>

								<Form.Item label='Limite de '>
									<InputNumber
										autoComplete='off'
										value={user?.blocksPerProgram}
										min={1}
										onChange={(value) => {
											return handleChangeUser('blocksPerProgram', value);
										}}
									/>
									<span className='ant-form-text'> blocos por programa</span>
								</Form.Item>

								<Form.Item label='Limite de '>
									<InputNumber
										autoComplete='off'
										value={user?.maxGenerationsPerProgram}
										min={1}
										onChange={(value) => {
											return handleChangeUser('maxGenerationsPerProgram', value);
										}}
									/>
									<span className='ant-form-text'> gerações por dia do mesmo programa</span>
								</Form.Item>

								<Form.Item label='Limite de '>
									<InputNumber
										autoComplete='off'
										value={user?.musicsPerBlock}
										min={1}
										onChange={(value) => {
											return handleChangeUser('musicsPerBlock', value);
										}}
									/>
									<span className='ant-form-text'> músicas por blocos</span>
								</Form.Item>

								<Form.Item label='Masterização'>
									<Select
										showSearch
										placeholder='Configure a masterização do usuário'
										value={user?.masterization}
										onChange={(value) => handleChangeUser('masterization', value)}>
										<Select.Option key='OPTIONAL'>Opcional</Select.Option>
										<Select.Option key='MANDATORY'>Obrigatório</Select.Option>
										<Select.Option key='BLOCKED'>Bloqueado</Select.Option>
									</Select>
								</Form.Item>

								<Form.Item label='Com assinatura'>
									<Switch
										checked={user?.forceSignature}
										unCheckedChildren='Não'
										checkedChildren='Sim'
										onChange={(value) => handleChangeUser('forceSignature', value)}
									/>
								</Form.Item>

								<Form.Item label='Permitir inserção de propagandas curtas'>
									<Switch
										checked={user?.allowShortAdds}
										unCheckedChildren='Não'
										checkedChildren='Sim'
										onChange={(value) => handleChangeUser('allowShortAdds', value)}
									/>
								</Form.Item>

								<Form.Item label='Permitir assinatura de músicas'>
									<Switch
										checked={user?.allowTracksSignature}
										unCheckedChildren='Não'
										checkedChildren='Sim'
										onChange={(value) => handleChangeUser('allowTracksSignature', value)}
									/>
								</Form.Item>

								<Form.Item
									label='Acesso ao banco de músicas'
									title={
										user?.userLevel === 7
											? 'Usuários do tipo "Locutor" precisam obrigatoriamente ter acesso ao banco de músicas'
											: ''
									}>
									<Switch
										checked={user?.tracksDB}
										unCheckedChildren='Não'
										checkedChildren='Sim'
										disabled={user?.userLevel === 7}
										onChange={(value) => handleChangeUser('tracksDB', value)}
									/>
								</Form.Item>

								<Form.Item label='Com conteúdo ao-vivo'>
									<Switch
										checked={user?.withLiveContent}
										unCheckedChildren='Não'
										checkedChildren='Sim'
										onChange={(value) => handleChangeUser('withLiveContent', value)}
									/>
								</Form.Item>
							</Form>
						</Tabs.TabPane>

						<Tabs.TabPane
							tab={
								<>
									<Icon type='unordered-list' /> Permissões
								</>
							}
							key='3'>
							<Form {...layout}>
								<Form.Item required label='Tipo da conta'>
									<Select
										value={user?.userLevel}
										onChange={(value) => {
											handleChangeUser('userLevel', value);
											handleChangeUser('caster', undefined);

											if (value === 7) {
												handleChangeUser('tracksDB', true);
											}
										}}>
										<Select.Option value={0}>Administrador</Select.Option>
										<Select.Option value={1}>
											Conta Talk <i>(Sem acesso às músicas)</i>
										</Select.Option>
										<Select.Option value={2}>Conta padrão</Select.Option>
										<Select.Option value={3}>Gerenciar elementos/modelos</Select.Option>
										<Select.Option value={4}>Gerenciar assinaturas</Select.Option>
										<Select.Option value={5}>Gerenciar músicas</Select.Option>
										<Select.Option value={6}>Vendedor</Select.Option>
										<Select.Option value={7}>Locutor</Select.Option>
										<Select.Option value={9}>Conta marketing</Select.Option>
									</Select>
								</Form.Item>

								{user.userLevel === 7 && (
									<Form.Item
										required
										extra='É necessário vincular um locutor à essa conta'
										label='Locutor'>
										<Select
											placeholder='Selecione um locutor'
											value={user?.caster}
											onChange={(value) => handleChangeUser('caster', value)}>
											{casters.map((c) => (
												<Select.Option value={c._id} key={c._id}>
													{c.name} {c.surname}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								)}

								<Form.Item required label='Permissões de compartilhamento'>
									<Select
										value={user?.sharePermissionLevel}
										onChange={(value) => {
											return handleChangeUser('sharePermissionLevel', value);
										}}>
										<Select.Option value={0}>Não pode compartilhar programas</Select.Option>
										<Select.Option value={1}>
											Pode compartilhar somente com a conta Talk
										</Select.Option>
										<Select.Option value={2}>Pode compartilhar com qualquer usuário</Select.Option>
									</Select>
								</Form.Item>

								<Form.Item label='Habilitar download de músicas'>
									<Switch
										checked={user?.allowTracksDownload}
										unCheckedChildren='Não'
										checkedChildren='Sim'
										onChange={(value) => handleChangeUser('allowTracksDownload', value)}
									/>
								</Form.Item>

								<Form.Item label='Habilitar download automático'>
									<Switch
										checked={user?.allowAutoDownload}
										unCheckedChildren='Não'
										checkedChildren='Sim'
										onChange={(value) => handleChangeUser('allowAutoDownload', value)}
									/>
								</Form.Item>
							</Form>
						</Tabs.TabPane>
					</Tabs>

					<Divider />

					<Form {...layout} onSubmit={handleSubmit}>
						<Form.Item {...tailLayout} style={{ marginTop: 16 }}>
							<Button type='primary' htmlType='submit' loading={fallback === 'updating-user'}>
								Salvar alterações <Icon type='save' />
							</Button>

							<Button
								style={{ marginLeft: 4 }}
								disabled={fallback === 'updating-user'}
								onClick={() => history.goBack()}>
								Cancelar <Icon type='close-circle' />
							</Button>
						</Form.Item>
					</Form>
				</Card>
			</Container>
		</>
	);
};

export default EditUser;
