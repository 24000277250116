import React, { useCallback, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import {
	Divider,
	Card,
	Icon,
	Alert,
	Tag,
	Typography,
	Modal,
	Input,
	message,
	Tooltip,
	Spin,
	Button,
} from 'antd';

import { Container, ActionButton, ProgramInfos, ProgramInfo, TitleContainer } from './styles';

import SharingsAPI from '../../services/sdks/share';
import ProgramsAPI from '../../services/sdks/programs';

const ProgramCard = ({ data, user }) => {
	const [program, setProgram] = useState(data);
	const [fallback, setFallback] = useState(null);
	const [deleteReason, setDeleteReason] = useState('');
	const [showDeleteModal, setShowDeleteModal] = useState('');

	const handleToggleFavorite = useCallback(async () => {
		try {
			const isFavorited = program?.isFavorited ? false : true;
			const payload = { isFavorited };

			program?.isShared
				? await SharingsAPI.modify(program?.sharing?._id, payload)
				: await ProgramsAPI.update(program._id, payload);

			setProgram((prev) => ({ ...prev, isFavorited }));

			message.success(
				isFavorited
					? 'Agora esse conteúdo aparecerá primeiro nas listagens!'
					: 'O Conteúdo foi removido dos seus favoritos!'
			);
		} catch (error) {
			console.error(error);
		}
	}, [program]);

	const handleRequestProgramExclusion = useCallback(async () => {
		try {
			if (!deleteReason) {
				return message.warn('Por favor, informe o motivo');
			}

			setFallback((prev) => ({ ...prev, exclusionRequest: true }));

			await ProgramsAPI.update(program._id, { deleteReason, deleteRequested: true });

			setProgram((prev) => ({ ...prev, deleteReason, deleteRequested: true }));
			setFallback((prev) => ({ ...prev, exclusionRequest: false }));
			setShowDeleteModal(false);
			setDeleteReason('');

			return message.success('A exclusão foi enviada para análise');
		} catch (error) {
			console.error(error);
		}
	}, [program, deleteReason]);

	const handleCancelProgramExclusion = useCallback(async () => {
		try {
			setFallback((prev) => ({ ...prev, exclusionRequest: true }));

			await ProgramsAPI.update(program._id, { deleteReason: null, deleteRequested: false });

			setProgram((prev) => ({ ...prev, deleteReason: null, deleteRequested: false }));
			setFallback((prev) => ({ ...prev, exclusionRequest: false }));

			return message.success('A exclusão foi cancelada');
		} catch (error) {
			console.error(error);
		}
	}, [program]);

	const handleToggleExclusionRequest = useCallback(() => {
		if (program?.deleteRequested) {
			return handleCancelProgramExclusion();
		} else {
			return setShowDeleteModal(true);
		}
	}, [program, handleCancelProgramExclusion, setShowDeleteModal]);

	const renderBadge = useCallback(() => {
		const tags = [];

		if (!program?.isActive) {
			tags.push(
				<Tag color='red' key='inactive'>
					<Icon type='stop' style={{ marginRight: 4 }} /> Indisponível por inadimplência
				</Tag>
			);
		}

		if (program?.isShared) {
			tags.push(
				<Tag color='gold' key='shared'>
					<Icon type='share-alt' style={{ marginRight: 4 }} /> Compartilhado por{' '}
					{program?.userId?.radioName}
				</Tag>
			);
		}

		return tags.length ? tags : null;
	}, [program]);

	return (
		<>
			<Container>
				<Card
					bordered
					extra={renderBadge()}
					actions={[
						<Link key='details' to={`/commom/programs/${program._id}/details`}>
							<ActionButton>
								<Icon type='eye' /> Detalhes
							</ActionButton>
						</Link>,

						<Spin spinning={fallback?.exclusionRequest ? true : false} key='delete'>
							<ActionButton
								onClick={handleToggleExclusionRequest}
								isDisabled={
									fallback?.exclusionRequest ||
									user?.userLevel === 9 ||
									program?.userId?._id !== user._id
								}
								disabled={
									fallback?.exclusionRequest ||
									user?.userLevel === 9 ||
									program?.userId?._id !== user._id
								}
								isDanger
							>
								<Icon type='delete' />
								Requisitar exclusão
							</ActionButton>
						</Spin>,
					]}
				>
					<Card.Meta
						description={program?.description}
						title={
							<TitleContainer>
								<Link key='details' to={`/commom/programs/${program._id}/details`}>
									{program?.name}
								</Link>

								<Tooltip
									mouseEnterDelay={0.5}
									title={program?.isFavorited ? 'Remover dos favoritos' : 'Adicionar aos favoritos'}
								>
									{program?.isFavorited ? (
										<Icon
											className='favorite-icon'
											type='heart'
											theme='filled'
											onClick={handleToggleFavorite}
											style={{ color: 'red', fontSize: 22 }}
										/>
									) : (
										<Icon
											className='favorite-icon'
											type='heart'
											onClick={handleToggleFavorite}
											style={{ fontSize: 22 }}
										/>
									)}
								</Tooltip>
							</TitleContainer>
						}
					/>

					<Divider />

					<ProgramInfos>
						<ProgramInfo>
							<Typography.Text className='key'>ID</Typography.Text>
							<Typography.Text className='value' copyable>
								{program?._id}
							</Typography.Text>
						</ProgramInfo>
						{program?.isShared && (
							<ProgramInfo>
								<Typography.Text className='key'>ID do compartilhamento</Typography.Text>
								<Typography.Text className='value' copyable>
									{program?.sharing?._id}
								</Typography.Text>
							</ProgramInfo>
						)}
						{program?.isActive && (
							<>
								<ProgramInfo>
									<Typography.Text className='key'>Gênero</Typography.Text>
									<Typography.Text className='value'>
										{program?.genreId?.name || '- -'}
									</Typography.Text>
								</ProgramInfo>
								<ProgramInfo>
									<Typography.Text className='key'>Nome dos arquivos</Typography.Text>
									<Typography.Text className='value'>{program?.filenameTemplate}</Typography.Text>
								</ProgramInfo>
								<ProgramInfo>
									<Typography.Text className='key'>Data de criação</Typography.Text>
									<Typography.Text className='value'>
										{format(parseISO(program?.dateUpload), "dd 'de' MMMM 'de' yyyy", {
											locale: ptBR,
										})}
									</Typography.Text>
								</ProgramInfo>
							</>
						)}
					</ProgramInfos>

					{program?.deleteRequested && (
						<Alert
							showIcon
							type='error'
							message={
								<div
									style={{
										width: '100%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<span>Exclusão requisitada!</span>
									<Button size='small' type='danger' onClick={handleCancelProgramExclusion}>
										Cancelar
									</Button>
								</div>
							}
							description={`➤ ${program?.deleteReason}`}
							style={{ width: '100%', margin: '24px 0' }}
						/>
					)}

					{!program?.isActive && (
						<Alert
							showIcon
							type='warning'
							message='Atenção!'
							description={
								<>
									Para regularizar sua mensalidade acesse{' '}
									<a
										href='https://www.talkradio.com.br/segunda-via-boletos'
										target='_blank'
										rel='noopener noreferrer'
									>
										www.talkradio.com.br/segunda-via-boletos
									</a>{' '}
									e retire as faturas de sua mensalidade. Em seguida, por gentileza, nos encaminhe o
									comprovante para providenciar a baixa no sistema.
									<br />
									<br />
									Atenciosamente, equipe TalkRadio.
								</>
							}
							style={{ width: '100%', margin: '24px 0' }}
						/>
					)}
				</Card>
			</Container>

			<Modal
				visible={!!showDeleteModal}
				title={
					<>
						<Icon type='delete' style={{ marginRight: 8 }} />
						Requisitar exclusão do programa
					</>
				}
				okText='Enviar requisição de exclusão'
				onOk={handleRequestProgramExclusion}
				onCancel={() => !fallback && setShowDeleteModal(false)}
				okButtonProps={{ loading: fallback === 'deleting-program', icon: 'delete' }}
				cancelButtonProps={{
					disabled: fallback === 'deleting-program',
					icon: 'close-circle',
				}}
			>
				<Typography.Text>
					Após requisitar a exclusão desse programa, seu pedido irá passar pela análise de nossos
					administradores e somente após aprovação, o mesmo será apagado.
				</Typography.Text>
				<Divider />

				<Typography.Paragraph>
					Por favor, informe o motivo da exclusão no campo abaixo:
				</Typography.Paragraph>

				<Input.TextArea
					value={deleteReason}
					rows={1}
					placeholder='Informe o motivo da exclusão'
					onChange={({ target: { value } }) => setDeleteReason(value)}
				/>
			</Modal>
		</>
	);
};

export default ProgramCard;
