import React, { useState, useEffect, useCallback } from 'react';
import {
	Breadcrumb,
	Button,
	Card,
	Divider,
	Modal,
	Pagination,
	Select,
	Spin,
	Switch,
	Typography,
	message,
} from 'antd';

import UsersAPI from '../../../../services/sdks/user';
import ProgramsAPI from '../../../../services/sdks/programs';
import SharingsAPI from '../../../../services/sdks/share';
import GenerationSchedules from '../../../../services/sdks/generation-schedules';

import Fallback from '../../../../components/Fallback';
import Meta from '../../../../components/Meta';
import {
	CardHeader,
	Container,
	EditScheduleContainer,
	PaginationContainer,
	ScheduleDay,
	ScheduleGrid,
} from './styles';
import Form from '../../../../components/Form';
import { FiCheckCircle, FiEdit2, FiSave, FiXCircle } from 'react-icons/fi';
import GenerationSchedulesAPI from '../../../../services/sdks/generation-schedules';

const weekLabelsMap = [
	'Domingo',
	'Segunda-Feira',
	'Terça-Feira',
	'Quarta-Feira',
	'Quinta-Feira',
	'Sexta-Feira',
	'Sábado',
];

const ProgramGenerationSchedules = () => {
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: null });
	const [filters, setFilters] = useState({ userId: undefined, programId: undefined });
	const [users, setUsers] = useState([]);
	const [programsOptions, setProgramsOptions] = useState({ programs: [], sharings: [] });
	const [generationSchedules, setGenerationSchedules] = useState([]);

	const [fallback, setFallback] = useState({ initialData: true });
	const [handlingSchedule, setHandlingSchedule] = useState(null);

	useEffect(() => {
		const fetchInitialData = async () => {
			setFallback((prev) => ({ ...prev, true: false }));

			try {
				const {
					data: { users },
				} = await UsersAPI.index(`active=true`);

				setUsers(users);
			} catch (error) {
				message.error('Houve um erro ao buscar os usuários');
			}

			setFallback((prev) => ({ ...prev, initialData: false }));
		};

		fetchInitialData();
	}, []);

	useEffect(() => {
		const fetchGenerationSchedules = async () => {
			setFallback((prev) => ({ ...prev, fetchingGenerationSchedules: true }));

			try {
				const res = await GenerationSchedules.getAll(
					pagination.current - 1,
					pagination.pageSize,
					filters
				);

				setGenerationSchedules(res.data.generationSchedules);
				setPagination((prev) => ({ ...prev, total: res.data.total }));
			} catch (error) {}

			setFallback((prev) => ({ ...prev, fetchingGenerationSchedules: false }));
		};

		fetchGenerationSchedules();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination.current, filters]);

	useEffect(() => {
		const fetchSharings = async () => {
			const response = await SharingsAPI.index(`user=${filters.userId}`);
			return response.data.sharings;
		};

		const fetchPrograms = async () => {
			const response = await ProgramsAPI.index(`userId=${filters.userId}`);
			return response.data.programs;
		};

		const fetchProgramsAndSharings = async () => {
			setFallback((prev) => ({ ...prev, fetchingProgramsAndSharings: true }));

			try {
				const [programs, sharings] = await Promise.all([fetchPrograms(), fetchSharings()]);
				setProgramsOptions({
					programs: programs.map((p) => ({ label: p.name, value: p._id })),
					sharings: sharings.map((s) => ({ label: s.program.name, value: s.program._id })),
				});
			} catch (error) {
				message.error('Houve um erro ao buscar os programas desse usuário');
			}

			setFallback((prev) => ({ ...prev, fetchingProgramsAndSharings: false }));
		};

		if (filters.userId) {
			fetchProgramsAndSharings();
		} else {
			setProgramsOptions({ programs: [], sharings: [] });
			setFilters((prev) => ({ ...prev, programId: undefined }));
		}
	}, [filters.userId]);

	const handleUpdateSchedule = useCallback(async () => {
		setFallback((prev) => ({ ...prev, updatingSchedule: true }));

		try {
			const weekSchedule = handlingSchedule.weekSchedule;
			await GenerationSchedulesAPI.update(handlingSchedule._id, { weekSchedule });

			setGenerationSchedules((prev) =>
				prev.map((gs) => {
					return gs._id === handlingSchedule._id ? { ...gs, weekSchedule } : gs;
				})
			);
			setHandlingSchedule(null);
			message.success('Cronograma atualizado com sucesso');
		} catch (error) {
			message.error('Houve um erro ao atualizar o cronograma');
		}

		setFallback((prev) => ({ ...prev, updatingSchedule: false }));
	}, [handlingSchedule]);

	const renderWeekSchedule = useCallback((weekSchedule) => {
		return (
			<ScheduleGrid>
				{weekLabelsMap.map((label, index) => {
					const daySchedule = weekSchedule.find((item) => item.day === index);

					return (
						<ScheduleDay key={index} isActive={daySchedule.isEnabled}>
							<div>
								{daySchedule.isEnabled ? <FiCheckCircle /> : <FiXCircle />} <strong>{label}</strong>
							</div>
							<small>{daySchedule.isEnabled ? 'Vai ao ar' : 'Não vai ao ar nesse dia'}</small>
						</ScheduleDay>
					);
				})}
			</ScheduleGrid>
		);
	}, []);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor, aguarde...' />;
	}

	return (
		<>
			<Meta title='Configurar Cronogramas de Geração dos Programas' />

			<Container>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[
						{ breadcrumbName: 'PAINEL ADMINISTRATIVO' },
						{ breadcrumbName: 'CONTEÚDOS' },
						{ breadcrumbName: 'CONFIGURAR CRONOGRAMAS DE GERAÇÃO' },
					]}
				/>

				<header>
					<Typography.Title level={2}>Configurar Cronogramas de Geração</Typography.Title>
				</header>

				<Form.Container layout='30% 30% 30%'>
					<Form.Item label='Filtrar por usuário'>
						<Select
							showSearch
							allowClear
							optionFilterProp='children'
							placeholder='Selecione o usuário'
							value={filters?.userId}
							onChange={(userId) => setFilters({ ...filters, userId })}
							filterOption={(input, { props: { _search } }) => {
								const regex = new RegExp(input, 'i');
								return _search.match(regex);
							}}>
							{users.map((user) => {
								const { radioName, city, state, email } = user;

								return (
									<Select.Option
										key={user._id}
										value={user?._id}
										_search={`${radioName}${city}${state}${email}`}>
										{radioName} - {city}/{state} ({email})
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>

					<Spin spinning={fallback?.fetchingProgramsAndSharings === true}>
						<Form.Item label='Filtrar por programa'>
							<Select
								showSearch
								allowClear
								disabled={!filters.userId}
								optionFilterProp='children'
								placeholder='Selecione o programa'
								value={filters?.programId}
								onChange={(programId) => setFilters({ ...filters, programId })}
								filterOption={(input, { props: { _search } }) => {
									const regex = new RegExp(input, 'i');
									return _search.match(regex);
								}}>
								<Select.OptGroup label='PROGRAMAS DO USUÁRIO' _search='PROGRAMAS DO USUÁRIO'>
									{programsOptions.programs.map((op) => (
										<Select.Option value={op.value} key={op.value} _search={op.label}>
											{op.label}
										</Select.Option>
									))}
								</Select.OptGroup>

								<Select.OptGroup label='COMPARTILHADOS' _search='COMPARTILHADOS'>
									{programsOptions.sharings.map((op) => (
										<Select.Option value={op.value} key={op.value} _search={op.label}>
											{op.label}
										</Select.Option>
									))}
								</Select.OptGroup>
							</Select>
						</Form.Item>
					</Spin>
				</Form.Container>

				<Divider />

				{generationSchedules.map((gs) => (
					<Card
						style={{ marginBottom: 24, width: '100%' }}
						key={gs._id}
						title={
							<CardHeader>
								<strong>{gs.programId.name}</strong>
								<span>
									{gs.userId.radioName} - {gs.userId.city}/{gs.userId.state}
								</span>
							</CardHeader>
						}
						extra={
							<Button size='small' type='default' onClick={() => setHandlingSchedule(gs)}>
								Editar Cronograma
							</Button>
						}>
						{renderWeekSchedule(gs.weekSchedule)}
					</Card>
				))}

				<PaginationContainer>
					<Pagination
						onChange={(current) => setPagination({ ...pagination, current })}
						total={pagination.total}
						current={pagination.current}
					/>
				</PaginationContainer>
			</Container>

			<Modal
				visible={handlingSchedule !== null}
				title={
					<>
						<FiEdit2 /> Editar Cronograma de Geração
					</>
				}
				okText={
					<>
						<FiSave /> Salvar alterações
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				closable={!fallback?.updatingSchedule}
				okButtonProps={{ loading: fallback?.updatingSchedule }}
				cancelButtonProps={{ disabled: fallback?.updatingSchedule }}
				onOk={handleUpdateSchedule}
				onCancel={() => setHandlingSchedule(null)}>
				<EditScheduleContainer>
					<div className='info'>
						<span>Programa</span>
						<span>{handlingSchedule?.programId.name}</span>
					</div>

					<div className='info'>
						<span>Usuário</span>
						<span>
							{handlingSchedule?.userId.radioName} - {handlingSchedule?.userId.city}/
							{handlingSchedule?.userId.state}
						</span>
					</div>

					{handlingSchedule && (
						<ul>
							{weekLabelsMap.map((label, index) => {
								const daySchedule = handlingSchedule.weekSchedule.find(
									(item) => item.day === index
								);
								const isEnabled = daySchedule.isEnabled;

								return (
									<li
										key={index}
										onClick={() =>
											setHandlingSchedule((prev) => ({
												...prev,
												weekSchedule: prev.weekSchedule.map((v) =>
													daySchedule.day === v.day ? { ...v, isEnabled: !isEnabled } : v
												),
											}))
										}>
										<Switch checked={isEnabled} />
										<div>
											{label}
											<small>{isEnabled ? 'Vai ao ar' : 'Não vai ao ar nesse dia'}</small>
										</div>
									</li>
								);
							})}
						</ul>
					)}
				</EditScheduleContainer>
			</Modal>
		</>
	);
};

export default ProgramGenerationSchedules;
